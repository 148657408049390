import copy from 'copy-to-clipboard';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getEmbedUrlRoot } from '../../lib/env';
import { getPlayerName } from '../../lib/player';
import { useFakeContinuatedQueryResult } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Permission, Team } from '../../lib/types';
import { Button, DangerButton } from '../Buttons';
import Toggle from '../inputs/Toggle';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { ActionModal, ActionModalButtons, ConfirmModal } from '../Modals';
import { HasTeamPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import Coins from '../widgets/Coins';
import { Table, TablePagination, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';

const useTeamLeaderboard = (teamId: string) => {
  const repo = useRepo();
  return useQuery(['team-leaderboard', teamId], () => repo.getTeamLeaderboard(teamId), { staleTime: 60 * 1000 });
};

const useOptionsMutation = (team: Team) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation((data: { enabled: boolean; anonymous: boolean }) => repo.updateTeamLeaderboardOptions(team.id, data), {
    onMutate: (variables) => {
      const ctx = _.cloneDeep(team.leaderboard);
      queryClient.setQueryData(['team', team.id], { ...team, leaderboard: variables });
      return ctx;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['team', team.id]);
    },
    onError: (err, variables, ctx: Team['leaderboard']) => {
      queryClient.setQueryData(['team', team.id], { ...team, leaderboard: ctx });
    },
  });
};

const TeamLeaderboardPage = () => {
  return (
    <TeamPageLayout>
      <TeamLeaderboardPageContent />
    </TeamPageLayout>
  );
};
const TeamLeaderboardPageContent = () => {
  const { t } = useTranslation();
  const { team } = useTeamPage();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const query = useFakeContinuatedQueryResult(useTeamLeaderboard(team.id), 20);
  const resetMutation = useMutation(() => repo.resetTeamLeaderboard(team.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['team-leaderboard', team.id]);
      broadcastSuccessToast(t('leaderboardHasBeenReset'));
    },
  });

  const [showEmbed, setShowEmbed] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const optionsMutation = useOptionsMutation(team);

  const handleConfirmReset = () => {
    setShowReset(false);
    resetMutation.mutate();
  };

  const handleEmbedCopy = (snippet: string) => {
    setShowEmbed(false);
    copy(snippet);
  };

  const makeOptionChangeHander = (key: 'anonymous' | 'enabled') => (enabled?: boolean) => {
    optionsMutation.mutate({
      anonymous: Boolean(team.leaderboard?.anonymous),
      enabled: Boolean(team.leaderboard?.enabled),
      [key]: enabled,
    });
  };

  return (
    <>
      <HasTeamPermission teamId={team.id} perm={Permission.ManageLeaderboard}>
        <div className="border-b border-gray-100 pb-2 mb-4">
          <div className="mb-4 flex items-center">
            <h2 className="text-xl leading-7 font-semibold flex-grow">{t('settings')}</h2>
            <div className="flex items-center space-x-3">
              {team.leaderboard?.enabled ? <Button onClick={() => setShowEmbed(true)}>{t('embedCode')}</Button> : null}
              <DangerButton onClick={() => setShowReset(true)}>{t('resetLeaderboard')}</DangerButton>
            </div>
          </div>
          <div className="my-4">
            <div className="flex space-x-10">
              <div>
                <Toggle enabled={team.leaderboard?.enabled} onChange={makeOptionChangeHander('enabled')} label={t('enabled')} />
              </div>
              <div>
                <Toggle
                  enabled={team.leaderboard?.anonymous}
                  onChange={makeOptionChangeHander('anonymous')}
                  label={t('anonymous')}
                />
              </div>
            </div>
          </div>
        </div>
      </HasTeamPermission>
      <div>
        <h2 className="my-4 text-xl leading-7 font-semibold flex-grow">{t('Ranking')}</h2>
        <Table>
          <Thead>
            <Th>{t('rank')}</Th>
            <Th>{t('name')}</Th>
            <Th>{t('score')}</Th>
          </Thead>
          <Tbody>
            {query.isLoading ? (
              <Tr>
                <Td colSpan={3}>{t('loadingEllipsis')}</Td>
              </Tr>
            ) : null}
            {query.isSuccess && query.data ? (
              query.data.length ? (
                query.data.map((entry) => (
                  <Tr>
                    <TdPrimary>{entry.rank}</TdPrimary>
                    <TdPrimary to={`/player/${entry.user.id}`}>{getPlayerName(entry.user)}</TdPrimary>
                    <Td>{entry.value.toLocaleString()}</Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={3}>{t('noEntriesEllipsis')}</Td>
                </Tr>
              )
            ) : null}
          </Tbody>
        </Table>
        {query.isSuccess && (query.data?.length || 0) > 0 ? (
          <TablePagination
            showingFrom={query.showingFrom}
            showingTo={query.showingTo}
            showingOfTotal={query.showingOfTotal}
            hasNextPage={query.hasNextPage}
            hasPreviousPage={query.hasPreviousPage}
            onNextPageClick={query.fetchNextPage}
            onPreviousPageClick={query.fetchPreviousPage}
          />
        ) : null}
      </div>

      {showReset ? (
        <ConfirmModal
          danger
          message={t('confirmResetLeaderboardThisCannotBeUndone')}
          title={t('resetLeaderboard')}
          onConfirm={handleConfirmReset}
          onCancel={() => setShowReset(false)}
        ></ConfirmModal>
      ) : null}

      {showEmbed ? <EmbedModal teamId={team.id} onClose={() => setShowEmbed(false)} onCopy={handleEmbedCopy} /> : null}
    </>
  );
};

const EmbedModal = ({ teamId, onCopy, onClose }: { teamId: string; onClose: () => void; onCopy: (snippet: string) => void }) => {
  const { t } = useTranslation();
  const iframeUrl = `${getEmbedUrlRoot()}/leaderboard?section=${teamId}`;
  const snippet = `<iframe src="${iframeUrl}" style="border: 0; width: 100%; height: 400px;"></iframe>`;
  return (
    <ActionModal title={t('Embed code')} onCancel={onClose}>
      <>
        <div>
          <p className="text-sm">{t('copyPasteToEmbedLeaderboard')}</p>
          <div className="w-full mt-2">
            <textarea
              readOnly
              className="w-full px-2 py-2 rounded border border-gray-300 shadow-sm text-sm h-24"
              onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => {
                (e.target as HTMLTextAreaElement).select();
                e.preventDefault();
              }}
              onFocus={(e) => {
                e.target.select();
                e.preventDefault();
              }}
              value={snippet}
            ></textarea>
          </div>
        </div>
        <ActionModalButtons onCancel={onClose} confirmButtonText={t('copyCode')} onConfirm={() => onCopy(snippet)} />
      </>
    </ActionModal>
  );
};

export default TeamLeaderboardPage;
