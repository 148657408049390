import { PlusIcon } from '@heroicons/react/24/solid';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
  useFiltering,
  usePermissionChecker,
  useSelection,
  UseSelectionResult,
  useSorting,
  UseSortingResult,
} from '../../lib/hooks';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Permission, Team } from '../../lib/types';
import { PrimaryButton } from '../Buttons';
import SearchInput from '../inputs/Search';
import StorePageLayout, { useStorePage } from '../layouts/StorePage';
import { ConfirmModal } from '../Modals';
import { HasStorePermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import Dropdown, { DropdownOption } from '../widgets/Dropdown';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdCheckbox,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThCheckbox,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';

const useStoreTeams = (storeId: string, searchTerm?: string, orderBy?: string) => {
  const repo = useRepo();
  return useContinuatedQuery(
    ['store-teams', storeId, { searchTerm, orderBy }],
    ({ pageParam }) => {
      return repo.getStoreTeams(storeId, { term: searchTerm }, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, storeId],
    }
  );
};

const useStoreTeamsRemoveMutation = (storeId: string) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { t } = useTranslation();
  return useMutation(({ teamIds }: { teamIds: string[] }) => repo.removeTeamsFromStore(teamIds, storeId), {
    onSuccess: (data, { teamIds }) => {
      // TODO Optimistic delete.
      queryClient.invalidateQueries(['store', storeId]);
      queryClient.invalidateQueries(['store-teams', storeId]);
      queryClient.invalidateQueries(['stores']);
      teamIds.forEach((teamId) => {
        queryClient.invalidateQueries(['team', teamId]);
        queryClient.invalidateQueries(['team-stores', teamId]);
      });
      queryClient.invalidateQueries(['teams']);
      broadcastSuccessToast(t('teamRemoved', { count: teamIds.length }));
    },
    onError: (err, variables, context: any) => {
      // TODO Display message.
    },
  });
};

const StoreTeamsPageContent = () => {
  const { t } = useTranslation();
  const { store } = useStorePage();

  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', { name: false });
  const query = useStoreTeams(store.id, filterTerm, sorting.sortField);
  const selection = useSelection(query);
  const [toRemoveItems, setToRemoveItems] = useState<string[]>([]);
  const removeMutation = useStoreTeamsRemoveMutation(store.id);
  const permChecker = usePermissionChecker();

  const handleRemove = () => {
    if (!toRemoveItems.length) {
      return;
    }
    setToRemoveItems([]);

    removeMutation.mutate(
      { teamIds: toRemoveItems },
      {
        onSuccess: () => {
          selection.removeFromSelection(toRemoveItems);
        },
      }
    );
  };

  const actions = [
    {
      label: t('remove'),
      can: permChecker.hasStorePermission(store.id, Permission.ManageStoreTeam),
      onClick: (ids: string[]) => setToRemoveItems(ids),
    },
  ].filter((action) => isUndefined(action.can) || action.can);

  const noContent = !store.team_ids.length;

  return (
    <>
      {noContent ? null : (
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            {actions.length ? (
              <div>
                <Dropdown
                  label={t('actions')}
                  options={actions.map((action) => ({
                    ...action,
                    disabled: !selection.selectedIds.length,
                    onClick: () => action.onClick(selection.selectedIds),
                  }))}
                />
              </div>
            ) : null}
            <div className="w-96">
              <SearchInput {...filterInputProps} />
            </div>
          </div>
        </div>
      )}

      {noContent ? (
        <ContentNoItems />
      ) : (
        <div>
          <TeamsTable actions={actions} queryResult={query} selectionResult={selection} sortingResult={sorting} />
        </div>
      )}

      {toRemoveItems.length ? (
        <ConfirmModal
          onConfirm={handleRemove}
          onCancel={() => setToRemoveItems([])}
          title={t('removeTeam', { count: toRemoveItems.length })}
          message={<Trans t={t} i18nKey="removeTeamConfirm" count={toRemoveItems.length} />}
          confirmButtonText={t('remove')}
          danger={false}
        />
      ) : null}
    </>
  );
};

const StoreTeamsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <StorePageLayout
      buttons={(store) => (
        <HasStorePermission storeId={store.id} perm={Permission.ManageStoreTeam}>
          <PrimaryButton onClick={() => history.push(`/store/${store.id}/add-team`)} icon={PlusIcon}>
            {t('addTeam', { count: 1 })}
          </PrimaryButton>
        </HasStorePermission>
      )}
    >
      <StoreTeamsPageContent />
    </StorePageLayout>
  );
};

type TeamsTableProps = {
  actions: (Omit<DropdownOption, 'onClick'> & { onClick: (userId: string[]) => void })[];
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Team>>;
  sortingResult: UseSortingResult;
  selectionResult: UseSelectionResult;
};

function TeamsTable({ actions, queryResult, selectionResult, sortingResult }: TeamsTableProps) {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = queryResult;
  const { onSelectAllChange, isEntirePageSelected, onSelectionChange, selectedIds } = selectionResult;

  return (
    <>
      <Table>
        <Thead>
          {actions.length ? <ThCheckbox onChange={onSelectAllChange} checked={isEntirePageSelected} /> : null}
          <ThSortableWithSorting sortKey="name" sortingResult={sortingResult}>
            {t('name')}
          </ThSortableWithSorting>
          {/* <Th>{t('market')}</Th> */}
          {actions.length ? <Th /> : null}
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={5}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess && data
            ? data.items.map((item) => (
                <TeamRow
                  team={item}
                  key={item.id}
                  onSelectChange={() => onSelectionChange(item)}
                  selected={selectedIds.includes(item.id)}
                  actions={actions.map((action) => ({
                    ...action,
                    onClick: () => action.onClick && action.onClick([item.id]),
                  }))}
                />
              ))
            : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={queryResult.showingFrom}
          showingTo={queryResult.showingTo}
          showingOfTotal={queryResult.showingOfTotal}
          hasNextPage={queryResult.hasNextPage}
          hasPreviousPage={queryResult.hasPreviousPage}
          onNextPageClick={queryResult.fetchNextPage}
          onPreviousPageClick={queryResult.fetchPreviousPage}
        />
      ) : null}
    </>
  );
}

const TeamRow: React.FC<{
  team: Team;
  onSelectChange: () => void;
  selected: boolean;
  actions: DropdownOption[];
}> = ({ team, onSelectChange, selected, actions }) => {
  return (
    <Tr key={team.id}>
      {actions.length ? <TdCheckbox checked={selected} onChange={onSelectChange} /> : null}
      <TdPrimary to={`/team/${team.id}`}>{team.name}</TdPrimary>
      {/* <Td>
        <ShorteningTagList component={StoreTag} tags={team.stores.map((s) => s.name)} />
      </Td> */}
      {actions.length ? <TdContextualMenu options={actions} /> : null}
    </Tr>
  );
};

const ContentNoItems = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { store } = useStorePage();
  return (
    <div className="flex flex-grow justify-center items-center text-center py-4">
      <div className="max-w-xs pb-10 text-sm">
        <h2 className="font-medium mb-1">{t('noTeams')}</h2>
        <HasStorePermission storeId={store.id} perm={Permission.ManageStoreTeam}>
          <p>{t('startByAddingATeamToThisStore')}</p>
          <div className="mt-6 flex justify-center space-x-4">
            <PrimaryButton large onClick={() => history.push(`/store/${store.id}/add-team`)}>
              {t('addTeam', { count: 1 })}
            </PrimaryButton>
          </div>
        </HasStorePermission>
      </div>
    </div>
  );
};

export default StoreTeamsPage;
