import { max } from 'date-fns';
import { Field, FieldArray, Form, FormikProps } from 'formik';
import { useMemo, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { hasTicketsEnabled, useAccount } from '../../lib/account';
import { getL10nValue, getLocaleName, isL10nArrayMultiLang, LocaleDefinition, setL10nValue } from '../../lib/l10n';
import { Item, ItemDrawMethod, ItemType, Product, RedemptionMethod } from '../../lib/types';
import { classNames, getItemDrawMethodStringKey, getItemTypeStringKey, getRedemptionMethodStringKey } from '../../lib/utils';
import { AnchorButton, Button, PrimaryButton } from '../Buttons';
import AddImageIcon from '../icons/AddImageIcon';
import Checkbox from '../inputs/Checkbox';
import FieldError from '../inputs/FieldError';
import Input from '../inputs/Input';
import Textarea from '../inputs/Textarea';
import { HasPolicy } from '../Policy';
import { DateTimePicker } from '../widgets/DateRangePicker';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';
import { ImageFileBase } from '../widgets/ImageFile';
import ItemLocaleSelect from '../widgets/ItemLocaleSelect';
import HelpText from './HelpText';

const methodsIncompatibleWithVariants = [RedemptionMethod.Download, RedemptionMethod.Voucher];

export const itemVariantSchema = yup
  .object({
    label: yup.string().required(),
    sku: yup.string(),
    num_items: yup.number().min(0).required(),
    num_items_unlimited: yup.boolean().required(),
  })
  .required();

export const itemValidationSchema = yup
  .object({
    type: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    start_time: yup.date().required(),
    end_time: yup
      .date()
      .required()
      .when('start_time', (startTime, schema) => (yup.date().isType(startTime) ? schema.min(startTime) : schema))
      .test('is-future', 'The end date should be in the future.', (endTime) => (endTime ? endTime > new Date() : false)),
    image_file: yup
      .mixed()
      .required()
      .test('max-file-size', 'File is too big', (file) => (file ? file.size < 1024 * 1024 * 2 : false)),
    redemption_method: yup.string().required(),
    sku: yup.string(),
  })
  .required()
  .when((data, schema) => {
    if (data.type === ItemType.Purchase) {
      return schema.concat(itemPurchaseSchema);
    } else if (data.type === ItemType.Raffle) {
      return schema.concat(itemRaffleSchema);
    } else if (data.type === ItemType.Sweepstakes) {
      return schema.concat(itemSweepstakesSchema);
    } else if (data.type === ItemType.Auction) {
      return schema.concat(itemAuctionSchema);
    } else if (data.type === ItemType.Contribution) {
      return schema.concat(itemContributionSchema);
    }
    return schema;
  })
  .when((data, schema) => {
    if (data.redemption_method === RedemptionMethod.Self) {
      return schema.concat(selfRedemptionSchema);
    } else if (data.redemption_method === RedemptionMethod.Download) {
      return schema.concat(downloadRedemptionSchema);
    } else if (data.redemption_method === RedemptionMethod.Shipping) {
      return schema.concat(shippingRedemptionSchema);
    }
    return schema;
  })
  .when((data, schema) => {
    if (data.type === ItemType.Purchase && data.variants.length) {
      let additional = {};
      let variantSchema = itemVariantSchema;
      if (data.redemption_method === RedemptionMethod.Shipping) {
        additional = { sku: yup.string() };
        variantSchema = itemVariantSchema.concat(shippingRedemptionSchema);
      }
      return schema.shape({
        ...additional,
        variants: yup.array().of(variantSchema).min(2).max(10).required(),
      });
    }
    return schema;
  });

const itemPurchaseSchema = yup
  .object({
    num_items: yup.number().min(0).required(),
    num_items_unlimited: yup.boolean().required(),
    cost: yup.number().min(1).required(),
    variants: yup.array().of(itemVariantSchema),
  })
  .required();

const itemRaffleSchema = yup
  .object({
    num_items: yup.number().min(1).required(),
    cost: yup.number().min(1).required(),
    draw_method: yup.mixed().oneOf(Object.values(ItemDrawMethod)),
  })
  .required();

const itemSweepstakesSchema = yup
  .object({
    num_items: yup.number().min(1).required(),
    cost: yup.number().min(1),
    draw_method: yup.mixed().oneOf(Object.values(ItemDrawMethod)).required(),
  })
  .required();

const itemAuctionSchema = yup
  .object({
    cost: yup.number().min(1).required(), // Opening bid.
    minimum_bid: yup.number().min(1).required(),
    handling_fee: yup.number().min(0).required(),
  })
  .required();

const itemContributionSchema = yup
  .object({
    contrib_goal: yup.number().min(1).required(),
    contrib_currency_symbol: yup.string().max(4),
    contrib_currency_value: yup.number().min(1),
  })
  .required();

const selfRedemptionSchema = yup.object({
  self_redeem_message: yup.string().min(10).max(500).required(),
});

const shippingRedemptionSchema = yup.object({
  sku: yup.string().required(),
});

const downloadRedemptionSchema = yup.object({
  download_file: yup
    .mixed()
    .required()
    .test('max-file-size', 'File is too big', (file) => (file ? file.size < 1024 * 1024 * 10 : false)),
  download_filename: yup
    .string()
    .matches(/^.+\.[a-z0-9]+$/i)
    .required(),
});

export const itemEditValidationSchema = yup
  .object({
    type: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
    start_time: yup.date().required(),
    end_time: yup
      .date()
      .required()
      .when('start_time', (startTime, schema) => (yup.date().isType(startTime) ? schema.min(startTime) : schema))
      .test('is-future', 'The end date should be in the future.', (endTime) => (endTime ? endTime > new Date() : false)),
    image_file: yup.mixed().test('max-file-size', 'File is too big', (file) => (file ? file.size < 1024 * 1024 * 2 : true)),
    redemption_method: yup.string().required(),
    sku: yup.string(),
  })
  .required()
  .when((data, schema) => {
    if (data.type === ItemType.Purchase) {
      return schema.concat(itemPurchaseSchema);
    } else if (data.type === ItemType.Raffle) {
      return schema.concat(itemRaffleSchema);
    } else if (data.type === ItemType.Sweepstakes) {
      return schema.concat(itemSweepstakesSchema);
    } else if (data.type === ItemType.Auction) {
      return schema.concat(itemAuctionSchema);
    } else if (data.type === ItemType.Contribution) {
      return schema.concat(itemContributionSchema);
    }
    return schema;
  })
  .when((data, schema) => {
    if (data.redemption_method === RedemptionMethod.Self) {
      return schema.concat(selfRedemptionSchema);
    } else if (data.redemption_method === RedemptionMethod.Download) {
      return schema.concat(downloadRedemptionEditSchema);
    } else if (data.redemption_method === RedemptionMethod.Shipping) {
      return schema.concat(shippingRedemptionSchema);
    }
    return schema;
  })
  .when((data, schema) => {
    if (data.type === ItemType.Purchase && data.variants.length) {
      let additional = {};
      let variantSchema = itemVariantSchema;
      if (data.redemption_method === RedemptionMethod.Shipping) {
        additional = { sku: yup.string() };
        variantSchema = itemVariantSchema.concat(shippingRedemptionSchema);
      }
      return schema.shape({
        ...additional,
        variants: yup.array().of(variantSchema).min(2).max(10).required(),
      });
    }
    return schema;
  });

const downloadRedemptionEditSchema = yup.object({
  download_file: yup.mixed().test('max-file-size', 'File is too big', (file) => (file ? file.size < 1024 * 1024 * 10 : true)),
  download_filename: yup
    .string()
    .matches(/^.+\.[a-z0-9]+$/i)
    .required(),
});

export type ItemFormValues = {
  type: ItemType;

  name: string;
  description: string;

  image_file: undefined | File | null;

  start_time: Date;
  end_time: Date;
  sku: string;

  cost: number;
  num_items: number;
  num_items_unlimited: boolean;

  handling_fee: number;
  minimum_bid: number;

  contrib_goal: number;
  contrib_currency_symbol: string;
  contrib_currency_value: number;

  draw_method: ItemDrawMethod;

  redemption_method: RedemptionMethod;

  self_redeem_message: string;

  download_file: undefined | File | null;
  download_filename: string;

  variants: { label: string; sku: string; num_items: number; num_items_unlimited: boolean; l10n: {} }[];

  l10n: {
    [index: string]: {
      locale: string;
      name?: string;
      description?: string;
      self_redeem_message?: string;
    };
  };
};

type ItemFormProps = {
  formik: FormikProps<ItemFormValues>;
  item?: Item | Product;
  onCancel?: () => void;

  locale?: string; // The current locale.
  locales: LocaleDefinition[]; // The available locales.
  onLocaleChange: (tag?: string) => void; // When the locale is changed.
  defaultLocale?: string; // The default locale.
};

export const ItemForm = ({ formik, locale, locales, defaultLocale, onLocaleChange, item, onCancel }: ItemFormProps) => {
  const account = useAccount();
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;
  const isEdit = Boolean(item);

  const forceVariants = isEdit && item?.doc_type === 'product';
  const isOrigMultiLang = item && isL10nArrayMultiLang(item.l10n, defaultLocale);
  const isMultiLang = Boolean(locale);

  const getLocalisedName = (property: keyof typeof values) => {
    return !isMultiLang || locale === defaultLocale ? property : `l10n.${locale}.${property}`;
  };

  const getLocalisedStateValue = (property: keyof typeof values) => {
    if (!locale) {
      return values[property];
    }
    // When the locale is the default locale, we permit the fallback on the item's property itself
    // because there may not have been a translation for this particular locale prior, or the default
    // locale was just set.
    return getL10nValue(values, property, locale, locale === defaultLocale) || '';
  };

  const setLocalisedStateValue = (property: keyof typeof values, value: string) => {
    if (!locale) {
      // When there is no locale being used, we save the property on the default locale (if any)
      // so that when we switch from not localised item, to a multi-language item, the default
      // language is populated with the right keys.
      setFieldValue(property, value);
      return setFieldValue('l10n', setL10nValue(values, property, value, defaultLocale).l10n);
    }

    // When the default locale is the locale passed, then we also set the property on the object
    // itself. This allows for the object to contain its own properties, while also keeping
    // track of the translations themselves. Keeping a record of the translations helps when
    // we switch to another default locale for the section, as translations won't be lost, and
    // we won't need to juggle with extracting the object's properties into the l10n property
    // and vice-versa as the default locale of the object changes.
    if (locale === defaultLocale) {
      setFieldValue(property, value);
    }
    setFieldValue('l10n', setL10nValue(values, property, value, locale).l10n);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    setFieldValue('download_file', file ?? null);
    if (file) {
      setFieldValue('download_filename', file.name);
    }
  };

  const handleTypeChange = useCallback(
    (v: DropdownSelectOption) => {
      const type = v.value;
      setFieldValue('type', type);
      if (item) {
        return;
      }

      // Change the default draw method when the item is being created.
      if (type === ItemType.Raffle && values.draw_method !== ItemDrawMethod.Auto) {
        setFieldValue('draw_method', ItemDrawMethod.Auto);
      } else if (type === ItemType.Sweepstakes && values.draw_method !== ItemDrawMethod.Manual) {
        setFieldValue('draw_method', ItemDrawMethod.Manual);
      }
    },
    [setFieldValue, item, values.draw_method]
  );

  const handleToUppercase = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.value.toUpperCase());
  };

  const itemTypes = useMemo(
    () =>
      [ItemType.Purchase, ItemType.Raffle, ItemType.Auction, ItemType.Contribution]
        .concat(hasTicketsEnabled(account) ? [ItemType.Sweepstakes] : [])
        .map((type) => ({
          value: type,
          label: t(getItemTypeStringKey(type)),
        })),
    [t, account]
  );

  const drawMethods = useMemo(
    () =>
      Object.values(ItemDrawMethod).map((method) => ({
        value: method,
        label: t(getItemDrawMethodStringKey(method)),
      })),
    [t]
  );

  const canUseVariants = !isEdit || item?.doc_type === 'product' || forceVariants;
  const hasVariants = canUseVariants && values.type === ItemType.Purchase && values.variants.length > 0;
  const redemptionMethods = useMemo(
    () =>
      [
        RedemptionMethod.Request,
        RedemptionMethod.Self,
        RedemptionMethod.Download,
        ...(account?.redemption_methods || []).filter((method) => Object.values(RedemptionMethod).includes(method)),
      ].map((method) => ({
        value: method,
        label: t(getRedemptionMethodStringKey(method)),
        disabled: methodsIncompatibleWithVariants.includes(method) && hasVariants,
      })),
    [hasVariants] // eslint-disable-line
  );

  useEffect(() => {
    const allowedRedemptionMethods = redemptionMethods.map((r) => r.value);
    if (!allowedRedemptionMethods.includes(formik.values.redemption_method)) {
      formik.setFieldValue('redemption_method', redemptionMethods[0].value);
    }
  }, [redemptionMethods]); // eslint-disable-line

  const canRemoveVariants = !forceVariants || values.variants.length > 2;
  const canSubmit = (formik.dirty || isOrigMultiLang !== isMultiLang) && !formik.isSubmitting;

  return (
    <Form className="flex flex-col flex-grow">
      <div className="flex flex-grow justify-between">
        <div className="flex-shrink-0 w-128">
          {/* <h2 className="font-semibold text-xl mb-3">{t('editItem')}</h2> */}
          <div className="space-y-6">
            <div>
              <div className="block leading-6 mb-1 text-gray-700 text-sm font-medium">{t('type')}</div>
              {isEdit ? <div className="text-sm">{t(getItemTypeStringKey(values.type))}</div> : null}
              {!isEdit ? (
                <div>
                  <DropdownSelect
                    options={itemTypes}
                    selected={itemTypes.find((type) => type.value === values.type)}
                    placeholder={t('selectEllipsis')}
                    onChange={handleTypeChange}
                  />
                </div>
              ) : null}
            </div>
            {locales.length > 1 ? (
              <div>
                <div className="leading-5 mb-3 text-gray-700 text-sm font-medium">{t('language')}</div>
                <div className="text-sm">
                  <label>
                    <Checkbox
                      checked={isMultiLang}
                      onChange={(e) => onLocaleChange(e.target.checked ? defaultLocale : undefined)}
                    />
                    <span className="ml-3">{t('multiLangItem')}</span>
                  </label>
                </div>
              </div>
            ) : null}
            <div>
              <div className="flex items-center leading-6 mb-1 text-gray-700 text-sm font-medium">
                <label>{t('name')}</label>
                {locale ? (
                  <div className="ml-2">
                    <ItemLocaleSelect selected={locale} locales={locales} onChange={onLocaleChange} />
                  </div>
                ) : null}
              </div>
              <div>
                <Input
                  name={getLocalisedName('name')}
                  value={getLocalisedStateValue('name')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocalisedStateValue('name', e.target.value)}
                  onBlur={formik.handleBlur}
                />
                <FieldError name={getLocalisedName('name')} />
              </div>
            </div>
            <div>
              <label>
                <div className="flex items-center leading-6 mb-1 text-gray-700 text-sm font-medium">
                  {t('description')}
                  <LocaleTag locale={locale} />
                </div>
                <div>
                  <Textarea
                    name={getLocalisedName('description')}
                    value={getLocalisedStateValue('description')}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setLocalisedStateValue('description', e.target.value)}
                    onBlur={formik.handleBlur}
                  />
                  <FieldError name={getLocalisedName('description')} />
                </div>
              </label>
            </div>

            <If value={values.type} is={ItemType.Purchase}>
              <div>
                <label className="block leading-5 mb-1 text-gray-700 text-sm font-medium" htmlFor="store-add-item-num-items">
                  {t('numberOfItems')}
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Field
                      as={Input}
                      name="num_items"
                      id="store-add-item-num-items"
                      disabled={values.num_items_unlimited || hasVariants}
                      type="number"
                      min={1}
                    />
                  </div>
                  <label className="flex items-center text-gray-700 text-sm font-medium">
                    <Field type="checkbox" as={Checkbox} name="num_items_unlimited" disabled={hasVariants} />
                    <div className="ml-3">{t('unlimited')}</div>
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('cost')}</div>
                  <div>
                    <Field as={Input} name="cost" type="number" min={1} />
                  </div>
                </label>
              </div>
            </If>

            <If value={values.type} is={ItemType.Raffle}>
              <div className="grid grid-cols-2 gap-4">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('numberOfItems')}</div>
                  <div className="flex items-center">
                    <Field as={Input} name="num_items" type="number" min={1} />
                  </div>
                </label>
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('cost')}</div>
                  <div>
                    <Field as={Input} name="cost" type="number" min={1} />
                  </div>
                </label>
              </div>
            </If>

            <If value={values.type} is={ItemType.Sweepstakes}>
              <div className="grid grid-cols-2 gap-4">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('numberOfItems')}</div>
                  <div className="flex items-center">
                    <Field as={Input} name="num_items" type="number" min={1} />
                  </div>
                </label>
                <HasPolicy policy="use_tickets_cost">
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('cost')}</div>
                    <div>
                      <Field as={Input} name="cost" type="number" min={1} />
                    </div>
                  </label>
                </HasPolicy>
              </div>
            </If>

            <If value={values.type} is={ItemType.Auction}>
              <div className="grid grid-cols-2 gap-4">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('auction.openingBid')}</div>
                  <div className="flex items-center">
                    <Field as={Input} name="cost" type="number" min={1} />
                  </div>
                </label>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('auction.bidIncrement')}</div>
                  <div className="flex items-center">
                    <Field as={Input} name="minimum_bid" type="number" min={1} />
                  </div>
                </label>
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('auction.bidFee')}</div>
                  <div className="flex items-center">
                    <Field as={Input} name="handling_fee" type="number" min={0} />
                  </div>
                </label>
              </div>
            </If>

            <If value={values.type} is={ItemType.Contribution}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('contribution.goal')}</div>
                    <div className="flex items-center">
                      <Field as={Input} name="contrib_goal" type="number" min={1} />
                    </div>
                  </label>
                  <HelpText>{t('contribution.goal_help')}</HelpText>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('contribution.currency_symbol')}</div>
                    <div className="flex items-center">
                      <Field as={Input} name="contrib_currency_symbol" maxLength={4} min={1} />
                    </div>
                  </label>
                  <HelpText>{t('contribution.currency_symbol_help')}</HelpText>
                </div>
                <div>
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('contribution.currency_value')}</div>
                    <div className="flex items-center">
                      <Field
                        as={Input}
                        name="contrib_currency_value"
                        type="number"
                        min={1}
                        disabled={!(values.contrib_currency_symbol || '').trim().length}
                      />
                    </div>
                  </label>
                  <HelpText>{t('contribution.currency_value_help')}</HelpText>
                </div>
              </div>
            </If>

            <div className="flex space-x-4">
              <div className="flex-1">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('startDate')}</div>
                  <DateTimePicker
                    name="start_time"
                    value={values.start_time}
                    onBlur={() => formik.setFieldTouched('start_time')}
                    onChange={(date) => setFieldValue('start_time', date)}
                    className="block w-full text-sm border border-gray-300 rounded-md shadow-sm"
                  />
                  <FieldError name="start_time" />
                </label>
              </div>
              <div className="flex-1">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('endDate')}</div>
                  <div>
                    <DateTimePicker
                      name="end_time"
                      value={values.end_time}
                      minDate={max([values.start_time, new Date()])}
                      onBlur={() => formik.setFieldTouched('end_time')}
                      onChange={(date) => setFieldValue('end_time', date)}
                      className="block w-full text-sm border border-gray-300 rounded-md shadow-sm"
                    />
                    <FieldError name={'end_time'} />
                  </div>
                </label>
              </div>
            </div>

            <IfOneOf value={values.type} of={[ItemType.Raffle, ItemType.Sweepstakes]}>
              <div>
                <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('drawMethod')}</div>
                <div>
                  <DropdownSelect
                    options={drawMethods}
                    selected={drawMethods.find((method) => method.value === values.draw_method)}
                    placeholder={t('selectEllipsis')}
                    onChange={(v) => setFieldValue('draw_method', v.value)}
                    origin="bottom"
                  />
                </div>
              </div>
            </IfOneOf>

            <div>
              <label htmlFor="store-add-item-image" className="block leading-5 mb-1 text-gray-700 text-sm font-medium">
                {t('image')}
              </label>
              <div>
                <ImageFileBase
                  name="image_file"
                  url={item?.image_url}
                  file={values.image_file}
                  onChange={(f) => {
                    formik.setFieldTouched('image_file');
                    setFieldValue('image_file', f);
                  }}
                  accept="image/png,image/jpeg,image/gif"
                  className="h-48 w-48"
                  id="store-add-item-image"
                >
                  {({ hasImage, imageSrc, changeAnchorProps, removeAnchorProps }) => {
                    return (
                      <div className="flex-grow flex flex-col items-center">
                        <div>
                          {hasImage ? (
                            <img src={imageSrc} alt="" className={classNames('max-w-24 max-h-24')} />
                          ) : (
                            <AddImageIcon className="h-9 w-9 text-gray-400" />
                          )}
                        </div>
                        <div className="flex-grow text-sm mt-3 text-center">
                          <p>
                            {hasImage ? (
                              isEdit ? (
                                <a className="text-blue-600" href="#change" {...changeAnchorProps}>
                                  {t('change')}
                                </a>
                              ) : (
                                <Trans
                                  t={t}
                                  i18nKey="changeOrRemove"
                                  components={[
                                    <a className="text-blue-600" href="#change" {...changeAnchorProps} />, // eslint-disable-line
                                    <a className="text-blue-600" href="#remove" {...removeAnchorProps} />, // eslint-disable-line
                                  ]}
                                />
                              )
                            ) : (
                              <a className="text-blue-600 font-medium" href="#upload" {...changeAnchorProps}>
                                {t('uploadAFile')}
                              </a>
                            )}
                          </p>
                          <p className="text-xs text-gray-500 mt-1">{t('itemImageSpecs')}</p>
                        </div>
                      </div>
                    );
                  }}
                </ImageFileBase>
                <FieldError name={'image_file'} />
              </div>
            </div>

            <IfNot value={values.type} is={ItemType.Contribution}>
              <div>
                <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('redemptionMethod')}</div>
                <div>
                  <DropdownSelect
                    options={redemptionMethods}
                    selected={redemptionMethods.find((method) => method.value === values.redemption_method)}
                    placeholder={t('selectEllipsis')}
                    onChange={(v) => setFieldValue('redemption_method', v.value)}
                    origin="bottom"
                  />
                </div>
              </div>

              <If value={values.redemption_method} is={RedemptionMethod.Self}>
                <div>
                  <label>
                    <div className="flex items-center leading-6 mb-1 text-gray-700 text-sm font-medium">
                      {t('messageAfterRedemption')}
                      <LocaleTag locale={locale} />
                    </div>
                    <div>
                      <Textarea
                        name={getLocalisedName('self_redeem_message')}
                        value={getLocalisedStateValue('self_redeem_message')}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setLocalisedStateValue('self_redeem_message', e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        maxLength={500}
                      />
                      <FieldError name={getLocalisedName('self_redeem_message')} />
                    </div>
                  </label>
                </div>
              </If>

              <If value={values.redemption_method} is={RedemptionMethod.Download}>
                <>
                  <div>
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('digitalDownloadFile')}</div>
                      <div className="text-sm">
                        <input type="file" name="download_file" onChange={handleFileChange} />
                        <FieldError name="download_file" />
                      </div>
                    </label>
                    <HelpText>{t('downloadFileHelp', { maxsize: '10MB' })}</HelpText>
                  </div>
                  <div>
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('digitalDownloadFileName')}</div>
                      <div>
                        <Field as={Input} name="download_filename" />
                        <FieldError name="download_filename" />
                      </div>
                    </label>
                  </div>
                </>
              </If>
            </IfNot>
          </div>
        </div>

        <div className="w-120">
          <IfNot value={values.type} is={ItemType.Contribution}>
            <div className="bg-gray-50 rounded pt-10 pb-12 pl-14 pr-10">
              <h3 className="text-lg font-semibold mb-4">{t('advancedSettings')}</h3>
              <div className="space-y-6">
                <div>
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                      <div>{t('sku')}</div>
                      <IfNot value={values.redemption_method} is={RedemptionMethod.Shipping}>
                        <div className="ml-6 text-gray-500">{t('optional')}</div>
                      </IfNot>
                    </div>
                    <div>
                      <Input
                        name="sku"
                        onChange={handleToUppercase}
                        onBlur={formik.handleBlur}
                        disabled={hasVariants}
                        value={values.sku}
                      />
                      <FieldError name="sku" />
                    </div>
                  </label>
                </div>

                {canUseVariants ? (
                  <If value={values.type} is={ItemType.Purchase}>
                    <HasPolicy policy="use_item_variants">
                      <div>
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                          <div>{t('variants')}</div>
                          <div className="ml-6 text-gray-500">{t('optional')}</div>
                        </div>
                        {hasVariants ? (
                          <div className="bg-blue-50 text-blue-800 p-4 mt-2">{t('variantsRequireTwoOrMoreOptions')}</div>
                        ) : null}
                        <FieldArray name="variants">
                          {(arrayHelpers) => {
                            const getVariantLocalisedName = (index: number, property: keyof (typeof values.variants)[0]) => {
                              return !isMultiLang || locale === defaultLocale
                                ? `variants.${index}.${property}`
                                : `variants.${index}.l10n.${locale}.${property}`;
                            };

                            const getVariantLocalisedStateValue = (index: number, property: keyof (typeof values.variants)[0]) => {
                              const variant = values.variants[index];
                              if (!locale) {
                                return variant[property];
                              }
                              return getL10nValue(variant, property, locale, locale === defaultLocale) || '';
                            };

                            const setVariantLocalisedStateValue = (
                              index: number,
                              property: keyof (typeof values.variants)[0],
                              value: string
                            ) => {
                              const valueName = `variants.${index}.${property}`;
                              if (!locale) {
                                return setFieldValue(valueName, value);
                              }
                              setFieldValue(
                                `variants.${index}`,
                                setL10nValue(values.variants[index], property, value, locale, locale === defaultLocale)
                              );
                            };

                            return (
                              <>
                                {hasVariants ? (
                                  <div className="space-y-6">
                                    {values.variants.map((variant, index) => (
                                      <div key={index} className="space-y-4 first:mt-3">
                                        <h4 className="font-semibold">{t('optionN', { n: index + 1 })}</h4>
                                        <div>
                                          <label>
                                            <div className="leading-6 mb-1 text-gray-700 text-sm font-medium flex items-center justify-end">
                                              <div className="flex-grow flex items-center">
                                                {t('variantLabel')}
                                                <LocaleTag locale={locale} />
                                              </div>
                                              <div>
                                                {canRemoveVariants ? (
                                                  <AnchorButton
                                                    className="text-xs text-blue-600"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                  >
                                                    {t('remove')}
                                                  </AnchorButton>
                                                ) : null}
                                              </div>
                                            </div>
                                            <div>
                                              <Input
                                                name={getVariantLocalisedName(index, 'label')}
                                                value={getVariantLocalisedStateValue(index, 'label')}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                  setVariantLocalisedStateValue(index, 'label', e.target.value)
                                                }
                                                onBlur={formik.handleBlur}
                                              />
                                              <FieldError name={getVariantLocalisedName(index, 'label')} />
                                            </div>
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                                              <div>{t('sku')}</div>
                                              <IfNot value={values.redemption_method} is={RedemptionMethod.Shipping}>
                                                <div className="ml-6 text-gray-500">{t('optional')}</div>
                                              </IfNot>
                                            </div>
                                            <div>
                                              <Field
                                                as={Input}
                                                onChange={handleToUppercase}
                                                name={`variants.${index}.sku`}
                                                onBlur={formik.handleBlur}
                                              />
                                              <FieldError name={`variants.${index}.sku`} />
                                            </div>
                                          </label>
                                        </div>
                                        <div>
                                          <label
                                            htmlFor={`store-add-item-variant-num-items-${index}`}
                                            className="block leading-5 mb-1 text-gray-700 text-sm font-medium"
                                          >
                                            {t('numberOfItems')}
                                          </label>
                                          <div className="flex items-center">
                                            <Field
                                              as={Input}
                                              name={`variants.${index}.num_items`}
                                              id={`store-add-item-variant-num-items-${index}`}
                                              disabled={variant.num_items_unlimited}
                                              type="number"
                                              min={1}
                                            />
                                            <div className="ml-4">
                                              <label className="flex items-center text-gray-700 text-sm font-medium">
                                                <Field
                                                  type="checkbox"
                                                  as={Checkbox}
                                                  name={`variants.${index}.num_items_unlimited`}
                                                />
                                                <div className="ml-3">{t('unlimited')}</div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                                {values.variants.length < 10 ? (
                                  <div className="mt-2">
                                    <Button
                                      onClick={() => {
                                        Array.from({ length: hasVariants ? 1 : 2 }).forEach(() => {
                                          arrayHelpers.push({
                                            label: '',
                                            sku: '',
                                            num_items: 1,
                                            num_items_unlimited: false,
                                          });
                                        });

                                        // We cannot have variants with some redemption methods.
                                        if (methodsIncompatibleWithVariants.includes(values.redemption_method)) {
                                          setFieldValue('redemption_method', RedemptionMethod.Request);
                                        }
                                      }}
                                    >
                                      {t('addVariant')}
                                    </Button>
                                  </div>
                                ) : null}
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </HasPolicy>
                  </If>
                ) : null}
              </div>
            </div>
          </IfNot>
        </div>
      </div>

      <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
        <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
          {t('save')}
        </PrimaryButton>
        <Button onClick={onCancel}>{t('discard')}</Button>
      </div>
    </Form>
  );
};

const If: React.FC<{ value?: any; is: any }> = ({ value, is, children }) => {
  if (value !== is) {
    return null;
  }
  return <>{children}</>;
};

const IfOneOf: React.FC<{ value?: any; of: any[] }> = ({ value, of, children }) => {
  if (!of.includes(value)) {
    return null;
  }
  return <>{children}</>;
};

const IfNot: React.FC<{ value?: any; is: any }> = ({ value, is, children }) => {
  if (value !== is) {
    return <>{children}</>;
  }
  return null;
};

const LocaleTag: React.FC<{ locale?: string }> = ({ locale }) => {
  if (!locale) return null;
  return (
    <div className="inline-block ml-2 bg-gray-100 rounded-full pb-0.5 px-3 text-xs leading-snug font-medium">
      {getLocaleName(locale)}
    </div>
  );
};
