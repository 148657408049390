import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission, Store } from '../../lib/types';
import { PillLink, PillNav } from './Pill';

const StoreNav = ({ store, activePill }: { store: Store; activePill?: string }) => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  const pills = [
    {
      id: 'items',
      name: t('items'),
      href: `/store/${store.id}`,
      exact: true,
      can: permChecker.hasStorePermission(store.id, Permission.ReadItem),
    },
    {
      id: 'teams',
      name: t('teams'),
      href: `/store/${store.id}/teams`,
      can: permChecker.hasStorePermission(store.id, Permission.ReadStoreTeam),
    },
    {
      id: 'transactions',
      name: t('transactions'),
      href: `/store/${store.id}/transactions`,
      can: permChecker.hasStorePermission(store.id, Permission.ReadTransaction),
    },
    {
      id: 'settings',
      name: t('settings'),
      href: `/store/${store.id}/settings`,
      can: permChecker.hasStorePermission(store.id, Permission.UpdateStore),
    },
  ].filter((nav) => _.isUndefined(nav.can) || nav.can);

  if (!pills.length) return null;
  return (
    <PillNav>
      {pills.map((tab) => {
        const active = !_.isUndefined(activePill) ? activePill === tab.id : undefined;
        return (
          <PillLink key={tab.href} to={tab.href} exact={tab.exact} active={active}>
            {tab.name}
          </PillLink>
        );
      })}
    </PillNav>
  );
};

export default StoreNav;
