import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getPlayerName } from '../../lib/player';
import { ContinuatedQueryObserverResult } from '../../lib/queries';
import { ContinuableResponse } from '../../lib/repository';
import { Transaction, TransactionType } from '../../lib/types';
import { getTransactionTypeStringKey } from '../../lib/utils';
import Coins, { Tickets } from '../widgets/Coins';
import { Table, TablePagination, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';

const TransactionsTable = ({
  displayPlayerName,
  queryResult,
  hasFilters,
}: {
  displayPlayerName?: boolean;
  hasFilters?: boolean;
  queryResult: ContinuatedQueryObserverResult<
    ContinuableResponse<Transaction, { users?: { id: string; firstname: string; lastname: string }[] }>
  >;
}) => {
  const { t } = useTranslation();
  const {
    isEnabled,
    isLoading,
    isSuccess,
    data,
    showingFrom,
    showingTo,
    showingOfTotal,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = queryResult;

  const nCols = displayPlayerName ? 6 : 5;
  const playersMap = data?.users?.reduce((carry, player) => ({ [player.id]: player, ...carry }), {});

  return (
    <>
      <Table>
        <Thead>
          {displayPlayerName ? <Th>{t('name')}</Th> : null}
          <Th>{t('type')}</Th>
          <Th>{t('transactionDetails')}</Th>
          <Th className="w-[90px]">{t('coins')}</Th>
          <Th>{t('time')}</Th>
        </Thead>
        <Tbody>
          {!isEnabled || isLoading ? (
            <Tr>
              <Td colSpan={5}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess ? (
            !data?.items?.length ? (
              <Tr>
                <Td colSpan={nCols}>{t(hasFilters ? 'noResultsEllipsis' : 'noTransactionsEllipsis')}</Td>
              </Tr>
            ) : (
              data.items.map((tx) => (
                <TransactionRow key={tx.id} transaction={tx} displayName={displayPlayerName} playersMap={playersMap} />
              ))
            )
          ) : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={showingFrom}
          showingTo={showingTo}
          showingOfTotal={showingOfTotal}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPageClick={fetchNextPage}
          onPreviousPageClick={fetchPreviousPage}
        />
      ) : null}
    </>
  );
};

const TransactionRow: React.FC<{
  transaction: Transaction;
  playersMap?: { [index: string]: { id: string; firstname: string; lastname: string } };
  displayName?: boolean;
}> = ({ transaction, playersMap, displayName }) => {
  const { t } = useTranslation();
  let amount = transaction.data?.coins_gained;
  let AmountComponent = Coins;

  const quantity = transaction.data?.quantity;
  const showQuantity = quantity > 1;
  const item = transaction.data?.item_name;
  const itemId = transaction.data?.item_id;
  if (transaction.type === TransactionType.CoinsExpired) {
    amount = transaction.data?.expired;
  } else if (transaction.type === TransactionType.BidPlaced) {
    amount = transaction.data?.bid;
  } else if (transaction.type === TransactionType.ContributionMade) {
    amount = transaction.data?.amount;
  } else if (transaction.type === TransactionType.ItemPurchase) {
    amount = quantity * transaction.data?.item_cost;
  } else if (transaction.type === TransactionType.RaffleTicketPurchased) {
    amount = quantity * transaction.data?.item_cost;
  } else if (transaction.type === TransactionType.SweepstakesTicketPurchased) {
    amount = quantity * transaction.data?.item_cost;
    AmountComponent = Tickets;
  } else if (transaction.type === TransactionType.TicketsGained) {
    amount = transaction.data?.tickets;
    AmountComponent = Tickets;
  }

  const date = new Date(transaction.timestamp * 1000);
  const user = playersMap ? playersMap[transaction.user_id] : undefined;
  const LinkElement = itemId ? Link : React.Fragment;
  const linkProps = itemId ? { to: `/item/${itemId}` } : { to: '' };

  return (
    <Tr hasFluid>
      {displayName ? (
        <TdPrimary to={user ? `/player/${user.id}` : undefined}>{user ? getPlayerName(user) : <em>{t('deleted')}</em>}</TdPrimary>
      ) : null}
      <Td>{t(getTransactionTypeStringKey(transaction.type))}</Td>
      <Td fluid>
        {[
          transaction.lang ? t(`${transaction.lang.string}`, transaction.lang.args) : null,
          item ? <LinkElement key={item.id} {...linkProps}>{`${showQuantity ? `${quantity}x ` : ''}${item}`}</LinkElement> : null,
        ].filter(Boolean)}
      </Td>
      <Td>{typeof amount === 'number' && !isNaN(amount) ? <AmountComponent amount={amount} /> : null}</Td>
      <Td>{date.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' })}</Td>
    </Tr>
  );
};

export default TransactionsTable;
