import { PencilIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useFiltering, usePermissionChecker, useSorting } from '../../lib/hooks';
import { useStores } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Permission } from '../../lib/types';
import { PrimaryButton } from '../Buttons';
import SearchInput from '../inputs/Search';
import { PageHeader } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { ConfirmModal } from '../Modals';
import { HasAccountPermission, HasStorePermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdContextualMenu,
  TdPrimary,
  Th,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';
import { AlertTag, ShorteningTagList, TeamTag } from '../widgets/Tag';

const useStoreDeleteMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { t } = useTranslation();
  return useMutation(({ id }: { id: string }) => repo.deleteStore(id), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(['store', id]);
      queryClient.invalidateQueries(['store-teams', id]);
      queryClient.invalidateQueries(['stores']);
      queryClient.invalidateQueries(['team']);
      queryClient.invalidateQueries(['team-stores']);
      queryClient.invalidateQueries(['teams']);
      broadcastSuccessToast(t('storeDeleted'));
    },
    onError: (err, variables, context: any) => {
      // TODO Display message.
    },
  });
};

const StoresPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const permChecker = usePermissionChecker();
  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', {});
  const {
    showingFrom,
    showingTo,
    showingOfTotal,
    isSuccess,
    isLoading,
    data,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useStores(filterTerm, sorting.sortField);
  const [toDeleteItems, setToDeleteItems] = useState<string[]>([]);
  const deleteMutation = useStoreDeleteMutation();

  const handleDelete = () => {
    if (!toDeleteItems.length) {
      return;
    }
    setToDeleteItems([]);
    deleteMutation.mutate({ id: toDeleteItems[0] });
  };

  const actions = [
    {
      label: t('delete'),
      danger: true,
      can: permChecker.hasAccountPermission(Permission.DeleteStore),
    },
  ].filter((action) => action.can);

  const canViewSomeTeams = permChecker.hasInAnyStorePermission(Permission.ReadStoreTeam);

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeader
          buttons={
            <>
              <HasAccountPermission perm={Permission.CreateStore}>
                <PrimaryButton onClick={() => history.push('/stores/create')} icon={PencilIcon}>
                  {t('createStore')}
                </PrimaryButton>
              </HasAccountPermission>
            </>
          }
        >
          {t('stores')}
        </PageHeader>
        <div className="mb-4">
          <div className="flex items-center">
            <div className="w-96">
              <SearchInput {...filterInputProps} />
            </div>
          </div>
        </div>
        <div>
          <Table>
            <Thead>
              <ThSortableWithSorting sortKey="name" sortingResult={sorting}>
                {t('name')}
              </ThSortableWithSorting>
              {canViewSomeTeams ? <Th>{t('teams')}</Th> : null}
              <ThSortableWithSorting sortKey="items_count" sortingResult={sorting}>
                {t('items')}
              </ThSortableWithSorting>
              {actions.length ? <Th /> : null}
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={4}>{t('loadingEllipsis')}</Td>
                </Tr>
              ) : null}
              {isSuccess && data
                ? data.items.map((store) => {
                    return (
                      <Tr key={store.id}>
                        <TdPrimary to={`/store/${store.id}`}>
                          {store.name}
                          <HasStorePermission storeId={store.id} perm={Permission.ReadStoreTeam}>
                            {!store.teams.length ? (
                              <div className="inline-block ml-2">
                                <AlertTag>{t('noTeams')}</AlertTag>
                              </div>
                            ) : null}
                          </HasStorePermission>
                        </TdPrimary>
                        {canViewSomeTeams ? (
                          <Td>
                            <HasStorePermission storeId={store.id} perm={Permission.ReadStoreTeam}>
                              <ShorteningTagList component={TeamTag} tags={store.teams.map((t) => t.name)} />
                            </HasStorePermission>
                          </Td>
                        ) : null}
                        <Td>{store.item_count}</Td>
                        {actions.length ? (
                          <TdContextualMenu
                            options={actions.map((action) => ({
                              ...action,
                              onClick: () => setToDeleteItems([store.id]),
                            }))}
                          />
                        ) : null}
                      </Tr>
                    );
                  })
                : null}
            </Tbody>
          </Table>
          {isSuccess && (data?.total || 0) > 0 ? (
            <TablePagination
              showingFrom={showingFrom}
              showingTo={showingTo}
              showingOfTotal={showingOfTotal}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              onNextPageClick={fetchNextPage}
              onPreviousPageClick={fetchPreviousPage}
            />
          ) : null}

          {toDeleteItems.length ? (
            <ConfirmModal
              onConfirm={handleDelete}
              onCancel={() => setToDeleteItems([])}
              title={t('deleteStore', { count: toDeleteItems.length })}
              message={<Trans t={t} i18nKey="deleteStoreConfirm" count={toDeleteItems.length} />}
              confirmButtonText={t('delete')}
            />
          ) : null}
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default StoresPage;
