import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router';
import { useAnchorButtonProps } from '../../../lib/hooks';

type PageHeaderProps<TButtonsRenderProps = any> = {
  buttons?: React.ReactNode | ((props?: TButtonsRenderProps) => React.ReactNode);
  buttonsRenderProps?: TButtonsRenderProps;
};

type PageHeaderWrappingNavProps<TButtonsRenderProps = any> = {
  title: React.ReactNode;
  buttons?: React.ReactNode | ((props?: TButtonsRenderProps) => React.ReactNode);
  buttonsRenderProps?: TButtonsRenderProps;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ buttons, buttonsRenderProps, children }) => {
  return (
    <div className="border-b border-gray-100 mb-6">
      <div className="flex">
        <h1 className="text-2xl font-semibold mb-6 leading-none flex-grow">{children}</h1>
        <div className="flex items-start">{typeof buttons === 'function' ? buttons(buttonsRenderProps) : buttons}</div>
      </div>
    </div>
  );
};

export const PageHeaderWrappingNav: React.FC<PageHeaderWrappingNavProps> = ({ buttons, buttonsRenderProps, children, title }) => {
  return (
    <div className="border-b border-gray-100 pb-4 mb-6">
      <div className="flex">
        <h1 className="text-2xl font-semibold mb-6 leading-none flex-grow">{title}</h1>
        <div className="flex items-start space-x-3">{typeof buttons === 'function' ? buttons(buttonsRenderProps) : buttons}</div>
      </div>
      {children}
    </div>
  );
};

export const PageHeaderWithBackLabel: React.FC<
  PageHeaderProps & { backLabel?: string; backTo?: string; backHandler?: () => void }
> = ({ buttons, buttonsRenderProps, children, backLabel, backTo, backHandler }) => {
  const history = useHistory();
  const handleBack = backHandler
    ? backHandler
    : () => {
        if (backTo) {
          return history.push(backTo);
        }
        history.goBack();
      };
  const anchorProps = useAnchorButtonProps(handleBack);

  return (
    <div className="border-b border-gray-100 pb-6 mb-8">
      <div className="flex">
        <div className="flex flex-col flex-grow">
          <div className="mb-2 text-xs leading-none">
            <a
              className="no-underline text-base leading-none text-gray-900 font-semibold inline-flex items-center"
              {...anchorProps}
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" /> {backLabel ? backLabel : <div className="content-loading h-4 w-24" />}
            </a>
          </div>
          <h1 className="ml-7 text-2xl font-semibold leading-none flex-grow">
            {children ? children : <div className="content-loading h-6 w-40"></div>}
          </h1>
        </div>
        <div className="flex items-start">{typeof buttons === 'function' ? buttons(buttonsRenderProps) : buttons}</div>
      </div>
    </div>
  );
};
