import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../lib/account';
import { UseSelectionResult, UseSortingResult } from '../../lib/hooks';
import { getAccountLevelThresholds, getLevelFromThresholds } from '../../lib/levels';
import { ContinuatedQueryObserverResult } from '../../lib/queries';
import { ContinuableResponse } from '../../lib/repository';
import { Player } from '../../lib/types';
import { ShortDay } from '../Time';
import Coins, { Tickets } from '../widgets/Coins';
import { DropdownOption } from '../widgets/Dropdown';
import { LevelBadge } from '../widgets/Level';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdCheckbox,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThCheckbox,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';
import { HasTicketsEnabled, HasTicketsEnabledInCurrentTeam } from '../utils/HasTicketsEnabled';

type Props = {
  actions: (Omit<DropdownOption, 'onClick'> & { onClick: (userId: string[]) => void })[];
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Player>>;
  sortingResult: UseSortingResult;
  selectionResult: UseSelectionResult;
  teamView?: boolean;
};

export default function PlayersTable({ actions, queryResult, selectionResult, sortingResult, teamView }: Props) {
  const account = useAccount();
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = queryResult;
  const { onSelectAllChange, isEntirePageSelected, onSelectionChange, selectedIds } = selectionResult;
  const levelThresholds = useMemo(() => getAccountLevelThresholds(account), [account]);
  const HasTicketsEnableComponent = teamView ? HasTicketsEnabledInCurrentTeam : HasTicketsEnabled;

  return (
    <>
      <Table>
        <Thead>
          {actions.length ? <ThCheckbox onChange={onSelectAllChange} checked={isEntirePageSelected} /> : null}
          <ThSortableWithSorting sortKey="name" sortingResult={sortingResult}>
            {t('name')}
          </ThSortableWithSorting>
          <ThSortableWithSorting sortKey="level" sortingResult={sortingResult}>
            {t('level')}
          </ThSortableWithSorting>
          <ThSortableWithSorting sortKey="coins" sortingResult={sortingResult} className="w-1/6">
            {t('coins')}
          </ThSortableWithSorting>
          <ThSortableWithSorting sortKey="coins_earned_lifetime" sortingResult={sortingResult} className="w-1/6">
            {t('totalCoins')}
          </ThSortableWithSorting>
          <HasTicketsEnableComponent>
            <ThSortableWithSorting sortKey="tickets" sortingResult={sortingResult} className="w-1/6">
              {t('tickets')}
            </ThSortableWithSorting>
          </HasTicketsEnableComponent>
          <ThSortableWithSorting sortKey="last_activity" sortingResult={sortingResult} className="w-1/5">
            {t('lastActive')}
          </ThSortableWithSorting>
          {actions.length ? <Th /> : null}
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={5}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess && data
            ? data.items.map((user) => (
                <PlayerRow
                  player={user}
                  key={user.id}
                  levelThresholds={levelThresholds}
                  onSelectChange={() => onSelectionChange(user)}
                  selected={selectedIds.includes(user.id)}
                  teamView={teamView}
                  actions={actions.map((action) => ({
                    ...action,
                    onClick: () => action.onClick && action.onClick([user.id]),
                  }))}
                />
              ))
            : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={queryResult.showingFrom}
          showingTo={queryResult.showingTo}
          showingOfTotal={queryResult.showingOfTotal}
          hasNextPage={queryResult.hasNextPage}
          hasPreviousPage={queryResult.hasPreviousPage}
          onNextPageClick={queryResult.fetchNextPage}
          onPreviousPageClick={queryResult.fetchPreviousPage}
        />
      ) : null}
    </>
  );
}

const PlayerRow: React.FC<{
  player: Player;
  onSelectChange: () => void;
  selected: boolean;
  actions: DropdownOption[];
  levelThresholds: number[];
  teamView?: boolean;
}> = ({ player, onSelectChange, selected, actions, levelThresholds, teamView }) => {
  const level = getLevelFromThresholds(levelThresholds, player.coins_earned_lifetime || 0);
  const HasTicketsEnableComponent = teamView ? HasTicketsEnabledInCurrentTeam : HasTicketsEnabled;

  return (
    <Tr key={player.id}>
      {actions.length ? <TdCheckbox checked={selected} onChange={onSelectChange} /> : null}
      <TdPrimary to={`/player/${player.id}`}>
        {player.firstname} {player.lastname}
      </TdPrimary>
      <Td>
        <div className="-my-4">
          <LevelBadge level={level} className="h-8" size="sm" />
        </div>
      </Td>
      <Td>
        <Coins amount={player.coins} />
      </Td>
      <Td>
        <Coins amount={player.coins_earned_lifetime} />
      </Td>
      <HasTicketsEnableComponent>
        <Td>
          <Tickets amount={player.tickets} />
        </Td>
      </HasTicketsEnableComponent>
      <Td>{player.last_activity ? <ShortDay ts={player.last_activity} /> : '-'}</Td>
      {actions.length ? <TdContextualMenu options={actions} /> : null}
    </Tr>
  );
};
