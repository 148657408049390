import { XMarkIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../lib/utils';

const SearchInput = ({
  type,
  className,
  withClear = false,
  onClear,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & {
  withClear?: boolean;
  onClear?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        type="text"
        className={classNames('block w-full pl-10 text-sm border-gray-300 rounded-md', withClear ? 'pr-10 ' : null)}
        placeholder={t('filter')}
        {...props}
      />
      {withClear ? (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <button type="button" onClick={() => onClear && onClear()} title={t('clear')}>
            <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
