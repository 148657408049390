import { useLayoutEffect, useRef, useState } from 'react';
import { classNames } from '../../lib/utils';

export type TagComponentProps = { children: string; invisible?: boolean };

const Tag = ({ children, className, invisible }: TagComponentProps & { className: string }) => {
  return (
    <div
      className={classNames(
        'flex-shrink-0 flex-grow-0 select-none px-2 py-0.5 text-xs inline-flex rounded-xl',
        className,
        invisible ? 'invisible' : ''
      )}
    >
      {children}
    </div>
  );
};

export type TagComponent = React.ComponentType<TagComponentProps>;

type TagListProps = { tags?: string[]; component: TagComponent };

// This ensures that only one row of tags is displayed, and appends the number of hidden tags if needed.
// Note that this may not work well on subsequent rerender when the number of tags have changed.
export const ShorteningTagList = ({ component = TeamTag, tags }: TagListProps) => {
  const MAX = 5;
  const [n, setN] = useState<number | null>(null);
  const div = useRef<HTMLDivElement>(null);
  const allTags = tags || [];
  const dep = allTags.join('-');

  useLayoutEffect(() => {
    setN(null);
  }, [dep]);

  useLayoutEffect(() => {
    if (!div.current || n !== null) return;
    const node = div.current;
    let lastTop = null;
    let nVisible = 0;
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i] as HTMLDivElement;
      const childTop = child.offsetTop;
      if (lastTop === null) {
        lastTop = childTop;
      } else if (lastTop !== childTop) {
        continue;
      }
      nVisible++;
    }
    setN(Math.min(nVisible, MAX));
  }, [n]);

  // Substract 1 as the '+1' tag is part of the number of visible elements.
  const finalTags = n !== null && n < allTags.length ? allTags.slice(0, Math.max(1, n - 1)) : allTags;
  const Component = component;

  return (
    <div className={classNames('flex space-x-1', n === null ? 'flex-wrap' : '')} ref={div}>
      {finalTags.map((tag, i) => (
        <Component key={`${tag}-${i}`}>{tag}</Component>
      ))}
      <Component invisible={allTags.length - finalTags.length <= 0}>{`+${allTags.length - finalTags.length}`}</Component>
    </div>
  );
};

export const StoreTag = (props: TagComponentProps) => <Tag {...props} className="bg-indigo-100 text-indigo-800" />;
export const TeamTag = (props: TagComponentProps) => <Tag {...props} className="bg-yellow-100 text-yellow-800" />;

export const SuccessTag = (props: TagComponentProps) => <Tag {...props} className="font-medium bg-green-100 text-green-800" />;
export const ExpiredTag = (props: TagComponentProps) => <Tag {...props} className="font-medium bg-gray-100 text-gray-800" />;
export const ScheduledTag = (props: TagComponentProps) => <Tag {...props} className="font-medium bg-blue-100 text-blue-800" />;
export const SoldoutTag = (props: TagComponentProps) => <Tag {...props} className="font-medium bg-gray-800 text-gray-100" />;

export const AlertTag = (props: TagComponentProps) => <Tag {...props} className="font-medium bg-red-100 text-red-800" />;

// Not compatible with the other tags.
export function SuperUserTag() {
  return (
    <div
      className="ml-2 inline-flex items-center text-[10px] px-1 py-0.5 rounded-xl leading-none flex-shrink-0 flex-grow-0 select-none bg-gray-300 text-gray-900"
      title="Only available to super users"
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-0.5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
          clipRule="evenodd"
        />
      </svg>
      Super user
    </div>
  );
}
