import { fromUnixTime, getUnixTime } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { isItemEditable } from '../../lib/item';
import {
  convertL10nValuesArrayToDict,
  convertL10nValuesDictToArray,
  getStoreLocales,
  isL10nArrayMultiLang,
  setDefaultL10nForLocaleIfMissing,
} from '../../lib/l10n';
import { useProduct, useStore } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Dict, ItemDrawMethod, ItemType, Product, RedemptionMethod, Store } from '../../lib/types';
import { itemEditValidationSchema, ItemForm, ItemFormValues, itemVariantSchema } from '../forms/ItemForm';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { NotificationError } from '../Notifications';
import Dropdown from '../widgets/Dropdown';
import { useProductActions } from './Product';

const useProductEditMutation = (isMultiLang: Boolean, defaultLocale?: string) => {
  const account = useAccount();
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation(
    (variables: any) => {
      const canUseL10n = account.policy.use_l10n && defaultLocale;

      // Update item.
      let item: Dict = {
        name: variables.name,
        description: variables.description,
        cost: Number(variables.cost),

        redemption_method: variables.redemption_method,
        self_redeem_message: variables.self_redeem_message,

        start_time: getUnixTime(variables.start_time),
        end_time: getUnixTime(variables.end_time),

        image_file: variables.image_file ? variables.image_file : null,
      };

      if (canUseL10n) {
        item.l10n = variables.l10n; // Assign values.
        item.l10n = convertL10nValuesDictToArray(
          // Upon saving, we ensure that there is an entry for the default locale with all
          // the properties that should be attached to it. That is done here because in the
          // event where the default value has changed, the form will display the right values
          // but the state won't be updated which means that saving the form won't add/update
          // the l10n entries. So this is just to make it more robust for users.
          setDefaultL10nForLocaleIfMissing(item, defaultLocale, ['name', 'description', 'self_redeem_message']).l10n
        );

        // When the item is not multi-lang, we only keep the default locale.
        if (!isMultiLang) {
          item.l10n = item.l10n.filter((l: { locale: string }) => l.locale === defaultLocale);
        }
      }

      // Create item.
      item = {
        ...item,
        num_items: variables.num_items_unlimited ? 0 : Number(variables.num_items) || 1,
        variants: variables.variants.map((variantData: yup.InferType<typeof itemVariantSchema>) => {
          const { num_items_unlimited, ...variant } = variantData;
          const data: Dict = { ...variant, num_items: num_items_unlimited ? 0 : variant.num_items };

          if (canUseL10n) {
            data.l10n = convertL10nValuesDictToArray(
              setDefaultL10nForLocaleIfMissing(variant as Dict, defaultLocale, ['label']).l10n
            );

            // When the item is not multi-lang, we only keep the default locale.
            if (!isMultiLang) {
              item.l10n = data.l10n.filter((l: { locale: string }) => l.locale === defaultLocale);
            }
          }

          return data;
        }),
      };

      return repo.updateProduct(variables.id, item);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['store-content', data.store_id]);
        queryClient.invalidateQueries(['team-market']);
        queryClient.setQueryData(['product', data.id], data);
      },
    }
  );
};

const ProductEditContent = (props: { product: Product; store: Store }) => {
  const item: Product = props.product;
  const store: Store = props.store;

  const history = useHistory();
  const account = useAccount();

  const handleBack = () => {
    history.goBack();
  };

  const [defaultLocale, locales] = useMemo(() => {
    const locales = getStoreLocales(account, store);
    if (!locales.length) {
      return [undefined, []];
    }
    return [locales[0].tag, locales];
  }, [account, store]);
  const [locale, setLocale] = useState<string>();

  const mutation = useProductEditMutation(Boolean(locale), defaultLocale);

  // Select the default locale when the item uses more than one locale.
  useEffect(() => {
    if (isL10nArrayMultiLang(item.l10n, defaultLocale)) {
      setLocale(defaultLocale);
    }
  }, [item]); // eslint-disable-line

  const initialData: ItemFormValues = useMemo(() => {
    return {
      type: ItemType.Purchase,

      name: item.name,
      description: item.description,

      image_file: undefined,

      start_time: fromUnixTime(item.start_time),
      end_time: fromUnixTime(item.end_time),

      cost: item.cost,

      redemption_method: item.redemption_method,
      self_redeem_message: item.self_redeem_message || '',

      variants: item.variants.map((variant) => ({
        ...variant,
        sku: variant.sku || '',
        num_items_unlimited: variant.num_items <= 0,

        // TODO Only if they can use localisation.
        l10n: convertL10nValuesArrayToDict(variant?.l10n),
      })),

      // TODO Only if they can use localisation.
      l10n: convertL10nValuesArrayToDict(item.l10n || []),

      // These do not apply to products.
      sku: '',
      num_items: 1,
      num_items_unlimited: false,
      handling_fee: 2,
      minimum_bid: 10,
      contrib_goal: 1000,
      contrib_currency_symbol: '',
      contrib_currency_value: 1,
      draw_method: ItemDrawMethod.Auto,
      download_file: undefined,
      download_filename: '',
    };
  }, [item]);

  // If item cannot be edited (e.g. it ended), redirect to item.
  if (!isItemEditable(item)) {
    return <Redirect to={`/product/${item.id}`} />;
  }

  return (
    <div className="flex flex-col flex-grow">
      {mutation.isError ? (
        <div className="mb-4">
          <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
        </div>
      ) : null}
      <Formik
        initialValues={initialData}
        onSubmit={(values, formik) => {
          mutation.mutate(
            { id: item.id, locale, ...values },
            {
              onSuccess: (item) => {
                history.push(`/product/${item.id}`);
              },
              onError: () => {
                // TODO Handle error.
              },
              onSettled: () => {
                formik.setSubmitting(false);
              },
            }
          );
        }}
        validationSchema={itemEditValidationSchema}
        validateOnMount
      >
        {(formik) => {
          return (
            <ItemForm
              formik={formik}
              locale={locale}
              locales={locales}
              defaultLocale={defaultLocale}
              onLocaleChange={setLocale}
              item={item}
              onCancel={handleBack}
            />
          );
        }}
      </Formik>
    </div>
  );
};

const ProductEditPage = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { isLoading, data: product } = useProduct(productId);
  const { isLoading: isStoreLoading, data: store } = useStore(product ? product.store_id : '', { enabled: Boolean(product) });
  const { actions, renderActions } = useProductActions(product, { page: 'edit' });
  return (
    <LayoutWithSidebar>
      <PageHeaderWithBackLabel
        backLabel={store?.name}
        backTo={product ? `/store/${product.store_id}` : undefined}
        buttons={product && actions.length ? <Dropdown right options={actions} label={t('actions')} /> : <></>}
      >
        {product?.name}
      </PageHeaderWithBackLabel>
      {isLoading || isStoreLoading || !product || !store ? null : <ProductEditContent product={product} store={store} />}
      {renderActions()}
    </LayoutWithSidebar>
  );
};

export default ProductEditPage;
