import { levelsTemplateUrl } from './flavour';
import { Account } from './types';

export type Level = { level: number; coins: number; badge_url?: string };
export type Levels = Level[];

export const LEVELS_BASELINE = 500;
export const LEVELS_MAX_COINS = 1000000;
export const MAX_LEVELS = 80;

export const getAccountLevelThresholds = (account: Account) => {
  return account.levels || getDefaultLevels().map((l) => l.coins);
};

export const getAccountLevels = (account: Account): Levels => {
  const decorateLevels = (levels: { level: number; coins: number }[]) => {
    return levels.map((level) => ({
      ...level,
      badge_url: getAccountLevelBadgeUrl(account, level.level),
    }));
  };

  if (!account.levels || account.levels.length < 2) {
    return decorateLevels(getDefaultLevels());
  }
  return decorateLevels(
    account.levels.map((coins, idx) => ({
      level: idx + 1,
      coins,
    }))
  );
};

let _defaultLevels: Levels | null = null;
export const getDefaultLevels = () => {
  if (!_defaultLevels) {
    let levels: Levels = [];
    for (let i = 0; i < 10; i++) {
      const prevIndex = i - 1;
      const level = i + 1;
      levels.push({
        level,
        coins: getLevelCoins(level, levels[prevIndex]?.coins),
      });
    }
    _defaultLevels = levels;
  }
  return _defaultLevels;
};

export const getAccountLevelBadgeUrl = (account: Account, level: number) => {
  return account.levels_badge?.[level];
};

export const getDefaultLevelBadgeUrl = (level: number, size?: 'sm') => {
  return levelsTemplateUrl.replace('{level}', level.toString().padStart(2, '0') + (size ? `-${size}` : ''));
};

export const getLevelBadgeUrl = (account: Account, level: number, size?: 'sm') => {
  const badgeUrl = account.levels_badge?.[level];
  return badgeUrl || getDefaultLevelBadgeUrl(level, size);
};

export const getLevelCoins = (level: number, prevLevel?: number) => {
  if (level === 1) return 0;
  else if (level === 2) return LEVELS_BASELINE;

  const prevCoins = prevLevel || 0;
  const coins = LEVELS_BASELINE + Math.floor((LEVELS_BASELINE / 10) * (level - 2));
  return Math.min(LEVELS_MAX_COINS, prevCoins + coins);
};

export const getLevelFromThresholds = (thresholds: number[], coins: number) => {
  if (coins <= 0 || !thresholds.length) {
    return 1;
  }
  let level = 0;
  for (let idx = 0; idx < thresholds.length; idx++) {
    const threshold = thresholds[idx];
    if (threshold > coins) {
      break;
    }
    level++;
  }
  return level;
};

export const postProcessLevelsList = (levels: Level[]) => {
  return levels
    .sort((a, b) => a.level - b.level)
    .slice(0, MAX_LEVELS)
    .map((level, index, levels) => {
      const prevLevel = levels[index - 1];
      if (!prevLevel) {
        return level;
      }
      const lastCoins = prevLevel.coins;
      if (level.coins <= lastCoins && level.coins !== LEVELS_MAX_COINS) {
        return {
          ...level,
          coins: Math.min(
            LEVELS_MAX_COINS,
            Math.floor(lastCoins + LEVELS_BASELINE + (LEVELS_BASELINE / 10) * Math.max(0, level.level - 2))
          ),
        };
      }
      return level;
    });
};
