import { forwardRef } from 'react';
import { useFieldError } from '../../lib/hooks';
import { classNames } from '../../lib/utils';

const Input = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { hasError } = useFieldError(props.name);
  return (
    <input
      ref={ref}
      type="text"
      className={classNames(
        'block w-full text-sm  rounded-md shadow-sm disabled:bg-gray-100',
        hasError && !props.disabled
          ? 'placeholder-red-300 text-red-900 border-red-500 focus:border-red-500 focus:ring-red-500'
          : 'border-gray-300'
      )}
      {...props}
    />
  );
});

export default Input;
