import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission } from '../../lib/types';
import { PillLink, PillNav } from './Pill';
import { hasPolicy, useAccount } from '../../lib/account';
import { useMemo } from 'react';

const ReportingNav = () => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  const account = useAccount();
  const showReports = hasPolicy(account, 'use_reporting') && permChecker.hasInAnyTeamPermission(Permission.ReadReport);

  const navItems = useMemo(() => {
    return [
      { name: t('reportingOverview'), href: '/reports/overview', can: showReports },
      {
        name: t('reportingDetailed'),
        href: '/reports/detailed',
        can: showReports,
      },
      {
        name: t('transactions'),
        href: '/transactions',
        can: permChecker.hasAnywhereInAccountPermission(Permission.ReadTransaction),
      },
    ].filter((nav) => isUndefined(nav.can) || nav.can);
  }, [showReports, t, permChecker]);

  return (
    <PillNav>
      {navItems.map((tab) => (
        <PillLink key={tab.href} to={tab.href}>
          {tab.name}
        </PillLink>
      ))}
    </PillNav>
  );
};

export default ReportingNav;
