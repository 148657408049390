import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Redirect, useParams } from 'react-router';
import { useUser } from '../../lib/auth';
import { getErrorMessage } from '../../lib/i18n';
import { isSuperUser } from '../../lib/perms';
import { useRepo } from '../../lib/repository';
import { HeaderWithBack } from '../Headers';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import { NotificationError } from '../Notifications';
import { Table, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';
import Input from '../inputs/Input';
import { PrimaryButton } from '../Buttons';

const DomainAdminsPage = () => {
  const user = useUser();

  if (!isSuperUser(user)) {
    return <Redirect to="/" />;
  }

  return <DomainAdminsPageContent />;
};

const DomainAdminsPageContent = () => {
  const { t } = useTranslation();
  const { domainId } = useParams<{ domainId: string }>();
  const queryClient = useQueryClient();
  const repo = useRepo();
  const query = useQuery(['domain-admins', domainId], () => repo.getDomainAdmins(domainId));
  const mutation = useMutation(async (data: { email: string; name: string }) => {
    return repo.createDomainAdmin(domainId, data.email, data.name);
  });

  return (
    <OutsideAccountLayout>
      <div className="flex flex-col flex-grow">
        <div className="ml-2">
          <HeaderWithBack withBorder={false}>{domainId}</HeaderWithBack>
        </div>

        <div className="px-10 pt-6 pb-10 bg-white shadow-sm">
          {mutation.isError ? (
            <div className="mb-6">
              <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
            </div>
          ) : null}
          <div className="mb-6">
            <Formik
              validationSchema={yup.object({
                email: yup.string().email().required(),
                name: yup.string().required(),
              })}
              initialValues={{ name: '', email: '' }}
              onSubmit={(data, form) => {
                mutation.mutate(data, {
                  onSuccess: () => {
                    form.setSubmitting(false);
                    form.resetForm();
                    queryClient.invalidateQueries(['domain-admins']);
                  },
                });
                return;
              }}
            >
              {({ isValid }) => {
                return (
                  <Form>
                    <div className="flex gap-4 items-end">
                      <div className="flex-1 grid grid-cols-2 gap-4">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">Email</div>
                          <div>
                            <Field as={Input} name="email" type="email" placeholder="admin@example.com" />
                          </div>
                        </label>
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">Name</div>
                          <div className="flex items-center">
                            <Field as={Input} name="name" type="text" placeholder="" />
                          </div>
                        </label>
                      </div>
                      <div>
                        <PrimaryButton type="submit" disabled={!isValid || mutation.isLoading}>
                          Add
                        </PrimaryButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <Table>
            <Thead>
              <Th>Name</Th>
              <Th>Email</Th>
            </Thead>
            <Tbody>
              {query.isLoading ? (
                <Tr>
                  <Td colSpan={2}>{t('loadingEllipsis')}</Td>
                </Tr>
              ) : null}
              {query.isSuccess && query.data
                ? query.data.map((user) => (
                    <Tr key={user.id}>
                      <TdPrimary>{user.name}</TdPrimary>
                      <Td>{user.email}</Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </div>
      </div>
    </OutsideAccountLayout>
  );
};

export default DomainAdminsPage;
