import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../lib/account';
import { useContinuatedQuery } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { OrderState, RedemptionMethod } from '../../lib/types';
import { OrdersTable } from '../OrdersTable';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeader } from '../layouts/partials/PageHeaders';
import { useMutation } from 'react-query';
import download from 'downloadjs';
import { Button } from '../Buttons';
import { ArrowLongDownIcon } from '@heroicons/react/24/solid';

const useOrders = ({ method, state, orderBy }: { method?: RedemptionMethod; state?: OrderState; orderBy?: string } = {}) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  const pageSize = 100;

  return useContinuatedQuery(
    ['orders', accountId, { pageSize, method, state, orderBy }],
    ({ pageParam }) => {
      return repo.getOrders(accountId, { method, state }, orderBy, pageSize, pageParam);
    },
    {
      pageSize: pageSize,
      keepPreviousData: true,
      resetPageDependencies: [accountId, method, state, orderBy],
    }
  );
};

const usePlayersExportMutation = (
  filters?: {
    method?: string;
    state?: string;
  },
  orderBy?: string
) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  return useMutation(() => repo.exportOrders(accountId, filters, orderBy), {
    onSuccess: (data) => {
      download(data, 'orders.csv', 'text/csv');
    },
  });
};

const OrdersPage = () => {
  const { t } = useTranslation();
  const [method, setMethod] = useState<RedemptionMethod | undefined>();
  const [state, setState] = useState<OrderState | undefined>();
  const query = useOrders({ method, state });
  const exportMutation = usePlayersExportMutation({ method, state });

  const { isLoading: isDownloading } = exportMutation;
  const totalRecords = query?.data?.total || 0;
  const canDownload = query.isSuccess && totalRecords <= 10000 && totalRecords > 0 && !isDownloading;

  return (
    <LayoutWithSidebar>
      <PageHeader
        buttons={
          <>
            <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
              {t('exportCsv')}
            </Button>
          </>
        }
      >
        {t('orders')}
      </PageHeader>
      <OrdersTable queryResult={query} state={state} setState={setState} method={method} setMethod={setMethod} />
    </LayoutWithSidebar>
  );
};

export default OrdersPage;
