import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStore } from '../../lib/queries';
import { Store } from '../../lib/types';
import StoreNav from '../nav/StoreNav';
import { PageHeaderWrappingNav } from './partials/PageHeaders';
import LayoutWithSidebar from './WithSidebar';

export const StorePageContext = createContext<{ store: Store }>({
  store: {
    id: 'abc123',
    account_id: 'abc123',
    name: 'Some Team',
    item_count: 0,
    team_ids: [],
    teams: [],
    redemptions_shipping_allow_edit: true,
    redemptions_shipping_required_fields: [],
  },
});

export const useStorePage = () => {
  return useContext(StorePageContext);
};

type StoreLayoutProps = {
  activeNavPill?: string;
  backAction?: () => void;
  buttons?: React.ReactNode | ((store: Store) => React.ReactNode);
};

const StorePageLayout: React.FC<StoreLayoutProps> = ({ children, buttons, activeNavPill }) => {
  return (
    <StorePageFreeLayout header={<StorePageHeader buttons={buttons} activeNavPill={activeNavPill} />}>
      {children}
    </StorePageFreeLayout>
  );
};

export const StorePageHeader = ({ buttons, activeNavPill }: StoreLayoutProps) => {
  const { store } = useStorePage();
  return (
    <PageHeaderWrappingNav title={store.name} buttons={buttons} buttonsRenderProps={store}>
      <StoreNav store={store} activePill={activeNavPill} />
    </PageHeaderWrappingNav>
  );
};

export const StorePageFreeLayout: React.FC<{ header: React.ReactNode }> = ({ children, header }) => {
  const { t } = useTranslation();
  const { storeId } = useParams<{ storeId: string }>();
  const { isLoading, data: store } = useStore(storeId);

  return (
    <LayoutWithSidebar>
      {isLoading || !store ? (
        t('loadingEllipsis')
      ) : (
        <StorePageContext.Provider value={{ store }}>
          {header}
          {children}
        </StorePageContext.Provider>
      )}
    </LayoutWithSidebar>
  );
};

export default StorePageLayout;
