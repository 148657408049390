import React from 'react';
import { hasTicketsEnabled, hasTicketsEnabledInTeam, useAccount } from '../../lib/account';
import { useTeamPage } from '../layouts/TeamPage';
import { Team } from '../../lib/types';

export const HasTicketsEnabled: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const account = useAccount();
  const showContent = not ? !hasTicketsEnabled(account) : hasTicketsEnabled(account);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};

export const HasTicketsEnabledInCurrentTeam: React.FC<{ not?: boolean }> = ({ children, not }) => {
  const { team } = useTeamPage();
  return (
    <HasTicketsEnabledInTeam not={not} team={team}>
      {children}
    </HasTicketsEnabledInTeam>
  );
};

export const HasTicketsEnabledInTeam: React.FC<{ team: Team; not?: boolean }> = ({ children, team, not }) => {
  const account = useAccount();
  const showContent = not ? !hasTicketsEnabledInTeam(account, team) : hasTicketsEnabledInTeam(account, team);
  if (!showContent) {
    return null;
  }
  return <>{children}</>;
};
