import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { usePermissionChecker } from '../lib/hooks';
import { ContinuatedQueryObserverResult, useStoreName, useTeamName } from '../lib/queries';
import { ContinuableResponse, useRepo } from '../lib/repository';
import { Order, OrderState, Permission, RedemptionMethod } from '../lib/types';
import Textarea from './inputs/Textarea';
import { ActionModal, ActionModalButtons, ConfirmModal } from './Modals';
import { HasAnyWhereInAccountPermission, HasStorePermission, HasTeamPermission } from './Permissions';
import { ShortDay } from './Time';
import { broadcastSuccessToast } from './Toasts';
import { Table, TablePagination, Tbody, Td, TdContextualMenu, TdPrimary, Th, Thead, Tr } from './widgets/Table';
import { StoreTag, TeamTag } from './widgets/Tag';
import { Placeholder } from './Placeholders';
import { Link } from 'react-router-dom';
import { getOrderStateStringKey, getRedemptionMethodStringKey } from '../lib/utils';
import DropdownSelect, { DropdownSelectOption } from './widgets/DropdownSelect';

const OrdersFilters = ({
  method,
  setMethod,
  state,
  setState,
}: {
  method?: RedemptionMethod;
  setMethod: (method?: RedemptionMethod) => void;
  state?: OrderState;
  setState: (state?: OrderState) => void;
}) => {
  const { t } = useTranslation();

  const methodOptions = useMemo(() => {
    return Object.values(RedemptionMethod)
      .filter((type) => type !== RedemptionMethod.None)
      .map((type) => ({
        label: t(getRedemptionMethodStringKey(type)),
        value: type,
      }))
      .sort((i1, i2) => (i1.label < i2.label ? -1 : 1));
  }, [t]);

  const stateOptions = useMemo(() => {
    return Object.values(OrderState)
      .map((type) => ({
        label: t(getOrderStateStringKey(type)),
        value: type,
      }))
      .sort((i1, i2) => (i1.label < i2.label ? -1 : 1));
  }, [t]);

  const selectMethod = useMemo(() => (method ? methodOptions.find((t) => t.value === method) : undefined), [method, methodOptions]);
  const selectedState = useMemo(() => (state ? stateOptions.find((t) => t.value === state) : undefined), [state, stateOptions]);

  const handleMethodChange = (type?: DropdownSelectOption) => {
    setMethod((type?.value as RedemptionMethod) || undefined);
  };

  const handleStateChange = (type?: DropdownSelectOption) => {
    setState((type?.value as OrderState) || undefined);
  };

  return (
    <div className="flex">
      <div className="flex flex-grow items-center space-x-2">
        <div className="w-48">
          <DropdownSelect
            placeholder={t('orderMethod')}
            onChange={handleMethodChange}
            options={methodOptions}
            selected={selectMethod}
            clearable
            onClear={handleMethodChange}
          />
        </div>
        <div className="w-48">
          <DropdownSelect
            placeholder={t('status')}
            onChange={handleStateChange}
            options={stateOptions}
            selected={selectedState}
            clearable
            onClear={handleStateChange}
          />
        </div>
      </div>
    </div>
  );
};

export const OrdersTable = ({
  queryResult,
  state,
  setState,
  method,
  setMethod,
}: {
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Order>>;
  method?: RedemptionMethod;
  setMethod: (method?: RedemptionMethod) => void;
  state?: OrderState;
  setState: (state?: OrderState) => void;
}) => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();

  const {
    showingFrom,
    showingTo,
    showingOfTotal,
    isSuccess,
    isLoading,
    data,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = queryResult;

  return (
    <div className="flex flex-grow flex-col">
      <div className="mb-4">
        <OrdersFilters state={state} setState={setState} method={method} setMethod={setMethod} />
      </div>
      {isSuccess && !data?.items.length ? (
        <div className="flex flex-grow items-center justify-center text-center">
          <p className="text-lg font-medium">{t('noOrders')}</p>
        </div>
      ) : (
        <Table>
          <Thead>
            <Th>{t('name')}</Th>
            <Th>{t('orderMethod')}</Th>
            <Th>{t('status')}</Th>
            <Th>{t('item')}</Th>
            <Th>{t('lastUpdate')}</Th>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={3}>{t('loadingEllipsis')}</Td>
              </Tr>
            ) : null}
            {isSuccess && data
              ? data.items.map((order) => (
                  <Tr key={order.id}>
                    <TdPrimary>
                      {/** This permission check is not complete and could open the link even if access is not allowed. */}
                      {permChecker.hasTeamPermission(order.school_id, Permission.ReadPlayer) ||
                      permChecker.hasStorePermission(order.store_id, Permission.ReadPlayer) ? (
                        <Link to={`/player/${order.user.id}`} className="mr-2">
                          {order.user.firstname} {order.user.lastname}
                        </Link>
                      ) : (
                        `${order.user.firstname} ${order.user.lastname}`
                      )}
                      <HasTeamPermission teamId={order.school_id} perm={Permission.ReadTeam}>
                        <OrderTeam teamId={order.school_id} />
                      </HasTeamPermission>
                    </TdPrimary>
                    <Td>{t(getRedemptionMethodStringKey(order.type as RedemptionMethod))}</Td>
                    <Td>{t(getOrderStateStringKey(order.state))}</Td>
                    <Td>
                      {permChecker.hasStorePermission(order.store_id, Permission.ReadItem) ? (
                        <Link to={`/item/${order.item.id}`} className="mr-2">
                          {order.item.name}
                        </Link>
                      ) : (
                        order.item.name
                      )}
                      <HasStorePermission storeId={order.store_id} perm={Permission.ReadStore}>
                        <OrderStore storeId={order.store_id} />
                      </HasStorePermission>
                    </Td>
                    <Td>{order.state_changed_on ? <ShortDay ts={order.state_changed_on} /> : '-'}</Td>
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      )}
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={showingFrom}
          showingTo={showingTo}
          showingOfTotal={showingOfTotal}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPageClick={fetchNextPage}
          onPreviousPageClick={fetchPreviousPage}
        />
      ) : null}
    </div>
  );
};

const OrderTeam: React.FC<{ teamId?: string }> = ({ teamId }) => {
  const name = useTeamName(teamId);
  return name ? (
    <Link to={`/team/${teamId}`}>
      <TeamTag>{name}</TeamTag>
    </Link>
  ) : (
    <Placeholder className="h-3 w-12 inline-block" />
  );
};

const OrderStore: React.FC<{ storeId?: string }> = ({ storeId }) => {
  const name = useStoreName(storeId);
  return name ? (
    <Link to={`/store/${storeId}`}>
      <StoreTag>{name}</StoreTag>
    </Link>
  ) : (
    <Placeholder className="h-3 w-12 inline-block" />
  );
};

export const OrdersRequestsTable = ({
  queryResult,
  showPlayerName = true,
}: {
  showPlayerName?: boolean;
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Order>>;
}) => {
  const { t } = useTranslation();
  const [action, setAction] = useState<['approve' | 'remove', string]>();
  const permChecker = usePermissionChecker();

  const repo = useRepo();
  const queryClient = useQueryClient();
  const approveMutation = useMutation(
    ({ order, message }: { order: Order; message: string }) => {
      return repo.acceptOrder(order.id, message, order.item.name);
    },
    {
      onSuccess: (data, variables) => {
        broadcastSuccessToast(t('orderAccepted'));
        queryClient.invalidateQueries('orders');
        queryClient.invalidateQueries(['pending-orders']);
        queryClient.invalidateQueries(['user-orders', variables.order.user_id]);
        queryClient.invalidateQueries(['user-transactions', variables.order.user_id]);
        queryClient.invalidateQueries(['transactions']);
      },
    }
  );

  const declineMutation = useMutation(
    ({ order }: { order: Order }) => {
      return repo.declineOrder(order.id);
    },
    {
      onSuccess: (data, variables) => {
        broadcastSuccessToast(t('orderDeclined'));
        queryClient.invalidateQueries('orders');
        queryClient.invalidateQueries(['pending-orders']);
        queryClient.invalidateQueries(['user-orders', variables.order.user_id]);
        queryClient.invalidateQueries(['user-transactions', variables.order.user_id]);
        queryClient.invalidateQueries(['transactions']);
      },
    }
  );

  const {
    showingFrom,
    showingTo,
    showingOfTotal,
    isSuccess,
    isLoading,
    data,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = queryResult;

  const handleApprove = (message: string) => {
    if (!action) return;
    const order = data?.items.find((order) => order.id === action[1]);
    if (!order) return;
    approveMutation.mutate({ order, message });
    setAction(undefined);
  };

  const handleRemove = () => {
    if (!action) return;
    const order = data?.items.find((order) => order.id === action[1]);
    if (!order) return;
    declineMutation.mutate({ order });
    setAction(undefined);
  };

  const nCols = showPlayerName ? 5 : 4;

  return (
    <div className="flex flex-grow flex-col">
      {isSuccess && !data?.items.length ? (
        <div className="flex flex-grow items-center justify-center text-center">
          <p className="text-lg font-medium">{t('noOrders')}</p>
        </div>
      ) : (
        <Table>
          <Thead>
            {showPlayerName ? <Th>{t('name')}</Th> : null}
            <Th>{t('item')}</Th>
            <Th>{t('message')}</Th>
            <Th>{t('requestDate')}</Th>
            <HasAnyWhereInAccountPermission perm={Permission.ManagerOrder}>
              <Th />
            </HasAnyWhereInAccountPermission>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={nCols}>{t('loadingEllipsis')}</Td>
              </Tr>
            ) : null}
            {isSuccess && data
              ? data.items.map((order) => (
                  <Tr key={order.id}>
                    {showPlayerName ? (
                      <TdPrimary to={`/player/${order.user.id}`}>
                        {order.user.firstname} {order.user.lastname}
                      </TdPrimary>
                    ) : null}
                    <Td>{order.item.name}</Td>
                    <Td>{order?.data?.message || ''}</Td>
                    <Td>
                      <ShortDay ts={order.state_changed_on} />
                    </Td>
                    {permChecker.hasOrderPermission(order, Permission.ManagerOrder) ? (
                      <TdContextualMenu
                        options={[
                          {
                            label: t('approveRequest'),
                            onClick: () => setAction(['approve', order.id]),
                          },
                          {
                            label: t('removeRequest'),
                            onClick: () => setAction(['remove', order.id]),
                          },
                        ]}
                      />
                    ) : (
                      <Td />
                    )}
                  </Tr>
                ))
              : null}
          </Tbody>
        </Table>
      )}
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={showingFrom}
          showingTo={showingTo}
          showingOfTotal={showingOfTotal}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPageClick={fetchNextPage}
          onPreviousPageClick={fetchPreviousPage}
        />
      ) : null}

      {action && action[0] === 'remove' ? (
        <ConfirmModal
          title={t('removeRequest')}
          message={t('areYouSure')}
          onCancel={() => setAction(undefined)}
          onConfirm={handleRemove}
          icon={undefined}
        />
      ) : null}

      {action && action[0] === 'approve' ? (
        <ApproveModal onCancel={() => setAction(undefined)} onConfirm={(message) => handleApprove(message)} />
      ) : null}
    </div>
  );
};

const approveSchema = yup.object({ message: yup.string().required() }).required();

const ApproveModal: React.FC<{ onCancel: () => void; onConfirm: (message: string) => void }> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: ({ message }) => {
      onConfirm(message);
    },
    validationSchema: approveSchema,
    validateOnMount: true,
  });

  return (
    <ActionModal onCancel={onCancel} title={t('approveRequest')} narrow>
      <p className="pb-2 text-sm text-gray-500">{t('redeemRequestProvideMessage')}</p>
      <form className="flex flex-col flex-grow" onSubmit={formik.handleSubmit}>
        <div className="flex-grow space-y-2">
          <div className="w-full">
            <label>
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                <div>{t('messageToPlayer')}</div>
              </div>
              <div>
                <Textarea name="message" value={formik.values.message} onChange={formik.handleChange} onBlur={formik.handleBlur} />
              </div>
            </label>
          </div>
        </div>
        <ActionModalButtons onCancel={onCancel} confirmButtonText={t('approve')} confirmButtonDisabled={!formik.isValid} />
      </form>
    </ActionModal>
  );
};
