import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../lib/account';
import { brandName, brandUrl, darkBgLogoSrc } from '../lib/flavour';

const PoweredBy = () => {
  const { account } = useContext(AccountContext);
  const { t } = useTranslation();

  if (!account?.policy.use_branding || !account.logo_url) {
    return null;
  }

  return (
    <div className="text-xs text-gray-300">
      <div className="mb-1">{t('poweredBy')}</div>
      <div>
        <a href={brandUrl} target="_blank" rel="noopener noreferrer">
          <img src={darkBgLogoSrc} alt={brandName} className="h-5" />
        </a>
      </div>
    </div>
  );
};

export default PoweredBy;
