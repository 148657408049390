import { Switch } from '@headlessui/react';
import { classNames } from '../../lib/utils';

const Toggle: React.FC<{ enabled?: boolean; onChange: (enabled: boolean) => void; label: string }> = ({
  enabled = false,
  onChange,
  label,
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch.Label as="span" className="mr-2">
        <span className="font-medium">{label}</span>
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-green-500' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export const ToggleInForm: React.FC<{ enabled?: boolean; onChange: (enabled: boolean) => void; label: string }> = ({
  enabled = false,
  onChange,
  label,
}) => {
  return (
    <Switch.Group as="div" className="flex items-center cursor-pointer">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-green-500' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-2">
        <span>{label}</span>
      </Switch.Label>
    </Switch.Group>
  );
};

export default Toggle;
