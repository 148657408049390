import { Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { AccountContext } from '../../lib/account';
import { AuthContext } from '../../lib/auth';
import { useNavigateTo } from '../../lib/hooks';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import { NotificationError } from '../Notifications';

const schema = yup.object({ name: yup.string().required() }).required();

const OwnAccountCreatePage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { refreshUser } = useContext(AuthContext);
  const { setAccount } = useContext(AccountContext);
  const goTo = useNavigateTo();

  const mutation = useMutation(
    async ({ name }: { name: string }) => {
      return repo.createOwnAccount(name);
    },
    {
      onSuccess: async (data) => {
        await refreshUser();
        setAccount(data);
        goTo('/');
      },
    }
  );
  const repo = useRepo();

  return (
    <OutsideAccountLayout>
      <div className="flex mb-6">
        <div className="flex-grow">
          <h1 className="font-semibold text-2xl">{t('createAccount')}</h1>
        </div>
      </div>

      <div className="px-10 pt-10 pb-6 bg-white shadow-sm">
        <Formik
          initialValues={{ name: '' }}
          onSubmit={(values, formik) => {
            mutation.mutate({ name: values.name }, { onError: () => formik.setSubmitting(false) });
          }}
          validationSchema={schema}
          validateOnMount
        >
          {({ isSubmitting, isValid }) => {
            const canSubmit = !isSubmitting && isValid;
            return (
              <>
                {mutation.isError ? (
                  <div className="font-normal mb-4">
                    <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
                  </div>
                ) : null}

                <Form className="flex flex-col flex-grow text-left">
                  <div className="flex-grow space-y-4">
                    <div className="">
                      <label>
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('accountName')}</div>
                        <div>
                          <Field as={Input} name="name" maxLength={255} />
                        </div>
                      </label>
                      <div className="font-normal mt-2 text-sm text-gray-500">{t('signup.accountNameHint')}</div>
                    </div>

                    <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                      <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                        {t('continue')}
                      </PrimaryButton>
                    </div>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </OutsideAccountLayout>
  );
};

export default OwnAccountCreatePage;
