import { get, setWith } from 'lodash';
import { stringSorter } from './sort';
import { Account, Dict, Store } from './types';

export type LocaleDefinition = {
  name: string;
  tag: string;
};

export const formatLocaleName = (locale: LocaleDefinition) => {
  return `${locale.name}`; // ` - ${locale.tag}`;
};

export const getAvailableLocales = () => {
  return availableLocales;
};

export const getLocaleName = (tag: string) => {
  const locale = availableLocales.find((k) => k.tag === tag);
  return locale ? formatLocaleName(locale) : '?';
};

export const getStoreLocales = (account: Account, store: Store): LocaleDefinition[] => {
  const [defaultLocale, ...additionalLocales] = getStoreLocaleTags(account, store).map((tag) => ({
    tag: tag,
    name: getLocaleName(tag),
  }));
  return !defaultLocale ? [] : [defaultLocale, ...additionalLocales.sort((a, b) => stringSorter(a.name, b.name))];
};

export const getStoreLocaleTags = (account: Account, store: Store): string[] => {
  if (!account.policy.use_l10n) {
    return [];
  }
  return store.locales || [];
};

export const convertL10nValuesArrayToDict = <T extends Dict, TLoc extends string>(
  data?: (T & { locale: TLoc })[]
): { [K in TLoc]: T } => {
  return (data || [])
    .filter((item) => Boolean(item.locale))
    .reduce<{ [K in TLoc]: T }>((carry, item) => ({ ...carry, [item.locale]: item }), {} as any);
};

export const convertL10nValuesDictToArray = <T extends Dict, TMap extends { [K in string]: T }>(
  data: TMap
): (T & { locale: keyof TMap })[] => {
  return Object.keys(data || {}).map((locale) => ({
    locale,
    ...data[locale],
  }));
};

/**
 * Clean the l10n value.
 *
 * Empty strings should be returned as undefined or when dealing
 * with states and inputs, we could be identifying an empty string
 * as a value and thus not fallback on other locales.
 */
function cleanL10nValue(value: any) {
  if (typeof value === 'string' && !value.length) {
    return undefined;
  }
  return value;
}

/**
 * Get a potentially localised value.
 *
 * If a locale is passed, attempts to identify the value by
 * looking at the property 'l10n' of the object passed.
 * The l10n property is an object where the properties are the
 * name of the locale. See {@link convertL10nValuesArrayToDict}.
 *
 * When the locale is falsy, or when it is not but the value is undefined
 * and fallbackOnDefault is true, returns the property of the object.
 */
export const getL10nValue = (object: Dict, property: string, locale?: string, fallbackOnDefault = false) => {
  let value;
  if (locale) {
    value = get((object.l10n || {})[locale] || {}, property);
  }
  if (!locale || (typeof value === 'undefined' && fallbackOnDefault)) {
    value = get(object, property);
  }
  return value;
};

/**
 * Whether the L10n array is multilingual.
 */
export function isL10nArrayMultiLang(l10n?: { locale: string }[], defaultLocale?: string) {
  if (!defaultLocale || !l10n?.length) return false;
  return Boolean(l10n.find((l) => l.locale !== defaultLocale));
}

/**
 * Set the default l10n value if missing.
 *
 * This checks that there is a l10n entry for the locale provided, and
 * that it contains all the properties that are expected. If not it will
 * get the values from the object, similarly to what getL10nValue would
 * do with fallback enabled.
 *
 * This expect the l10n object to be a dictionnary.
 */
export const setDefaultL10nForLocaleIfMissing = <T extends Dict>(object: T, locale: string, properties: string[]): T => {
  if (!locale) {
    return object;
  }
  const l10n = object.l10n || {};
  return {
    ...object,
    l10n: {
      ...l10n,
      [locale]: properties.reduce((carry, property) => {
        if (typeof carry[property] !== 'undefined') {
          // The property is already defined on the key.
          return carry;
        }
        const value = cleanL10nValue(get(object, property));
        if (typeof value === 'undefined') {
          // The property from the object does not exist.
          return carry;
        }
        return setWith({ ...carry }, property, value, Object);
      }, l10n[locale] || {}),
    },
  };
};

/**
 * Set a potentially localised value.
 *
 * If a locale is passed, set the value into the property 'l10n'
 * at the key defined by property. Th l10n property is an object
 * where the properties are the name of the locale. See
 * {@link convertL10nValuesArrayToDict}.
 *
 * When the locale is falsy, set the property on the object.
 */
export const setL10nValue = <T extends Dict>(
  object: T,
  property: string,
  value: string,
  locale?: string,
  alsoSetDefault = false
): T => {
  value = cleanL10nValue(value);
  let data = { ...object };
  if (!locale || alsoSetDefault) {
    data = setWith({ ...data }, property, value, Object);
  }
  if (locale) {
    const l10n = object.l10n || {};
    data = {
      ...data,
      l10n: {
        ...l10n,
        [locale]: setWith({ ...(l10n[locale] || {}) }, property, value, Object),
      },
    };
  }
  return data;
};

const availableLocales: LocaleDefinition[] = [
  { name: 'Afrikaans', tag: 'af' },
  { name: 'Amharic', tag: 'am' },
  { name: 'Arabic', tag: 'ar' },
  { name: 'Azerbaijani', tag: 'az' },
  { name: 'Belarusian', tag: 'be' },
  { name: 'Bulgarian', tag: 'bg' },
  { name: 'Bangla', tag: 'bn' },
  { name: 'Catalan', tag: 'ca' },
  { name: 'Czech', tag: 'cs' },
  { name: 'Danish', tag: 'da' },
  { name: 'German', tag: 'de' },
  { name: 'Greek', tag: 'el' },
  { name: 'English', tag: 'en' },
  { name: 'English (Canada)', tag: 'en-CA' },
  { name: 'English (United Kingdom)', tag: 'en-GB' },
  { name: 'English (United States)', tag: 'en-US' },
  { name: 'Spanish', tag: 'es' },
  { name: 'Spanish (Argentina)', tag: 'es-AR' },
  { name: 'Spanish (Chile)', tag: 'es-CL' },
  { name: 'Spanish (Colombia)', tag: 'es-CO' },
  { name: 'Spanish (Spain)', tag: 'es-ES' },
  { name: 'Spanish (Mexico)', tag: 'es-MX' },
  { name: 'Spanish (Venezuela)', tag: 'es-VE' },
  { name: 'Estonian', tag: 'et' },
  { name: 'Basque', tag: 'eu' },
  { name: 'Persian', tag: 'fa' },
  { name: 'Finnish', tag: 'fi' },
  { name: 'Filipino', tag: 'fil' },
  { name: 'French', tag: 'fr' },
  { name: 'Galician', tag: 'gl' },
  { name: 'Gujarati', tag: 'gu' },
  { name: 'Hindi', tag: 'hi' },
  { name: 'Hebrew', tag: 'he' },
  { name: 'Croatian', tag: 'hr' },
  { name: 'Hungarian', tag: 'hu' },
  { name: 'Armenian', tag: 'hy' },
  { name: 'Indonesian', tag: 'id' },
  { name: 'Icelandic', tag: 'is' },
  { name: 'Italian', tag: 'it' },
  { name: 'Japanese', tag: 'ja' },
  { name: 'Georgian', tag: 'ka' },
  { name: 'Kazakh', tag: 'kk' },
  { name: 'Khmer', tag: 'km' },
  { name: 'Kannada', tag: 'kn' },
  { name: 'Korean', tag: 'ko' },
  { name: 'Kyrgyz', tag: 'ky' },
  { name: 'Lao', tag: 'lo' },
  { name: 'Lithuanian', tag: 'lt' },
  { name: 'Latvian', tag: 'lv' },
  { name: 'Macedonian', tag: 'mk' },
  { name: 'Malayalam', tag: 'ml' },
  { name: 'Mongolian', tag: 'mn' },
  { name: 'Marathi', tag: 'mr' },
  { name: 'Malay', tag: 'ms' },
  { name: 'Burmese', tag: 'my' },
  { name: 'Nepali', tag: 'ne' },
  { name: 'Dutch', tag: 'nl' },
  { name: 'Norwegian', tag: 'no' },
  { name: 'Punjabi', tag: 'pa' },
  { name: 'Polish', tag: 'pl' },
  { name: 'Portuguese (Brazil)', tag: 'pt-BR' },
  { name: 'Portuguese (Portugal)', tag: 'pt-PT' },
  { name: 'Romansh', tag: 'rm' },
  { name: 'Romanian', tag: 'ro' },
  { name: 'Russian', tag: 'ru' },
  { name: 'Sinhala', tag: 'si' },
  { name: 'Slovak', tag: 'sk' },
  { name: 'Slovenian', tag: 'sl' },
  { name: 'Albanian', tag: 'sq' },
  { name: 'Serbian', tag: 'sr' },
  { name: 'Swedish', tag: 'sv' },
  { name: 'Swedish (Finland)', tag: 'sv-FI' },
  { name: 'Kiswahili', tag: 'sw' },
  { name: 'Tamil', tag: 'ta' },
  { name: 'Telugu', tag: 'te' },
  { name: 'Thai', tag: 'th' },
  { name: 'Turkish', tag: 'tr' },
  { name: 'Ukrainian', tag: 'uk' },
  { name: 'Urdu', tag: 'ur' },
  { name: 'Vietnamese', tag: 'vi' },
  { name: 'Chinese (Simplified)', tag: 'zh-CN' },
  { name: 'Chinese (Hong Kong)', tag: 'zh-HK' },
  { name: 'Chinese (Traditional)', tag: 'zh-TW' },
  { name: 'Zulu', tag: 'zu' },
].sort((a, b) => stringSorter(a.name, b.name));
