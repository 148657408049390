import { ArrowLongDownIcon, UserMinusIcon } from '@heroicons/react/24/solid';
import download from 'downloadjs';
import { isUndefined } from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { hasTicketsEnabled, useAccount } from '../../lib/account';
import { usePermissionChecker, useSelection } from '../../lib/hooks';
import { usePlayerCoinsMutation, usePlayerDeleteMutation, usePlayerTicketsMutation } from '../../lib/mutations';
import { useContinuatedQuery } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Permission, Player } from '../../lib/types';
import { Button } from '../Buttons';
import { AddCoinsModal, AddTicketsModal, DeletePlayerModal } from '../Modals';
import { HasAccountPermission, HasAnyWhereInAccountPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import PlayersNav from '../nav/PlayersNav';
import { PlayerQuotaWarning } from '../players/QuotaWarning';
import PlayersTable from '../players/Table';
import { PlayersFilters, PlayersFiltersContext, PlayersFiltersProvider } from '../transactions/Filters';
import Dropdown from '../widgets/Dropdown';

const PLAYERS_DOWNLOAD_LIMIT = 10000; // Sync with backend.

const useUsers = (searchTerm?: string, orderBy?: string) => {
  const { id: accountId } = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useContinuatedQuery(
    ['users', { searchTerm, orderBy, accountId }],
    ({ pageParam }) => {
      return repo.getPlayers(accountId, { term: searchTerm }, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, accountId],
      onSuccess: (data) => {
        data.items.forEach((player) => {
          queryClient.setQueryData(['user', player.id], player);
        });
      },
    }
  );
};

const usePlayersExportMutation = (
  filters?: {
    term?: string;
  },
  orderBy?: string
) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  return useMutation(() => repo.exportPlayers(accountId, filters, orderBy), {
    onSuccess: (data) => {
      download(data, 'players.csv', 'text/csv');
    },
  });
};

const findPlayerWithId = (players: Player[] | undefined, id: string): Player | undefined => {
  if (!players) return;
  return players.find((player) => player.id === id);
};

const PlayersPage = () => {
  return (
    <LayoutWithSidebar>
      <div>
        <PlayersFiltersProvider>
          <PlayersPageContent />
        </PlayersFiltersProvider>
      </div>
    </LayoutWithSidebar>
  );
};

const PlayersPageContent = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const history = useHistory();
  const {
    filter: { filterTerm },
    sorting,
  } = useContext(PlayersFiltersContext);
  const query = useUsers(filterTerm, sorting.sortField);
  const exportMutation = usePlayersExportMutation({ term: filterTerm }, sorting.sortField);
  const selection = useSelection(query);
  const [toDeleteItems, setToDeleteItems] = useState<string[]>([]);
  const [toAddCoinsItems, setToAddCoinsItems] = useState<string[]>([]);
  const [toAddTicketsItems, setToAddTicketsItems] = useState<string[]>([]);
  const deleteMutation = usePlayerDeleteMutation(query.queryKey);
  const userCoinsMutation = usePlayerCoinsMutation();
  const userTicketsMutation = usePlayerTicketsMutation();
  const permChecker = usePermissionChecker();

  const { isLoading: isDownloading } = exportMutation;
  const totalRecords = query?.data?.total || 0;
  const canDownload = query.isSuccess && totalRecords <= PLAYERS_DOWNLOAD_LIMIT && totalRecords > 0 && !isDownloading;

  const handleAddCoins = (coins: number, message?: string, reason?: string) => {
    if (!toAddCoinsItems.length) {
      return;
    }
    setToAddCoinsItems([]);

    // This may not scale well for large number of users.
    toAddCoinsItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      userCoinsMutation.mutate(
        { player, coins, message, reason },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toAddCoinsItems);
            broadcastSuccessToast(t('coinsAwarded'));
          },
        }
      );
    });
  };

  const handleAddTickets = (amount: number) => {
    if (!toAddTicketsItems.length) {
      return;
    }
    setToAddTicketsItems([]);

    // This may not scale well for large number of users.
    toAddTicketsItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      userTicketsMutation.mutate(
        { player, amount },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toAddTicketsItems);
            broadcastSuccessToast(t('ticketsAwarded'));
          },
        }
      );
    });
  };

  const handleDelete = () => {
    if (!toDeleteItems.length) {
      return;
    }
    setToDeleteItems([]);

    // This may not scale well for large number of users.
    toDeleteItems.forEach((id) => {
      const player = findPlayerWithId(query.data?.items, id);
      if (!player) return;
      deleteMutation.mutate(
        { player },
        {
          onSuccess: () => {
            // The callback will be called for the last registered mutation.
            selection.removeFromSelection(toDeleteItems);
            broadcastSuccessToast(t('playerDeleted', { count: toDeleteItems.length }));
          },
        }
      );
    });
  };

  const actions = [
    {
      label: t('addCoins'),
      can: permChecker.hasInAllTeamsPermission(Permission.AwardCoin),
      onClick: (ids: string[]) => setToAddCoinsItems(ids),
    },
    {
      label: t('addTickets'),
      can: hasTicketsEnabled(account) && permChecker.hasInAllTeamsPermission(Permission.AwardCoin),
      onClick: (ids: string[]) => setToAddTicketsItems(ids),
    },
    {
      label: t('delete'),
      danger: true,
      onClick: (ids: string[]) => setToDeleteItems(ids),
      can: permChecker.hasInAllTeamsPermission(Permission.DeletePlayer),
    },
  ].filter((action) => isUndefined(action.can) || action.can);

  return (
    <>
      <PageHeaderWrappingNav
        title={
          <>
            {t('players')}
            {account.policy.max_users > 0 && permChecker.hasAccountPermission(Permission.ReadPlayer) ? (
              <div className="ml-3 inline-block text-xs font-normal text-gray-500 whitespace-nowrap">
                {account.cur_users || 0} / {account.policy.max_users}
              </div>
            ) : null}
          </>
        }
        buttons={
          <>
            <HasAccountPermission perm={Permission.PurgeInactivePlayers}>
              <Button onClick={() => history.push('/players/purge')} icon={UserMinusIcon}>
                {t('purgeInactive')}
              </Button>
            </HasAccountPermission>
            <HasAnyWhereInAccountPermission perm={Permission.ReadPlayer}>
              <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
                {t('exportCsv')}
              </Button>
            </HasAnyWhereInAccountPermission>
          </>
        }
      >
        <PlayersNav />
      </PageHeaderWrappingNav>

      <PlayerQuotaWarning />

      <div className="mb-4">
        <div className="flex items-center space-x-2">
          {actions.length ? (
            <div>
              <Dropdown
                label={t('actions')}
                options={actions.map((action) => ({
                  ...action,
                  disabled: !selection.selectedIds.length,
                  onClick: () => action.onClick(selection.selectedIds),
                }))}
              />
            </div>
          ) : null}
          <div className="w-96">
            <PlayersFilters />
          </div>
        </div>
      </div>
      <div>
        <PlayersTable queryResult={query} selectionResult={selection} sortingResult={sorting} actions={actions} />
      </div>

      {toDeleteItems.length ? (
        <DeletePlayerModal onDelete={handleDelete} onCancel={() => setToDeleteItems([])} count={toDeleteItems.length} />
      ) : null}

      {toAddCoinsItems.length ? (
        <AddCoinsModal onConfirm={handleAddCoins} onCancel={() => setToAddCoinsItems([])} count={toAddCoinsItems.length} />
      ) : null}

      {toAddTicketsItems.length ? (
        <AddTicketsModal onConfirm={handleAddTickets} onCancel={() => setToAddTicketsItems([])} count={toAddTicketsItems.length} />
      ) : null}
    </>
  );
};

export default PlayersPage;
