import _ from 'lodash';
import { Account, AccountPermissions, Permission, Player } from './types';

export type AccountPermissionsExtended = AccountPermissions & {
  // Somewhere below the account-level.
  somewhere: Permission[];

  // Perms found in one or more teams, stores.
  some_stores: Permission[];
  some_teams: Permission[];

  // Perms found all teams.
  all_stores: Permission[];
  all_teams: Permission[];
};

type ActingUser = { account_ids: string[]; permissions?: Permission[]; domain_id?: string; is_superuser?: boolean };

export interface PermissionChecker {
  (user: ActingUser): boolean;
}

export const canCreateAccounts: PermissionChecker = (user) => {
  return hasPermission(user, Permission.create_account);
};

export const canSwitchAccount: PermissionChecker = (user) => {
  return user.account_ids.length > 1 || isDomainAdmin(user) || canCreateAccounts(user);
};

export function extendAccountPermissions(perms: AccountPermissions) {
  const allTeams = _.intersection(..._.values(perms.teams));
  const someTeams = _.uniq(_.flatten(_.values(perms.teams)));
  const allStores = _.intersection(..._.values(perms.stores));
  const someStores = _.uniq(_.flatten(_.values(perms.stores)));

  return {
    ...perms,

    somewhere: _.uniq(_.flatten([someTeams, someStores])),
    all_teams: allTeams,
    some_teams: someTeams,
    all_stores: allStores,
    some_stores: someStores,
  };
}

const hasPermission = (user: ActingUser, perm: Permission) => {
  return Boolean(user?.permissions?.includes(perm));
};

export const hasAccountPermission = (perms: AccountPermissions, perm: Permission) => {
  return perms.account.includes(perm);
};

export const hasAnyAccountPermission = (accountPerms: AccountPermissions, perms: Permission[]) => {
  return perms.some((perm) => accountPerms.account.includes(perm));
};

export const hasAnywhereInAccountPermission = (perms: AccountPermissionsExtended, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.somewhere.includes(perm)) {
    return true;
  }
  return false;
};

/**
 * Whether the user has an implicity permission on the user.
 *
 * Essentially, this checks whether the user has the permission in a store
 * related to the user, as opposed to limiting the permission check to the
 * team in which the user is.
 *
 * /!\ This should only be used where implicit permissions are enough, for
 *     instance you wouldn't want to use this to check whether the user can
 *     be deleted because a related should never be able to do so anyway.
 */
export const hasImplicitPlayerPermission = (
  player: Pick<Player, 'school_id' | 'store_ids_interacted_with'>,
  perms: AccountPermissionsExtended,
  perm: Permission
) => {
  if (hasTeamPermission(player.school_id, perms, perm)) {
    return true;
  } else if ((player.store_ids_interacted_with || []).some((storeId) => hasStorePermission(storeId, perms, perm))) {
    return true;
  }
  return false;
};

export const hasInAllStoresPermission = (perms: AccountPermissionsExtended, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.all_stores.includes(perm)) {
    return true;
  }
  return false;
};

export const hasInAllTeamsPermission = (perms: AccountPermissionsExtended, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.all_teams.includes(perm)) {
    return true;
  }
  return false;
};

export const hasInAnyStorePermission = (perms: AccountPermissionsExtended, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.some_stores.includes(perm)) {
    return true;
  }
  return false;
};

export const hasInAnyTeamPermission = (perms: AccountPermissionsExtended, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.some_teams.includes(perm)) {
    return true;
  }
  return false;
};

export const hasOrderPermission = (order: { school_id: string; store_id: string }, perms: AccountPermissions, perm: Permission) => {
  return hasStorePermission(order.store_id, perms, perm) || hasTeamPermission(order.school_id, perms, perm);
};

export const hasStorePermission = (storeId: string, perms: AccountPermissions, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.stores[storeId]?.includes(perm)) {
    return true;
  }
  return false;
};

export const hasTeamPermission = (teamId: string, perms: AccountPermissions, perm: Permission) => {
  if (perms.account.includes(perm)) {
    return true;
  } else if (perms.teams[teamId]?.includes(perm)) {
    return true;
  }
  return false;
};

export const isCoreDomain = (user: ActingUser) => user.domain_id === 'motrain-mootivated';
export const isDomainAdmin = (user: ActingUser) => Boolean(user.domain_id);
export const isDomainAdminOf = (user: ActingUser, account: Account) => isDomainAdmin(user) && account.domain_id === user.domain_id;
export const isSuperUser = (user: ActingUser) => Boolean(user.is_superuser);
