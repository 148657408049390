import { useTranslation } from 'react-i18next';
import { useFieldError } from '../../lib/hooks';

const FieldError = ({ name }: { name?: string }) => {
  const { t } = useTranslation();
  const { hasError, stringKey } = useFieldError(name);
  return hasError ? <p className="mt-2 text-sm text-red-600">{t(stringKey)}</p> : null;
};

export default FieldError;
