import { AxiosError } from 'axios';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { Player } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import { TeamPageFreeLayout, useTeamPage } from '../layouts/TeamPage';
import { NotificationError } from '../Notifications';
import { broadcastSuccessToast } from '../Toasts';

const playerSchema = yup
  .object({ firstname: yup.string().required(), lastname: yup.string().required(), email: yup.string().email().required() })
  .required();

const usePlayerCreateMutation = (teamId: string) => {
  const { t } = useTranslation();
  const { id: accountId } = useAccount();
  const repo = useRepo();
  const queryClient = useQueryClient();
  return useMutation<Player, AxiosError, yup.InferType<typeof playerSchema>>(
    (variables) => {
      return repo.createPlayer(teamId, variables);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['users']);
        queryClient.invalidateQueries(['accounts']);
        queryClient.invalidateQueries(['account', accountId]);
        queryClient.invalidateQueries(['team-players', teamId]);
        broadcastSuccessToast(t('playerAdded'));
      },
    }
  );
};

const PlayerCreateContent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { team } = useTeamPage();

  const handleBack = () => {
    history.goBack();
  };

  const mutation = usePlayerCreateMutation(team.id);

  return (
    <div className="flex flex-col flex-grow">
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
        }}
        onSubmit={(values, formik) => {
          mutation.mutate(values, {
            onSuccess: (player) => {
              history.push(`/player/${player.id}`);
            },
            onError: () => {
              // TODO Handle error.
            },
            onSettled: () => {
              formik.setSubmitting(false);
            },
          });
        }}
        validationSchema={playerSchema}
        validateOnMount
      >
        {(formik) => {
          const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;
          let errorMessage: string | undefined = undefined;
          if (
            mutation.isError &&
            mutation?.error?.response?.status === 422 &&
            mutation?.error?.response?.data.code === 'PLAYER_QUOTA_EXCEEDED'
          ) {
            errorMessage = t('error.playerQuotaMetNoMoreAllowed');
          }

          return (
            <Form className="flex flex-col flex-grow">
              {mutation.isError ? (
                <div className="mb-4">
                  <NotificationError>{errorMessage || getErrorMessage(mutation.error)}</NotificationError>
                </div>
              ) : null}
              <div className="flex-grow space-y-8">
                <div className="flex space-x-6">
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('firstName')}</div>
                      <div>
                        <Field as={Input} name="firstname" />
                      </div>
                    </label>
                  </div>
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('lastName')}</div>
                      <div>
                        <Field as={Input} name="lastname" />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="w-96">
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                    <div>
                      <Field as={Input} name="email" type="email" />
                    </div>
                  </label>
                </div>
              </div>

              <div className="w-full border-t border-gray-100 mt-8 pt-4 flex">
                <div className="flex-grow"></div>
                <div className="flex flex-row-reverse items-end">
                  <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                    {t('save')}
                  </PrimaryButton>
                  <Button onClick={() => handleBack()}>{t('discard')}</Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const Header = () => {
  const { team } = useTeamPage();
  const { t } = useTranslation();
  return <PageHeaderWithBackLabel backLabel={team.name}>{t('untitledPlayer')}</PageHeaderWithBackLabel>;
};

const TeamAddPlayerPage = () => {
  return (
    <TeamPageFreeLayout header={<Header />}>
      <PlayerCreateContent />
    </TeamPageFreeLayout>
  );
};

export default TeamAddPlayerPage;
