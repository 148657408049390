import { useFormikContext } from 'formik';
import _uniq from 'lodash.uniq';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { QueryObserverResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { AccountContext } from './account';
import {
  hasAccountPermission,
  hasAnyAccountPermission,
  hasAnywhereInAccountPermission,
  hasImplicitPlayerPermission,
  hasInAnyStorePermission,
  hasInAnyTeamPermission,
  hasOrderPermission,
  hasStorePermission,
  hasTeamPermission,
} from './perms';
import { AccountRole, Order, Permission, Player } from './types';

export type UseAnchorButtonProps = {
  role: 'button';
  tabIndex: 0;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLAnchorElement>) => void;
};

export const useAnchorButtonProps = (onClick: () => void): UseAnchorButtonProps => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === ' ') e.preventDefault();
  };
  const handleKeyUp = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onClick();
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick();
  };
  return { role: 'button', tabIndex: 0, onClick: handleClick, onKeyDown: handleKeyDown, onKeyUp: handleKeyUp };
};

export const useFieldError = (name?: string) => {
  // We can't use useField() because it throws an error when there is no formik.
  const formik = useFormikContext<any>();
  const meta = formik?.getFieldMeta(name && name.length ? name : '__noname');
  const hasError = meta?.error && meta?.touched;
  return {
    hasError,
    stringKey: meta?.error?.startsWith('i18next:') ? meta?.error?.slice(8) : 'validation:invalidData',
  };
};

export type UseFilteringType = {
  filterTerm?: string;
  filterInputProps: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    withClear: boolean;
    onClear: () => void;
  };
};

export const useFiltering = (): UseFilteringType => {
  const [filterTerm, setFilterTerm] = useState('');
  const [effectiveTerm] = useDebounce(filterTerm, 300);

  return {
    filterTerm: typeof effectiveTerm === 'string' && effectiveTerm !== '' ? effectiveTerm : undefined,
    filterInputProps: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFilterTerm(e.target.value),
      value: filterTerm,
      withClear: Boolean(filterTerm),
      onClear: () => setFilterTerm(''),
    },
  };
};

export const useManagedFiltering = (onChange: (term?: string) => void, term?: string): UseFilteringType => {
  const [filterTerm, setFilterTerm] = useState(term);
  const [effectiveTerm] = useDebounce(filterTerm, 300);

  const onClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  // When the term passed changes, reset the internal state.
  useEffect(() => {
    setFilterTerm(term || '');
  }, [term]);

  // Debounce the value entered in the field, and report of the change.
  useEffect(() => {
    onChange(effectiveTerm);
  }, [effectiveTerm, onChange]);

  return {
    filterTerm: typeof term === 'string' && term !== '' ? term : undefined,
    filterInputProps: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFilterTerm(e.target.value),
      value: filterTerm,
      withClear: Boolean(filterTerm),
      onClear: onClear,
    },
  };
};

export const useMembership = () => {
  const { membership } = useContext(AccountContext);
  return membership;
};

export const useNavigateTo = () => {
  const history = useHistory();
  return (path: string) => history.push(path);
};

export const useOnce = (fn: () => void) => {
  useEffect(fn, []); // eslint-disable-line
};

export const usePeriodicRerender = () => {
  useRefresher(1);
};

export const useRefresher = (intervalSecs = 30) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const t = setTimeout(() => {
      setCount(count > 9999999 ? 0 : count + 1);
    }, intervalSecs * 1000);
    return () => clearTimeout(t);
  });
};

export const usePermissionChecker = () => {
  const { membership, permissions } = useContext(AccountContext);

  const checker = useMemo(() => {
    return {
      canAssignRole: (role: AccountRole) => membership && membership.assignable_roles.includes(role),
      hasAnywhereInAccountPermission: (perm: Permission) =>
        permissions ? hasAnywhereInAccountPermission(permissions, perm) : false,
      hasAnyAccountPermission: (perms: Permission[]) => (permissions ? hasAnyAccountPermission(permissions, perms) : false),
      hasImplicityPlayerPermission: (player: Player, perm: Permission) =>
        permissions ? hasImplicitPlayerPermission(player, permissions, perm) : false,
      hasInAnyStorePermission: (perm: Permission) => (permissions ? hasInAnyStorePermission(permissions, perm) : false),
      hasInAnyTeamPermission: (perm: Permission) => (permissions ? hasInAnyTeamPermission(permissions, perm) : false),
      hasInAllStoresPermission: (perm: Permission) => (permissions ? hasInAnyStorePermission(permissions, perm) : false),
      hasInAllTeamsPermission: (perm: Permission) => (permissions ? hasInAnyTeamPermission(permissions, perm) : false),
      hasOrderPermission: (order: Order, perm: Permission) => (permissions ? hasOrderPermission(order, permissions, perm) : false),
      hasStorePermission: (storeId: string, perm: Permission) =>
        permissions ? hasStorePermission(storeId, permissions, perm) : false,
      hasTeamPermission: (teamId: string, perm: Permission) => (permissions ? hasTeamPermission(teamId, permissions, perm) : false),
      hasAccountPermission: (perm: Permission) => (permissions ? hasAccountPermission(permissions, perm) : false),
    };
  }, [membership, permissions]);

  return checker;
};

export type UseSelectionResult<T extends { id: string } = { id: string }> = {
  isEntirePageSelectable: boolean;
  isEntirePageSelected: boolean;
  isSelectable: (item: T) => boolean;
  isSelected: (item: T) => boolean;
  selectedIds: string[];
  removeFromSelection: (ids: string[]) => void;
  onSelectAllChange: (allChecked: boolean) => void;
  onSelectionChange: (item: T) => void;
};

export const useSelection = <
  T extends { id: string } = { id: string },
  TQueryResult extends QueryObserverResult<{ items: T[] }> = QueryObserverResult<{ items: T[] }>
>(
  queryResult: TQueryResult,
  exceptItems: T['id'][] | ((item: T) => boolean) = []
): UseSelectionResult<T> => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data, isLoading } = queryResult;
  const isSelectable = Array.isArray(exceptItems) ? (item: T) => !exceptItems.includes(item.id) : (item: T) => !exceptItems(item);

  const selectableIdsInPage = (data?.items.filter((item) => isSelectable(item)) || []).map((item) => item.id);
  const isEntirePageSelectable = isLoading || Boolean(selectableIdsInPage.length);
  const isEntirePageSelected = Boolean(selectableIdsInPage.length) && !selectableIdsInPage.some((id) => !selectedIds.includes(id));

  return {
    isEntirePageSelectable,
    isEntirePageSelected,
    isSelectable,
    isSelected: (item: T) => selectedIds.includes(item.id),
    selectedIds,

    removeFromSelection: (ids: string[]) => {
      setSelectedIds(selectedIds.filter((id) => !ids.includes(id)));
    },
    onSelectAllChange: (allChecked: boolean) => {
      if (allChecked) {
        setSelectedIds(_uniq(selectedIds.concat(selectableIdsInPage)));
      } else {
        setSelectedIds(selectedIds.filter((id) => !selectableIdsInPage.includes(id)));
      }
    },
    onSelectionChange: (item: T) => {
      if (!isSelectable(item)) {
        return;
      }
      const id = item.id;
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter((x) => x !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    },
  };
};

export type UseSortingResult = {
  sortField: string;
  sortKey: string | null;
  isDescending: boolean;
  isKeySelected: (key: string) => boolean;
  onChange: (key: string) => void;
};

export const useSorting = (defaultKey: string, defaultDescendings: { [index: string]: boolean }): UseSortingResult => {
  const [[sortKey, sortIsDescending], setSortKey] = useState<[string | null, boolean]>([null, false]);

  const getDefaultIsDescending = (key: string) =>
    typeof defaultDescendings[key] !== 'undefined' ? defaultDescendings[key] : false;

  const effectiveSortKey = sortKey || defaultKey;
  const effectiveIsDescending = sortKey ? sortIsDescending : getDefaultIsDescending(defaultKey);

  const onChange = (newKey: string) => {
    let isNewDescending = getDefaultIsDescending(newKey);
    if (effectiveSortKey === newKey) {
      isNewDescending = !effectiveIsDescending;
    }
    setSortKey([newKey, isNewDescending]);
  };

  const sortField = `${effectiveIsDescending ? '-' : ''}${effectiveSortKey}`;

  return {
    sortField: sortField,
    sortKey: sortKey,
    isDescending: sortIsDescending,
    isKeySelected: (key: string) => sortKey === key,
    onChange,
  };
};
