const CheckIcon = (props: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04297 9.56366C1.4352 7.86314 -1.13563 10.453 0.552399 12.0727C2.6417 14.2583 4.731 16.5247 6.89788 18.6268C7.86094 19.5162 8.5859 19.5162 9.46871 18.6268L24.8134 3.24936C26.4212 1.54884 23.9306 -1.04102 22.2426 0.659502L8.26488 14.7434L3.04297 9.56366Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckIcon;
