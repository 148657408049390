import React from 'react';
import { useAccount } from '../../lib/account';
import AccountNav from '../nav/AccountNav';
import { PageHeaderWrappingNav } from './partials/PageHeaders';
import LayoutWithSidebar from './WithSidebar';

const AccountSettingsPageLayout: React.FC<{}> = ({ children }) => {
  const account = useAccount();

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col flex-grow">
        <PageHeaderWrappingNav title={account.name}>
          <AccountNav />
        </PageHeaderWrappingNav>
        {children}
      </div>
    </LayoutWithSidebar>
  );
};

export default AccountSettingsPageLayout;
