import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../lib/hooks';
import { usePendingOrdersCount } from '../../lib/queries';
import { Permission } from '../../lib/types';
import { PillLink, PillNav } from './Pill';

const PlayersNav = () => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  const canViewOrders = permChecker.hasAnywhereInAccountPermission(Permission.ReadOrder);
  const pendingOrdersQuery = usePendingOrdersCount({
    enabled: canViewOrders,
  });
  return (
    <PillNav>
      {[
        { name: t('all'), href: '/players', exact: true },
        {
          name: t('orders'),
          href: '/players/orders',
          can: canViewOrders,
          badgeCount: pendingOrdersQuery.isSuccess ? pendingOrdersQuery.data : 0,
        },
      ]
        .filter((nav) => isUndefined(nav.can) || nav.can)
        .map((tab) => (
          <PillLink key={tab.href} to={tab.href} exact={tab.exact} badgeCount={tab?.badgeCount}>
            {tab.name}
          </PillLink>
        ))}
    </PillNav>
  );
};

export default PlayersNav;
