import queryString from 'query-string';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../lib/auth';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { getAccountRoleStringKey, getLogoutUrl } from '../../lib/utils';
import { setLastAccountId } from '../AccountNegotiator';
import { Button, PrimaryButton } from '../Buttons';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import Spinner from '../widgets/Spinner';

const useInvitationSummary = (id: string, secret: string) => {
  const repo = useRepo();
  return useQuery(
    ['invitation-summary', id],
    async () => {
      return repo.getInvitationSummary(id, secret);
    },
    { retry: false }
  );
};

const useInvitationAcceptMutation = () => {
  const repo = useRepo();
  return useMutation(({ id, secret }: { id: string; secret: string }) => {
    return repo.acceptInvitation(id, secret);
  }, {});
};

const InvitationConfirmPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ invitationId: string; secret: string }>();
  const { isLoading, isSuccess, data, error } = useInvitationSummary(params.invitationId, params.secret);
  const mutation = useInvitationAcceptMutation();
  const queryClient = useQueryClient();
  const authContext = useContext(AuthContext);

  const handleAccept = () => {
    mutation.mutate(
      { id: params.invitationId, secret: params.secret },
      {
        onSuccess: async (data) => {
          await authContext.refreshUser();
          setLastAccountId(data.account_id); // This is a dirty hack!
          history.push('/');
        },
        onError: () => {
          queryClient.invalidateQueries(['invitation-summary', params.invitationId]);
        },
      }
    );
  };

  const canSubmit = mutation.isLoading || mutation.isSuccess;

  return (
    <OutsideAccountLayout>
      <div className="flex-grow flex justify-center items-center">
        <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="text-sm">
              {isSuccess && data ? (
                <>
                  <p className="">
                    <Trans
                      i18nKey="youHaveBeenInvitedByForIn"
                      t={t}
                      components={{
                        1: <strong />,
                        2: <strong />,
                        3: <strong />,
                      }}
                      values={{
                        role: t(getAccountRoleStringKey(data.role)),
                        account: data.account_name,
                        name: data.invited_by_name,
                      }}
                    />
                  </p>
                  <div className="w-full border-t border-gray-100 mt-10 pt-4 flex justify-between items-start">
                    <div className="mr-4 self-center">
                      <Link to={getLogoutUrl(location)} className="text-blue-600">
                        {t('useAnotherAccount')}
                      </Link>
                    </div>
                    <div className="flex flex-row-reverse items-end flex-shrink-0">
                      <PrimaryButton onClick={handleAccept} className="ml-3" disabled={canSubmit}>
                        {t('accept')}
                      </PrimaryButton>
                      <Button onClick={() => history.push('/')} disabled={canSubmit}>
                        {t('decline')}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    {getErrorMessage(error, {
                      default: 'invitationInvalidOrExpired',
                      ALREADY_MEMBER: 'youAlreadyAreAdmin',
                    })}
                  </p>
                  <div className="w-full border-t border-gray-100 mt-6 pt-4 flex flex-row-reverse items-end flex-shrink-0">
                    <PrimaryButton onClick={() => history.push('/')}>{t('continue')}</PrimaryButton>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </OutsideAccountLayout>
  );
};

export default InvitationConfirmPage;
