import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../lib/utils';
import { PlainButton } from '../Buttons';
import Checkbox from '../inputs/Checkbox';
import { ShorteningTagList, TagComponent } from './Tag';

export type DropdownSelectOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type DropdownSelectprops = {
  placeholder: string;
  selected?: DropdownSelectOption;
  disabled?: boolean;
  options: (DropdownSelectOption | 'divider')[];
  clearable?: boolean;
  onChange: (value: DropdownSelectOption) => void;
  onClear?: () => void;
  origin?: 'top-right' | 'bottom';
};

const DropdownSelect: React.FC<DropdownSelectprops> = ({
  selected,
  options,
  onChange,
  onClear,
  placeholder,
  disabled,
  clearable,
  origin,
}) => {
  const { t } = useTranslation();
  const usePlaceholder = !selected;

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClear && onClear();
  };

  const handleClearKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key !== ' ' && e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClearKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key !== ' ' && e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onClear && onClear();
  };

  const showClear = !disabled && clearable && selected;

  let originClass = 'mt-2';
  if (origin === 'bottom') {
    originClass = 'origin-bottom-left bottom-9 mb-2';
  } else if (origin === 'top-right') {
    originClass = 'origin-top-right right-0 mt-2';
  }

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          className={classNames(
            'flex text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50',
            usePlaceholder ? 'text-gray-500' : 'text-gray-700 font-medium',
            'disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-default disabled:text-gray-400'
          )}
        >
          <span className="flex-grow block truncate">{usePlaceholder ? placeholder : selected.label}</span>
          <div className="flex-shrink-0 ml-2 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
            {showClear ? (
              <div className="inline-flex mr-2 flex-shrink-0">
                <PlainButton onClick={handleClear} onKeyDown={handleClearKeyDown} onKeyUp={handleClearKeyUp} type="button">
                  <XMarkIcon className={classNames('h-4 w-4', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
                  <span className="sr-only">{t('clearSelection')}</span>
                </PlainButton>
              </div>
            ) : null}
            <div className="inline-flex pl-1 flex-shrink-0">
              <ChevronDownIcon className={classNames('h-5 w-5', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
            </div>
          </div>
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            className={classNames(
              'absolute z-10 w-full min-w-56 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none',
              originClass
            )}
          >
            {options.map((option, idx) => {
              if (option === 'divider') {
                return <div key={`divider-${idx}`} className="border-t border-t-gray-100 pt-1 mt-1"></div>;
              }

              return (
                <Listbox.Option
                  key={option.value}
                  disabled={option.disabled}
                  className={({ active, disabled }) =>
                    classNames(
                      'text-gray-700 select-none relative py-2 px-4',
                      disabled ? 'bg-gray-50 text-gray-500' : active ? 'text-gray-900 bg-gray-100' : '',
                      selected ? 'pr-10' : ''
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {option.label}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <CheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export type DropdownSelectSimplifiedProps = {
  placeholder?: string;
  selected?: string;
  disabled?: boolean;
  options: (DropdownSelectOption | 'divider')[];
  clearable?: boolean;
  onChange: (value: string) => void;
  onClear?: () => void;
  origin?: 'top-right' | 'bottom';
};

export const DropdownSelectSimplified: React.FC<DropdownSelectSimplifiedProps> = ({
  selected,
  options,
  onChange,
  onClear,
  placeholder,
  disabled,
  clearable,
  origin,
}) => {
  const { t } = useTranslation();
  const usePlaceholder = !selected;

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClear && onClear();
  };

  const handleClearKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key !== ' ' && e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClearKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key !== ' ' && e.key !== 'Enter') {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    onClear && onClear();
  };

  const showClear = !disabled && clearable && selected;

  let originClass = 'mt-2';
  if (origin === 'bottom') {
    originClass = 'origin-bottom-left bottom-9 mb-2';
  } else if (origin === 'top-right') {
    originClass = 'origin-top-right right-0 mt-2';
  }

  const label = useMemo(() => {
    return (options.find((option) => option !== 'divider' && option.value === selected) as DropdownSelectOption | undefined)?.label;
  }, [selected, options]);

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          className={classNames(
            'flex text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50',
            usePlaceholder ? 'text-gray-500' : 'text-gray-700 font-medium',
            'disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-default disabled:text-gray-400'
          )}
        >
          <span className="flex-grow block truncate">{usePlaceholder ? placeholder || t('selectEllipsis') : label}</span>
          <div className="flex-shrink-0 ml-2 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
            {showClear ? (
              <div className="inline-flex mr-2 flex-shrink-0">
                <PlainButton onClick={handleClear} onKeyDown={handleClearKeyDown} onKeyUp={handleClearKeyUp} type="button">
                  <XMarkIcon className={classNames('h-4 w-4', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
                  <span className="sr-only">{t('clearSelection')}</span>
                </PlainButton>
              </div>
            ) : null}
            <div className="inline-flex pl-1 flex-shrink-0">
              <ChevronDownIcon className={classNames('h-5 w-5', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
            </div>
          </div>
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            className={classNames(
              'absolute z-10 w-full min-w-56 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none',
              originClass
            )}
          >
            {options.map((option, idx) => {
              if (option === 'divider') {
                return <div key={`divider-${idx}`} className="border-t border-t-gray-100 pt-1 mt-1"></div>;
              }

              return (
                <Listbox.Option
                  key={option.value}
                  disabled={option.disabled}
                  className={({ active, disabled }) =>
                    classNames(
                      'select-none relative py-2 px-4',
                      disabled ? 'bg-gray-50 text-gray-500' : active ? 'text-gray-900 bg-gray-100' : 'text-gray-700 ',
                      selected ? 'pr-10' : ''
                    )
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {option.label}
                      </span>

                      {selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                          <CheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export const MultiDropdownSelect: React.FC<{
  options: { label: string; value: string }[];
  selected?: string[];
  onChange: (values: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  tagComponent: TagComponent;
}> = ({ options, placeholder, selected, onChange, disabled, tagComponent }) => {
  const { t } = useTranslation();
  const usePlaceholder = !selected || !selected.length;

  const labels = useMemo(() => {
    return (
      selected?.map((teamId) => {
        const team = options.find((option) => option.value === teamId);
        return team ? team.label : teamId;
      }) || []
    );
  }, [selected, options]);

  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled} multiple>
      <div className="relative w-full">
        <Listbox.Button
          className={classNames(
            'flex text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50',
            usePlaceholder ? 'text-gray-500' : 'text-gray-700 font-medium',
            'disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-default disabled:text-gray-400'
          )}
        >
          <div className="flex-grow truncate">
            {usePlaceholder ? placeholder || t('selectEllipsis') : <ShorteningTagList component={tagComponent} tags={labels} />}
          </div>
          <div className="flex-shrink-0 ml-2 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
            <div className="inline-flex pl-1 flex-shrink-0">
              <ChevronDownIcon className={classNames('h-5 w-5', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
            </div>
          </div>
        </Listbox.Button>

        <Transition
          unmount={false}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            static
            className="absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
          >
            {options.map((option) => {
              const checked = selected?.includes(option.value);
              return (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    classNames(
                      'text-gray-700 select-none relative py-2 px-4',
                      active ? 'text-gray-900 bg-gray-100' : '',
                      checked ? 'pr-10' : ''
                    )
                  }
                  value={option.value}
                >
                  {({ active }) => (
                    <>
                      <div className="flex items-center">
                        <div className="mr-2 flex-shrink-0">
                          <Checkbox checked={checked} readOnly />
                        </div>

                        <div className={classNames('font-normal flex-grow truncate')}>{option.label}</div>
                      </div>
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default DropdownSelect;
