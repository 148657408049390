import { endOfDay, startOfDay, subDays, subMonths } from 'date-fns';
import 'flatpickr/dist/flatpickr.min.css';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { TFunction, useTranslation } from 'react-i18next';
// @ts-ignore
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';
import { FIRST_DAY_OF_WEEK } from '../../lib/date';
import { useFieldError } from '../../lib/hooks';
import { classNames } from '../../lib/utils';

function getFlatpickerShortcutPlugin(onChange: (dates: Date[]) => void, t: TFunction) {
  return ShortcutButtonsPlugin({
    button: [
      { label: t('period.today') },
      { label: t('period.yesterday') },
      { label: t('period.lastNDays', { n: 7 }) },
      { label: t('period.lastNDays', { n: 30 }) },
      { label: t('period.lastNMonths', { n: 3 }) },
      { label: t('period.lastNMonths', { n: 6 }) },
    ],
    onClick: (index: number, fp: any) => {
      const now = new Date();
      let from = startOfDay(now);
      let to = endOfDay(now);

      if (index === 0) {
        from = startOfDay(now);
      } else if (index === 1) {
        from = startOfDay(subDays(now, 1));
        to = endOfDay(from);
      } else if (index === 2) {
        from = startOfDay(subDays(now, 7));
      } else if (index === 3) {
        from = startOfDay(subDays(now, 30));
      } else if (index === 4) {
        from = startOfDay(subMonths(now, 3));
      } else if (index === 5) {
        from = startOfDay(subMonths(now, 6));
      }

      fp.setDate([from, to]);
      onChange([from, to]);
    },
  });
}

export const DatePicker: React.FC<{
  name?: string;
  onChange: (date: Date | undefined) => void;
  onBlur?: () => void;
  value: Date | undefined;
  minDate?: Date;
  className?: React.HTMLProps<'input'>['className'];
}> = ({ name, onChange, onBlur, value, minDate, className }) => {
  const { hasError } = useFieldError(name);
  return (
    <Flatpickr
      options={{
        mode: 'single',
        minDate: minDate,
        locale: {
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
      }}
      onChange={(dates) => onChange(dates[0])}
      onClose={onBlur}
      value={value}
      className={classNames(
        'text-sm focus:ring-0',
        hasError ? 'placeholder-red-300 text-red-900 border-red-500 focus:border-red-500' : 'text-gray-700',
        className
      )}
    />
  );
};

export const DateTimePicker: React.FC<{
  name?: string;
  onChange: (date: Date | undefined) => void;
  onBlur?: () => void;
  value: Date | undefined;
  minDate?: Date;
  className?: React.HTMLProps<'input'>['className'];
}> = ({ name, onChange, onBlur, value, minDate, className }) => {
  const { hasError } = useFieldError(name);
  return (
    <Flatpickr
      options={{
        mode: 'single',
        minDate: minDate,
        enableTime: true,
        locale: {
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
      }}
      onChange={(dates) => onChange(dates[0])}
      onClose={onBlur}
      value={value}
      className={classNames(
        'text-sm focus:ring-0',
        hasError ? 'placeholder-red-300 text-red-900 border-red-500 focus:border-red-500' : 'text-gray-700',
        className
      )}
    />
  );
};

const DateRangePicker: React.FC<{
  onChange: (dates: (Date | undefined)[]) => void;
  onBlur?: () => void;
  value: (Date | undefined)[];
  className?: React.HTMLProps<'input'>['className'];
  maxDate?: Date;
  minDate?: Date;
}> = ({ onChange, onBlur, value, className, maxDate, minDate }) => {
  const { t } = useTranslation();
  return (
    <Flatpickr
      options={{
        mode: 'range',
        plugins: [getFlatpickerShortcutPlugin(onChange, t)],
        maxDate: maxDate,
        minDate: minDate,
        locale: {
          firstDayOfWeek: FIRST_DAY_OF_WEEK,
        },
      }}
      onChange={onChange}
      onClose={onBlur}
      value={value as Date[]}
      className={classNames('text-sm text-gray-700 border-0 focus:ring-0', className)}
    />
  );
};

export default DateRangePicker;
