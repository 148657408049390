import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { AccountContext, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { getErrorMessage } from '../../lib/i18n';
import { Level, Levels, getAccountLevels, postProcessLevelsList } from '../../lib/levels';
import { useRepo } from '../../lib/repository';
import { Permission } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import { NotificationError } from '../Notifications';
import { broadcastLoadingToast, broadcastSuccessToast, dismissToast } from '../Toasts';
import Input from '../inputs/Input';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeader } from '../layouts/partials/PageHeaders';
import { CoinIcon } from '../widgets/Coins';
import ImageFile from '../widgets/ImageFile';
import { LevelBadge } from '../widgets/Level';
import { Table, Tbody, Td, TdContextualMenu, Th, Thead, Tr } from '../widgets/Table';

type LevelImageInForm = File | undefined | null;
type LevelImagesInForm = { [index: number]: LevelImageInForm };

const useLevelsPlayerCount = (levels: Levels) => {
  const repo = useRepo();
  const account = useAccount();
  const permChecker = usePermissionChecker();
  const thresholds = levels.map((l) => l.coins);
  return useQuery(
    ['account', account.id, 'levels-player-count', thresholds],
    () => repo.getAccountLevelsPlayerCount(account.id, thresholds),
    {
      staleTime: Infinity, // This is an expensive query, we do not refetch it.
      cacheTime: 3600 * 1000, // Cache for longer.
      retry: false,
      enabled: permChecker.hasAccountPermission(Permission.ReadPlayer),
    }
  );
};

const LevelsPage = () => {
  const account = useAccount();
  const levels = useMemo(() => getAccountLevels(account), [account]);
  const { t } = useTranslation();

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeader>{t('levels')}</PageHeader>
        <LevelsPageContent levels={levels} />
      </div>
    </LayoutWithSidebar>
  );
};

const LevelsPageContent = ({ levels: accountLevels }: { levels: Levels }) => {
  const { t } = useTranslation();
  const repo = useRepo();
  const account = useAccount();
  const { refreshAccount } = useContext(AccountContext);

  const playersCount = useLevelsPlayerCount(accountLevels);
  const mutation = useMutation(
    async ({ levels, images }: { levels: Levels; images: LevelImagesInForm }) => {
      let blobs;
      let toastId;

      // Requesting the blobs.
      const requestBlobs = Object.keys(images).reduce<{ level: number; extension: string }[]>((carry, levelStr) => {
        const level = parseInt(levelStr, 10);
        const image = images[level];
        if (!image) {
          return carry;
        }
        return [...carry, { level, extension: image.name.split('.').pop() || '' }];
      }, []);
      if (requestBlobs.length) {
        toastId = broadcastLoadingToast(t('uploadingFilesPleaseWait'));
        blobs = await repo.getAccountLevelsBlobAccess(account.id, requestBlobs);
      }

      // Uploading the images.
      const blobChunks = _.chunk(blobs, 5);
      let blobNames: { [index: number]: string } = {};
      for (const chunk of blobChunks) {
        await Promise.all(
          chunk.map(async (blob) => {
            const file = images[blob.level];
            if (!file) return;
            const ingested = await repo.ingestFile(file, blob.blob_config);
            blobNames[blob.level] = `${ingested.container}/${ingested.blobName}`;
          })
        );
      }

      // Build the new level structure.
      const levelsWithImages = levels.map((level) => {
        let image: null | undefined | string = blobNames[level.level];
        if (images[level.level] === null) {
          image = null;
        }
        return {
          ...level,
          image,
        };
      });

      await repo.setAccountLevels(account.id, levelsWithImages);
      return {
        toastId,
      };
    },
    {
      onSuccess: (data) => {
        if (data?.toastId) {
          dismissToast(data.toastId);
        }
        refreshAccount();
        broadcastSuccessToast(t('levelsSaved'));
      },
      onError: () => {
        dismissToast();
      },
    }
  );

  const playersCountByThreshold = useMemo(() => {
    let result: Record<string, number> = {};
    if (!playersCount.data) {
      return result;
    }
    for (let i = 0; i < playersCount.data.length; i++) {
      const entry = playersCount.data[i];
      const nextEntry = playersCount.data[i + 1] || { coins: -1, players: 0 };
      result[`${entry.coins}_${nextEntry.coins}`] = entry.players;
    }
    return result;
  }, [playersCount.data]);

  // Create formik.
  const initialValues = useMemo(
    () => ({ levels: accountLevels, images: {} as { [index: number]: File | undefined | null } }),
    [accountLevels]
  );
  const formik = useFormik({
    initialValues,
    onSubmit: (values, formik) => {
      formik.setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => formik.setSubmitting(false),
      });
    },
  });

  // When the account changes, reset the form.
  useEffect(() => {
    formik.setValues(() => initialValues);
    formik.resetForm({ values: initialValues });
  }, [accountLevels]); // eslint-disable-line

  // All form stuff.
  const { setFieldValue, values, resetForm, isSubmitting, isValid, dirty } = formik;
  const levels = values.levels;
  const setLevels = (v: Levels) => setFieldValue('levels', v);

  const handleChange = (l: Level, coins: number) => {
    const newLevels = postProcessLevelsList(levels.filter((level) => l !== level).concat([{ ...l, coins }]));
    setLevels(newLevels);
  };

  const handleImageChange = (l: Level, image: File | undefined | null) => {
    setFieldValue('images', { ...values.images, [l.level]: image });
  };

  const handleAddLevel = () => {
    setLevels(postProcessLevelsList([...levels, { level: levels.length + 1, coins: 0 }]));
  };

  const handleDeleteLevel = (level: Level) => {
    if (level.level <= 2) {
      return;
    }
    setLevels(postProcessLevelsList(levels.filter((l) => l !== level)));
  };

  const canSubmit = !isSubmitting && isValid && dirty;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
        {mutation.isError ? (
          <div className="my-4">
            <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
          </div>
        ) : null}

        <div className="my-4">
          <p>{t('levelsSetupHelp')}</p>
        </div>
        <Table>
          <Thead>
            <Th>{t('level')}</Th>
            <Th></Th>
            <Th>{t('players')}</Th>
            <Th></Th>
          </Thead>
          <Tbody>
            {levels.map((level, i) => {
              const prevLevel = levels[i - 1];
              const nextLevel = levels[i + 1];
              const min = prevLevel?.coins || 0;
              const thresholdKey = `${level.coins}_${typeof nextLevel !== 'undefined' ? nextLevel.coins : -1}`;
              return (
                <LevelRow
                  key={level.level}
                  level={level}
                  image={values.images[level.level]}
                  onChange={(coins) => handleChange(level, coins)}
                  onImageChange={(f) => handleImageChange(level, f)}
                  min={min}
                  disabled={level.level <= 1}
                  onDelete={() => handleDeleteLevel(level)}
                  canDelete={level.level > 2 && level.level === levels.length}
                  playerCount={playersCount.isLoading || !playersCount.data ? -1 : playersCountByThreshold[thresholdKey]}
                />
              );
            })}
          </Tbody>
        </Table>

        <div className="mt-4">
          <Button onClick={() => handleAddLevel()} disabled={levels.length >= 80}>
            {t('addLevel')}
          </Button>
        </div>

        {mutation.isError ? (
          <div className="my-4">
            <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
          </div>
        ) : null}

        <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
          <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
            {t('save')}
          </PrimaryButton>
          <Button onClick={() => resetForm()}>{t('discard')}</Button>
        </div>
      </form>
    </FormikProvider>
  );
};

const LevelRow = ({
  level,
  image,
  onChange,
  onImageChange,
  onDelete,
  min,
  disabled,
  canDelete,
  playerCount,
}: {
  level: Level;
  onChange: (coins: number) => void;
  onImageChange: (file: File | undefined | null) => void;
  onDelete: () => void;
  image: File | undefined | null;
  min: number;
  disabled?: boolean;
  canDelete?: boolean;
  playerCount?: number;
}) => {
  const [value, setValue] = useState(level.coins.toString());

  useEffect(() => {
    setValue(level.coins.toString());
  }, [level]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onChange(parseInt(value, 10) || 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.replace(/[^0-9]/, ''));
  };

  const { t } = useTranslation();
  return (
    <Tr>
      <Td className="w-auto">
        <ImageFile
          placeholder={<LevelBadge level={level.level} className="h-12" useDefault />}
          url={level.badge_url}
          file={image}
          onChange={(f) => onImageChange(f)}
          accept="image/png, image/svg"
          imageClassName="max-h-12"
        />
      </Td>
      <Td>
        <div className="flex gap-2 items-center">
          <div className="w-36">
            <Input
              min={min}
              placeholder={t('coins')}
              value={value}
              disabled={disabled}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <CoinIcon />
        </div>
      </Td>
      <Td>{typeof playerCount == 'undefined' ? '-' : playerCount < 0 ? '' : playerCount}</Td>

      {canDelete ? (
        <TdContextualMenu
          options={[
            {
              label: t('delete'),
              onClick: () => onDelete(),
            },
          ]}
        />
      ) : (
        <Td />
      )}
    </Tr>
  );
};

export default LevelsPage;
