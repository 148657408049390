import { addDays, getUnixTime, startOfHour } from 'date-fns';
import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { hasPolicy, useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { convertL10nValuesDictToArray, getStoreLocales, setDefaultL10nForLocaleIfMissing } from '../../lib/l10n';
import { useRepo } from '../../lib/repository';
import { Dict, Item, ItemDrawMethod, ItemType, Product, RedemptionMethod } from '../../lib/types';
import { ItemForm, ItemFormValues, itemValidationSchema, itemVariantSchema } from '../forms/ItemForm';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import { StorePageFreeLayout, useStorePage } from '../layouts/StorePage';
import { NotificationError } from '../Notifications';
import { broadcastSuccessToast } from '../Toasts';

const useItemCreateMutation = (isMultiLang: Boolean, defaultLocale?: string) => {
  const { t } = useTranslation();
  const { store } = useStorePage();
  const account = useAccount();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const canUseTicketsCost = hasPolicy(account, 'use_tickets_cost');

  return useMutation<Item | Product, unknown, unknown, unknown>(
    (variables: any) => {
      const saveDlFile = variables.redemption_method === RedemptionMethod.Download;
      const canUseL10n = account.policy.use_l10n && defaultLocale;

      // Pre-adjustments.
      if (variables.type === ItemType.Contribution) {
        variables.redemption_method = RedemptionMethod.None;
      }
      if (variables.type === ItemType.Sweepstakes && !canUseTicketsCost) {
        variables.cost = 1;
      }

      // Create item.
      let item: Dict = {
        type: variables.type,
        name: variables.name,
        description: variables.description,
        sku: variables.sku,
        cost: Number(variables.cost),

        redemption_method: variables.redemption_method,
        self_redeem_message: variables.self_redeem_message,

        start_time: getUnixTime(variables.start_time),
        end_time: getUnixTime(variables.end_time),

        image_file: variables.image_file ? variables.image_file : null,
      };

      if (canUseL10n) {
        item.l10n = variables.l10n; // Assign values.
        item.l10n = convertL10nValuesDictToArray(
          // Upon saving, we ensure that there is an entry for the default locale with all
          // the properties that should be attached to it. That is done here because in the
          // event where the default value has changed, the form will display the right values
          // but the state won't be updated which means that saving the form won't add/update
          // the l10n entries. So this is just to make it more robust for users.
          setDefaultL10nForLocaleIfMissing(item, defaultLocale, ['name', 'description', 'self_redeem_message']).l10n
        );

        // When the item is not multi-lang, we only keep the default locale.
        if (!isMultiLang) {
          item.l10n = item.l10n.filter((l: { locale: string }) => l.locale === defaultLocale);
        }
      }

      // Create product.
      if (variables.type === ItemType.Purchase && variables.variants?.length) {
        item = {
          ...item,
          variants: variables.variants.map((variantData: yup.InferType<typeof itemVariantSchema>) => {
            const { num_items_unlimited, ...variant } = variantData;
            const data: Dict = { ...variant, num_items: num_items_unlimited ? 0 : variant.num_items };

            if (canUseL10n) {
              data.l10n = convertL10nValuesDictToArray(
                setDefaultL10nForLocaleIfMissing(variant as Dict, defaultLocale, ['label']).l10n
              );

              // When the item is not multi-lang, we only keep the default locale.
              if (!isMultiLang) {
                item.l10n = data.l10n.filter((l: { locale: string }) => l.locale === defaultLocale);
              }
            }

            return data;
          }),
        };
        return repo.createProduct(store.id, item);
      }

      // Create item.
      item = {
        ...item,
        num_items: variables.num_items_unlimited ? 0 : Number(variables.num_items) || 1,

        minimum_bid: Number(variables.minimum_bid) || 0,
        handling_fee: Number(variables.handling_fee) || 0,

        contrib_goal: Number(variables.contrib_goal) || 1000,
        contrib_currency_symbol: variables.contrib_currency_symbol || '',
        contrib_currency_value: Number(variables.contrib_currency_value) || 0,

        draw_method: variables.draw_method || undefined,

        download_file: saveDlFile ? variables.download_file : undefined,
        download_filename: saveDlFile ? variables.download_filename : undefined,
      };

      return repo.createItem(store.id, item);
    },
    {
      onSuccess: (data, variables) => {
        const storeId = store.id;
        queryClient.invalidateQueries(['store-content', storeId]);
        queryClient.invalidateQueries(['team-market']);
        if (data.doc_type === 'product') {
          queryClient.setQueryData(['product', data.id], data);
        } else {
          queryClient.setQueryData(['item', data.id], data);
        }

        broadcastSuccessToast(t('itemAdded'));
      },
    }
  );
};

const ItemCreateContent = () => {
  const history = useHistory();
  const account = useAccount();
  const { store } = useStorePage();

  const handleBack = () => {
    history.goBack();
  };

  const [defaultLocale, locales] = useMemo(() => {
    const locales = getStoreLocales(account, store);
    if (!locales.length) {
      return [undefined, []];
    }
    return [locales[0].tag, locales];
  }, [account, store]);
  const [locale, setLocale] = useState<string>();

  const mutation = useItemCreateMutation(Boolean(locale), defaultLocale);

  const initialValues: ItemFormValues = useMemo(
    () => {
      const thisHour = startOfHour(new Date());
      return {
        type: ItemType.Purchase,

        name: '',
        description: '',
        image_file: null,
        start_time: thisHour,
        end_time: addDays(thisHour, 30),
        sku: '',

        cost: 10,
        num_items: 1,
        num_items_unlimited: false,

        handling_fee: 2,
        minimum_bid: 10,

        contrib_goal: 1000,
        contrib_currency_symbol: '',
        contrib_currency_value: 1,

        draw_method: ItemDrawMethod.Auto,

        redemption_method: RedemptionMethod.Request,

        self_redeem_message: '',

        download_file: null,
        download_filename: '',

        variants: [] as { label: string; sku: string; num_items: number; num_items_unlimited: boolean; l10n: {} }[],

        l10n: {},
      };
    },
    [] // eslint-disable-line
  );

  return (
    <div className="flex flex-col flex-grow">
      {mutation.isError ? (
        <div className="mb-4">
          <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
        </div>
      ) : null}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formik) => {
          mutation.mutate(values, {
            onSuccess: (item) => {
              if (item.doc_type === 'product') {
                history.push(`/product/${item.id}`);
              } else {
                history.push(`/item/${item.id}`);
              }
            },
            onError: () => {
              // TODO Handle error.
            },
            onSettled: () => {
              formik.setSubmitting(false);
            },
          });
        }}
        validationSchema={itemValidationSchema}
        validateOnMount
      >
        {(formik) => {
          return (
            <ItemForm
              formik={formik}
              locale={locale}
              locales={locales}
              defaultLocale={defaultLocale}
              onLocaleChange={setLocale}
              onCancel={handleBack}
            />
          );
        }}
      </Formik>
    </div>
  );
};

const Header = () => {
  const { store } = useStorePage();
  const { t } = useTranslation();
  return <PageHeaderWithBackLabel backLabel={store.name}>{t('untitledItem')}</PageHeaderWithBackLabel>;
};

const StoreAddItemPage = () => {
  return (
    <StorePageFreeLayout header={<Header />}>
      <ItemCreateContent />
    </StorePageFreeLayout>
  );
};

export default StoreAddItemPage;
