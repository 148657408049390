import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useLoginMutation } from '../../lib/auth';
import { PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import FullpageLayout from '../layouts/Fullpage';
import Logo from '../Logo';
import { NotificationError } from '../Notifications';
import Recaptcha, { useRecaptchaRef } from '../widgets/Recaptcha';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const LoginPage: React.FC<{ onLogin: (url?: string) => void }> = ({ children, onLogin }) => {
  const { t } = useTranslation();
  const loginMutation = useLoginMutation();
  const captchaRef = useRecaptchaRef();

  const handleSubmit = async (values: any) => {
    const token = await captchaRef.current?.executeAsync();
    return loginMutation.mutateAsync(
      { ...values, captchaToken: token },
      {
        onSuccess: () => {
          onLogin();
        },
      }
    );
  };

  return (
    <FullpageLayout>
      <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow font-medium text-center">
        <div>
          <div className="mb-6">
            <div className="mb-6 flex justify-center items-center">
              <Logo />
            </div>
            <div className="text-2xl leading-8 text-gray-600">{t('signup.signInToAccount')}</div>
          </div>

          <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit} validationSchema={schema} validateOnMount>
            {({ isSubmitting, isValid }) => {
              const canSubmit = !isSubmitting && isValid && captchaRef.current;
              return (
                <>
                  {loginMutation.isError ? (
                    <div className="mb-4">
                      <NotificationError>{t('signup.loginFailed')}</NotificationError>
                    </div>
                  ) : null}

                  <Form className="flex flex-col flex-grow text-left">
                    <Recaptcha recaptchaRef={captchaRef} />

                    <div className="flex-grow space-y-4">
                      <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                          <div>
                            <Field as={Input} name="email" />
                          </div>
                        </label>
                      </div>
                      <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                            <div>{t('password')}</div>
                          </div>
                          <div>
                            <Field as={Input} name="password" type="password" />
                          </div>
                        </label>
                        <div className="text-sm mt-2">
                          <Link to="/forgot_password" className="text-blue-600">
                            {t('signup.forgotPassword')}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 flex flex-col">
                      <PrimaryButton large submit disabled={!canSubmit}>
                        {t('signIn')}
                      </PrimaryButton>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>

        <div className="mt-6">
          <Link to="/signup" className="text-blue-600">
            {t('signup.dontHaveAccountCreateOne')}
          </Link>
        </div>
      </div>
    </FullpageLayout>
  );
};

export default LoginPage;
