import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownSelect, { DropdownSelectOption } from './DropdownSelect';

const EnabledDropdown = ({ enabled = false, onChange }: { enabled?: boolean; onChange: (enabled: boolean) => void }) => {
  const { t } = useTranslation();
  const options: DropdownSelectOption[] = useMemo(() => {
    return [
      {
        value: '1',
        label: t('enabled'),
      },
      {
        value: '0',
        label: t('disabled'),
      },
    ];
  }, [t]);
  const isEnabled = (value: string) => value === '1';
  const option = options.find((option) => (enabled ? isEnabled(option.value) : !isEnabled(option.value)));
  const handleChange = (option: { value: string }) => onChange(isEnabled(option.value));
  return <DropdownSelect options={options} selected={option} onChange={handleChange} placeholder={''} />;
};

export default EnabledDropdown;
