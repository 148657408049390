import { PencilIcon } from '@heroicons/react/24/solid';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { AccountContext } from '../../lib/account';
import { useFiltering, usePermissionChecker, useSorting } from '../../lib/hooks';
import { useTeams } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Permission } from '../../lib/types';
import { PrimaryButton } from '../Buttons';
import { ConfirmModal } from '../Modals';
import { HasAccountPermission, HasTeamPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import SearchInput from '../inputs/Search';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import TeamsNav from '../nav/TeamsNav';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThSortableWithSorting,
  Thead,
  Tr,
} from '../widgets/Table';
import { AlertTag, ShorteningTagList, StoreTag } from '../widgets/Tag';

const useTeamDeleteMutation = () => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { refreshAccount } = useContext(AccountContext);
  return useMutation(({ id }: { id: string }) => repo.deleteTeam(id), {
    onSuccess: (data, { id }) => {
      queryClient.invalidateQueries(['team', id]);
      queryClient.invalidateQueries(['teams']);
      queryClient.invalidateQueries(['team-stores']);
      queryClient.invalidateQueries(['store']);
      queryClient.invalidateQueries(['stores']);
      queryClient.invalidateQueries(['store-teams']);
      refreshAccount();
    },
    onError: (err, variables, context: any) => {
      // TODO Display message.
    },
  });
};

const TeamsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', {});
  const {
    showingFrom,
    showingTo,
    showingOfTotal,
    isSuccess,
    isLoading,
    data,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useTeams(filterTerm, sorting.sortField);
  const [toDeleteItems, setToDeleteItems] = useState<string[]>([]);
  const deleteMutation = useTeamDeleteMutation();
  const permChecker = usePermissionChecker();
  const canViewSomeStores = permChecker.hasInAnyStorePermission(Permission.ReadTeamStore);

  const handleDelete = () => {
    if (!toDeleteItems.length) {
      return;
    }
    setToDeleteItems([]);
    deleteMutation.mutate(
      { id: toDeleteItems[0] },
      {
        onSuccess: () => {
          broadcastSuccessToast(t('teamDeleted'));
        },
      }
    );
  };

  const actions = [
    {
      label: t('delete'),
      danger: true,
      can: permChecker.hasAccountPermission(Permission.DeleteTeam),
    },
  ].filter((action) => action.can);

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeaderWrappingNav
          title={t('teams')}
          buttons={
            <>
              <HasAccountPermission perm={Permission.CreateTeam}>
                <PrimaryButton onClick={() => history.push('/teams/create')} icon={PencilIcon}>
                  {t('createTeam')}
                </PrimaryButton>
              </HasAccountPermission>
            </>
          }
        >
          <TeamsNav />
        </PageHeaderWrappingNav>

        <div className="mb-4">
          <div className="flex items-center">
            {/* <div className="mr-2">
              <Dropdown
                label={t('actions')}
                options={[
                  {
                    label: t('delete'),
                    danger: true,
                  },
                ]}
              />
            </div> */}
            <div className="w-96">
              <SearchInput {...filterInputProps} />
            </div>
          </div>
        </div>
        <div>
          <Table>
            <Thead>
              {/* <ThCheckbox /> */}
              <ThSortableWithSorting sortKey="name" sortingResult={sorting}>
                {t('name')}
              </ThSortableWithSorting>
              {canViewSomeStores ? <Th>{t('stores')}</Th> : null}
              <Th>{t('players')}</Th>
              {actions.length ? <Th /> : null}
            </Thead>
            <Tbody>
              {isLoading ? (
                <Tr>
                  <Td colSpan={2 + (canViewSomeStores ? 1 : 0) + (actions.length ? 1 : 0)}>{t('loadingEllipsis')}</Td>
                </Tr>
              ) : null}
              {isSuccess && data
                ? data.items.map((team) => {
                    return (
                      <Tr key={team.id}>
                        {/* <TdCheckbox /> */}
                        <TdPrimary to={`/team/${team.id}`}>
                          {team.name}
                          <HasTeamPermission teamId={team.id} perm={Permission.ReadTeamStore}>
                            {!team.stores.length ? (
                              <div className="inline-block ml-2">
                                <AlertTag>{t('noStores')}</AlertTag>
                              </div>
                            ) : null}
                          </HasTeamPermission>
                        </TdPrimary>
                        {canViewSomeStores ? (
                          <Td>
                            <HasTeamPermission teamId={team.id} perm={Permission.ReadTeamStore}>
                              <ShorteningTagList component={StoreTag} tags={team.stores.map((s) => s.name)} />
                            </HasTeamPermission>
                          </Td>
                        ) : null}
                        <Td>{team.player_count}</Td>
                        {actions.length ? (
                          <TdContextualMenu
                            options={actions.map((action) => ({ ...action, onClick: () => setToDeleteItems([team.id]) }))}
                          />
                        ) : null}
                      </Tr>
                    );
                  })
                : null}
            </Tbody>
          </Table>
          {isSuccess && (data?.total || 0) > 0 ? (
            <TablePagination
              showingFrom={showingFrom}
              showingTo={showingTo}
              showingOfTotal={showingOfTotal}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              onNextPageClick={fetchNextPage}
              onPreviousPageClick={fetchPreviousPage}
            />
          ) : null}

          {toDeleteItems.length ? (
            <ConfirmModal
              onConfirm={handleDelete}
              onCancel={() => setToDeleteItems([])}
              title={t('deleteTeam', { count: toDeleteItems.length })}
              message={<Trans t={t} i18nKey="deleteTeamConfirm" count={toDeleteItems.length} />}
              confirmButtonText={t('delete')}
            />
          ) : null}
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

export default TeamsPage;
