import { ArrowLongDownIcon } from '@heroicons/react/24/solid';
import download from 'downloadjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { useParams } from 'react-router';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Transaction } from '../../lib/types';
import { Button } from '../Buttons';
import StorePageLayout from '../layouts/StorePage';
import TransactionsFilters, { TransactionFiltersContext, TransactionsFiltersProvider } from '../transactions/Filters';
import TransactionsTable from '../transactions/Table';

const TX_DOWNLOAD_LIMIT = 10000; // Sync with backend.

const useTransactions = (
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const { storeId } = useParams<{ storeId: string }>();
  const repo = useRepo();
  const results = useContinuatedQuery(
    ['store-transactions', storeId, filters, orderBy],
    ({ pageParam }) => {
      return repo.getStoreTransactions(storeId, filters, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [storeId, orderBy, ...Object.values(filters || {})],
    }
  );

  return results;
};

const useTransactionsExportMutation = (
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const { storeId } = useParams<{ storeId: string }>();
  const repo = useRepo();

  return useMutation(() => repo.exportStoreTransactions(storeId, filters, orderBy), {
    onSuccess: (data) => {
      download(data, 'transactions.csv', 'text/csv');
    },
  });
};

const StoreTransactionsPage = () => {
  return (
    <TransactionsFiltersProvider>
      <TransactionsContainer />
    </TransactionsFiltersProvider>
  );
};

const TransactionsContainer: React.FC = () => {
  const { timestampFrom, timestampTo, type, details } = useContext(TransactionFiltersContext);
  const filters = {
    type: type,
    details: details,
    dateFrom: timestampFrom,
    dateTo: timestampTo,
  };
  const query = useTransactions(filters);
  const exportMutation = useTransactionsExportMutation(filters);
  return <Transactions queryResult={query} exportMutation={exportMutation} />;
};

const Transactions: React.FC<{
  exportMutation: UseMutationResult<Blob, unknown, void, unknown>;
  queryResult: ContinuatedQueryObserverResult<
    ContinuableResponse<Transaction, { users: { id: string; firstname: string; lastname: string }[] }>
  >;
}> = ({ exportMutation, queryResult }) => {
  const { t } = useTranslation();
  const { hasFilters } = useContext(TransactionFiltersContext);

  const { isLoading: isDownloading } = exportMutation;

  const totalRecords = queryResult?.data?.total || 0;
  const canDownload = queryResult.isSuccess && totalRecords <= TX_DOWNLOAD_LIMIT && !isDownloading;
  return (
    <StorePageLayout
      buttons={[
        <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
          {t('exportCsv')}
        </Button>,
      ]}
    >
      <TransactionsFilters />
      <div className="mt-4">
        <TransactionsTable queryResult={queryResult} hasFilters={hasFilters} displayPlayerName />
      </div>
    </StorePageLayout>
  );
};

export default StoreTransactionsPage;
