import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useAccount } from '../../lib/account';
import { canSwitchAccount } from '../../lib/perms';
import { getSwitchUrl } from '../../lib/utils';
import { UserAvatar } from '../Avatars';
import { PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import { HasPermission } from '../Permissions';

const WithoutSidebar: React.FC<{ backTo?: string }> = ({ children, backTo }) => {
  const account = useAccount();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const switchButton = (
    <HasPermission checker={canSwitchAccount}>
      <PrimaryButton onClick={() => history.push(getSwitchUrl(location))}>{t('switchAccount')}</PrimaryButton>
    </HasPermission>
  );
  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-16 flex inset-x-0 px-4 bg-white">
        <div className="flex-grow"></div>
        <div className="p-4">
          <UserAvatar className="w-8 h-8" />
        </div>
      </div>
      <div className="flex flex-grow bg-gray-50 py-6">
        <div className="mx-auto w-[959px]">
          {backTo ? (
            <HeaderWithBack withBorder={false} sideComponent={switchButton}>
              {account.name}
            </HeaderWithBack>
          ) : (
            <div className="flex mb-6">
              <div className="flex-grow">
                <h1 className="font-semibold text-2xl">{account.name}</h1>
              </div>
              <div>{switchButton}</div>
            </div>
          )}
          <div className="border-t border-gray-200 pt-6">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default WithoutSidebar;
