import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react';
import { classNames } from '../../lib/utils';

export type DropdownOption = {
  onClick?: () => void;
  label: string;
  className?: string;
  danger?: boolean;
  disabled?: boolean | ((option: DropdownOption) => boolean);
};

const Dropdown: React.FC<{
  options: DropdownOption[];
  label: string;
  minimal?: boolean;
  icon?: React.ReactNode;
  right?: boolean;
}> = ({ label, options, icon, minimal, right }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            'text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100',
            minimal
              ? 'flex items-center hover:text-gray-600'
              : 'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 '
          )}
        >
          <span className={classNames(minimal ? 'sr-only' : '')}>{label}</span>
          {!minimal ? <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" /> : icon}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            right ? 'origin-top-right right-0' : 'origin-top-left left-0',
            'absolute z-10 mt-2 w-full min-w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          <div className="py-1">
            {options.map((option, i) => {
              const disabledFn = typeof option.disabled === 'function' ? option.disabled : () => option.disabled || false;

              if (disabledFn(option)) {
                return (
                  <div className="block px-4 py-2 text-sm text-gray-400" key={i}>
                    {option.label}
                  </div>
                );
              }

              const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                option.onClick && option.onClick();
              };

              return (
                <Menu.Item key={i}>
                  {({ active }) => (
                    <button
                      onClick={handleClick}
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100' : null,
                        option.danger ? 'text-red-600' : active ? 'text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      {option.label}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
