import { now } from './date';
import { StoreContentItem } from './repository';
import { Item, ItemAuctionState, ItemDrawMethod, ItemPurchaseVariant, ItemRaffleStatus, ItemType, Product } from './types';

export function getDrawMethod(item: Item | Product): ItemDrawMethod {
  const drawMethod = 'draw_method' in item ? item.draw_method : undefined;
  return drawMethod || ItemDrawMethod.Auto; // Can only be undefined for raffles, and that's its default.
}

export function isExpired(item: Item | Product | StoreContentItem): boolean {
  return item.end_time < now();
}

function isItem(item: Item | Product): item is Item {
  return item.doc_type === 'store_item';
}

function isProduct(item: Item | Product): item is Product {
  return item.doc_type === 'product';
}

export function isProductVariant(item: Item | Product): item is ItemPurchaseVariant {
  if (isProduct(item)) return false;
  return item.type === ItemType.Purchase && Boolean(item.product_id);
}

export function isItemEditable(item: Item | Product) {
  const isAnItem = isItem(item);
  if (item.end_time < now()) {
    return false;
  } else if (isAnItem && item.type === ItemType.Raffle && item.raffle_status !== ItemRaffleStatus.Open) {
    return false;
  } else if (isAnItem && item.type === ItemType.Auction && item.auction_state !== ItemAuctionState.Idle) {
    return false;
  } else if (isProductVariant(item)) {
    return false;
  }
  return true;
}

export function isStoreItemContentEditable(item: StoreContentItem) {
  if (item.end_time < now()) {
    return false;
  } else if (item.doc_type === 'store_item' && item.type === ItemType.Raffle && item.raffle_status !== ItemRaffleStatus.Open) {
    return false;
  } else if (item.doc_type === 'store_item' && item.type === ItemType.Auction && item.auction_state !== ItemAuctionState.Idle) {
    return false;
  }
  return true;
}
