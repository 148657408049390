import { PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useAccount } from '../../lib/account';
import { useFiltering, usePermissionChecker, useSorting, UseSortingResult } from '../../lib/hooks';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { AccountMember, Permission } from '../../lib/types';
import { getAccountRoleStringKey } from '../../lib/utils';
import { PrimaryButton } from '../Buttons';
import SearchInput from '../inputs/Search';
import { PageHeader } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { HasAnyWhereInAccountPermission } from '../Permissions';
import { Table, TablePagination, Tbody, Td, TdPrimary, Th, Thead, ThSortableWithSorting, Tr } from '../widgets/Table';

const useMembers = (searchTerm?: string, orderBy?: string) => {
  const { id: accountId } = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useContinuatedQuery(
    ['members', { searchTerm, orderBy, accountId }],
    ({ pageParam }) => {
      return repo.getMembers(accountId, { term: searchTerm }, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, accountId],
      onSuccess: (data) => {
        data.items.forEach((member) => {
          queryClient.setQueryData(['member', member.id], member);
        });
      },
    }
  );
};

const MembersPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', { name: false });
  const query = useMembers(filterTerm, sorting.sortField);
  return (
    <LayoutWithSidebar>
      <div>
        <PageHeader
          buttons={
            <HasAnyWhereInAccountPermission perm={Permission.ManageMember}>
              <PrimaryButton onClick={() => history.push('/members/invite')} icon={PlusIcon}>
                {t('inviteAdmin')}
              </PrimaryButton>
              {/* <PrimaryButton onClick={() => history.push('/admins/create')} icon={PencilIcon}>
                {t('createAdmin')}
              </PrimaryButton> */}
            </HasAnyWhereInAccountPermission>
          }
        >
          {t('admins')}
        </PageHeader>
        <div className="mb-4">
          <div className="flex items-center">
            <div className="w-96">
              <SearchInput {...filterInputProps} />
            </div>
          </div>
        </div>
        <div>
          <MembersTable queryResult={query} sortingResult={sorting} />
        </div>
      </div>
    </LayoutWithSidebar>
  );
};

type TableProps = {
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<AccountMember>>;
  sortingResult: UseSortingResult;
};

function MembersTable({ queryResult, sortingResult }: TableProps) {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = queryResult;

  return (
    <>
      <Table>
        <Thead>
          <ThSortableWithSorting sortKey="name" sortingResult={sortingResult}>
            {t('name')}
          </ThSortableWithSorting>
          <Th>{t('email')}</Th>
          <Th>{t('role')}</Th>
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={2}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess && data ? data.items.map((user) => <MemberRow member={user} key={user.user.id} />) : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={queryResult.showingFrom}
          showingTo={queryResult.showingTo}
          showingOfTotal={queryResult.showingOfTotal}
          hasNextPage={queryResult.hasNextPage}
          hasPreviousPage={queryResult.hasPreviousPage}
          onNextPageClick={queryResult.fetchNextPage}
          onPreviousPageClick={queryResult.fetchPreviousPage}
        />
      ) : null}
    </>
  );
}

const MemberRow: React.FC<{
  member: AccountMember;
}> = ({ member }) => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();

  const canEdit =
    permChecker.canAssignRole(member.role) &&
    (permChecker.hasAccountPermission(Permission.ManageMember) ||
      member.store_ids?.some((storeId) => permChecker.hasStorePermission(storeId, Permission.ManageMember)) ||
      member.team_ids?.some((teamId) => permChecker.hasTeamPermission(teamId, Permission.ManageMember)));

  return (
    <Tr key={member.user.id}>
      <TdPrimary to={canEdit ? `/member/${member.id}` : undefined}>{member.user.name}</TdPrimary>
      <Td>{member.user.email}</Td>
      <Td>{t(getAccountRoleStringKey(member.role))}</Td>
    </Tr>
  );
};

export default MembersPage;
