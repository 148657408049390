import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import type { Toast as ToastType, ToastOptions } from 'react-hot-toast';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { PlainButton } from './Buttons';

export const broadcastLoadingToast = (message: string, options?: ToastOptions) => toast.loading(message, options);
export const broadcastSuccessToast = (message: string, options?: ToastOptions) => toast.success(message, options);
export const broadcastErrorToast = (message: string, options?: ToastOptions) => toast.error(message, options);

export const dismissToast = (id?: string) => toast.dismiss(id);

const Toast = ({ bread }: { bread: ToastType }) => {
  const { t } = useTranslation();
  return (
    <Transition
      {...bread.ariaProps}
      show={bread.visible}
      appear={bread.visible}
      className="w-[384px] flex items-center p-4 shadow-lg rounded-lg bg-white border border-gray-100 transition transform duration-150"
      enterFrom="opacity-0 translate-y-4"
      enterTo="opacity-100 translate-y-0"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-4"
    >
      {bread.type === 'success' ? <CheckCircleIcon className="flex-shrink-0 flex-grow-0 text-green-400 h-5 w-5 " /> : null}
      {bread.type === 'error' ? <XMarkIcon className="flex-shrink-0 flex-grow-0 text-red-400 h-5 w-5 " /> : null}
      <div className="flex-grow ml-4 mr-2">{bread.message}</div>
      {bread.type !== 'loading' ? (
        <div className="flex-shrink-0 flex items-center justify-center">
          <PlainButton onClick={() => toast.dismiss(bread.id)}>
            <XMarkIcon className="h-5 w-5 text-gray-400" />
            <span className="sr-only">{t('close')}</span>
          </PlainButton>
        </div>
      ) : null}
    </Transition>
  );
};

export const ToastContainer = () => {
  return (
    <Toaster position="bottom-center" toastOptions={{ duration: 4000, className: 'min-w-[384px]' }} containerStyle={{ bottom: 60 }}>
      {(t) => <Toast bread={t} />}
    </Toaster>
  );
};
