import { useLogoutMutation } from '../../lib/auth';
import { useOnce } from '../../lib/hooks';
import { CatchWantsUrlFromQueryString } from '../utils/WantsUrl';

const LogoutPage = () => {
  const logoutMutation = useLogoutMutation();

  useOnce(() => {
    logoutMutation.mutate();
  });

  return <CatchWantsUrlFromQueryString />;
};

export default LogoutPage;
