import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React, { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';
import { UseSortingResult } from '../../lib/hooks';
import { classNames } from '../../lib/utils';
import { Button } from '../Buttons';
import SortAscIcon from '../icons/SortAscIcon';
import SortDescIcon from '../icons/SortDescIcon';
import SortIcon from '../icons/SortIcon';
import Checkbox from '../inputs/Checkbox';
import Dropdown, { DropdownOption } from './Dropdown';

export const Th: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <th scope="col" className={classNames('text-left text-xs font-medium text-gray-500 uppercase tracking-wider', className)}>
      <div className="flex items-center px-4 py-3">{children}</div>
    </th>
  );
};

export const ThSortable: React.FC<{
  className?: string;
  isSortDescending: boolean;
  isSorted: boolean;
  onSortChange: () => void;
}> = ({ children, className, isSorted, isSortDescending, onSortChange }) => {
  return (
    <th scope="col" className={classNames('px-2', className)}>
      <button
        type="button"
        className="px-2 w-full text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center"
        onClick={onSortChange}
      >
        <div className="flex-grow py-3">{children}</div>
        <div className="ml-1">{isSorted ? !isSortDescending ? <SortAscIcon /> : <SortDescIcon /> : <SortIcon />}</div>
      </button>
    </th>
  );
};

export const ThSortableWithSorting: React.FC<{ sortKey: string; sortingResult: UseSortingResult; className?: string }> = ({
  sortKey,
  sortingResult,
  children,
  className,
}) => {
  return (
    <ThSortable
      isSorted={sortingResult.isKeySelected(sortKey)}
      isSortDescending={sortingResult.isDescending}
      onSortChange={() => sortingResult.onChange(sortKey)}
      className={className}
    >
      {children}
    </ThSortable>
  );
};

export const ThCheckbox: React.FC<{ checked?: boolean; disabled?: boolean; onChange?: (checked: boolean) => void }> = ({
  checked,
  disabled,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <th scope="col" className="w-6 max-w-6 pl-4 pt-3 pr-2 leading-none align-top text-left">
      <label className="">
        <Checkbox checked={checked} onChange={(e) => onChange && onChange(e.target.checked)} disabled={disabled} />
        <span className="sr-only">{t('selectAll')}</span>
      </label>
    </th>
  );
};

export const Tr: React.FC<React.HTMLAttributes<HTMLTableRowElement> & { hasFluid?: boolean }> = ({
  className,
  children,
  hasFluid,
  ...props
}) => {
  return (
    <tr
      className={classNames('odd:bg-white even:bg-gray-50 last:rounded-b-lg', hasFluid ? '[&>*]:align-top' : '', className)}
      {...props}
    >
      {children}
    </tr>
  );
};

export const Td: React.FC<React.TdHTMLAttributes<HTMLTableCellElement> & { fluid?: boolean }> = ({
  className,
  children,
  fluid,
  ...props
}) => {
  return (
    <td
      className={classNames('px-4 py-4 text-sm text-gray-500', fluid ? 'break-word' : 'whitespace-nowrap truncate', className)}
      {...props}
    >
      {children}
    </td>
  );
};

export const TdPrimary: React.FC<{ to?: LinkProps['to'] }> = ({ children, to }) => {
  return (
    <th scope="row" className="whitespace-nowrap text-sm text-gray-900 font-medium text-left truncate">
      {to ? (
        <div className="p-px">
          <Link to={to} className="px-4 py-4 block">
            <div className="-m-px">{children}</div>
          </Link>
        </div>
      ) : (
        <div className="px-4 py-4 w-full">{children}</div>
      )}
    </th>
  );
};

export const TdCheckbox: React.FC<{ checked?: boolean; onChange?: () => void; centered?: boolean; disabled?: boolean }> = ({
  checked,
  onChange,
  centered,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <td className={classNames('pl-4 pr-2 leading-none', centered ? 'align-middle' : 'pt-4 align-top')}>
      <label>
        <Checkbox onChange={onChange ? (e) => onChange() : undefined} checked={checked} disabled={disabled} />
        <span className="sr-only">{t('selectRow')}</span>
      </label>
    </td>
  );
};

export const TdContextualMenu: React.FC<{ options: DropdownOption[] }> = ({ options }) => {
  const { t } = useTranslation();
  return (
    <td className="w-12">
      <div className="w-full flex items-center justify-center my-2">
        <Dropdown right label={t('actions')} icon={<EllipsisHorizontalIcon className="h-5 w-5" />} minimal options={options} />
      </div>
    </td>
  );
};

export const Table = ({ children }: { children: React.ReactElement | null | (null | React.ReactElement)[] }) => {
  let childrenArray = React.Children.toArray(children) as React.ReactElement[];
  let pagination = childrenArray.slice(2)[0];
  if (pagination) {
    childrenArray = childrenArray.filter((child) => child !== pagination);
    pagination = cloneElement(pagination, { ...pagination.props, glued: true });
  }
  return (
    <div className="border border-gray-200 max-w-full">
      <table className="w-full max-w-full divide-y divide-gray-200">{childrenArray}</table>
      {pagination ? <div className="border-t border-gray-200 w-full px-6 py-4">{pagination}</div> : null}
    </div>
  );
};

export const Thead: React.FC = ({ children }) => {
  return (
    <thead className="bg-gray-50">
      <tr>{children}</tr>
    </thead>
  );
};
export const Tbody: React.FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};

export const TablePagination: React.FC<{
  disabled?: boolean;
  glued?: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  onNextPageClick?: () => void;
  onPreviousPageClick?: () => void;
  showingFrom?: number;
  showingTo?: number;
  showingOfTotal?: number;
  largeButtons?: boolean;
}> = ({
  disabled,
  glued,
  hasPreviousPage,
  hasNextPage,
  onNextPageClick,
  onPreviousPageClick,
  largeButtons = true,
  showingFrom = 0,
  showingTo = 0,
  showingOfTotal = 0,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames('flex justify-between items-center', !glued ? 'mt-6' : '')}>
      <div className="text-sm">
        {showingFrom
          ? t('showingFromToOfTotal', { from: showingFrom, to: showingTo, total: showingOfTotal })
          : t('showingCountOfTotal', { count: showingTo, total: showingOfTotal })}
      </div>
      <div>
        <nav className="flex gap-3">
          {hasPreviousPage ? (
            <div>
              <Button
                large={largeButtons}
                disabled={disabled || !hasPreviousPage}
                onClick={(e) => onPreviousPageClick && onPreviousPageClick()}
              >
                {t('previous')}
              </Button>
            </div>
          ) : null}
          {!hasNextPage ? null : (
            <div>
              <Button
                large={largeButtons}
                disabled={disabled || !hasNextPage}
                onClick={(e) => onNextPageClick && onNextPageClick()}
              >
                {t('next')}
              </Button>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};
