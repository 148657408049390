const SortDescIcon = (props: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0706 7.99074L7.00808 4.74074C6.71125 4.50309 6.28929 4.50309 5.99246 4.74074L1.92996 7.99074C1.5795 8.2712 1.52275 8.78266 1.80321 9.13312C2.08366 9.48358 2.59512 9.54032 2.94558 9.25987L6.50027 6.41612L10.055 9.25987C10.4054 9.54032 10.9169 9.48358 11.1973 9.13312C11.4778 8.78266 11.421 8.2712 11.0706 7.99074Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0549 14.7407L6.50025 17.5845L2.94557 14.7407C2.71886 14.5593 2.41258 14.5126 2.14211 14.6182C1.87164 14.7238 1.67806 14.9657 1.6343 15.2528C1.59054 15.5398 1.70323 15.8284 1.92994 16.0098L5.99244 19.2598C6.28927 19.4975 6.71123 19.4975 7.00807 19.2598L11.0706 16.0098C11.421 15.7294 11.4778 15.2179 11.1973 14.8675C10.9169 14.517 10.4054 14.4602 10.0549 14.7407Z"
        fill="#374151"
      />
    </svg>
  );
};

export default SortDescIcon;
