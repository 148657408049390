import { useEffect } from 'react';
import { useHistory } from 'react-router';

export default function ScrollTopOnRouteChange() {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  });

  return null;
}
