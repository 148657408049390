import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { i18n } from 'i18next';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import successSrc from '../assets/success.svg';
import { ReactComponent as Sunset } from '../assets/sunset.svg';
import { hasPolicy, isTrialExpired, useAccount } from '../lib/account';
import { useUser } from '../lib/auth';
import { brandName } from '../lib/flavour';
import { usePermissionChecker } from '../lib/hooks';
import { getErrorMessage } from '../lib/i18n';
import { isDomainAdminOf, isSuperUser } from '../lib/perms';
import { useRepo } from '../lib/repository';
import { AccountState, AccountSupportType, Permission, Plan } from '../lib/types';
import { classNames } from '../lib/utils';
import { setLastAccountId } from './AccountNegotiator';
import { LinkPrimaryButton, PrimaryButton } from './Buttons';
import CheckIcon from './icons/CheckIcon';
import Toggle from './inputs/Toggle';
import OutsideAccountLayout from './layouts/OutsideAccount';
import WithoutSidebar from './layouts/WithoutSidebar';
import { NotificationError } from './Notifications';
import Spinner from './widgets/Spinner';
import DropdownSelect, { DropdownSelectOption } from './widgets/DropdownSelect';
import { usePlatforms } from '../lib/queries';

const AccountBillingGate: React.FC = ({ children }) => {
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const canManageBilling = permChecker.hasAccountPermission(Permission.ManageBilling);
  const useBilling = hasPolicy(account, 'use_self_billing');
  const canUpgrade = useBilling && canManageBilling;

  return (
    <Switch>
      {canUpgrade ? (
        <Route path="/plans">
          <WithoutSidebar backTo="/">
            <StripeWrapper>
              <AccountPlans />
            </StripeWrapper>
          </WithoutSidebar>
        </Route>
      ) : null}
      <Route>
        <AccountBillingGateContent>{children}</AccountBillingGateContent>
      </Route>
    </Switch>
  );
};

export default AccountBillingGate;

const AccountBillingGateContent: React.FC = ({ children }) => {
  const user = useUser();
  const account = useAccount();
  const permChecker = usePermissionChecker();

  const isSuspended = account.state === AccountState.Suspended;
  const canManageBilling = permChecker.hasAccountPermission(Permission.ManageBilling);
  const hasTrialExpired = isTrialExpired(account);
  const isAccessPrevented = isSuspended || hasTrialExpired;
  const useBilling = hasPolicy(account, 'use_self_billing');
  const canUpgrade = useBilling && canManageBilling;

  if (isAccessPrevented && !isSuperUser(user) && !isDomainAdminOf(user, account)) {
    if (!canManageBilling) {
      return <AccountSuspendedBlockedPage />;
    } else if (hasTrialExpired) {
      return <AccountTrialExpiredPage canUpgrade={canUpgrade} />;
    }
    return <AccountSuspendedBlockedPage />;
  }

  return <>{children}</>;
};

function valueOrUnlimited(i18n: i18n, value: number) {
  if (value < 0) {
    return i18n.t('unlimited');
  }
  return value;
}

function yesOrNo(i18n: i18n, value: boolean) {
  return value ? (
    <div className="inline-block">
      <CheckIcon className="text-[#adcf44]" />
      <span className="sr-only">{i18n.t('yes')}</span>
    </div>
  ) : (
    <span className="sr-only">{i18n.t('no')}</span>
  );
}

const AccountSuspendedBlockedPage = () => {
  const { t } = useTranslation();
  return (
    <WithoutSidebar>
      <div className="mt-8 flex flex-col items-center justify-center max-w-lg m-auto">
        <div>
          <Sunset className="" />
        </div>
        <h3 className="mt-6 text-2xl font-medium">{t('yourAccountIsSuspended')}</h3>
        <p className="mt-3 text-center">{t('accountSuspendedContactYourAccountManager')}</p>
      </div>
    </WithoutSidebar>
  );
};

const AccountTrialExpiredPage = ({ canUpgrade }: { canUpgrade: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <WithoutSidebar>
      <div className="mt-8 flex flex-col items-center justify-center max-w-lg m-auto">
        <div>
          <Sunset className="" />
        </div>
        <h3 className="mt-6 text-2xl font-medium">{t('yourTrialHasExpired')}</h3>
        <p className="mt-3 text-center">
          {canUpgrade ? t('ifYouEnjoyFeaturesUpgradeToPaidPlan') : t('trialExpiresContactYourAccountManager')}
        </p>
        {canUpgrade ? (
          <div className="mt-6 flex items-center">
            <div className=" max-w-sm">
              <PrimaryButton onClick={() => history.push('/plans')}>{t('showMeThePlans')}</PrimaryButton>
            </div>
          </div>
        ) : null}
      </div>
    </WithoutSidebar>
  );
};

const AccountPlans = () => {
  const otherPlatformId = 'other';

  const { t: _, i18n } = useTranslation();
  const repo = useRepo();
  const account = useAccount();
  const stripe = useStripe();

  const [isAnnual, setIsAnnual] = useState(true);
  const [platform, setPlatform] = useState(account.platform);

  const plansQuery = useQuery(['account', account.id, 'available-plans'], () => repo.getAccountAvailablePlans(account.id));
  const query = usePlatforms();

  const accountMutation = useMutation((data: { platform?: string }) => repo.updateAccount(account.id, data));
  const checkoutMutation = useMutation(async ({ priceId }: { priceId: string }) => {
    return repo.createAccountSubscriptionViaStripeCheckout(account.id, priceId);
  });

  const platformOptions = useMemo(() => {
    return [
      ...(query.data || []).map((platform) => ({
        value: platform.id,
        label: platform.name,
      })),
      { value: otherPlatformId, label: _('other') },
    ];
  }, [query.data, _]);
  const platformSelected = useMemo(() => {
    return platformOptions ? platformOptions.find((p) => p.value === platform) : undefined;
  }, [platformOptions, platform]);

  if (query.isLoading || !query.data || plansQuery.isLoading || !plansQuery.data) {
    return null;
  } else if (query.isError || plansQuery.isError) {
    return <NotificationError>{getErrorMessage(query.error || plansQuery.error)}</NotificationError>;
  }

  const handleUpgrade = (priceId?: string) => {
    if (!priceId || !stripe) {
      return;
    }
    checkoutMutation.mutate(
      { priceId },
      {
        onSuccess: (data) => {
          stripe.redirectToCheckout({ sessionId: data.session_id });
        },
        onError: () => {},
      }
    );
  };

  const handlePlatformChange = (platformOption: DropdownSelectOption) => {
    setPlatform(platformOption.value);
    accountMutation.mutate({ platform: platformOption.value === otherPlatformId ? '' : platformOption.value });
  };

  if (checkoutMutation.isLoading || checkoutMutation.isSuccess) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  } else if (checkoutMutation.isError) {
    return <NotificationError>{getErrorMessage(checkoutMutation.error)}</NotificationError>;
  }

  return (
    <div className="text-center mt-4">
      <h1 className=" text-5xl">{_('billing.upgradeYourAccount')}</h1>
      <p className="text-2xl mt-4">{_('billing.continueToAccessGreatFeatures')}</p>

      <div className="flex items-center gap-4 justify-center mt-6">
        <div className="font-bold">{_('billing.yourPlatform')}</div>
        <div className="text-left min-w-48">
          <DropdownSelect
            options={platformOptions as NonNullable<typeof platformOptions>}
            selected={platformSelected}
            placeholder={_('selectEllipsis')}
            onChange={handlePlatformChange}
          ></DropdownSelect>
        </div>
      </div>

      {platform ? (
        <>
          <div className="flex justify-center items-center mt-8 mb-6 text-lg font-bold">
            <Toggle enabled={isAnnual} onChange={(enabled) => setIsAnnual(enabled)} label={_('billing.payAnnually')} />{' '}
            <div className="text-blue ml-2 text-xs">{_('billing.save20pcParens')}</div>
          </div>
          <PlansTable plans={plansQuery.data} isAnnual={isAnnual} onUpgrade={handleUpgrade} platform={platform} />
        </>
      ) : null}
    </div>
  );
};

const PlansTable = ({
  plans,
  isAnnual,
  onUpgrade,
  platform,
}: {
  plans: Plan[];
  isAnnual?: boolean;
  onUpgrade: (priceId?: string) => void;
  platform?: string;
}) => {
  const { t: _, i18n } = useTranslation();
  const filteredPlans = useMemo(
    () =>
      plans.filter((p) => {
        if (platform !== 'thinkific') {
          return p.id !== 'standard';
        }
        return true;
      }),
    [plans, platform]
  );

  const popularPlan = 'premium';

  return (
    <div className="mt-6">
      <table className="w-full text-center m-auto billing-plans-table bg-white">
        <tbody>
          <tr className="titles">
            <td className="text-left w-64"></td>
            {filteredPlans.map((plan, idx) => {
              const { name } = plan;
              const price = isAnnual ? plan.yearly_cost_per_month : plan.monthly_cost;
              const isPopular = plan.id === popularPlan;
              return (
                <td key={idx} className="relative">
                  <div
                    className={classNames(
                      `bg-[#adcf44] text-white text-sm font-bold h-6 flex items-center justify-center text-center inset-x-0 top-0 absolute`,
                      !isPopular ? 'invisible' : null
                    )}
                  >
                    {_('billing.mostPopular')}
                  </div>
                  <div className="text-gray-500 text-2xl font-bold mt-6">{name}</div>
                  <div className="mt-2 mb-4 mx-auto border-b-2 border-silver-3 w-24"></div>
                  <div className={classNames(!price ? 'invisible' : '')}>
                    <div className="flex items-start justify-center">
                      <span className="text-gray-500 mt-1">US$</span>
                      <span className="text-5xl">{price || ' '}</span>
                    </div>
                    <div className="text-xs text-gray-500">{_('billing.slashMonth')}</div>
                  </div>
                </td>
              );
            })}
          </tr>
          <tr>
            <td className="text-left">{_('billing.userSpaces')}</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{p.id !== 'custom' ? p.policy.max_users : _('billing.tiered')}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.storeItems')}</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_items)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.dashboardAdmins')}</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_dashboard_users)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.userSpecificStores')}¹</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{valueOrUnlimited(i18n, p.policy.max_sections)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.shippingIntegration')}²</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{yesOrNo(i18n, p.policy.use_shipping_integration)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.api')}</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{yesOrNo(i18n, p.policy.use_api)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.l10n')}³</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>{yesOrNo(i18n, p.policy.use_l10n)}</td>
            ))}
          </tr>
          <tr>
            <td className="text-left">{_('billing.support')}</td>
            {filteredPlans.map((p, idx) => (
              <td key={idx}>
                {p.support.includes(AccountSupportType.Call) ? _('billing.supportEmailAndCall') : _('billing.supportEmail')}
              </td>
            ))}
          </tr>
          <tr>
            <td className="text-left"></td>
            {filteredPlans.map((p, idx) => {
              const priceId = isAnnual ? p.price_id_annual : p.price_id;
              return (
                <td key={idx}>
                  <div className="py-4">
                    <PrimaryButton onClick={() => onUpgrade(priceId)} disabled={!priceId}>
                      {_('billing.upgrade')}
                    </PrimaryButton>
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      <div className="my-8 text-xs text-left">
        {[
          _('billing.footNotes.userSpaces'),
          _('billing.footNotes.pricesForSelfManagedOnly'),
          _('billing.footNotes.toMakeChangesEmailUsAt', { email: 'support@motrain.com' }),
          '¹ ' + _('billing.footNotes.whatAreSections'),
          '² ' + _('billing.footNotes.whatIsShippingIntegration'),
          '³ ' + _('billing.footNotes.whatIsMultiLang'),
        ].map((note, idx) => {
          return (
            <p className="m-0" key={idx}>
              {note}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const StripeWrapper: React.FC = (props) => {
  const [s] = useState(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || ''));
  return <Elements stripe={s}>{props.children}</Elements>;
};

export const StripeCheckoutCallbackPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const repo = useRepo();
  const queryClient = useQueryClient();

  const qs = queryString.parse(location.search);
  const accountId = qs.account_id;
  const sessionId = qs.session_id;

  const mutation = useMutation(
    async ({ accountId, sessionId }: { accountId: string; sessionId: string }) => {
      return repo.completeAccountSubscriptionViaStripeCheckout(accountId, sessionId);
    },
    {
      onSuccess: (account, { accountId }) => {
        // Preload the cache query.
        queryClient.setQueryData(['account', accountId], account);
      },
      onSettled: (data, err, { accountId }) => {
        // Affirm the fact that this account should be loaded, although it shouldn't be needed.
        setLastAccountId(accountId);
      },
    }
  );

  useEffect(() => {
    mutation.mutate({ accountId: (accountId || '').toString(), sessionId: (sessionId || '').toString() });
  }, [sessionId, accountId]); // eslint-disable-line

  return (
    <OutsideAccountLayout>
      <div className="flex-grow flex justify-center items-center">
        <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow">
          {mutation.isIdle || mutation.isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : null}
          {mutation.isError ? (
            <>
              <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
              <div className="mt-4 inline-block">
                <LinkPrimaryButton to="/">{t('continue')}</LinkPrimaryButton>
              </div>
            </>
          ) : null}
          {mutation.isSuccess ? (
            <div className="text-center flex flex-col justify-center">
              <h1 className="text-3xl font-bold">{t('billing.paymentSuccessful')}</h1>
              <p className="mt-4 text-2xl">{t('billing.thankYouForSigningUpFor', { product: brandName })}</p>
              <div className="my-12 flex justify-center">
                <img src={successSrc} alt="" />
              </div>
              <p>
                <LinkPrimaryButton to="/">{t('continue')}</LinkPrimaryButton>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </OutsideAccountLayout>
  );
};
