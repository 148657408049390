import { DropzoneState, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { UseAnchorButtonProps, useAnchorButtonProps, useFieldError } from '../../lib/hooks';
import { classNames } from '../../lib/utils';
import AddImageIcon from '../icons/AddImageIcon';

type ImageFileSharedProps = {
  name?: string;
  url?: string | null; // The URL to the previously saved file.
  onChange: (file?: File | null) => void; // Null means remove existing, File is new file, undefined is not set.
  accept?: HTMLInputElement['accept'];
  file?: File | null;
  className?: string;
  id?: string; // The input field ID.
};

const ImageFile = ({
  placeholder,
  imageClassName,
  ...props
}: ImageFileSharedProps & {
  placeholder?: React.ReactNode; // The component to show when no file were set.
  imageClassName?: string;
}) => {
  const { t } = useTranslation();

  return (
    <ImageFileBase {...props}>
      {({ hasImage, imageSrc, changeAnchorProps, removeAnchorProps }) => (
        <>
          <div className="shrink-0">
            {hasImage ? (
              <img src={imageSrc} alt="" className={classNames('max-w-[300px] max-h-8', imageClassName)} />
            ) : placeholder ? (
              placeholder
            ) : (
              <AddImageIcon className="h-8 w-8 text-gray-400" />
            )}
          </div>
          <p className="flex-grow text-sm ml-3">
            {hasImage ? (
              <Trans
                t={t}
                i18nKey="changeOrRemove"
                components={[
                  <a className="text-blue-600" href="#change" {...changeAnchorProps} />, // eslint-disable-line
                  <a className="text-blue-600" href="#remove" {...removeAnchorProps} />, // eslint-disable-line
                ]}
              />
            ) : (
              <Trans
                t={t}
                i18nKey="uploadAFileOrDnD"
                components={[
                  <a className="text-blue-600" href="#upload" {...changeAnchorProps} />, // eslint-disable-line
                ]}
              />
            )}
          </p>
        </>
      )}
    </ImageFileBase>
  );
};

type ImageFileChildProps = {
  dropZone: DropzoneState;
  hasImage: boolean;
  imageSrc: string;
  changeAnchorProps: UseAnchorButtonProps;
  removeAnchorProps: UseAnchorButtonProps;
};

type ImageFileBaseProps = ImageFileSharedProps & {
  children: (props: ImageFileChildProps) => React.ReactNode;
};

export const ImageFileBase = ({ name, url, onChange, accept, file, children, id, className }: ImageFileBaseProps) => {
  const { hasError } = useFieldError(name);
  const imageRemoved = file === null;
  const hasImage = !imageRemoved ? Boolean(url || file) : false;
  const imageSrc = file ? URL.createObjectURL(file) : url ? url : '';
  const handleDrop = (files: File[]) => {
    onChange(files ? files[0] : undefined);
  };

  const dropZone = useDropzone({
    maxFiles: 1,
    accept,
    onDrop: handleDrop,
    noClick: true,
  });

  const changeAnchorProps = useAnchorButtonProps(() => {
    dropZone.open();
  });

  const removeAnchorProps = useAnchorButtonProps(() => {
    onChange(url ? null : undefined);
  });

  return (
    <>
      <div
        {...dropZone.getRootProps()}
        className={classNames(
          'border-2 border-dashed rounded pt-2 pb-3 px-4 inline-flex items-center whitespace-normal',
          dropZone.isDragActive ? 'border-gray-500' : hasError ? 'border-red-500' : 'border-gray-300',
          className
        )}
      >
        <input {...dropZone.getInputProps()} id={id} />
        {children({
          dropZone,
          hasImage,
          imageSrc,
          changeAnchorProps,
          removeAnchorProps,
        })}
      </div>
    </>
  );
};

export default ImageFile;
