import React from 'react';
import { hasPolicy, useAccount } from '../lib/account';
import { AccountPolicy } from '../lib/types';

export const HasPolicy: React.FC<{ policy: keyof AccountPolicy; not?: boolean }> = ({ policy, children, not }) => {
  const account = useAccount();
  let showContent = hasPolicy(account, policy);
  if (not) {
    showContent = !showContent;
  }
  return showContent ? <>{children}</> : null;
};
