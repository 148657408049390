import { fromUnixTime, getUnixTime } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Redirect, useHistory, useParams } from 'react-router';
import { hasPolicy, useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { getDrawMethod, isItemEditable, isProductVariant } from '../../lib/item';
import {
  convertL10nValuesArrayToDict,
  convertL10nValuesDictToArray,
  getStoreLocales,
  isL10nArrayMultiLang,
  setDefaultL10nForLocaleIfMissing,
} from '../../lib/l10n';
import { useItem, useStore } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Dict, Item, ItemDrawMethod, ItemType, RedemptionMethod, Store } from '../../lib/types';
import { itemEditValidationSchema, ItemForm, ItemFormValues } from '../forms/ItemForm';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { NotificationError } from '../Notifications';
import { broadcastSuccessToast } from '../Toasts';
import Dropdown from '../widgets/Dropdown';
import { useItemActions } from './Item';

const useItemEditMutation = (isMultiLang: Boolean, defaultLocale?: string) => {
  const account = useAccount();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const canUseTicketsCost = hasPolicy(account, 'use_tickets_cost');

  return useMutation(
    (variables: any) => {
      const saveDlFile = variables.redemption_method === RedemptionMethod.Download;
      const canUseL10n = account.policy.use_l10n && defaultLocale;

      // Pre-adjustments.
      if (variables.type === ItemType.Contribution) {
        variables.redemption_method = RedemptionMethod.None;
      }
      if (variables.type === ItemType.Sweepstakes && !canUseTicketsCost) {
        variables.cost = 1;
      }

      // Update item.
      let item: Dict = {
        name: variables.name,
        description: variables.description,
        sku: variables.sku,
        cost: Number(variables.cost),

        redemption_method: variables.redemption_method,
        self_redeem_message: variables.self_redeem_message,

        start_time: getUnixTime(variables.start_time),
        end_time: getUnixTime(variables.end_time),

        image_file: variables.image_file ? variables.image_file : null,
      };

      if (canUseL10n) {
        item.l10n = variables.l10n; // Assign values.
        item.l10n = convertL10nValuesDictToArray(
          // Upon saving, we ensure that there is an entry for the default locale with all
          // the properties that should be attached to it. That is done here because in the
          // event where the default value has changed, the form will display the right values
          // but the state won't be updated which means that saving the form won't add/update
          // the l10n entries. So this is just to make it more robust for users.
          setDefaultL10nForLocaleIfMissing(item, defaultLocale, ['name', 'description', 'self_redeem_message']).l10n
        );

        // When the item is not multi-lang, we only keep the default locale.
        if (!isMultiLang) {
          item.l10n = item.l10n.filter((l: { locale: string }) => l.locale === defaultLocale);
        }
      }

      // Create item.
      item = {
        ...item,
        num_items: variables.num_items_unlimited ? 0 : Number(variables.num_items) || 1,

        minimum_bid: Number(variables.minimum_bid) || 0,
        handling_fee: Number(variables.handling_fee) || 0,

        contrib_goal: Number(variables.contrib_goal) || 1000,
        contrib_currency_symbol: variables.contrib_currency_symbol || '',
        contrib_currency_value: Number(variables.contrib_currency_value) || 0,

        draw_method: variables.draw_method || undefined,

        download_file: saveDlFile ? variables.download_file : undefined,
        download_filename: saveDlFile ? variables.download_filename : undefined,
      };

      return repo.updateItem(variables.id, item);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['store-content', data.store_id]);
        queryClient.invalidateQueries(['team-market']);
        queryClient.setQueryData(['item', data.id], data);
        broadcastSuccessToast(t('itemSaved'));
      },
    }
  );
};

const ItemEditContent = (props: { item: Item; store: Store }) => {
  const item: Item = props.item;
  const store: Store = props.store;

  // TODO If item is a variant, show a message to go to product.

  const history = useHistory();
  const account = useAccount();

  const handleBack = () => {
    history.goBack();
  };

  const [defaultLocale, locales] = useMemo(() => {
    const locales = getStoreLocales(account, store);
    if (!locales.length) {
      return [undefined, []];
    }
    return [locales[0].tag, locales];
  }, [account, store]);
  const [locale, setLocale] = useState<string>();

  const mutation = useItemEditMutation(Boolean(locale), defaultLocale);

  // Select the default locale when the item uses more than one locale.
  useEffect(() => {
    if (isL10nArrayMultiLang(item.l10n, defaultLocale)) {
      setLocale(defaultLocale);
    }
  }, [item]); // eslint-disable-line

  const initialData: ItemFormValues = useMemo(() => {
    return {
      type: item.type,

      name: item.name,
      description: item.description,

      image_file: undefined,

      start_time: fromUnixTime(item.start_time),
      end_time: fromUnixTime(item.end_time),
      sku: item.sku || '',

      cost: item.cost,
      num_items: item.num_items || 1,
      num_items_unlimited: item.num_items <= 0,

      handling_fee: item.type === ItemType.Auction ? item.handling_fee : 2,
      minimum_bid: item.type === ItemType.Auction ? item.minimum_bid : 10,

      contrib_goal: item.type === ItemType.Contribution ? item.contrib_goal : 1000,
      contrib_currency_symbol: item.type === ItemType.Contribution ? item.contrib_currency_symbol : '',
      contrib_currency_value: item.type === ItemType.Contribution ? item.contrib_currency_value : 1,

      draw_method: getDrawMethod(item),

      redemption_method: item.redemption_method,

      self_redeem_message: item.self_redeem_message || '',

      download_file: undefined,
      download_filename: item.download_filename || '',

      variants: [], // An item does not have variants.

      // TODO Only if they can use localisation.
      l10n: convertL10nValuesArrayToDict(item.l10n || []),
    };
  }, [item]);

  // If item cannot be edited (e.g. it ended), redirect to item.
  if (!isItemEditable(item)) {
    if (isProductVariant(item)) {
      return <Redirect to={`/product/${item.product_id}`} />;
    }
    return <Redirect to={`/item/${item.id}`} />;
  }

  return (
    <div className="flex flex-col flex-grow">
      {mutation.isError ? (
        <div className="mb-4">
          <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
        </div>
      ) : null}
      <Formik
        initialValues={initialData}
        onSubmit={(values, formik) => {
          mutation.mutate(
            { id: item.id, locale, ...values },
            {
              onSuccess: (item) => {
                history.push(`/item/${item.id}`);
              },
              onError: () => {
                // TODO Handle error.
              },
              onSettled: () => {
                formik.setSubmitting(false);
              },
            }
          );
        }}
        validationSchema={itemEditValidationSchema}
        validateOnMount
      >
        {(formik) => {
          return (
            <ItemForm
              formik={formik}
              locale={locale}
              locales={locales}
              defaultLocale={defaultLocale}
              onLocaleChange={setLocale}
              item={item}
              onCancel={handleBack}
            />
          );
        }}
      </Formik>
    </div>
  );
};

const ItemEditPage = () => {
  const { t } = useTranslation();
  const { itemId } = useParams<{ itemId: string }>();
  const { isLoading, data: item } = useItem(itemId);
  const { isLoading: isStoreLoading, data: store } = useStore(item ? item.store_id : '', { enabled: Boolean(item) });
  const { actions, renderActions } = useItemActions(item, { page: 'edit' });
  return (
    <LayoutWithSidebar>
      <PageHeaderWithBackLabel
        backLabel={store?.name}
        backTo={item ? `/store/${item.store_id}` : undefined}
        buttons={item && actions.length ? <Dropdown right options={actions} label={t('actions')} /> : <></>}
      >
        {item?.name}
      </PageHeaderWithBackLabel>
      {isLoading || isStoreLoading || !item || !store ? null : <ItemEditContent item={item} store={store} />}
      {renderActions()}
    </LayoutWithSidebar>
  );
};

export default ItemEditPage;
