import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import Input from '../inputs/Input';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { broadcastSuccessToast } from '../Toasts';

const storeSchema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

const useStoreCreateMutation = ({ addToTeam }: { addToTeam?: string }) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { id: accountId } = useAccount();
  return useMutation(
    async ({ name }: { name: string }) => {
      const store = await repo.createStore(accountId, name);
      if (addToTeam) {
        await repo.addStoresToTeam([store.id], addToTeam);
      }
      return store;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stores');
        if (addToTeam) {
          queryClient.invalidateQueries(['team-stores', addToTeam]);
        }
      },
    }
  );
};

const StoreCreatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: locationState } = useLocation<{ intent: 'add-to-team'; teamId: string } | undefined>();

  const mutation = useStoreCreateMutation({
    addToTeam: locationState?.intent === 'add-to-team' ? locationState.teamId : undefined,
  });
  const nextUrl = locationState?.intent === 'add-to-team' ? `/team/${locationState.teamId}/market` : null;

  const handleBack = () => {
    history.goBack();
  };

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col flex-grow">
        <HeaderWithBack backHandler={handleBack}>{t('untitledStore')}</HeaderWithBack>
        <Formik
          initialValues={{ name: '' }}
          onSubmit={(values, formik) => {
            mutation.mutate(values, {
              onSuccess: (store) => {
                history.push(nextUrl || `/store/${store.id}`);
                broadcastSuccessToast(t('storeCreated'));
              },
              onError: () => {
                // TODO Handle error.
                formik.setSubmitting(false);
              },
            });
          }}
          validationSchema={storeSchema}
          validateOnMount
        >
          {({ isValid, isSubmitting }) => {
            const canSubmit = isValid && !isSubmitting;
            return (
              <Form className="flex flex-col flex-grow mt-2">
                <div className="flex-grow space-y-8">
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                      <div>
                        <Field as={Input} name="name" />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                  <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                    {t('save')}
                  </PrimaryButton>
                  <Button onClick={handleBack}>{t('discard')}</Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </LayoutWithSidebar>
  );
};

export default StoreCreatePage;
