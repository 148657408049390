import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ExclamationCircleIcon, ExclamationTriangleIcon, UsersIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/solid';
import fromUnixTime from 'date-fns/fromUnixTime';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router';
import { AccountContext } from '../../lib/account';
import { useFiltering, useNavigateTo } from '../../lib/hooks';
import { canCreateAccounts, isDomainAdmin, isSuperUser } from '../../lib/perms';
import { useRecentAccounts } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Account, AccountState } from '../../lib/types';
import { getAccountPaymentTypeStringKey, getAccountStateStringKey } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import SearchInput from '../inputs/Search';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import { NotificationError } from '../Notifications';
import { HasPermission } from '../Permissions';
import { Placeholder, PlaceholderList } from '../Placeholders';

const AccountStateStatus = ({ state }: { state: AccountState }) => {
  let Icon = ExclamationTriangleIcon;
  if (state === AccountState.Active) {
    Icon = CheckIcon;
  } else if (state === AccountState.Suspended) {
    Icon = ExclamationCircleIcon;
  }
  const { t } = useTranslation();
  return (
    <span>
      <Icon className="h-4 w-4 inline-block mr-2" />
      {t(getAccountStateStringKey(state))}
    </span>
  );
};

const AccountRow: React.FC<{ account: Account; onSelect: () => void }> = ({ account, onSelect }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow bg-white">
      <a className="w-full h-full flex min-h-20 items-center p-6 text-gray-800" href="#go" onClick={onSelect}>
        <div className="flex-grow">
          <div className="font-medium leading-5">
            {account.name}
            <HasPermission checker={isSuperUser}>
              <div className="leading-none text-xs text-gray-500 font-normal">{account.domain_id}</div>
            </HasPermission>
          </div>
          {/* TODO Implement last login */}
          {/* <div className="text-xs leading-7 text-gray-500">Last login: July 8, 2021</div> */}
          <HasPermission checker={isDomainAdmin}>
            <div className="grid grid-cols-3 text-xs text-gray-500 mt-2">
              <div>
                <UsersIcon className="h-4 w-4 inline-block mr-2" />
                {account.cur_users}/{account.max_users <= 0 ? t('unlimited') : account.max_users}
              </div>
              <div>
                {t(getAccountPaymentTypeStringKey(account.payment_type))} ({fromUnixTime(account.expiry).toLocaleDateString()})
              </div>
              <div>
                <AccountStateStatus state={account.state} />
              </div>
            </div>
          </HasPermission>
        </div>
        <div>
          <ChevronRightIcon className="h-6 w-6" />
        </div>
      </a>
    </div>
  );
};

const RecentAccounts: React.FC<{ onSelect: (account: Account) => void }> = ({ onSelect }) => {
  const { t } = useTranslation();
  const query = useRecentAccounts();

  if (!query.hasRecentAccounts) {
    return null;
  }

  return (
    <div className="mb-4 border-b border-b-gray-200 pb-4">
      <h2 className="font-medium text-sm mb-2">{t('recentlyAccessed')}</h2>
      <div className="grid grid-cols-5 gap-4">
        {!query.recentAccounts ? (
          <PlaceholderList count={Math.max(1, Math.min(5, query.nRecentAccounts))}>
            <Placeholder className="h-24" />
          </PlaceholderList>
        ) : (
          query.recentAccounts?.map((account) => {
            return (
              <div className="shadow bg-white" key={account.id}>
                <a className="w-full h-full flex p-6 pb-6 text-gray-800" href="#go" onClick={() => onSelect(account)}>
                  <div className="flex-grow overflow-hidden">
                    <div className="font-medium leading-5 break-words">
                      {account.name}
                      <HasPermission checker={isSuperUser}>
                        <div className="leading-none text-xs text-gray-500 font-normal mt-1">{account.domain_id}</div>
                      </HasPermission>
                    </div>
                  </div>
                </a>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const AccountSelectPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const repo = useRepo();
  const goTo = useNavigateTo();
  const { setAccount } = useContext(AccountContext);
  const { filterTerm, filterInputProps } = useFiltering();
  const history = useHistory();

  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = useInfiniteQuery(
    ['accounts', filterTerm],
    ({ pageParam }) => {
      return repo.getMyAccounts(filterTerm, pageParam);
    },
    {
      getNextPageParam: (lastPage, pages) => (lastPage.continuation ? lastPage.continuation : undefined),
    }
  );

  const total = data?.pages[0].total || 0;
  const totalOnPage = data?.pages.map((p) => p.items.length).reduce((c, i) => c + i, 0) || 0;
  const handleAccountSelect = (account: Account) => {
    setAccount(account);
    // TODO Implement wanted URL.
    goTo('/');
  };

  return (
    <OutsideAccountLayout>
      <div className="flex mb-6">
        <div className="flex-grow">
          <h1 className="font-semibold text-2xl">{t('accounts')}</h1>
        </div>
        <div>
          <HasPermission checker={canCreateAccounts}>
            <PrimaryButton icon={PencilIcon} onClick={() => history.push('/accounts/create')}>
              {t('createAccount')}
            </PrimaryButton>
          </HasPermission>
        </div>
      </div>

      <div className="mb-6 w-1/2">
        <SearchInput {...filterInputProps} />
      </div>

      {filterTerm ? null : <RecentAccounts onSelect={handleAccountSelect} />}

      <div>
        {isLoading ? (
          <div className="space-y-4">
            <PlaceholderList count={5}>
              <Placeholder className="h-20" />
            </PlaceholderList>
          </div>
        ) : null}
        {isError ? <NotificationError>{t('error.unknown')}</NotificationError> : null}
        {isSuccess && data ? (
          <>
            <div className="space-y-4">
              {data.pages.map((page) => {
                return page.items.map((account, index) => {
                  return <AccountRow account={account} onSelect={() => handleAccountSelect(account)} key={account.id} />;
                });
              })}
            </div>
            {total > 0 ? (
              <div className="flex items-center justify-center my-6 space-x-4">
                <div className="text-sm">{t('showingCountOfTotal', { count: totalOnPage, total })}</div>
                <div>
                  <Button disabled={!hasNextPage || isFetchingNextPage} large onClick={() => fetchNextPage()}>
                    {t('showMore')}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="mt-2 text-lg text-gray-700">
                {filterTerm ? t('noResultsFor', { term: filterTerm }) : t('noAccountsFound')}
              </div>
            )}
          </>
        ) : null}
      </div>
    </OutsideAccountLayout>
  );
};

export default AccountSelectPage;
