import { PencilIcon, PlusIcon, QueueListIcon } from '@heroicons/react/24/solid';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import {
  useFiltering,
  usePermissionChecker,
  useSelection,
  UseSelectionResult,
  useSorting,
  UseSortingResult,
} from '../../lib/hooks';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Permission, Store } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import SearchInput from '../inputs/Search';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { ConfirmModal } from '../Modals';
import { HasAccountPermission, HasTeamPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import Dropdown, { DropdownOption } from '../widgets/Dropdown';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdCheckbox,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThCheckbox,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';

const useTeamStores = (teamId: string, searchTerm?: string, orderBy?: string) => {
  const repo = useRepo();
  return useContinuatedQuery(
    ['team-stores', teamId, { searchTerm, orderBy }],
    ({ pageParam }) => {
      return repo.getTeamStores(teamId, { term: searchTerm }, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, teamId],
    }
  );
};

const useTeamStoresRemoveMutation = (teamId: string) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  const { t } = useTranslation();
  return useMutation(({ storeIds }: { storeIds: string[] }) => repo.removeStoresFromTeam(storeIds, teamId), {
    onSuccess: (data, { storeIds }) => {
      // TODO Optimistic delete.
      queryClient.invalidateQueries(['team', teamId]);
      queryClient.invalidateQueries(['team-stores', teamId]);
      queryClient.invalidateQueries(['teams']);
      storeIds.forEach((storeId) => {
        queryClient.invalidateQueries(['store', storeId]);
        queryClient.invalidateQueries(['store-teams', storeId]);
      });
      queryClient.invalidateQueries(['stores']);

      broadcastSuccessToast(t('storeRemoved', { count: storeIds.length }));
    },
    onError: (err, variables, context: any) => {
      // TODO Display message.
    },
  });
};

const TeamStoresPageContent = () => {
  const { t } = useTranslation();
  const { team } = useTeamPage();

  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', { name: false });
  const query = useTeamStores(team.id, filterTerm, sorting.sortField);
  const selection = useSelection(query);
  const [toRemoveItems, setToRemoveItems] = useState<string[]>([]);
  const removeMutation = useTeamStoresRemoveMutation(team.id);
  const permChecker = usePermissionChecker();

  const handleRemove = () => {
    if (!toRemoveItems.length) {
      return;
    }
    setToRemoveItems([]);

    removeMutation.mutate(
      { storeIds: toRemoveItems },
      {
        onSuccess: () => {
          selection.removeFromSelection(toRemoveItems);
        },
      }
    );
  };

  const actions = [
    {
      label: t('remove'),
      can: permChecker.hasTeamPermission(team.id, Permission.ManageTeamStore),
      onClick: (ids: string[]) => setToRemoveItems(ids),
    },
  ].filter((action) => isUndefined(action.can) || action.can);

  const hasFilters = typeof filterTerm === 'string' && filterTerm.trim().length;
  const noContent = !hasFilters && (!team.stores.length || (query.isSuccess && !query.data?.total));

  return (
    <>
      {noContent ? null : (
        <div className="mb-4">
          <div className="flex items-center space-x-2">
            {actions.length ? (
              <div>
                <Dropdown
                  label={t('actions')}
                  options={actions.map((action) => ({
                    ...action,
                    disabled: !selection.selectedIds.length,
                    onClick: () => action.onClick(selection.selectedIds),
                  }))}
                />
              </div>
            ) : null}
            <div className="w-96">
              <SearchInput {...filterInputProps} />
            </div>
          </div>
        </div>
      )}

      {noContent ? (
        <ContentNoItems />
      ) : (
        <div>
          <StoresTable actions={actions} queryResult={query} selectionResult={selection} sortingResult={sorting} />
        </div>
      )}

      {toRemoveItems.length ? (
        <ConfirmModal
          onConfirm={handleRemove}
          onCancel={() => setToRemoveItems([])}
          title={t('removeStore', { count: toRemoveItems.length })}
          message={<Trans t={t} i18nKey="removeStoreConfirm" count={toRemoveItems.length} />}
          confirmButtonText={t('remove')}
          danger={false}
        />
      ) : null}
    </>
  );
};

const TeamStoresPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <TeamPageLayout
      buttons={(team) => (
        <>
          <HasTeamPermission teamId={team.id} perm={Permission.ManageTeamStore}>
            {team.stores.length ? (
              <Button onClick={() => history.push(`/team/${team.id}/market/layout`)} icon={QueueListIcon}>
                {t('categorizeItems')}
              </Button>
            ) : null}
            <PrimaryButton onClick={() => history.push(`/team/${team.id}/add-store`)} icon={PlusIcon}>
              {t('addStore', { count: 1 })}
            </PrimaryButton>
          </HasTeamPermission>
        </>
      )}
    >
      <TeamStoresPageContent />
    </TeamPageLayout>
  );
};

type StoreTableProps = {
  actions: (Omit<DropdownOption, 'onClick'> & { onClick: (userId: string[]) => void })[];
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Store>>;
  sortingResult: UseSortingResult;
  selectionResult: UseSelectionResult;
};

function StoresTable({ actions, queryResult, selectionResult, sortingResult }: StoreTableProps) {
  const { t } = useTranslation();
  const { isLoading, isSuccess, data } = queryResult;
  const { onSelectAllChange, isEntirePageSelected, onSelectionChange, selectedIds } = selectionResult;

  return (
    <>
      <Table>
        <Thead>
          {actions.length ? <ThCheckbox onChange={onSelectAllChange} checked={isEntirePageSelected} /> : null}
          <ThSortableWithSorting sortKey="name" sortingResult={sortingResult}>
            {t('name')}
          </ThSortableWithSorting>
          <ThSortableWithSorting sortKey="items_count" sortingResult={sortingResult}>
            {t('items')}
          </ThSortableWithSorting>
          {actions.length ? <Th /> : null}
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={5}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess && data
            ? data.items.map((item) => (
                <StoreRow
                  store={item}
                  key={item.id}
                  onSelectChange={() => onSelectionChange(item)}
                  selected={selectedIds.includes(item.id)}
                  actions={actions.map((action) => ({
                    ...action,
                    onClick: () => action.onClick && action.onClick([item.id]),
                  }))}
                />
              ))
            : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={queryResult.showingFrom}
          showingTo={queryResult.showingTo}
          showingOfTotal={queryResult.showingOfTotal}
          hasNextPage={queryResult.hasNextPage}
          hasPreviousPage={queryResult.hasPreviousPage}
          onNextPageClick={queryResult.fetchNextPage}
          onPreviousPageClick={queryResult.fetchPreviousPage}
        />
      ) : null}
    </>
  );
}

const StoreRow: React.FC<{
  store: Store;
  onSelectChange: () => void;
  selected: boolean;
  actions: DropdownOption[];
}> = ({ store, onSelectChange, selected, actions }) => {
  return (
    <Tr key={store.id}>
      {actions.length ? <TdCheckbox checked={selected} onChange={onSelectChange} /> : null}
      <TdPrimary to={`/store/${store.id}`}>{store.name}</TdPrimary>
      <Td>{store.item_count}</Td>
      {actions.length ? <TdContextualMenu options={actions} /> : null}
    </Tr>
  );
};

const ContentNoItems = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { team } = useTeamPage();
  const permChecker = usePermissionChecker();

  let message: string | null = null;
  if (permChecker.hasAccountPermission(Permission.CreateStore)) {
    message = 'createStoreOrAddExistingToTeam';
  }

  return (
    <div className="flex flex-grow justify-center items-center text-center py-4">
      <div className="max-w-xs pb-10 text-sm">
        <h2 className="font-medium mb-1">{t('noStores')}</h2>
        <HasTeamPermission teamId={team.id} perm={Permission.ManageTeamStore}>
          {message ? <p>{t(message)}</p> : null}
          <div className="mt-6 flex justify-center space-x-4">
            <HasAccountPermission perm={Permission.CreateStore}>
              <PrimaryButton
                large
                onClick={() =>
                  history.push(`/stores/create`, {
                    intent: 'add-to-team',
                    teamId: team.id,
                  })
                }
                icon={PencilIcon}
              >
                {t('createStore')}
              </PrimaryButton>
            </HasAccountPermission>
            <PrimaryButton large onClick={() => history.push(`/team/${team.id}/add-store`)} icon={PlusIcon}>
              {t('addStore', { count: 1 })}
            </PrimaryButton>
          </div>
        </HasTeamPermission>
      </div>
    </div>
  );
};

export default TeamStoresPage;
