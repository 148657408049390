import { Menu, Transition } from '@headlessui/react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, PlusIcon, ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../lib/account';
import { useUser } from '../../lib/auth';
import { useNavigateTo, usePermissionChecker } from '../../lib/hooks';
import { canCreateAccounts, canSwitchAccount } from '../../lib/perms';
import { Account, Permission } from '../../lib/types';
import { classNames } from '../../lib/utils';
import { HasAnyAccountPermission, HasPermission } from '../Permissions';

const AccountDropdown: React.FC<{ accountName: string; accounts?: Account[] }> = ({ accountName, accounts }) => {
  const { t } = useTranslation();
  const user = useUser();
  const permChecker = usePermissionChecker();

  const goTo = useNavigateTo();
  const { setAccount } = useContext(AccountContext);
  const handleClick = (account: Account) => {
    setAccount(account);
    goTo('/');
  };

  const hasRecentAccounts = Boolean(accounts?.length);
  const settingsPerms = [Permission.UpdateAccount, Permission.ManageApi, Permission.ManageBilling, Permission.ManageBranding];
  const canSwitch = canSwitchAccount(user);
  const canViewSettings = permChecker.hasAnyAccountPermission(settingsPerms);
  const isEmpty = !canSwitch && !canViewSettings && !hasRecentAccounts;

  const sortedAccounts = useMemo(() => accounts?.sort((a, b) => a.name.localeCompare(b.name)), [accounts]);

  if (isEmpty) {
    return (
      <div className="w-full text-left">
        <div className="select-none text-gray-700 flex w-full text-left rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium">
          <div className="flex-grow">{accountName}</div>
        </div>
      </div>
    );
  }

  return (
    <Menu as="div" className="relative w-full text-left">
      <div>
        <Menu.Button
          className={classNames(
            'text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100',
            'flex w-full text-left rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50'
          )}
        >
          <div className="flex-grow">{accountName}</div>
          <div className="flex-shrink-0">
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'origin-top-left left-0 right-0',
            'absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
            'divide-y divide-gray-100'
          )}
        >
          <HasAnyAccountPermission perms={settingsPerms}>
            <div className="py-1">
              <Menu.Item>
                {({ active }) =>
                  getMenuButton({
                    active,
                    // TODO Ensure that the user can go to that page.
                    onClick: () => goTo('/account/branding'),
                    icon: Cog8ToothIcon,
                    children: t('accountSettings'),
                  })
                }
              </Menu.Item>
            </div>
          </HasAnyAccountPermission>
          {hasRecentAccounts ? (
            <>
              <div className="py-1">
                {sortedAccounts?.map((account) => {
                  return (
                    <Menu.Item key={account.id}>
                      {({ active }) =>
                        getMenuButton({
                          active,
                          onClick: () => handleClick(account),
                          children: account.name,
                        })
                      }
                    </Menu.Item>
                  );
                })}
              </div>
            </>
          ) : null}
          {canSwitch ? (
            <div className="py-1">
              <Menu.Item>
                {({ active }) =>
                  getMenuButton({
                    active,
                    onClick: () => goTo('/switch'),
                    icon: ArrowsRightLeftIcon,
                    children: t('switchAccount'),
                  })
                }
              </Menu.Item>
            </div>
          ) : (
            <HasPermission checker={canCreateAccounts}>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) =>
                    getMenuButton({
                      active,
                      onClick: () => goTo('/accounts/create'),
                      icon: PlusIcon,
                      children: t('createAccount'),
                    })
                  }
                </Menu.Item>
              </div>
            </HasPermission>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

// We cannot use a sub component because Menu.Item doesn't work as expected, there must be a trick
// that I am not aware of, but this technique works so it's OK.
function getMenuButton({
  active,
  onClick,
  children,
  icon,
}: {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
  icon?: React.ComponentType<React.ComponentProps<'svg'>>;
}) {
  const Icon = icon;
  return (
    <button
      type="button"
      className={classNames(
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'w-full group flex items-center px-4 py-2 text-sm text-left'
      )}
      onClick={() => onClick()}
    >
      {Icon ? <Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" /> : null}
      {children}
    </button>
  );
}

export default AccountDropdown;
