import React from 'react';
import { useUser } from '../lib/auth';
import { usePermissionChecker } from '../lib/hooks';
import { isSuperUser, PermissionChecker } from '../lib/perms';
import { Permission, Player } from '../lib/types';

export const HasAccountPermission: React.FC<{ perm: Permission }> = ({ perm, children }) => {
  const permChecker = usePermissionChecker();
  return permChecker.hasAccountPermission(perm) ? <>{children}</> : null;
};

export const HasAnyAccountPermission: React.FC<{ perms: Permission[] }> = ({ perms, children }) => {
  const permChecker = usePermissionChecker();
  return permChecker.hasAnyAccountPermission(perms) ? <>{children}</> : null;
};

export const HasAnyWhereInAccountPermission: React.FC<{ perm: Permission }> = ({ perm, children }) => {
  const permChecker = usePermissionChecker();
  return permChecker.hasAnywhereInAccountPermission(perm) ? <>{children}</> : null;
};

export const HasImplicitPlayerPermission: React.FC<{ player?: Player; perm: Permission }> = ({ player, perm, children }) => {
  const permChecker = usePermissionChecker();
  return player && permChecker.hasImplicityPlayerPermission(player, perm) ? <>{children}</> : null;
};

export const HasInAnyStorePermission: React.FC<{ perm: Permission }> = ({ perm, children }) => {
  const permChecker = usePermissionChecker();
  return permChecker.hasInAnyStorePermission(perm) ? <>{children}</> : null;
};

export const HasInAnyTeamPermission: React.FC<{ perm: Permission }> = ({ perm, children }) => {
  const permChecker = usePermissionChecker();
  return permChecker.hasInAnyTeamPermission(perm) ? <>{children}</> : null;
};

export const HasStorePermission: React.FC<{ storeId?: string; perm: Permission }> = ({ storeId, perm, children }) => {
  const permChecker = usePermissionChecker();
  return storeId && permChecker.hasStorePermission(storeId, perm) ? <>{children}</> : null;
};

export const HasTeamPermission: React.FC<{ teamId?: string; perm: Permission }> = ({ teamId, perm, children }) => {
  const permChecker = usePermissionChecker();
  return teamId && permChecker.hasTeamPermission(teamId, perm) ? <>{children}</> : null;
};

export const HasPermission: React.FC<{ checker: PermissionChecker }> = ({ checker, children }) => {
  const user = useUser();
  return checker(user) ? <>{children}</> : null;
};

export const IsSuperUser: React.FC = ({ children }) => {
  const user = useUser();
  return isSuperUser(user) ? <>{children}</> : null;
};
