import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

declare global {
  interface Window {
    Motrain: {
      RECAPTCHA_SITE_KEY: string;
    };
    recaptchaOptions: {
      useRecaptchaNet: boolean;
    };
  }
}

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

type RecaptchaRef = { executeAsync: () => Promise<string | null> };

const Recaptcha: React.FC<{ recaptchaRef: React.MutableRefObject<RecaptchaRef | null> }> = (props) => {
  const ref = useRef<ReCAPTCHA | null>(null);

  const handleRef = (r: ReCAPTCHA) => {
    ref.current = r;
    props.recaptchaRef.current = {
      // Use this function name for legacy reason.
      executeAsync: async () => {
        const t = await r.executeAsync();
        r.reset(); // Automatically reset because we cannot reuse the same token twice.
        return t;
      },
    };
  };

  return <ReCAPTCHA ref={handleRef} sitekey={window.Motrain.RECAPTCHA_SITE_KEY} size="invisible" />;
};

export const useRecaptchaRef = () => {
  return useRef<RecaptchaRef | null>(null);
};

export default Recaptcha;
