import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { usePermissionChecker } from '../../lib/hooks';
import { useRepo } from '../../lib/repository';
import { Job, JobState, Permission } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { Table, Tbody, Td, Th, Thead, Tr } from '../widgets/Table';

const importSchema = yup
  .object({
    file: yup.mixed().required(),
  })
  .required();

const useJobHistory = (teamId: string) => {
  const repo = useRepo();
  return useQuery(['team-coins-import', teamId], () => repo.getTeamCoinsImportJobHistory(teamId), {
    staleTime: 0,
    cacheTime: 0,
  });
};

const useCoinsImportMutation = (teamId: string) => {
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation(
    async ({ file }: { file: File }) => {
      const blob = await repo.getTeamCoinsImportBlobAccess(teamId);
      const ingested = await repo.ingestFile(file, blob);
      return repo.importCoinsFromCsv(teamId, `${ingested.container}/${ingested.blobName}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['team-coins-import']);
      },
    }
  );
};

const TeamCoinsImportPage = () => {
  return (
    <TeamPageLayout activeNavPill="players">
      <TeamCoinsImportPageContent />
    </TeamPageLayout>
  );
};

const TeamCoinsImportPageContent = () => {
  const { t } = useTranslation();
  const [fileKey, setFileKey] = useState(Date.now());
  const history = useHistory();
  const { team } = useTeamPage();
  const permChecker = usePermissionChecker();

  const historyQuery = useJobHistory(team.id);
  const hasPendingJob =
    historyQuery.isSuccess && historyQuery.data.some((j) => [JobState.Idle, JobState.Started].includes(j.state));

  const importMutation = useCoinsImportMutation(team.id);

  const formik = useFormik<{ file?: File }>({
    initialValues: { file: undefined },
    isInitialValid: false,
    validationSchema: importSchema,
    onSubmit: (values, formik) => {
      if (hasPendingJob) return;
      importMutation.mutate(
        { file: values.file! },
        {
          onSuccess: () => {
            setFileKey(Date.now());
            formik.resetForm();
          },
        }
      );
    },
  });

  const canSubmit =
    permChecker.hasTeamPermission(team.id, Permission.AwardCoin) &&
    formik.isValid &&
    historyQuery.isSuccess &&
    !hasPendingJob &&
    !importMutation.isLoading;

  const handleBack = (e: any) => {
    e.preventDefault();
    history.push(`/team/${team.id}`);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('file', e.target.files ? e.target.files[0] : null);
  };

  return (
    <>
      <div className="flex flex-col">
        <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
          <p className="mb-6">{t('importCoinsFromCsvDescription')}</p>
          <div className="flex-grow space-y-8">
            <div className="w-96">
              <label>
                <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('csvFile')}</div>
                <div>
                  <input type="file" name="file" onChange={handleFileChange} key={fileKey} />
                </div>
              </label>
            </div>
          </div>

          <div className="w-full border-t border-gray-100 mt-10 pt-4 flex items-end flex-col">
            <div className="flex flex-row-reverse items-end">
              <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                {t('importCoins')}
              </PrimaryButton>
              <Button onClick={handleBack}>{t('cancel')}</Button>
            </div>
            <div className="text-xs text-right mt-4 text-gray-500">{t('importCoinsFromCsvNote')}</div>
          </div>
        </form>
      </div>
      <div className="my-6">
        <h2 className="my-4 font-medium text-lg">{t('previousImports')}</h2>
        {historyQuery.isLoading ? t('loadingEllipsis') : null}
        {historyQuery.isSuccess ? <HistoryTable jobs={historyQuery.data} /> : null}
      </div>
    </>
  );
};

function HistoryTable({ jobs }: { jobs: Job[] }) {
  const { t } = useTranslation();
  return (
    <div>
      {!jobs.length ? (
        <p className="italic">{t('noneYet')}</p>
      ) : (
        <Table>
          <Thead>
            <Th>{t('createdOn')}</Th>
            <Th>{t('status')}</Th>
            <Th>{t('completedOn')}</Th>
            <Th></Th>
          </Thead>
          <Tbody>
            {jobs.map((job) => {
              return (
                <Tr key={job.id}>
                  <Td>{new Date(job.created_on * 1000).toLocaleString()}</Td>
                  <Td>{t(`jobState.${job.state}`)}</Td>
                  <Td>{job.completed_on ? new Date(job.completed_on * 1000).toLocaleString() : '-'}</Td>
                  <Td>{job.state === JobState.Complete ? job.result.success : '-'}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </div>
  );
}

export default TeamCoinsImportPage;
