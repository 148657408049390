import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import WithoutSidebar from '../layouts/WithoutSidebar';
import { NotificationError } from '../Notifications';
import Spinner from '../widgets/Spinner';
import queryString from 'query-string';
import { useAccount } from '../../lib/account';

const useAppValidate = (clientId: string, redirectUri: string, responseType: string) => {
  const repo = useRepo();
  return useQuery([], async () => repo.validateApp(clientId, redirectUri, responseType), { retry: false });
};

const useAppAcceptMutation = () => {
  const account = useAccount();
  const repo = useRepo();
  return useMutation((data: { state: string; client_id: string; redirect_uri: string; response_type: string }) => {
    return repo.acceptApp(account.id, data.client_id, data.redirect_uri, data.response_type, data.state);
  }, {});
};

const AppAuthorisePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const parsedQs = queryString.parse(location.search);

  const state = (parsedQs.state || '').toString();
  const client_id = (parsedQs.client_id || '').toString();
  const redirect_uri = (parsedQs.redirect_uri || '').toString();
  const response_type = (parsedQs.response_type || '').toString();

  const { isLoading, isSuccess, data, error } = useAppValidate(client_id, redirect_uri, response_type);

  const mutation = useAppAcceptMutation();

  const handleDecline = () => {
    history.replace('/');
  };
  const declineButtonDisabled = mutation.isLoading;

  const handleConfirm = () => {
    mutation.mutate(
      { state, client_id, redirect_uri, response_type },
      {
        onSuccess: ({ redirect_url }) => {
          window.location.href = redirect_url;
        },
      }
    );
  };
  const confirmButtonDisabled = mutation.isLoading;

  return (
    <WithoutSidebar>
      <div className="flex-grow flex justify-center items-center">
        <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow">
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : isSuccess && data ? (
            <div>
              {mutation.isError ? (
                <div className="mb-4">
                  <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
                </div>
              ) : null}
              <h3 className="mb-8 text-center font-bold text-lg">{data.name}</h3>
              <p className="my-4">{t('app.confirmAppAddToAccount')}</p>
              <div className="mt-8 flex flex-row">
                <div className="w-1/2 pr-2">
                  <Button onClick={handleDecline} disabled={declineButtonDisabled}>
                    {t('cancel')}
                  </Button>
                </div>
                <div className="w-1/2 pl-2 text-right">
                  <PrimaryButton onClick={handleConfirm} disabled={confirmButtonDisabled}>
                    {t('confirm')}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          ) : (
            <>
              <NotificationError>{getErrorMessage(error)}</NotificationError>
              <div className="w-full border-t border-gray-100 mt-6 pt-4 flex flex-row-reverse items-end flex-shrink-0">
                <PrimaryButton onClick={() => history.push('/')}>{t('continue')}</PrimaryButton>
              </div>
            </>
          )}
        </div>
      </div>
    </WithoutSidebar>
  );
};

export default AppAuthorisePage;
