import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { getErrorMessage } from '../../lib/i18n';
import { getLocaleName } from '../../lib/l10n';
import { useRepo } from '../../lib/repository';
import { Store } from '../../lib/types';
import { classNames } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import HelpText from '../forms/HelpText';
import Checkbox from '../inputs/Checkbox';
import Input from '../inputs/Input';
import { ToggleInForm } from '../inputs/Toggle';
import StorePageLayout, { useStorePage } from '../layouts/StorePage';
import { NotificationError } from '../Notifications';
import { HasPolicy } from '../Policy';
import { broadcastSuccessToast } from '../Toasts';
import LangDropdown, { langOptions } from '../widgets/LangDropdown';
import { ShorteningTagList, TagComponentProps } from '../widgets/Tag';

const storeSchema = yup
  .object({
    name: yup.string().required(),
    redemptions_shipping_export_recipients: yup.string().test('is-list-of-emails', 'Invalid emails', (value) => {
      if (typeof value !== 'string') return true;
      if (!value.trim().length) return true;
      return value.split(/\s*;\s*/).every((email) => yup.string().email().isValidSync(email));
    }),
    redemptions_shipping_allow_edit: yup.boolean(),
    redemptions_shipping_required_fields: yup.array().of(yup.string()),
    support_email: yup.string().email(),
  })
  .required();

const useStoreUpdateMutation = (storeId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((data: Partial<Store>) => repo.updateStore(storeId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('stores');
      queryClient.invalidateQueries(['store', storeId]);
      broadcastSuccessToast(t('settingsSaved'));
    },
  });
};

const StoreSettingsPage = () => {
  return (
    <StorePageLayout>
      <StoreSettingsPageContent />
    </StorePageLayout>
  );
};

const StoreSettingsPageContent = () => {
  const { t } = useTranslation();
  const { store } = useStorePage();
  const mutation = useStoreUpdateMutation(store.id);

  return (
    <>
      <Formik
        initialValues={{
          name: store.name,
          redemptions_shipping_allow_edit: store.redemptions_shipping_allow_edit,
          redemptions_shipping_export_recipients: store.redemptions_shipping_export_recipients,
          redemptions_shipping_required_fields: store.redemptions_shipping_required_fields,
          support_email: store.support_email,
          locales: store.locales,
        }}
        onSubmit={(values, formik) => {
          mutation.mutate(values, {
            onSettled: () => {
              formik.setSubmitting(false);
            },
            onSuccess: () => {
              formik.resetForm({ values });
            },
          });
        }}
        validationSchema={storeSchema}
        validateOnMount
      >
        {({ isValid, isSubmitting, dirty, resetForm, values, setFieldValue }) => {
          const canSubmit = !isSubmitting && isValid && dirty;
          const canDiscard = !isSubmitting && dirty;
          const handleDiscard = () => resetForm();
          const [defaultLanguage, ...otherLanguages] = values.locales || [];

          return (
            <Form className="flex flex-col flex-grow">
              {mutation.isError ? (
                <div className="mb-4">
                  <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
                </div>
              ) : null}
              <div className="flex flex-grow justify-between">
                <div className="flex-shrink-0 w-128">
                  <div className="flex-grow space-y-8">
                    <div className="w-96">
                      <label>
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                        <div>
                          <Field as={Input} name="name" />
                        </div>
                      </label>
                    </div>

                    <HasPolicy policy="use_shipping_integration">
                      <div className="w-96">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('supportEmail')}</div>
                          <div>
                            <Field as={Input} name="support_email" type="email" />
                          </div>
                          <HelpText>{t('storeSupportEmailHelp')}</HelpText>
                        </label>
                      </div>

                      <h3 className="text-lg font-semibold mb-4">{t('shippingOrders')}</h3>

                      <div className="w-96">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                            {t('shippingRedemptionsExportsRecipients')}
                          </div>
                          <div>
                            <Field as={Input} name="redemptions_shipping_export_recipients" />
                          </div>
                          <HelpText>{t('shippingRedemptionsExportsRecipientsHelp')}</HelpText>
                        </label>
                      </div>

                      <div className="w-96">
                        <ToggleInForm
                          enabled={values.redemptions_shipping_allow_edit}
                          onChange={(enabled) => setFieldValue('redemptions_shipping_allow_edit', enabled)}
                          label={t('shippingRedemptionsAllowPlayerEdit')}
                        />
                        <HelpText>{t('shippingRedemptionsAllowPlayerEditHelp')}</HelpText>
                      </div>

                      <div className="w-96">
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">
                          {t('shippingRedemptionsRequiredFields')}
                        </div>
                        <HelpText>{t('shippingRedemptionsRequiredFieldsHelp')}</HelpText>
                        <div className="mt-2 gap-2 flex flex-col">
                          {/* <label><Field as={Checkbox} name="redemptions_shipping_required_fields.phone" /></label> */}
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" disabled checked />
                            <div className="ml-2 inline-block">{t('name')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" disabled checked />
                            <div className="ml-2 inline-block">{t('email')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="locationname" />
                            <div className="ml-2 inline-block">{t('locationName')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="locationid" />
                            <div className="ml-2 inline-block">{t('locationId')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="addressline1" />
                            <div className="ml-2 inline-block">{t('addressLine')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="city" />
                            <div className="ml-2 inline-block">{t('city')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="postcode" />
                            <div className="ml-2 inline-block">{t('zipPostCode')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="state" />
                            <div className="ml-2 inline-block">{t('stateProvince')}</div>
                          </label>
                          <label className="block">
                            <Field as={Checkbox} type="checkbox" name="redemptions_shipping_required_fields" value="country" />
                            <div className="ml-2 inline-block">{t('country')}</div>
                          </label>
                        </div>
                      </div>
                    </HasPolicy>
                  </div>
                </div>
                <div className="w-120">
                  <HasPolicy policy="use_l10n">
                    <div className="bg-gray-50 rounded pt-10 pb-12 pl-14 pr-10">
                      <h3 className="text-lg font-semibold mb-4">{t('languages')}</h3>
                      <div className="space-y-5">
                        <div>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                            <div>{t('defaultLanguage')}</div>
                          </div>
                          <div>
                            <FieldArray name="locales">
                              {(arrayHelpers) => (
                                <LangDropdown
                                  selected={defaultLanguage}
                                  onChange={(lang) => {
                                    const oldIdx = values.locales?.indexOf(lang);
                                    if (typeof oldIdx !== 'undefined' && oldIdx > -1) {
                                      arrayHelpers.remove(oldIdx);
                                      arrayHelpers.insert(0, lang);
                                      return;
                                    }
                                    arrayHelpers.replace(0, lang);
                                  }}
                                />
                              )}
                            </FieldArray>
                          </div>
                        </div>
                        <div>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                            <div>{t('additionalLanguages')}</div>
                          </div>
                          <div>
                            <MultiLangDropdown
                              defaultLanguage={defaultLanguage}
                              selected={otherLanguages}
                              onAdd={(v) => setFieldValue('locales', values.locales?.concat(v))}
                              onRemove={(v) =>
                                setFieldValue('locales', [defaultLanguage].concat(otherLanguages.filter((l, i) => v !== l)))
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </HasPolicy>
                </div>
              </div>

              <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                  {t('save')}
                </PrimaryButton>
                <Button onClick={handleDiscard} disabled={!canDiscard}>
                  {t('discard')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const MultiLangDropdown: React.FC<{
  defaultLanguage?: string;
  selected?: string[];
  onAdd: (value: string) => void;
  onRemove: (value: string) => void;
}> = ({ selected, defaultLanguage, onAdd, onRemove }) => {
  const { t } = useTranslation();
  const usePlaceholder = !selected || !selected.length;
  const disabled = !Boolean(defaultLanguage);

  function handleSelect(value: string) {
    const checked = selected?.includes(value);
    if (!checked) {
      onAdd(value);
    } else {
      onRemove(value);
    }
  }

  const languages = useMemo(() => {
    return selected?.map(getLocaleName) || [];
  }, [selected]);

  return (
    <Listbox value="" onChange={(value) => handleSelect(value)} disabled={disabled}>
      <div className="relative">
        <Listbox.Button
          disabled={disabled}
          className={classNames(
            'flex text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50',
            usePlaceholder ? 'text-gray-500' : 'text-gray-700 font-medium',
            'disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-default disabled:text-gray-400'
          )}
        >
          <div className="flex-grow truncate">
            {usePlaceholder ? t('selectEllipsis') : <ShorteningTagList component={LocaleTag} tags={languages} />}
          </div>
          <div className="flex-shrink-0 ml-2 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
            <div className="inline-flex pl-1 flex-shrink-0">
              <ChevronDownIcon className={classNames('h-5 w-5', disabled ? 'text-gray-400' : 'text-gray-500')} aria-hidden="true" />
            </div>
          </div>
        </Listbox.Button>

        <Transition
          unmount={false}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options
            static
            className="absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
          >
            {langOptions
              .filter((option) => option.value !== defaultLanguage)
              .map((option) => {
                const checked = selected?.includes(option.value);
                return (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        'text-gray-700 select-none relative py-2 px-4',
                        active ? 'text-gray-900 bg-gray-100' : '',
                        checked ? 'pr-10' : ''
                      )
                    }
                    value={option.value}
                  >
                    {({ active }) => (
                      <>
                        <div className="flex items-center">
                          <div className="mr-2 flex-shrink-0">
                            <Checkbox checked={checked} readOnly />
                          </div>

                          <div className={classNames('font-normal flex-grow truncate')}>{option.label}</div>
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

const LocaleTag = (props: TagComponentProps) => {
  return (
    <div
      className={classNames(
        'inline-block bg-gray-100 rounded-full pb-0.5 px-3 text-xs leading-snug font-medium',
        props.invisible ? 'invisible' : ''
      )}
    >
      {props.children}
    </div>
  );
};

export default StoreSettingsPage;
