import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { usePasswordResetMutation } from '../../lib/auth';
import { passwordSchema } from '../../lib/validation';
import { PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import FullpageLayout from '../layouts/Fullpage';
import Logo from '../Logo';
import Notification, { NotificationError } from '../Notifications';
import queryString from 'query-string';

const schema = yup
  .object({
    password: passwordSchema.required(),
    password2: passwordSchema.required().when('password', function (password, schema) {
      return schema.oneOf([password]);
    }),
  })
  .required();

const NewPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const mutation = usePasswordResetMutation();

  const handleSubmit = async (values: any) => {
    const qs = queryString.parse(window.location.search);
    return mutation.mutateAsync({ password: values.password, token: qs.token?.toString() || '' });
  };

  return (
    <FullpageLayout>
      <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow text-center">
        <div>
          <div className="mb-6">
            <div className="mb-6 flex justify-center items-center">
              <Logo />
            </div>
            <div className="text-2xl leading-8 text-gray-600 font-medium">{t('signup.resetYourPassword')}</div>
          </div>

          <Formik initialValues={{ password: '', password2: '' }} onSubmit={handleSubmit} validationSchema={schema} validateOnMount>
            {({ isSubmitting, isValid }) => {
              const canSubmit = !isSubmitting && isValid;

              return (
                <>
                  {mutation.isError ? (
                    <div className="mb-4">
                      <NotificationError>{t('signup.errorWhileResettingPassword')}</NotificationError>
                    </div>
                  ) : null}

                  {mutation.isSuccess ? (
                    <div className="mb-4">
                      <Notification>{t('signup.newPasswordSaved')}</Notification>
                    </div>
                  ) : null}

                  {!mutation.isSuccess ? (
                    <Form className="flex flex-col flex-grow text-left">
                      <p className="font-normal text-sm my-4">{t('signup.pleaseEnterNewPassword')}</p>

                      <div className="flex-grow space-y-4">
                        <div className="">
                          <label>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('password')}</div>
                            <div>
                              <Field as={Input} type="password" name="password" />
                            </div>
                          </label>
                        </div>

                        <div className="">
                          <label>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('signup.passwordConfirm')}</div>
                            <div>
                              <Field as={Input} type="password" name="password2" />
                            </div>
                          </label>
                          <div className="font-normal mt-2 text-sm text-gray-500">{t('validation:passwordRequirements')}</div>
                        </div>
                      </div>

                      <div className="mt-6 flex flex-col">
                        <PrimaryButton large submit disabled={!canSubmit}>
                          {t('signup.saveNewPassword')}
                        </PrimaryButton>
                      </div>
                    </Form>
                  ) : null}
                </>
              );
            }}
          </Formik>
        </div>

        <div className="mt-6">
          <Link to="/login" className="text-blue-600">
            {t('signup.signInToAccount')}
          </Link>
        </div>
      </div>
    </FullpageLayout>
  );
};

export default NewPasswordPage;
