import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { AccountContext, useAccount } from '../../lib/account';
import { BlobIngested, useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import { broadcastSuccessToast } from '../Toasts';
import DoubleCoinIcon from '../icons/DoubleCoinIcon';
import DoubleTicketIcon from '../icons/DoubleTicketIcon';
import AccountSettingsPageLayout from '../layouts/AccountSettingsPage';
import { HasTicketsEnabled } from '../utils/HasTicketsEnabled';
import ImageFile from '../widgets/ImageFile';
import { IsSuperUser } from '../Permissions';
import { ToggleInForm } from '../inputs/Toggle';
import { brandName } from '../../lib/flavour';
import { SuperUserTag } from '../widgets/Tag';

const useBrandingMutation = (accountId: string) => {
  const repo = useRepo();
  const { t } = useTranslation();
  const { refreshAccount } = useContext(AccountContext);
  return useMutation(
    async ({
      logo,
      iconDouble,
      ticketsIcon,
      hasPoweredBy,
    }: {
      logo?: File | null;
      iconDouble?: File | null;
      ticketsIcon?: File | null;
      hasPoweredBy?: boolean | null;
    }) => {
      let blobs;
      let fileUploadPromise: Promise<[null | BlobIngested, null | BlobIngested, null | BlobIngested]> = Promise.resolve([
        null,
        null,
        null,
      ]);

      if (logo || iconDouble || ticketsIcon) {
        blobs = await repo.getBrandingBlobAccess(accountId, {
          logo: logo ? logo.name.split('.').pop() : undefined,
          icon_double: iconDouble ? iconDouble.name.split('.').pop() : undefined,
          tickets_icon: ticketsIcon ? ticketsIcon.name.split('.').pop() : undefined,
        });
        fileUploadPromise = Promise.all([
          logo ? repo.ingestFile(logo, blobs.logo) : null,
          iconDouble ? repo.ingestFile(iconDouble, blobs.icon_double) : null,
          ticketsIcon ? repo.ingestFile(ticketsIcon, blobs.tickets_icon) : null,
        ]);
      }

      const [uploadedLogo, uploadedIconDouble, uploadedTicketsIcon] = await fileUploadPromise;
      return await repo.setBranding(
        accountId,
        {
          logo: uploadedLogo ? `${uploadedLogo.container}/${uploadedLogo.blobName}` : logo instanceof File ? undefined : logo,
          icon_double: uploadedIconDouble
            ? `${uploadedIconDouble.container}/${uploadedIconDouble.blobName}`
            : iconDouble instanceof File
            ? undefined
            : iconDouble,
          tickets_icon: uploadedTicketsIcon
            ? `${uploadedTicketsIcon.container}/${uploadedTicketsIcon.blobName}`
            : ticketsIcon instanceof File
            ? undefined
            : ticketsIcon,
        },
        hasPoweredBy
      );
    },
    {
      onSuccess: () => {
        refreshAccount();
        broadcastSuccessToast(t('settingsSaved'));
      },
    }
  );
};

const AccountBrandingPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { refreshAccount } = useContext(AccountContext);
  const account = useAccount();
  const mutation = useBrandingMutation(account.id);

  const initialValues = {
    has_poweredby: account.has_poweredby ?? true,
  };
  const formik = useFormik<{ logo?: File | null; reward_icon?: File | null; tickets_icon?: File | null; has_poweredby?: boolean }>({
    initialValues,
    onSubmit: (values, form) => {
      mutation.mutate(
        { logo: values.logo, iconDouble: values.reward_icon, ticketsIcon: values.tickets_icon, hasPoweredBy: values.has_poweredby },
        {
          onSuccess: () => {
            refreshAccount();
          },
          onError: () => {
            form.setSubmitting(false);
          },
        }
      );
    },
  });

  // When the account changes, which happens after we've refreshed it, reset the form.
  useEffect(() => {
    formik.resetForm({ values: initialValues });
    formik.setSubmitting(false);
  }, [account]); // eslint-disable-line

  const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;

  return (
    <AccountSettingsPageLayout>
      <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
        <div className="flex-grow space-y-3 divide-y divide-gray-200 [&>*]:pt-3 first:[&>*]:pt-0">
          <div className="">
            <h2 className="text-lg font-medium mb-4">{t('logo')}</h2>
            <p>
              {t(
                'Image requirements: a transparent background PNG image that will appear on a light background. The recommended size is 238x40 pixels.'
              )}
            </p>
            <div className="mt-2">
              <div className="font-medium mb-2">{t('preview')}</div>
              <ImageFile
                url={account.logo_url}
                file={formik.values.logo}
                onChange={(f) => formik.setFieldValue('logo', f)}
                accept="image/png"
              />
            </div>
          </div>
          <div>
            <h2 className="text-lg font-medium mb-4">{t('rewardIcon')}</h2>
            <p>{t('rewardIconRequirements', { w: 116, h: 72 })}</p>
            <div className="mt-2">
              <div className="font-medium mb-2">{t('Preview')}</div>
              <ImageFile
                url={account.points_icon_double_url}
                file={formik.values.reward_icon}
                onChange={(f) => formik.setFieldValue('reward_icon', f)}
                accept="image/png, image/svg"
                imageClassName="w-[29px] h-[18px]"
                placeholder={<DoubleCoinIcon className="w-auto h-[18px]" />}
              />
            </div>
          </div>
          <HasTicketsEnabled>
            <div>
              <h2 className="text-lg font-medium mb-4">{t('ticketsIcon')}</h2>
              <p>{t('rewardIconRequirements', { w: 116, h: 72 })}</p>
              <div className="mt-2">
                <div className="font-medium mb-2">{t('Preview')}</div>
                <ImageFile
                  url={account.tickets_icon_url}
                  file={formik.values.tickets_icon}
                  onChange={(f) => formik.setFieldValue('tickets_icon', f)}
                  accept="image/png, image/svg"
                  imageClassName="w-[29px] h-[18px]"
                  placeholder={<DoubleTicketIcon className="w-auto h-[18px]" />}
                />
              </div>
            </div>
          </HasTicketsEnabled>
          <IsSuperUser>
            <div>
              <h2 className="text-lg font-medium mb-4">
                Powered by
                <SuperUserTag />
              </h2>
              <div>
                <ToggleInForm
                  enabled={formik.values.has_poweredby}
                  onChange={(enabled) => formik.setFieldValue('has_poweredby', enabled)}
                  label={`Display 'Powered by ${brandName}' on the store`}
                />
              </div>
            </div>
          </IsSuperUser>
        </div>

        <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
          <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
            {t('save')}
          </PrimaryButton>
          <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
        </div>
      </form>
    </AccountSettingsPageLayout>
  );
};

export default AccountBrandingPage;
