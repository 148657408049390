import { useTranslation } from 'react-i18next';
import { getDefaultLevelBadgeUrl, getLevelBadgeUrl } from '../../lib/levels';
import { useAccount } from '../../lib/account';

export const LevelBadge = ({
  level,
  className,
  size,
  useDefault,
}: {
  level: number;
  className?: string;
  size?: 'sm';
  useDefault?: boolean;
}) => {
  const { t } = useTranslation();
  const account = useAccount();
  const src = useDefault ? getDefaultLevelBadgeUrl(level, size) : getLevelBadgeUrl(account, level, size);
  return <img src={src} alt={t('levelx', { n: level })} className={className} />;
};
