import { ArrowLongDownIcon } from '@heroicons/react/24/solid';
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarWeeks,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  format,
  fromUnixTime,
  getUnixTime,
  isSameDay,
  max,
  min,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Redirect } from 'react-router';
import { hasPolicy, hasTicketsEnabled, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { useRepo } from '../../lib/repository';
import { Permission } from '../../lib/types';
import { Button } from '../Buttons';
import { HasAnyWhereInAccountPermission, HasInAnyTeamPermission } from '../Permissions';
import { Placeholder, PlaceholderList } from '../Placeholders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import ReportingNav from '../nav/ReportingNav';
import DateRangePicker from '../widgets/DateRangePicker';
import { DropdownSelectOption, DropdownSelectSimplified, MultiDropdownSelect } from '../widgets/DropdownSelect';
import { Table, Tbody, Td, Th, Thead, Tr } from '../widgets/Table';
import { TeamTag } from '../widgets/Tag';

import { Bar } from 'react-chartjs-2';
import { formatDateRange } from '../../lib/date';
import { useTeamsSelection } from './ReportsOverview';

const ReportsDetailedPage = () => {
  const permChecker = usePermissionChecker();
  const account = useAccount();

  if (!permChecker.hasInAnyTeamPermission(Permission.ReadReport) || !hasPolicy(account, 'use_reporting')) {
    return <Redirect to="/transactions" />;
  }

  return (
    <LayoutWithSidebar>
      <ReportsOverviewPageContent />
    </LayoutWithSidebar>
  );
};

const START_OF_WEEK = 1; // Monday.

const ReportsOverviewPageContent = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const repo = useRepo();

  const [reportId, setReportId] = useState<string | undefined>();
  const [granularity, setGranularity] = useState<'week' | 'month'>('month');

  const [dates, setDates] = useState<(Date | undefined)[]>(() => [
    subYears(startOfMonth(new Date()), 1),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const datesWeekDiff = useMemo(() => {
    return dates[0] && dates[1] ? Math.abs(differenceInCalendarWeeks(dates[1], dates[0], { weekStartsOn: START_OF_WEEK })) : 0;
  }, [dates]);

  useEffect(() => {
    if (datesWeekDiff >= 12 && granularity !== 'month') {
      setGranularity('month');
    }
  }, [datesWeekDiff, setGranularity, granularity]);

  const handleSetDates = useCallback(
    (dates: (Date | undefined)[]) => {
      const finalDates = [dates[0] ? startOfDay(dates[0]) : dates[0], dates[1] ? endOfDay(dates[1]) : dates[1]];
      setDates(finalDates);
    },
    [setDates]
  );

  const granularityOptions = useMemo(() => {
    return [
      { label: t('week'), value: 'week', disabled: datesWeekDiff >= 12 },
      { label: t('month'), value: 'month' },
    ];
  }, [t, datesWeekDiff]);

  const {
    teamIds,
    setTeamIds,
    getTeamName,
    isLoading: isTeamsLoading,
    isSuccess: isTeamsSuccess,
    options: teamOptions,
  } = useTeamsSelection();

  const filters = useMemo(() => {
    let filters: any = { timestamps: [] };
    if (dates[0] && dates[1]) {
      const weekly = granularity === 'week' && datesWeekDiff < 12;
      let intervals = weekly
        ? eachWeekOfInterval({ start: dates[0], end: dates[1] }, { weekStartsOn: START_OF_WEEK })
        : eachMonthOfInterval({ start: dates[0], end: dates[1] }).slice(0, 18);
      intervals.push(weekly ? addWeeks(intervals[intervals.length - 1], 1) : addMonths(intervals[intervals.length - 1], 1));
      filters.timestamps = intervals.map((date) => {
        return getUnixTime(max([dates[0]!, min([date, dates[1]!])]));
      });
    }
    if (teamIds.length) {
      filters.teamIds = teamIds;
    }
    return filters;
  }, [dates, teamIds, granularity, datesWeekDiff]);

  const isEnabled = Boolean(isTeamsSuccess && reportId && filters.timestamps.length > 1);
  const {
    isSuccess,
    data,
    isLoading: isReportLoading,
    isFetching: isReportFetching,
  } = useQuery(
    ['reports-detailed', account.id, reportId, filters],
    () => repo.getReportDetailed(account.id, reportId || '', filters.timestamps, filters),
    {
      enabled: isEnabled,
      staleTime: 1000 * 60 * 10, // Increase to 10 minutes.
      cacheTime: 1000 * 60 * 60, // Increase to an hour.
    }
  );
  const isLoading = isReportLoading || isTeamsLoading;
  const ticketsEnabled = hasTicketsEnabled(account);

  const reportOptions = useMemo(() => {
    return [
      { label: t('activePlayers'), value: 'active_players' },
      { label: t('newPlayers'), value: 'new_players' },
      { label: t('friendReferrals'), value: 'player_referrals' },
      'divider',
      { label: t('courseCompletions'), value: 'courses_completed' },
      { label: t('activityCompletions'), value: 'activities_completed' },
      'divider',
      { label: t('coinsEarned'), value: 'coins_earned' },
      { label: t('coinsSpent'), value: 'coins_spent' },
      'divider',
      { label: t('itemsAcquired'), value: 'items_acquired' },
      { label: t('ordersCompleted'), value: 'orders_completed' },
      'divider',
      { label: t('coinsFundraised'), value: 'coins_contributed' },
      { label: t('fundraiserParticipants'), value: 'contributions_participants' },
      ...(ticketsEnabled
        ? [
            'divider',
            { label: t('ticketsEarned'), value: 'tickets_earned' },
            { label: t('ticketsUsed'), value: 'tickets_spent' },
            { label: t('sweepstakesParticipants'), value: 'sweepstakes_participants' },
          ]
        : []),
    ] as DropdownSelectOption[];
  }, [t, ticketsEnabled]);

  const reportName = useMemo(() => {
    return reportOptions.find((option) => option.value === reportId)?.label || '?';
  }, [reportId, reportOptions]);

  const exportMutation = useMutation(async () => {
    if (!reportId) {
      return Promise.reject();
    }
    let datePart = '';
    if (dates[0] && (!dates[1] || isSameDay(dates[0], dates[1]))) {
      datePart = format(dates[0], 'yyyy-MM-dd');
    } else if (dates[0] && dates[1]) {
      datePart = `${format(dates[0], 'yyyy-MM-dd')}-to-${format(dates[1], 'yyyy-MM-dd')}`;
    }
    const csvData: any =
      data?.map((row) => ({
        start_time: fromUnixTime(row.range[0]).toUTCString(),
        end_time: fromUnixTime(row.range[1]).toUTCString(),
        [reportId]: row.value,
      })) || [];

    const csvConfig = mkConfig({
      columnHeaders: ['start_time', 'end_time', reportId],
      filename: [`report`, reportId, datePart].filter(Boolean).join('-'),
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  });
  const canDownload = isEnabled && !isLoading && !isReportFetching && !exportMutation.isLoading;

  return (
    <>
      <PageHeaderWrappingNav
        title={t('reporting')}
        buttons={
          <>
            <HasInAnyTeamPermission perm={Permission.ReadReport}>
              <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
                {t('exportCsv')}
              </Button>
            </HasInAnyTeamPermission>
          </>
        }
      >
        <ReportingNav />
      </PageHeaderWrappingNav>
      <MetricsFilters
        reportOptions={reportOptions}
        reportId={reportId}
        setReportId={setReportId}
        dates={dates}
        setDates={handleSetDates}
        teamOptions={teamOptions}
        teamIds={teamIds}
        setTeamIds={setTeamIds}
        granularity={granularity}
        granularityOptions={granularityOptions}
        setGranularity={setGranularity}
      />
      {!reportId ? <ZeroStateContent /> : null}
      {isReportLoading ? <LoadingState /> : null}
      {isEnabled && isSuccess ? (
        <div className="space-y-6">
          <ReportChart data={data} name={reportName} />
          <Table>
            <Thead>
              <Th>{t('date')}</Th>
              <Th>{t('value')}</Th>
            </Thead>
            <Tbody>
              {data?.map((item, i) => {
                return (
                  <Tr key={i}>
                    <Td>{formatDateRange(fromUnixTime(item.range[0]), fromUnixTime(item.range[1]))}</Td>
                    <Td>{item.value}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      ) : null}
    </>
  );
};

const LoadingState = () => {
  return (
    <div className="space-y-6">
      <div className="max-w-3xl h-96 mx-auto gap-4 flex flex-col">
        <div className="grow flex justify-center">
          <Placeholder className="h-3 w-32" />
        </div>
        <div className="shrink-0 gap-4 flex items-end h-72">
          <Placeholder className="w-10 h-1/5" />
          <Placeholder className="w-10 h-1/2" />
          <Placeholder className="w-10 h-5/6" />
          <Placeholder className="w-10 h-2/3" />
          <Placeholder className="w-10 h-2/5" />
          <Placeholder className="w-10 h-3/5" />
          <Placeholder className="w-10 h-full" />
          <Placeholder className="w-10 h-1/4" />
          <Placeholder className="w-10 h-1/2" />
          <Placeholder className="w-10 h-4/5" />
          <Placeholder className="w-10 h-3/4" />
          <Placeholder className="w-10 h-2/6" />
          <Placeholder className="w-10 h-1/2" />
        </div>
        <div className="shrink-0 h-14 flex items-center">
          <Placeholder className="w-full h-1/2" />
        </div>
      </div>
      <div className="space-y-2">
        <PlaceholderList count={12}>
          <Placeholder className="h-12" />
        </PlaceholderList>
      </div>
    </div>
  );
};

const ZeroStateContent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-grow justify-center items-center text-center py-4">
      <div className="max-w-xs pb-10 text-sm">
        <h2 className="font-medium mb-1">{t('selectAReport')}</h2>
        <p>{t('startBySelectingReport')}</p>
      </div>
    </div>
  );
};

const MetricsFilters = ({
  reportOptions,
  setReportId,
  reportId,
  teamOptions,
  teamIds,
  setTeamIds,
  dates,
  setDates,
  granularity,
  setGranularity,
  granularityOptions,
}: {
  reportOptions: DropdownSelectOption[];
  setReportId: (reportId: string) => void;
  reportId?: string;
  dates: (Date | undefined)[];
  setDates: (dates: (Date | undefined)[]) => void;
  teamOptions: { label: string; value: string }[];
  teamIds: string[];
  setTeamIds: (teamIds: string[]) => void;
  granularity: 'week' | 'month';
  granularityOptions: DropdownSelectOption[];
  setGranularity: (granularity: 'week' | 'month') => void;
}) => {
  const { t } = useTranslation();
  const maxDate = useMemo(() => (!dates[0] ? undefined : endOfDay(subDays(addMonths(dates[0], 18), 1))), [dates]);
  const minDate = useMemo(() => (!dates[0] ? undefined : startOfDay(addDays(subMonths(dates[0], 18), 1))), [dates]);

  return (
    <div className="flex mb-6 gap-2">
      <div className="flex flex-grow items-stretch space-x-2">
        <div className="w-64">
          <DropdownSelectSimplified
            options={reportOptions}
            onChange={setReportId}
            selected={reportId}
            placeholder={t('selectReportEllipsis')}
          />
        </div>
        <div className="w-64">
          <MultiDropdownSelect
            options={teamOptions}
            onChange={setTeamIds}
            selected={teamIds}
            placeholder={t('filterTeamsEllipsis')}
            disabled={!teamOptions.length}
            tagComponent={TeamTag}
          />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="text-sm bg-white border border-gray-300 flex rounded-md overflow-hidden shadow-sm">
          <div className="rounded-bl-md rounded-tl-md flex-grow border-r border-gray-300 bg-gray-50 text-gray-500 py-2 px-3 truncate">
            {t('dateRange')}
          </div>
          <div className="flex flex-grow">
            <DateRangePicker
              value={dates}
              onChange={setDates}
              className="flex-grow py-2 px-3"
              maxDate={maxDate}
              minDate={minDate}
            />
          </div>
        </div>
        <div className="w-32">
          <DropdownSelectSimplified
            origin="top-right"
            options={granularityOptions}
            onChange={setGranularity as (value: string) => void}
            selected={granularity}
          />
        </div>
      </div>
    </div>
  );
};

const ReportChart = ({ name, data }: { name: string; data?: { range: [number, number]; value: number }[] }) => {
  const config = useMemo(() => {
    const labels = data?.map((item) => formatDateRange(fromUnixTime(item.range[0]), fromUnixTime(item.range[1]))) || [];
    const datasetData = data?.map((item) => item.value) || [];
    return {
      data: {
        labels,
        datasets: [
          {
            label: name,
            data: datasetData,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: { precision: 0 },
          },
        },
        plugins: {
          title: {
            display: true,
            text: name,
          },
          tooltip: {
            enabled: true,
          },
        },
      },
    };
  }, [data, name]);

  if (!data) {
    return null;
  }

  return (
    <div className="w-full max-w-3xl h-96 mx-auto">
      <Bar {...config} />
    </div>
  );
};

export default ReportsDetailedPage;
