import * as yup from 'yup';

export function isPasswordValid(password?: string) {
  return Boolean(
    typeof password === 'string' &&
      password.length &&
      password.length >= 8 &&
      password.match(/[a-z]/) &&
      password.match(/[0-9]/) &&
      password.match(/[^a-z0-9]/i)
  );
}

export const passwordSchema = yup.string().test('is-password', 'The password is invalid', (value) => isPasswordValid(value));
