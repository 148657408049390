import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/solid';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import { get, keyBy, mapValues, omit, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import * as yup from 'yup';
import { hasPolicy, useAccount } from '../../lib/account';
import { convertL10nValuesDictToArray, getL10nValue, setL10nValue } from '../../lib/l10n';
import { useRepo } from '../../lib/repository';
import { classNames } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import AccountSettingsPageLayout from '../layouts/AccountSettingsPage';
import { HasPolicy } from '../Policy';
import { broadcastSuccessToast } from '../Toasts';
import { CoinIcon } from '../widgets/Coins';
import Dropdown from '../widgets/Dropdown';
import { langOptions } from '../widgets/LangDropdown';

import 'react-quill/dist/quill.snow.css';
import DropdownSelect, { DropdownSelectprops } from '../widgets/DropdownSelect';

const useAccountInformation = () => {
  const repo = useRepo();
  const account = useAccount();
  return useQuery(['account', account.id, 'store-information'], () => repo.getAccountStoreInformation(account.id));
};

const LangSelector = ({
  selected,
  onChange,
  placeholder,
  origin,
  locales,
}: {
  selected?: string;
  onChange: (lang: string) => void;
  placeholder?: DropdownSelectprops['placeholder'];
  origin?: DropdownSelectprops['origin'];
  locales?: string[];
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const usedOptions: any = locales?.map((locale) => langOptions.find((opt) => opt.value === locale)).filter(Boolean) || [];
    return (usedOptions.length ? usedOptions.concat('divider') : []).concat(
      langOptions.filter((lang) => !locales?.includes(lang.value))
    );
  }, [locales]);

  const option = langOptions.find((option) => option.value === selected);
  const handleChange = (option: { value: string }) => onChange(option.value);
  return (
    <DropdownSelect
      options={options}
      selected={option}
      placeholder={placeholder ? placeholder : t('selectEllipsis')}
      onChange={handleChange}
      origin={origin}
    />
  );
};

const Editor = ({
  value,
  onChange,
  disabled,
}: {
  value: ReactQuillProps['value'];
  onChange: ReactQuillProps['onChange'];
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const handleChange: ReactQuillProps['onChange'] = (...args) => {
    // We need to delay this because when onChange happens in the same render, we can
    // have issues especially because when using formik.setValues(fn), fn receives an
    // outdated state, which causes problems when we also use resetForm in the same cycle.
    setTimeout(() => onChange && onChange(...args), 0);
  };

  return (
    <div className="flex flex-col">
      <div id="quill-toolbar">
        <span className="ql-formats">
          <select className="ql-header" disabled={disabled}>
            <option value="1">{t('editor.title')}</option>
            <option value="2">{t('editor.subtitle')}</option>
            <option value="0" selected>
              {t('editor.normal')}
            </option>
          </select>
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-bold" title={t('editor.bold')} disabled={disabled} />
          <button type="button" className="ql-italic" title={t('editor.italic')} disabled={disabled} />
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-link" title={t('editor.link')} disabled={disabled} />
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-list" value="ordered" title={t('editor.numberedList')} disabled={disabled} />
          <button type="button" className="ql-list" value="bullet" title={t('editor.bulletList')} disabled={disabled} />
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-clean" title={t('editor.clearFormatting')} disabled={disabled} />
        </span>
      </div>
      <ReactQuill
        readOnly={disabled}
        value={value}
        onChange={handleChange}
        theme="snow"
        modules={{
          toolbar: { container: '#quill-toolbar' },
        }}
        style={{
          width: '100%',
        }}
        formats={['header', 'bold', 'italic', 'list', 'bullet', 'link']}
      />
    </div>
  );
};

const schema = yup
  .object({
    content: yup.string().required(),
  })
  .required();

const AccountInformationPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const repo = useRepo();
  const info = useAccountInformation();
  const queryClient = useQueryClient();

  const hasL10nPolicy = hasPolicy(account, 'use_l10n');
  const defaultLocale = hasL10nPolicy && account.locales ? account.locales[0] : undefined;
  const [locale, setLocale] = useState<string | undefined>(defaultLocale);
  const noLocaleOrIsDefault = !locale || locale === defaultLocale;

  const mutation = useMutation(
    ({
      content,
      rules,
      l10n,
    }: {
      content: string;
      rules: Record<number, { id: number; coins: number | ''; label: string }>;
      l10n: Record<string, { content?: string; rules?: Record<number, { label?: string }> }>;
    }) => {
      const finalrules = Object.values(rules).filter(
        (rule) => typeof rule.coins === 'number' && !isNaN(rule.coins) && Boolean(rule.label.trim())
      ) as { id: number; coins: number; label: string }[];
      let finalL10n;
      if (hasL10nPolicy && defaultLocale) {
        finalL10n = convertL10nValuesDictToArray(l10n).map((locale) => ({
          ...locale,
          content: locale.content === '<p><br></p>' ? '' : locale.content,
          rules: Object.keys(locale?.rules || {}).map((key) => ({
            id: key,
            ...locale.rules[key],
          })),
        }));
      }
      return repo.updateAccountStoreInformation(account.id, content, finalrules, finalL10n);
    }
  );

  const initialValues = useMemo(() => {
    return {
      content: info.data?.info.content || '',
      rules: keyBy(info.data?.rules || [], (rule) => rule.id),
      l10n: keyBy(
        info.data?.l10n?.map((locale) => ({
          ...locale,
          rules: keyBy(locale?.rules || [], (rule) => rule.id),
        })),
        (l10n) => l10n.locale
      ),
    };
  }, [info.data]);

  const formik = useFormik<typeof initialValues>({
    initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values, form) => {
      mutation.mutate(values, {
        onSuccess: (data) => {
          queryClient.invalidateQueries(['account', account.id, 'store-information']);
          broadcastSuccessToast(t('informationSaved'));
        },
        onError: () => {
          form.resetForm();
          form.setSubmitting(false);
        },
      });
    },
  });

  const usedLocales = useMemo(() => {
    if (!defaultLocale) return [];
    return uniq([defaultLocale].concat(Object.keys(formik.values.l10n)));
  }, [formik.values.l10n, defaultLocale]);

  // When the query data changes, reset the form..
  useEffect(() => {
    formik.setValues(() => initialValues);
    formik.resetForm({ values: initialValues });
    formik.setSubmitting(false);
  }, [info.data]); // eslint-disable-line

  const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;

  const getLocalisedFormikValue = (property: string) => {
    if (!locale || !hasL10nPolicy) {
      return get(formik.values, property);
    }
    return getL10nValue(formik.values, property, locale, locale === defaultLocale);
  };

  const setLocalisedFormikValue = (property: string, value: string) => {
    formik.setValues((values) => setL10nValue(values, property, value, locale, locale === defaultLocale));
  };

  const handleAddRule = () => {
    // Increment ID when adding a rule. Always starts at 0.
    const allIds = Object.values(formik.values.rules).map((r) => r.id);
    const nextId = Math.max(...(allIds.length ? allIds : [-1])) + 1;
    formik.setFieldValue('rules', {
      ...formik.values.rules,
      [nextId]: { id: nextId, coins: '', label: '' },
    });
  };

  const handleRemoveRule = (key: number) => {
    formik.setFieldValue('rules', omit(formik.values.rules, [key]));
    formik.setFieldValue(
      'l10n',
      mapValues(formik.values.l10n, (locale) => ({
        ...locale,
        rules: omit(locale.rules || {}, [key]),
      }))
    );
  };

  const contentValue = getLocalisedFormikValue('content');

  const handleLocaleChange = (locale: string) => {
    setLocale(locale);
  };

  return (
    <AccountSettingsPageLayout>
      <FormikProvider value={formik}>
        <Form className="flex flex-col flex-grow">
          <div className="flex-grow flex flex-col">
            <div className="flex">
              <div className="grow">
                <p>{t('accountInformationHelp')}</p>
              </div>

              <HasPolicy policy="use_l10n">
                {defaultLocale ? (
                  <LangSelector onChange={handleLocaleChange} origin="top-right" selected={locale} locales={usedLocales} />
                ) : null}
              </HasPolicy>
            </div>

            <div className="mt-4">
              <Editor
                /** Imperative to use key on locale, otherwise the old onChange event is fired. */
                key={locale}
                value={contentValue}
                onChange={(value) => {
                  if (info.isLoading) {
                    return;
                  } else if (contentValue !== value) {
                    setLocalisedFormikValue('content', value);
                  }
                }}
                disabled={info.isLoading}
              />
            </div>

            <div className="mt-6 space-y-4">
              {Object.values(formik.values?.rules).map((rule) => {
                const key = rule.id;
                const fieldPrefix = `rules.${key}`;
                return (
                  <div key={`${key}-${locale}`} className="flex gap-4">
                    <div className="flex gap-2 items-center">
                      <div className="w-32">
                        <Field
                          name={`${fieldPrefix}.coins`}
                          as={Input}
                          type="number"
                          min="0"
                          placeholder={info.isLoading ? t('loadingEllipsis') : t('coins')}
                          disabled={info.isLoading}
                        />
                      </div>
                      <CoinIcon />
                    </div>
                    <div className="flex-1">
                      <Input
                        placeholder={
                          noLocaleOrIsDefault
                            ? t('untitledCoinEvent')
                            : getL10nValue(formik.values, `${fieldPrefix}.label`, defaultLocale, true)
                        }
                        maxLength={64}
                        disabled={info.isLoading}
                        value={getLocalisedFormikValue(`${fieldPrefix}.label`)}
                        onChange={(e) => setLocalisedFormikValue(`${fieldPrefix}.label`, e.target.value)}
                      />
                    </div>
                    <div className={classNames('flex items-center')}>
                      <Dropdown
                        right
                        label={t('actions')}
                        icon={<EllipsisHorizontalIcon className="h-5 w-5" />}
                        minimal
                        options={[
                          {
                            label: t('remove'),
                            onClick: () => handleRemoveRule(key),
                            disabled: info.isLoading,
                          },
                        ]}
                      />
                    </div>
                  </div>
                );
              })}
              {noLocaleOrIsDefault ? (
                <Button icon={PlusIcon} onClick={handleAddRule} disabled={info.isLoading}>
                  {t('addCoinEvent')}
                </Button>
              ) : null}
            </div>
          </div>

          <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
            <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
              {t('save')}
            </PrimaryButton>
            <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
          </div>
        </Form>
      </FormikProvider>
    </AccountSettingsPageLayout>
  );
};

export default AccountInformationPage;
