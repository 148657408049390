import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { addSeconds, differenceInSeconds, formatDistanceToNowStrict, fromUnixTime, getUnixTime } from 'date-fns';
import { isUndefined } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { now } from '../../lib/date';
import { UseSortingResult, useAnchorButtonProps, usePeriodicRerender, usePermissionChecker, useSorting } from '../../lib/hooks';
import { isExpired, isItemEditable, isProductVariant } from '../../lib/item';
import { useItemDeleteMutation, useItemDownloadFileMutation, useItemDuplicateMutation } from '../../lib/mutations';
import { getPlayerName } from '../../lib/player';
import {
  ContinuatedQueryObserverResult,
  useContinuatedQuery,
  useFakeContinuableContinuatedQueryResult,
  useFakeContinuatedQueryResult,
  useItem,
  useStoreName,
} from '../../lib/queries';
import { ContinuableResponse, StoreContentItem, useRepo } from '../../lib/repository';
import {
  Item,
  ItemDrawMethod,
  ItemRaffle,
  ItemRaffleStatus,
  ItemRaffleV2,
  ItemSweepstakes,
  ItemType,
  ItemVoucher,
  Permission,
  Player,
  Product,
  RaffleEntry,
  RedemptionMethod,
  Transaction,
} from '../../lib/types';
import { classNames, getItemTypeStringKey, getRaffleStatusStringKey, getRedemptionMethodStringKey } from '../../lib/utils';
import { AnchorButton, Button, PrimaryButton } from '../Buttons';
import { ActionModal, ActionModalButtons, ConfirmModal, DuplicateModal } from '../Modals';
import Notification from '../Notifications';
import { HasImplicitPlayerPermission, HasStorePermission } from '../Permissions';
import { Placeholder } from '../Placeholders';
import { broadcastErrorToast, broadcastSuccessToast } from '../Toasts';
import { Fullscreenable, useFullscreen } from '../layouts/Fullscreen';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import { ItemTransactionsFilters, TransactionFiltersContext, TransactionsFiltersProvider } from '../transactions/Filters';
import TransactionsTable from '../transactions/Table';
import Coins, { Tickets } from '../widgets/Coins';
import Dropdown from '../widgets/Dropdown';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdContextualMenu,
  TdPrimary,
  Th,
  ThSortableWithSorting,
  Thead,
  Tr,
} from '../widgets/Table';
import { AlertTag, ExpiredTag, ScheduledTag, SoldoutTag, SuccessTag } from '../widgets/Tag';
import { getErrorMessage } from '../../lib/i18n';
import nl2br from 'react-nl2br';
import { hasPolicy, hasVoucherMethodEnabled, useAccount } from '../../lib/account';
import Textarea from '../inputs/Textarea';
import uniq from 'lodash.uniq';

export const ItemStatus: React.FC<{ item: StoreContentItem | Item | Product }> = ({ item }) => {
  usePeriodicRerender();
  const { t } = useTranslation();
  const hasExpired = isExpired(item);
  const isSoldout =
    !hasExpired &&
    item.doc_type === 'store_item' &&
    item.type === ItemType.Purchase &&
    item.num_items > 0 &&
    item.num_items <= item.num_purchased;
  if (item.start_time > now()) {
    return <ScheduledTag>{t('scheduled')}</ScheduledTag>;
  } else if (isSoldout) {
    return <SoldoutTag>{t('soldOut')}</SoldoutTag>;
  } else if (hasExpired) {
    return <ExpiredTag>{t('expired')}</ExpiredTag>;
  }
  return <SuccessTag>{t('active')}</SuccessTag>;
};

const LabelInfo: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className="flex">
      <div className="inline-block font-semibold whitespace-nowrap">{label}</div>
      <div className="inline-block ml-2">{children}</div>
    </div>
  );
};

export const useItemActions = (item?: Item, options: { page: 'edit' | 'view' } = { page: 'view' }) => {
  const isVariant = item && isProductVariant(item);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const deleteMutation = useItemDeleteMutation();
  const duplicateMutation = useItemDuplicateMutation();
  const permChecker = usePermissionChecker();
  const { t } = useTranslation();
  const history = useHistory();

  const handleDelete = () => {
    if (!item || deleteMutation.isLoading) return;
    deleteMutation.mutate(
      { itemId: item.id, storeId: item.store_id },
      {
        onSuccess: (data, variables) => {
          broadcastSuccessToast(t('itemDeleted'));
          history.push(`/store/${variables.storeId}`);
        },
        onSettled: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  const handleDuplicate = (name: string) => {
    if (!item || duplicateMutation.isLoading) return;
    duplicateMutation.mutate(
      { itemId: item.id, name },
      {
        onSuccess: (data) => {
          broadcastSuccessToast(t('itemDuplicated'));
          history.push(`/item/${data.id}`);
        },
        onSettled: () => {
          setShowDuplicateModal(false);
        },
      }
    );
  };

  type Action = { can?: boolean; danger?: boolean; disabled?: boolean; label: string; onClick: () => void };
  const actions: Action[] =
    item && !isVariant
      ? ([
          options.page === 'view'
            ? {
                label: t('edit'),
                can: permChecker.hasStorePermission(item.store_id, Permission.UpdateItem),
                onClick: () => history.push(`/item/${item.id}/edit`),
                disabled: !isItemEditable(item),
              }
            : null,
          options.page === 'edit'
            ? {
                label: t('viewDetails'),
                can: permChecker.hasStorePermission(item.store_id, Permission.ReadItem),
                onClick: () => history.push(`/item/${item.id}`),
              }
            : null,
          {
            label: t('duplicate'),
            onClick: () => setShowDuplicateModal(true),
            can: permChecker.hasStorePermission(item.store_id, Permission.CreateItem),
          },
          {
            label: t('delete'),
            danger: true,
            onClick: () => setShowDeleteModal(true),
            can: permChecker.hasStorePermission(item.store_id, Permission.DeleteItem),
          },
        ]
          .filter(Boolean)
          .filter((action) => isUndefined((action as Action).can) || (action as Action).can) as Action[])
      : [];

  return {
    actions: actions,
    renderActions: () => {
      return (
        <>
          {showDuplicateModal ? (
            <DuplicateModal
              onConfirm={handleDuplicate}
              onCancel={() => setShowDuplicateModal(false)}
              baseName={item?.name || 'unknown'}
            />
          ) : null}

          {showDeleteModal ? (
            <ConfirmModal
              onConfirm={handleDelete}
              onCancel={() => setShowDeleteModal(false)}
              title={t('deleteItem', { count: 1 })}
              message={<Trans t={t} i18nKey="deleteItemConfirm" count={1} />}
              confirmButtonText={t('delete')}
            />
          ) : null}
        </>
      );
    },
  };
};

const ItemPage = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const { itemId } = useParams<{ itemId: string }>();
  const downloadFileMutation = useItemDownloadFileMutation();
  const { isLoading, data: item, invalidateRelatedQueries } = useItem(itemId);
  const storeName = useStoreName(item?.store_id);
  const { actions, renderActions } = useItemActions(item);
  const [showDrawWinnersModal, setShowDrawWinnersModal] = useState(false);
  const [showImportVouchersModal, setShowImportVouchersModal] = useState(false);

  const isItemUsingVoucherMethod = Boolean(item?.redemption_method === RedemptionMethod.Voucher);

  const isPurchase = item?.type === ItemType.Purchase;
  const isAuction = item?.type === ItemType.Auction;
  const isContribution = item?.type === ItemType.Contribution;
  const isRaffle = item?.type === ItemType.Raffle;
  const isSweepstakes = item?.type === ItemType.Sweepstakes;
  const isVariant = item && isProductVariant(item);
  const canUseTicketsCost = hasPolicy(account, 'use_tickets_cost');

  const handleFileDownload = () => {
    if (item?.redemption_method !== RedemptionMethod.Download) return;
    if (downloadFileMutation.isLoading) return;
    downloadFileMutation.mutate(item.id);
  };

  const fileDownloadAnchorProps = useAnchorButtonProps(handleFileDownload);

  // Reload when the item reaches its end.
  useEffect(() => {
    if (!item) return;

    const delay = differenceInSeconds(addSeconds(fromUnixTime(item.end_time), 1), new Date(), { roundingMethod: 'ceil' });
    if (delay <= 0 || delay > 86400) {
      // Cap the maximum as setTimeout doesn't work well with long periods of time.
      return;
    }

    const t = setTimeout(() => {
      invalidateRelatedQueries();
    }, delay * 1000);

    return () => clearTimeout(t);
  }, [item, invalidateRelatedQueries]);

  const isPageLoading = isLoading;

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeaderWithBackLabel
          backTo={item ? `/store/${item.store_id}` : undefined}
          backLabel={storeName}
          buttons={item && actions.length ? <Dropdown right options={actions} label={t('actions')} /> : null}
        >
          {item?.name}
        </PageHeaderWithBackLabel>
        {isPageLoading || !item ? null : (
          <>
            {isPurchase && isVariant ? (
              <div className="mb-4">
                <Notification type="info">
                  <Trans
                    i18nKey="itemIsVariantOf"
                    t={t}
                    components={[<Link to={`/product/${item.product_id}`} className="font-medium hover:underline" />]}
                    values={{ product: item.product_name }}
                  />
                </Notification>
              </div>
            ) : null}

            <div className="flex">
              <div className="mr-6 shrink-0">
                <img src={item.image_url} alt="" role="presentation" className="w-48 border border-gray-300 rounded-lg" />
              </div>
              <div>
                <div className="mb-4">{nl2br(item.description)}</div>
                <div className="space-y-3" style={{ columns: 2 }}>
                  <LabelInfo label={t('status')}>
                    <ItemStatus item={item} />
                  </LabelInfo>
                  <LabelInfo label={t('type')}>{t(getItemTypeStringKey(item.type))}</LabelInfo>
                  {isPurchase || isRaffle || isSweepstakes ? (
                    <>
                      {!isSweepstakes ? (
                        <LabelInfo label={t('cost')}>
                          <Coins amount={item.cost} />
                        </LabelInfo>
                      ) : null}
                      {isSweepstakes && canUseTicketsCost ? (
                        <LabelInfo label={t('cost')}>
                          <Tickets amount={item.cost} />
                        </LabelInfo>
                      ) : null}
                      <LabelInfo label={t('numberOfItems')}>{item.num_items <= 0 ? t('unlimited') : item.num_items}</LabelInfo>
                      {isPurchase ? <LabelInfo label={t('numberOfPurchases')}>{item.num_purchased}</LabelInfo> : null}
                    </>
                  ) : null}
                  {item.sku ? <LabelInfo label={t('sku')}>{item.sku}</LabelInfo> : null}
                  {isRaffle ? (
                    <>
                      <LabelInfo label={t('raffleStatus')}>{t(getRaffleStatusStringKey(item.raffle_status))}</LabelInfo>
                      <ItemRaffleDetails item={item} />
                    </>
                  ) : null}
                  {isSweepstakes ? (
                    <>
                      <ItemSweepstakesDetails item={item} />
                    </>
                  ) : null}
                  {isAuction ? (
                    <>
                      <LabelInfo label={t('auction.openingBid')}>
                        <Coins amount={item.cost} />
                      </LabelInfo>
                      <LabelInfo label={t('auction.bidIncrement')}>
                        <Coins amount={item.minimum_bid} />
                      </LabelInfo>
                      <LabelInfo label={t('auction.bidFee')}>
                        <Coins amount={item.handling_fee} />
                      </LabelInfo>
                    </>
                  ) : null}
                  {isContribution ? (
                    <>
                      <LabelInfo label={t('contribution.contributed')}>
                        <Coins amount={item.contrib_coins} />
                      </LabelInfo>
                      <LabelInfo label={t('contribution.goal')}>
                        <div className="flex items-center">
                          <Coins amount={item.contrib_goal} />
                          {item.contrib_currency_symbol && item.contrib_currency_value ? (
                            <div className="ml-2 text-gray-500 text-sm">
                              ({item.contrib_currency_symbol}
                              {Math.floor(item.contrib_goal / item.contrib_currency_value)})
                            </div>
                          ) : null}
                        </div>
                      </LabelInfo>
                    </>
                  ) : null}
                  {!isContribution ? (
                    <LabelInfo label={t('redemptionMethod')}>{t(getRedemptionMethodStringKey(item.redemption_method))}</LabelInfo>
                  ) : null}
                  {item.redemption_method === RedemptionMethod.Self ? (
                    <LabelInfo label={t('messageAfterRedemption')}>{item.self_redeem_message}</LabelInfo>
                  ) : null}
                  {item.redemption_method === RedemptionMethod.Download ? (
                    <LabelInfo label={t('digitalDownloadFile')}>
                      <a {...fileDownloadAnchorProps} className="text-blue-600">
                        {item.download_filename}
                      </a>
                    </LabelInfo>
                  ) : null}
                  <LabelInfo label={t('startDate')}>{fromUnixTime(item.start_time).toLocaleString()}</LabelInfo>
                  <LabelInfo label={t('endDate')}>{fromUnixTime(item.end_time).toLocaleString()}</LabelInfo>
                  <TimeRemainingInfo item={item} />
                </div>
              </div>
            </div>

            {isItemUsingVoucherMethod ? (
              <HasStorePermission storeId={item.store_id} perm={Permission.ManageItemVouchers}>
                <div className="my-6">
                  <ItemVouchers item={item} onImport={() => setShowImportVouchersModal(true)} />
                </div>
                {showImportVouchersModal ? (
                  <ImportVouchersModal
                    item={item}
                    onCancel={() => setShowImportVouchersModal(false)}
                    onConfirm={() => setShowImportVouchersModal(false)}
                  />
                ) : null}
              </HasStorePermission>
            ) : null}

            {isRaffle || isSweepstakes ? (
              <div className="my-6">
                <ItemEntries item={item} onDrawWinners={() => setShowDrawWinnersModal(true)} />
              </div>
            ) : null}

            {isRaffle && showDrawWinnersModal ? (
              <DrawRaffleWinnersModal
                item={item}
                onCancel={() => setShowDrawWinnersModal(false)}
                onConfirm={() => setShowDrawWinnersModal(false)}
              />
            ) : null}

            {isSweepstakes && showDrawWinnersModal ? (
              <DrawSweepstakesWinnersModal
                item={item}
                onCancel={() => setShowDrawWinnersModal(false)}
                onConfirm={() => setShowDrawWinnersModal(false)}
              />
            ) : null}

            <HasStorePermission storeId={item.store_id} perm={Permission.ReadTransaction}>
              <div className="my-6">
                <TransactionsFiltersProvider>
                  <ItemTransactionsContainer item={item} />
                </TransactionsFiltersProvider>
              </div>
            </HasStorePermission>
          </>
        )}
        {renderActions()}
      </div>
    </LayoutWithSidebar>
  );
};

const useItemTransactions = (
  itemId: string,
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const repo = useRepo();
  const results = useContinuatedQuery(
    ['item-transactions', itemId, filters, orderBy],
    ({ pageParam }) => {
      return repo.getItemTransactions(itemId, filters, orderBy, 10, pageParam);
    },
    {
      keepPreviousData: true,
      pageSize: 10,
      resetPageDependencies: [itemId, orderBy, ...Object.values(filters || {})],
    }
  );

  return results;
};

const useItemVouchers = (item?: Item) => {
  const repo = useRepo();
  return useFakeContinuableContinuatedQueryResult<
    ItemVoucher,
    {
      users: Pick<Player, 'id' | 'firstname' | 'lastname' | 'school_id' | 'store_ids_interacted_with'>[];
    }
  >(
    useQuery(['item', item?.id, 'vouchers'], async () => await repo.getItemVouchers(item?.id || '-')),
    5
  );
};

const useItemVouchersDeleteMutation = (item?: Item) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation(
    (voucherIds: number[]) => {
      if (!item) return Promise.reject();
      return repo.deleteItemVouchers(item.id, voucherIds);
    },
    {
      onSuccess: () => {
        broadcastSuccessToast(t('deletionSuccessful'));
        queryClient.invalidateQueries(['item', item?.id, 'vouchers']);
      },
    }
  );
};

const ItemTransactionsContainer: React.FC<{ item: Pick<Item, 'id' | 'type' | 'redemption_method'> }> = ({ children, item }) => {
  const { timestampFrom, timestampTo, type, details } = useContext(TransactionFiltersContext);
  const query = useItemTransactions(item.id, {
    type: type,
    details: details,
    dateFrom: timestampFrom,
    dateTo: timestampTo,
  });

  return (
    <Fullscreenable>
      <ItemTransactions queryResult={query} itemType={item.type} redemptionMethod={item.redemption_method}>
        {children}
      </ItemTransactions>
    </Fullscreenable>
  );
};

export const ItemTransactions: React.FC<{
  itemType: ItemType;
  redemptionMethod: RedemptionMethod;
  queryResult: ContinuatedQueryObserverResult<ContinuableResponse<Transaction>>;
}> = ({ itemType, redemptionMethod, queryResult }) => {
  const { t } = useTranslation();
  const { isFullscreen, goFullscreen } = useFullscreen();
  const { hasFilters } = useContext(TransactionFiltersContext);

  return (
    <>
      <h2 className="font-semibold text-xl leading-8 mb-3">
        {isFullscreen ? (
          t('transactions')
        ) : (
          <AnchorButton className="inline-flex items-center" onClick={goFullscreen}>
            {t('transactions')}
            <ArrowTopRightOnSquareIcon aria-hidden="true" className="ml-2 h-6 w-6 text-gray-500" />
          </AnchorButton>
        )}
      </h2>
      <ItemTransactionsFilters type={itemType} redemption={redemptionMethod} />
      <div className="mt-4">
        <TransactionsTable queryResult={queryResult} hasFilters={hasFilters} displayPlayerName />
      </div>
    </>
  );
};

const ItemRaffleDetails = ({ item }: { item: ItemRaffle }) => {
  const { t } = useTranslation();
  let nTickets = 0;
  let nParticipants = 0;

  if (item.version < 2) {
    return null;
    // We do not support legacy entries.
    // const entries = item.raffle_entries || [];
    // nTickets = entries.reduce((carry, entry) => carry + (entry.num_entries || 0), 0);
    // nParticipants = entries.length;
  } else if (item.start_time > now()) {
    return null;
  }

  item = item as ItemRaffleV2;
  nTickets = item.raffle_tickets_sold || 0;
  nParticipants = item.raffle_participants || 0;

  return (
    <>
      <LabelInfo label={t('raffleParticipation')}>
        {t('nTicketsSlashnParticipants', {
          tickets: nTickets,
          participants: nParticipants,
        })}
      </LabelInfo>
    </>
  );
};

const ItemSweepstakesDetails = ({ item }: { item: ItemSweepstakes }) => {
  const { t } = useTranslation();
  let nTickets = 0;
  let nParticipants = 0;

  if (item.start_time > now()) {
    return null;
  }

  nTickets = item.num_purchased || 0;
  nParticipants = item.num_participants || 0;

  return (
    <>
      <LabelInfo label={t('raffleParticipation')}>
        {t('nTicketsSlashnParticipants', {
          tickets: nTickets,
          participants: nParticipants,
        })}
      </LabelInfo>
    </>
  );
};

const ItemEntries = ({ item, onDrawWinners }: { item: ItemRaffle | ItemSweepstakes; onDrawWinners: () => void }) => {
  const repo = useRepo();
  const { t } = useTranslation();
  const sorting = useSorting('last_modified', { last_modified: true, quantity: true });
  const orderBy = sorting.sortField;

  const results = useContinuatedQuery(
    ['item-entries', item.id, orderBy],
    ({ pageParam }) => {
      return repo.getItemEntries(item.id, orderBy, 5, pageParam);
    },
    {
      keepPreviousData: true,
      pageSize: 5,
      resetPageDependencies: [item.id, orderBy],
    }
  );

  return (
    <>
      <div className="flex mb-3">
        <div className="grow">
          <h2 className="font-semibold text-xl leading-8">{t('players')}</h2>
        </div>
        <div>
          <HasStorePermission storeId={item.store_id} perm={Permission.DrawWinner}>
            <DrawWinnersButton onClick={() => onDrawWinners()} item={item} />
          </HasStorePermission>
        </div>
      </div>
      <div className="mt-4">
        <ItemEntriesTable queryResult={results} sorting={sorting} item={item} />
      </div>
    </>
  );
};

const DrawWinnersButton = ({ item, onClick }: { item: Item; onClick: () => void }) => {
  const { t } = useTranslation();
  usePeriodicRerender();
  return (
    <>
      {((item.type === ItemType.Sweepstakes && !item.drawn) ||
        (item.type === ItemType.Raffle && item.raffle_status === ItemRaffleStatus.Open)) &&
      item.end_time < getUnixTime(new Date()) &&
      item.draw_method === ItemDrawMethod.Manual ? (
        <Button onClick={() => onClick()}>{t('drawWinnersAction')}</Button>
      ) : null}
    </>
  );
};

const ItemEntriesTable = ({
  queryResult,
  sorting,
  item,
}: {
  queryResult: ContinuatedQueryObserverResult<
    ContinuableResponse<RaffleEntry, { users?: { id: string; firstname: string; lastname: string }[] }>
  >;
  sorting: UseSortingResult;
  item: ItemSweepstakes | ItemRaffle;
}) => {
  const { t } = useTranslation();
  const {
    isEnabled,
    isLoading,
    isSuccess,
    data,
    showingFrom,
    showingTo,
    showingOfTotal,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = queryResult;

  const playersMap = useMemo(() => data?.users?.reduce((carry, player) => ({ [player.id]: player, ...carry }), {}), [data?.users]);
  const winnerIds = (item.type === ItemType.Raffle ? item.raffle_draw?.winners : item.draw_winners) || [];
  const excludedIds = item.type === ItemType.Sweepstakes ? item?.draw_excluded || [] : [];

  return (
    <>
      <Table>
        <Thead>
          <Th>{t('name')}</Th>
          <ThSortableWithSorting sortingResult={sorting} sortKey="quantity">
            {t('entries')}
          </ThSortableWithSorting>
          <ThSortableWithSorting sortingResult={sorting} sortKey="last_modified">
            {t('time')}
          </ThSortableWithSorting>
        </Thead>
        <Tbody>
          {!isEnabled || isLoading ? (
            <Tr>
              <Td colSpan={3}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess ? (
            !data?.items?.length ? (
              <Tr>
                <Td colSpan={3}>{t('noResultsEllipsis')}</Td>
              </Tr>
            ) : (
              data.items.map((entry) => (
                <ItemEntryRow
                  key={entry.id}
                  entry={entry}
                  playersMap={playersMap}
                  isWinner={winnerIds.includes(entry.user_id)}
                  isExcluded={excludedIds.includes(entry.user_id)}
                />
              ))
            )
          ) : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={showingFrom}
          showingTo={showingTo}
          showingOfTotal={showingOfTotal}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPageClick={fetchNextPage}
          onPreviousPageClick={fetchPreviousPage}
        />
      ) : null}
    </>
  );
};

const ItemEntryRow: React.FC<{
  entry: RaffleEntry;
  playersMap?: { [index: string]: { id: string; firstname: string; lastname: string } };
  isWinner?: boolean;
  isExcluded?: boolean;
}> = ({ entry, playersMap, isWinner, isExcluded }) => {
  const { t } = useTranslation();

  const date = new Date(entry.last_modified * 1000);
  const user = playersMap ? playersMap[entry.user_id] : undefined;

  return (
    <Tr>
      <TdPrimary to={user ? `/player/${user.id}` : undefined}>
        {user ? getPlayerName(user) : <em>{t('deleted')}</em>}
        {isWinner ? (
          <div className="ml-2 inline-block">
            <SuccessTag>{t('winner')}</SuccessTag>
          </div>
        ) : null}
        {isExcluded ? (
          <div className="ml-2 inline-block">
            <AlertTag>{t('excluded')}</AlertTag>
          </div>
        ) : null}
      </TdPrimary>
      <Td>{entry.tickets.toLocaleString()}</Td>
      <Td>{date.toLocaleString(undefined, { dateStyle: 'medium', timeStyle: 'short' })}</Td>
    </Tr>
  );
};

const ItemVouchers = ({ item, onImport }: { item: Item; onImport: () => void }) => {
  const [toDeleteIds, setToDeleteIds] = useState<number[]>([]);
  const { t } = useTranslation();
  const queryResult = useItemVouchers(item);
  const deleteMutation = useItemVouchersDeleteMutation(item);

  const voucherErrorKey = useMemo(() => {
    if (isExpired(item) || queryResult.isLoading) {
      return null;
    }

    const data = queryResult.data;
    if (!data?.items.length) {
      return 'dashboard:itemHasNoVouchers';
    }

    if (item.num_items > 0 && item.num_items > queryResult.showingOfTotal) {
      return 'dashboard:itemHasNotEnoughVouchersForQuantity';
    }

    return null;
  }, [queryResult.data, queryResult.showingOfTotal, queryResult.isLoading, item]);

  const handleDelete = useCallback(() => {
    setToDeleteIds([]);
    deleteMutation.mutate(toDeleteIds, {});
  }, [deleteMutation, setToDeleteIds, toDeleteIds]);

  return (
    <>
      <div className="flex mb-3">
        <div className="grow">
          <h2 className="font-semibold text-xl leading-8">{t('vouchers')}</h2>
        </div>
        <div>
          <Button onClick={onImport}>{t('importVouchers')}</Button>
        </div>
      </div>
      <div className="mt-4">
        {voucherErrorKey ? (
          <div className="my-4">
            <Notification type="warning">{t(voucherErrorKey)}</Notification>
          </div>
        ) : null}
        <ItemVouchersTable queryResult={queryResult} item={item} onDelete={setToDeleteIds} />
      </div>
      {toDeleteIds.length ? (
        <ConfirmModal
          title={t('areYouSure')}
          message={t('confirmDelete')}
          confirmButtonText={t('delete')}
          onConfirm={() => handleDelete()}
          onCancel={() => setToDeleteIds([])}
        />
      ) : null}
    </>
  );
};

const ItemVouchersTable = ({
  queryResult,
  item,
  onDelete,
}: {
  onDelete: (voucherIds: number[]) => void;
  queryResult: ReturnType<typeof useItemVouchers>;
  item: Item;
}) => {
  const { t } = useTranslation();

  const {
    isLoading,
    isSuccess,
    data,
    showingFrom,
    showingTo,
    showingOfTotal,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = queryResult;

  const playersMap: Record<string, Player> = useMemo(
    () => (data?.users || []).reduce((carry, player) => ({ [player.id]: player, ...carry }), {}),
    [data?.users]
  );
  const hasVouchers = queryResult.data?.items?.length || false;

  return (
    <>
      <Table>
        <Thead>
          <Th>{t('voucher')}</Th>
          <Th>{t('voucherRedeemedOn')}</Th>
          <Th>{t('voucherRedeemedBy')}</Th>
          <Th />
        </Thead>
        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan={4}>{t('loadingEllipsis')}</Td>
            </Tr>
          ) : null}
          {isSuccess ? (
            !hasVouchers ? (
              <Tr>
                <Td colSpan={4}>{t('noResultsEllipsis')}</Td>
              </Tr>
            ) : (
              data.items.map((entry) => {
                const player = entry.redeemed_by ? playersMap[entry.redeemed_by] : null;
                return (
                  <Tr key={entry.id} hasFluid>
                    <Td fluid className={classNames(entry.redeemed_on ? 'line-through' : null)}>
                      {entry.code}
                    </Td>
                    <Td>{entry.redeemed_on ? fromUnixTime(entry.redeemed_on).toLocaleString() : '-'}</Td>
                    <Td>
                      {entry.redeemed_by ? (
                        player ? (
                          <HasImplicitPlayerPermission player={player} perm={Permission.ReadPlayer}>
                            <Link to={`/player/${entry.redeemed_by}`}>{getPlayerName(player)}</Link>
                          </HasImplicitPlayerPermission>
                        ) : (
                          <em>{t('unknown')}</em>
                        )
                      ) : (
                        '-'
                      )}
                    </Td>
                    {entry.redeemed_on ? (
                      <Td />
                    ) : (
                      <TdContextualMenu
                        options={[
                          {
                            label: t('delete'),
                            danger: true,
                            onClick: () => onDelete([entry.id]),
                          },
                        ]}
                      />
                    )}
                  </Tr>
                );
              })
            )
          ) : null}
        </Tbody>
      </Table>
      {isSuccess && (data?.total || 0) > 0 ? (
        <TablePagination
          showingFrom={showingFrom}
          showingTo={showingTo}
          showingOfTotal={showingOfTotal}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onNextPageClick={fetchNextPage}
          onPreviousPageClick={fetchPreviousPage}
        />
      ) : null}
    </>
  );
};

const DrawRaffleWinnersModal = ({
  item,
  onCancel,
  onConfirm,
}: {
  item: ItemRaffle;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const repo = useRepo();
  const queryClient = useQueryClient();

  const commitMutation = useMutation(() => repo.commitDrawWinners(item.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['store-content', item.store_id]);
      queryClient.invalidateQueries(['team-market']);
      queryClient.invalidateQueries(['item', item.id]);
      queryClient.invalidateQueries(['item-entries', item.id]);
      broadcastSuccessToast(t('winnersDrawn'));
      onConfirm();
    },
    onError: (err) => {
      broadcastErrorToast(getErrorMessage(err));
    },
  });

  const frozen = commitMutation.isLoading;

  const handleCancel = () => {
    if (commitMutation.isLoading) return;
    onCancel();
  };

  const handleConfirm = () => {
    commitMutation.mutate();
  };

  return (
    <ConfirmModal
      onCancel={handleCancel}
      title={t('drawWinnersAction')}
      confirmButtonDisabled={frozen}
      cancelButtonDisabled={commitMutation.isLoading}
      onConfirm={handleConfirm}
      cancelButtonText={t('later')}
      message={t('areYouSure')}
    />
  );
};

const DrawSweepstakesWinnersModal = ({
  item,
  onCancel,
  onConfirm,
}: {
  item: ItemSweepstakes;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const haveCandidatesBeenDrawn = Boolean(item?.draw_candidates);
  const [showConfirm, setShowConfirm] = useState<Pick<Player, 'id' | 'firstname' | 'lastname' | 'email'> | null>(null);

  const query = useQuery(['item-candidates', item.id], () => repo.getDrawCandidates(item.id), {
    enabled: haveCandidatesBeenDrawn,
  });

  const mutation = useMutation(({ exclude }: { exclude?: string[] }) => repo.pickDrawCandidates(item.id, exclude), {
    onSuccess: () => {
      queryClient.invalidateQueries(['store-content', item.store_id]);
      queryClient.invalidateQueries(['team-market']);
      queryClient.invalidateQueries(['item', item.id]);
      queryClient.invalidateQueries(['item-candidates', item.id]);
      queryClient.invalidateQueries(['item-entries', item.id]);
    },
  });

  const commitMutation = useMutation(() => repo.commitDrawWinners(item.id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['store-content', item.store_id]);
      queryClient.invalidateQueries(['team-market']);
      queryClient.invalidateQueries(['item', item.id]);
      queryClient.invalidateQueries(['item-entries', item.id]);
      onConfirm();
    },
  });

  const frozen = mutation.isLoading || query.isLoading || commitMutation.isLoading;
  const candidates = query.data || [];
  const finalCandidates = [
    ...candidates,
    ...Array.from({ length: Math.max(0, item.num_items - candidates.length) }).map(() => null),
  ];

  const handleCancel = () => {
    if (commitMutation.isLoading) return;
    onCancel();
  };

  const handleConfirm = () => {
    commitMutation.mutate();
  };

  return (
    <ActionModal onCancel={handleCancel} title={t('drawWinnersAction')}>
      <div className="relative">
        <p className="text-sm mt-4">{t('manualDrawIntro')}</p>
        <div className="my-4">
          <Table>
            <Tbody>
              {finalCandidates.map((candidate, idx) => {
                if (!candidate) {
                  return (
                    <Tr hasFluid key={idx}>
                      <Td>{query.isLoading && !query.data ? <Placeholder className="h-5" /> : <em>{t('unallocated')}</em>}</Td>
                      <Td />
                      <Td />
                    </Tr>
                  );
                }
                return (
                  <Tr hasFluid key={candidate.id}>
                    <TdPrimary>{getPlayerName(candidate)}</TdPrimary>
                    <Td fluid>{candidate.email}</Td>
                    <TdContextualMenu
                      options={[
                        {
                          onClick: () => setShowConfirm(candidate),
                          label: t('exclude'),
                          danger: true,
                          disabled: frozen,
                        },
                      ]}
                    ></TdContextualMenu>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {!haveCandidatesBeenDrawn ? (
            <div className="absolute -inset-2 flex items-center justify-center bg-gray-200/80">
              <PrimaryButton onClick={() => mutation.mutate({})} disabled={mutation.isLoading || mutation.isSuccess}>
                {t('drawWinnersAction')}
              </PrimaryButton>
            </div>
          ) : null}
        </div>
      </div>
      <ActionModalButtons
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmButtonDisabled={frozen || !haveCandidatesBeenDrawn}
        cancelButtonDisabled={commitMutation.isLoading}
        cancelButtonText={t('later')}
      />

      {showConfirm !== null ? (
        <ConfirmModal
          title={t('excludeNameQ', { name: getPlayerName(showConfirm) })}
          message={
            <>
              <p>
                <Trans
                  i18nKey="reallyExcludePlayerFromDraw"
                  components={[<strong />]}
                  values={{ name: getPlayerName(showConfirm) }}
                />
              </p>
              <p className="mt-2">{t('playerDrawExclusionNote')}</p>
            </>
          }
          confirmButtonText={t('exclude')}
          confirmButtonDisabled={mutation.isLoading}
          onCancel={() => setShowConfirm(null)}
          onConfirm={() => {
            mutation.mutate(
              { exclude: [showConfirm.id] },
              {
                onSuccess: () => setShowConfirm(null),
              }
            );
          }}
        />
      ) : null}
    </ActionModal>
  );
};

const ImportVouchersModal = ({ item, onCancel, onConfirm }: { item: Item; onCancel: () => void; onConfirm: () => void }) => {
  const { t } = useTranslation();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const [text, setText] = useState('');
  const vouchers = useMemo(
    () =>
      uniq(
        text
          .split('\n')
          .map((line) => line.trim())
          .filter(Boolean)
          .filter((v) => v.length <= 100)
      ).slice(0, 1000),
    [text]
  );

  const mutation = useMutation(() => repo.createItemVouchers(item.id, vouchers), {
    onSuccess: () => {
      queryClient.invalidateQueries(['item', item.id, 'vouchers']);
      onConfirm();
      broadcastSuccessToast(t('importSuccessful'));
    },
  });

  const canConfirm = vouchers.length > 0 && !mutation.isLoading;

  const handleCancel = () => {
    if (mutation.isLoading) return;
    onCancel();
  };

  const handleConfirm = () => {
    mutation.mutate();
  };

  return (
    <ActionModal onCancel={handleCancel} title={t('importVouchers')}>
      <div className="relative">
        <p className="text-sm mt-4">{t('importVouchersIntro', { limit: Number(1000).toLocaleString().toString() })}</p>
        <div className="my-4">
          <Textarea onChange={(e) => setText(e.currentTarget.value)} placeholder={'ABCX42\nDEFY1337\n...'} rows={10}></Textarea>
        </div>
      </div>
      <ActionModalButtons
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmButtonDisabled={!canConfirm}
        confirmButtonText={t('importQty', { quantity: vouchers.length })}
        cancelButtonDisabled={mutation.isLoading}
        cancelButtonText={t('cancel')}
      />
    </ActionModal>
  );
};

export const TimeRemainingInfo = ({ item }: { item: Item | Product }) => {
  const { t } = useTranslation();
  const isOngoing = item.start_time < now() && item.end_time > now();
  const endDate = fromUnixTime(item.end_time);
  usePeriodicRerender();

  if (!isOngoing) {
    return null;
  }

  return <LabelInfo label={t('timeLeft')}>{formatDistanceToNowStrict(endDate)}</LabelInfo>;
};

export default ItemPage;
