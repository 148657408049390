import { useContext } from 'react';
import { AccountContext } from '../lib/account';
import { logoSrc } from '../lib/flavour';

const Logo = () => {
  const { account } = useContext(AccountContext);
  const brandSrc = account?.policy?.use_branding && account?.logo_url ? account.logo_url : logoSrc;
  return <img src={brandSrc} alt="" className="max-h-8" />;
};

export default Logo;
