import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../lib/account';
import DoubleCoinIcon from '../icons/DoubleCoinIcon';
import DoubleTicketIcon from '../icons/DoubleTicketIcon';

export const CoinIcon = () => {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const brandedCoins = account?.points_icon_double_url;

  return brandedCoins ? (
    <img src={brandedCoins} alt={t('coins')} className="flex-shrink-0 w-[29px] h-[18px] max-w-full" />
  ) : (
    <DoubleCoinIcon aria-label={t('coins')} className="flex-shrink-0" />
  );
};

const Coins = ({ amount }: { amount?: Number }) => {
  // TODO Implement decimal places.
  //   const { value, decimalPlaces } = props;
  //   const coins = (value / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces).toString();
  return (
    <div className="flex items-center">
      <CoinIcon />
      <span className="ml-1">{(amount || 0).toLocaleString()}</span>
    </div>
  );
};

export const TicketIcon = () => {
  const { t } = useTranslation();
  const { account } = useContext(AccountContext);
  const customIcon = account?.tickets_icon_url;

  return customIcon ? (
    <img src={customIcon} alt={t('tickets')} className="flex-shrink-0 w-[29px] h-[18px] max-w-full" />
  ) : (
    <DoubleTicketIcon aria-label={t('tickets')} className="flex-shrink-0 w-auto h-[18px]" />
  );
};

export const Tickets = ({ amount }: { amount?: Number }) => {
  return (
    <div className="flex items-center">
      <TicketIcon />
      <span className="ml-1">{(amount || 0).toLocaleString()}</span>
    </div>
  );
};

export default Coins;
