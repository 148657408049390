import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUser } from '../../lib/auth';
import { knowledgeBaseUrl } from '../../lib/flavour';
import { classNames } from '../../lib/utils';
import { UserAvatar } from '../Avatars';
import { IsSuperUser } from '../Permissions';

const UserDropdown = () => {
  const { t } = useTranslation();
  const user = useUser();
  return (
    <Menu as="div" className="relative w-full text-left">
      <div>
        <Menu.Button
          className={classNames(
            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:rounded-full'
          )}
        >
          <UserAvatar className="w-8 h-8" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'origin-top-right right-0 max-w-xs min-w-[200px]',
            'absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          <div className="px-4 py-2">
            <p className="text-sm font-medium text-gray-900 truncate capitalize">{user.name}</p>
          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/profile"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  {t('profile')}
                </Link>
              )}
            </Menu.Item>
          </div>

          <IsSuperUser>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/domains"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'w-full group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    Domains
                  </Link>
                )}
              </Menu.Item>
            </div>
          </IsSuperUser>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href={knowledgeBaseUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  {t('help')}
                </a>
              )}
            </Menu.Item>
          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/logout"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  {t('logout')}
                </Link>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserDropdown;
