import { ArrowLongDownIcon } from '@heroicons/react/24/solid';
import { endOfDay, format, getUnixTime, isSameDay, startOfDay, subDays } from 'date-fns';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Redirect } from 'react-router';
import { hasPolicy, hasTicketsEnabled, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { useTeams } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Permission } from '../../lib/types';
import { Button } from '../Buttons';
import { HasAnyWhereInAccountPermission, HasInAnyTeamPermission } from '../Permissions';
import { Placeholder } from '../Placeholders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import ReportingNav from '../nav/ReportingNav';
import DateRangePicker from '../widgets/DateRangePicker';
import { MultiDropdownSelect } from '../widgets/DropdownSelect';
import { StatAmount, StatPercentage } from '../widgets/StatAmount';
import { TeamTag } from '../widgets/Tag';

type MetricsSection = { title: string; metrics: { name: string; value: number; type?: 'ratio' }[] };

const ReportsOverviewPage = () => {
  const permChecker = usePermissionChecker();
  const account = useAccount();

  if (!permChecker.hasInAnyTeamPermission(Permission.ReadReport) || !hasPolicy(account, 'use_reporting')) {
    return <Redirect to="/transactions" />;
  }

  return (
    <LayoutWithSidebar>
      <ReportsOverviewPageContent />
    </LayoutWithSidebar>
  );
};

export const useTeamsSelection = () => {
  const [teamIds, setTeamIds] = useState<string[]>([]);
  const teamsQuery = useTeams(undefined, undefined, 100); // TODO Handle more teams.

  const teamOptions = useMemo(() => {
    return (
      teamsQuery.data?.items.map((team) => {
        return { label: team.name, value: team.id };
      }) || []
    );
  }, [teamsQuery.data]);

  const teamNames = useMemo(() => {
    return Object.fromEntries(teamsQuery?.data?.items.map((team) => [team.id, team.name]) || []);
  }, [teamsQuery.data]);

  const getTeamName = useCallback((teamId: string) => teamNames[teamId] || '?', [teamNames]);

  return {
    options: teamOptions,
    teamIds,
    setTeamIds,
    getTeamName,
    isSuccess: teamsQuery.isSuccess,
    isLoading: teamsQuery.isLoading,
  };
};

const ReportsOverviewPageContent = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const repo = useRepo();

  const [dates, setDates] = useState<(Date | undefined)[]>([startOfDay(subDays(new Date(), 7)), endOfDay(subDays(new Date(), 1))]);
  const {
    teamIds,
    setTeamIds,
    getTeamName,
    isLoading: isTeamsLoading,
    isSuccess: isTeamsSuccess,
    options: teamOptions,
  } = useTeamsSelection();

  const filters = useMemo(() => {
    let filters: any = {};
    if (dates[0]) {
      filters.dateFrom = getUnixTime(dates[0]);
    }
    if (dates[1]) {
      filters.dateTo = getUnixTime(dates[1]);
    }
    if (teamIds.length) {
      filters.teamIds = teamIds;
    }
    return filters;
  }, [dates, teamIds]);

  const handleSetDates = useCallback(
    (dates: (Date | undefined)[]) => {
      setDates([dates[0] ? startOfDay(dates[0]) : dates[0], dates[1] ? endOfDay(dates[1]) : dates[1]]);
    },
    [setDates]
  );

  const {
    isSuccess,
    data,
    isLoading: isReportLoading,
    isFetching: isReportFetching,
  } = useQuery(['reports-overview', account.id, filters], () => repo.getReportOverview(account.id, filters), {
    enabled: isTeamsSuccess,
    staleTime: 1000 * 60 * 10, // Increase to 10 minutes.
    cacheTime: 1000 * 60 * 60, // Increase to an hour.
  });
  const isLoading = isReportLoading || isTeamsLoading;
  const ticketsEnabled = hasTicketsEnabled(account);

  const metrics = useMemo(() => {
    if (!isSuccess || !data) {
      return {
        active_players: 0,
        new_players: 0,
        player_referrals: 0,
        courses_completed: 0,
        activities_completed: 0,
        coins_earned: 0,
        coins_spent: 0,
        coins_redemption_rate: 0,
        items_acquired: 0,
        orders_completed: 0,
        average_coin_spend: 0,
        coins_contributed: 0,
        contributions_participants: 0,
        tickets_earned: 0,
        tickets_spent: 0,
        sweepstakes_participants: 0,
      };
    }

    // The number of coins to consider when calculating the spending rate.
    const earnedForRateCalculation = Math.max(data.coins_earned, data.coins_spent);
    const spendingRate = earnedForRateCalculation > 0 ? Math.round((data.coins_spent / earnedForRateCalculation) * 10000) / 100 : 0;

    return {
      ...data,
      coins_redemption_rate: spendingRate,
      average_coin_spend: data.spending_players > 0 ? Math.round((data.coins_spent / data.spending_players) * 100) / 100 : 0,
    };
  }, [isSuccess, data]);

  const exportMutation = useMutation(async () => {
    let datePart = '';
    if (dates[0] && (!dates[1] || isSameDay(dates[0], dates[1]))) {
      datePart = format(dates[0], 'yyyy-MM-dd');
    } else if (dates[0] && dates[1]) {
      datePart = `${format(dates[0], 'yyyy-MM-dd')}-to-${format(dates[1], 'yyyy-MM-dd')}`;
    }
    const teams = teamIds.map(getTeamName);
    const data = [
      { name: 'start_time', value: dates[0] ? dates[0].toUTCString() : '' },
      { name: 'end_time', value: dates[1] ? dates[1].toUTCString() : '' },
      { name: 'teams', value: teams.join(', ') },
      ...Object.entries(metrics).map(([key, value]) => ({ name: key, value })),
    ];
    const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: [`report-overview`, datePart].filter(Boolean).join('-') });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  });
  const canDownload = !isLoading && !isReportFetching && !exportMutation.isLoading;

  const metricsSections = useMemo(() => {
    return [
      {
        title: t('playerEngagement'),
        metrics: [
          { name: t('activePlayers'), value: metrics.active_players },
          { name: t('newPlayers'), value: metrics.new_players },
          { name: t('friendReferrals'), value: metrics.player_referrals },
        ],
      },
      {
        title: t('lmsEngagement'),
        metrics: [
          { name: t('courseCompletions'), value: metrics.courses_completed },
          { name: t('activityCompletions'), value: metrics.activities_completed },
        ],
      },
      {
        title: t('coinEconomy'),
        metrics: [
          { name: t('totalEarned'), value: metrics.coins_earned },
          { name: t('totalSpent'), value: metrics.coins_spent },
          {
            name: t('coinsRedemptionRate'),
            value: metrics.coins_redemption_rate,
            type: 'ratio',
          },
        ],
      },
      {
        title: t('storeInsights'),
        metrics: [
          { name: t('itemsAcquired'), value: metrics.items_acquired },
          { name: t('ordersCompleted'), value: metrics.orders_completed },
          { name: t('averageCoinSpend'), value: metrics.average_coin_spend },
        ],
      },
      {
        title: t('fundraiserEngagement'),
        metrics: [
          { name: t('coinsFundraised'), value: metrics.coins_contributed },
          { name: t('participants'), value: metrics.contributions_participants },
        ],
      },
      ticketsEnabled
        ? {
            title: t('sweepstakesEngagement'),
            metrics: [
              { name: t('ticketsEarned'), value: metrics.tickets_earned },
              { name: t('ticketsUsed'), value: metrics.tickets_spent },
              { name: t('participants'), value: metrics.sweepstakes_participants },
            ],
          }
        : null,
    ].filter(Boolean) as MetricsSection[];
  }, [metrics, t, ticketsEnabled]);

  return (
    <>
      <PageHeaderWrappingNav
        title={t('reporting')}
        buttons={
          <>
            <HasInAnyTeamPermission perm={Permission.ReadReport}>
              <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
                {t('exportCsv')}
              </Button>
            </HasInAnyTeamPermission>
          </>
        }
      >
        <ReportingNav />
      </PageHeaderWrappingNav>
      <MetricsFilters dates={dates} setDates={handleSetDates} teamOptions={teamOptions} teamIds={teamIds} setTeamIds={setTeamIds} />
      <div className="space-y-8">
        {metricsSections.map((section) => {
          return <Metrics title={section.title} metrics={section.metrics} isLoading={isLoading} />;
        })}
      </div>
    </>
  );
};

const MetricsFilters = ({
  teamOptions,
  teamIds,
  setTeamIds,
  dates,
  setDates,
}: {
  dates: (Date | undefined)[];
  setDates: (dates: (Date | undefined)[]) => void;
  teamOptions: { label: string; value: string }[];
  teamIds: string[];
  setTeamIds: (teamIds: string[]) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex mb-4">
      <div className="flex flex-grow items-stretch space-x-2">
        <div className="w-96">
          <MultiDropdownSelect
            options={teamOptions}
            onChange={setTeamIds}
            selected={teamIds}
            placeholder={t('filterTeamsEllipsis')}
            disabled={!teamOptions.length}
            tagComponent={TeamTag}
          />
        </div>
      </div>
      <div>
        <div className="text-sm bg-white border border-gray-300 flex rounded-md overflow-hidden shadow-sm">
          <div className="rounded-bl-md rounded-tl-md flex-grow border-r border-gray-300 bg-gray-50 text-gray-500 py-2 px-3">
            {t('dateRange')}
          </div>
          <div className="flex flex-grow">
            <DateRangePicker value={dates} onChange={setDates} className="flex-grow py-2 px-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

const Metrics = ({ title, metrics, isLoading }: MetricsSection & { isLoading: boolean }) => {
  return (
    <div>
      <h3 className="font-bold text-lg mb-4">{title}</h3>
      <dl className="grid grid-flow-col gap-5 grid-cols-3">
        {metrics.map(({ name, value, type }) => {
          return (
            <div key={name} className="overflow-hidden rounded-lg bg-white shadow p-6 text-center">
              <dt className="truncate font-medium text-gray-500">{name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 flex justify-center">
                {isLoading ? <Placeholder className="h-8 mt-1 w-12" /> : null}
                {!isLoading ? type !== 'ratio' ? <StatAmount amount={value} /> : <StatPercentage percentage={value} /> : null}
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};

export default ReportsOverviewPage;
