import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Route, Switch } from 'react-router';
import { AccountContext } from '../lib/account';
import { useUser } from '../lib/auth';
import { useAddRecentAccountMutation } from '../lib/mutations';
import { canCreateAccounts, extendAccountPermissions, isCoreDomain } from '../lib/perms';
import { useRepo } from '../lib/repository';
import { Account } from '../lib/types';
import AccountCreatePage from './pages/AccountCreate';
import AccountSelectPage from './pages/AccountSelect';
import OwnAccountCreatePage from './pages/OwnAccountCreate';
import {
  CatchWantsUrlAndRedirect,
  CatchWantsUrlFromQueryString,
  RedirectToWantsUrlOrChildren,
  WantsUrlContextProvider,
} from './utils/WantsUrl';

export function setLastAccountId(accountId: string) {
  localStorage.setItem('last-account-id', accountId);
}

const AccountManager: React.FC = ({ children }) => {
  const repo = useRepo();
  const user = useUser();
  const recentAccountMutation = useAddRecentAccountMutation();

  const singleAccountId = user.account_ids.length === 1 ? user.account_ids[0] : null;
  const lastAccountId = localStorage.getItem('last-account-id');

  // Take precedence over the last account ID, then the single account ID. This will not
  // select the right account for a user who logs out and logs back in as another user as
  // the last account ID may refer to another account. We can fix that later. Users who
  // can create accounts always see the selection screen first.
  const [accountId, setAccountId] = useState<string | null>(canCreateAccounts(user) ? null : lastAccountId || singleAccountId);

  const queryClient = useQueryClient();
  const accountQuery = useQuery(
    ['account', accountId],
    () => {
      if (!accountId) return Promise.reject();
      return repo.getAccount(accountId);
    },
    { enabled: Boolean(accountId), retry: false, refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 }
  );
  const accountMembershipQuery = useQuery(
    ['account-membership', accountId],
    () => {
      if (!accountId) return Promise.reject();
      return repo.getAccountMembership(accountId);
    },
    { enabled: Boolean(accountId), retry: false, cacheTime: 0, staleTime: 0 }
  );

  const account = accountQuery.data;
  const membership = accountMembershipQuery.data;

  const patchAccount = (values: Partial<Account>, replace: boolean = false) => {
    if (!accountId) return;
    queryClient.invalidateQueries(['accounts']);
    queryClient.setQueryData(['account', accountId], !replace ? _.merge({}, account || {}, values) : values);
  };

  const refreshAccount = () => {
    if (!accountId) return;
    queryClient.invalidateQueries(['accounts']);
    queryClient.invalidateQueries(['account', accountId]);
  };

  const setAccount = async (account: Account) => {
    queryClient.setQueryData(['account', account.id], account);
    setAccountId(account.id);
    setLastAccountId(account.id);
    recentAccountMutation.mutate(account);
  };

  const unsetAccount = () => {
    setAccountId(null);
  };

  return (
    <WantsUrlContextProvider>
      <AccountContext.Provider
        value={{
          account,
          membership,
          permissions: membership ? extendAccountPermissions(membership.permissions) : undefined,
          setAccount,
          patchAccount,
          refreshAccount,
          unsetAccount,
          isLoading: accountQuery.isLoading || accountMembershipQuery.isLoading,
        }}
      >
        <Switch>
          <Route path="/accounts/create" exact component={AccountCreatePage} />
          <Route path="/new-account" component={OwnAccountCreatePage} />
          <Route path="/switch" component={AccountSelectPage}>
            <CatchWantsUrlFromQueryString />
            <AccountSelectPage />
          </Route>
          <Route>
            <AccountNegotiator>{children}</AccountNegotiator>
          </Route>
        </Switch>
      </AccountContext.Provider>
    </WantsUrlContextProvider>
  );
};

const AccountNegotiator: React.FC = (props) => {
  const { account, permissions, isLoading } = useContext(AccountContext);
  const user = useUser();

  if (account && permissions) {
    return <RedirectToWantsUrlOrChildren>{props.children}</RedirectToWantsUrlOrChildren>;
  }

  // If we're currently loading it, or it's successful it means we've got something to show.
  if (isLoading) {
    return null;
  }

  // If the user does not have any account, but can create their own account, let's go!
  if (!user.account_ids.length && user.can_create_own_account && (!user.domain_id || isCoreDomain(user))) {
    return <CatchWantsUrlAndRedirect to="/new-account" />;
  }

  return <CatchWantsUrlAndRedirect to="/switch" />;
};

export default AccountManager;
