import axios, { AxiosInstance } from 'axios';
import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import { AxiosContext } from './axios';
import { now } from './date';
import { Permission } from './types';

export type AccessToken = {
  expires: number;
  access_token: string;
  csrf: string;
};

export type LoggedInUser = {
  id: string;
  domain_id: string | undefined;
  account_ids: string[];
  school_ids: string[];
  name: string;
  lang: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  email: string;
  user_role: string;
  permissions: Permission[];
  can_create_own_account?: boolean;
  is_superuser?: boolean;
  recent_account_ids?: string[];
};

type ContextData = {
  auth: AccessToken;
  user: LoggedInUser;
};

type LoginData = {
  api_key: string;
  api_key_expires: string;
  refresh_token_csrf: string;
  account_ids: string[];
  school_ids: string[];
  eula_url: string;
  blob_host: string;
  dashboard_user: LoggedInUser;
};

export const useLoginMutation = () => {
  const { tokenManager } = useContext(AuthContext);
  return useMutation(async ({ email, password, captchaToken }: { email: string; password: string; captchaToken: string }) => {
    await tokenManager.login(email, password, captchaToken);
  });
};

export const useLoginWithSSOTokenMutation = () => {
  const { tokenManager } = useContext(AuthContext);
  return useMutation(async ({ token }: { token?: string }) => {
    if (!token) return Promise.reject();
    await tokenManager.loginWithSSOToken(token);
    return tokenManager.getUser();
  });
};

export const useLogoutMutation = () => {
  const { onLogout } = useContext(AuthContext);
  return useMutation(() => {
    onLogout();
    return Promise.resolve();
  });
};

export const usePasswordResetRequestMutation = () => {
  const axios = useContext(AxiosContext);
  return useMutation(async ({ email, captchaToken }: { email: string; captchaToken: string }) => {
    return axios.post('/_/admin-api/public/password/reset-request', {
      email,
      'g-recaptcha-response': captchaToken,
    });
  });
};

export const usePasswordResetMutation = () => {
  const axios = useContext(AxiosContext);
  return useMutation(async ({ token, password }: { token: string; password: string }) => {
    return axios.post('/_/admin-api/public/password/reset', {
      reset_token: token,
      new_password: password,
    });
  });
};

export const useSignUpMutation = () => {
  const { tokenManager } = useContext(AuthContext);
  return useMutation(
    async ({
      firstname,
      lastname,
      company,
      password,
      email,
      captchaToken,
    }: {
      firstname: string;
      lastname: string;
      company: string;
      captchaToken: string;
      email: string;
      password: string;
    }) => {
      await tokenManager.signUp(firstname, lastname, company, email, password, captchaToken);
    }
  );
};

export const useUser = (): LoggedInUser => {
  const { user } = useContext(AuthContext);
  if (!user) {
    throw new Error('Hook useUser should not be used when logged out.');
  }
  return user;
};

function readAccessToken() {
  return readFromStorage<AccessToken>('motrain-auth-at');
}

function removeAccessToken() {
  return localStorage.removeItem('motrain-auth-at');
}

function saveAccessToken(accessToken: AccessToken) {
  return localStorage.setItem('motrain-auth-at', JSON.stringify(accessToken));
}

function convertLoginDataToContextData(loginData: LoginData): ContextData {
  return {
    auth: convertLoginDataToAccessToken(loginData),
    user: loginData.dashboard_user,
  };
}

function convertLoginDataToAccessToken(loginData: LoginData): AccessToken {
  return {
    access_token: loginData.api_key,
    expires: parseInt(loginData.api_key_expires, 10),
    csrf: loginData.refresh_token_csrf,
  };
}

function readFromStorage<T>(key: string): T | null {
  const data = localStorage.getItem(key);
  if (data === null) {
    return data;
  }
  try {
    return JSON.parse(data);
  } catch {}
  return null;
}

export class TokenManager {
  protected loginData: LoginData | undefined;

  constructor(protected axios: AxiosInstance) {}

  getAccessToken = async (attempts: number = 0): Promise<AccessToken> => {
    // Read from localStorage.
    let at = readAccessToken();
    if (at === null) return Promise.reject();

    // If expired or about to expire, attempt to refresh.
    if (await this.shouldRefresh(at)) {
      // TODO Handle refreshing concurrency.
      if (localStorage.getItem('motrain-auth-at-refreshing') !== null && attempts < 10) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.getAccessToken(attempts + 1));
          }, 100);
        });
      }
      localStorage.setItem('motrain-auth-at-refreshing', '1');

      let refreshed;
      try {
        refreshed = await this.refreshToken(at);
      } catch {
        refreshed = null;
      }
      localStorage.removeItem('motrain-auth-at-refreshing');

      if (!refreshed) return Promise.reject();

      this.loginData = refreshed;
      at = convertLoginDataToAccessToken(refreshed);
      saveAccessToken(at);
    }

    return at;
  };

  async getUser(): Promise<LoggedInUser> {
    const at = await this.getAccessToken();
    if (!at || !this.loginData) return Promise.reject();
    return convertLoginDataToContextData(this.loginData).user;
  }

  async login(email: string, password: string, captchaToken: string): Promise<void> {
    const resp = await this.axios.post<LoginData>(
      '/admin/login',
      {
        email,
        password,
        'g-recaptcha-response': captchaToken,
      },
      { withCredentials: true }
    );
    this.loginData = resp.data;
    saveAccessToken(convertLoginDataToAccessToken(this.loginData));
  }

  async loginWithSSOToken(token: string): Promise<void> {
    const resp = await this.axios.post<LoginData>(
      '/admin/login_sso',
      {
        ssotoken: token,
      },
      { withCredentials: true }
    );
    this.loginData = resp.data;
    saveAccessToken(convertLoginDataToAccessToken(this.loginData));
  }

  async logout() {
    this.loginData = undefined;
    removeAccessToken();
  }

  async signUp(
    firstname: string,
    lastname: string,
    company: string,
    email: string,
    password: string,
    captchaToken: string
  ): Promise<void> {
    const resp = await this.axios.post<LoginData>(
      '/admin/signup',
      {
        first_name: firstname,
        last_name: lastname,
        company: company,
        email: email,
        password: password,
        'g-recaptcha-response': captchaToken,
      },
      { withCredentials: true }
    );
    this.loginData = resp.data;
    saveAccessToken(convertLoginDataToAccessToken(this.loginData));
  }

  protected refreshToken = async (at: AccessToken): Promise<LoginData> => {
    return (await this.axios.post<LoginData>('/admin/refresh_token', { csrf: at.csrf }, { withCredentials: true })).data;
  };

  protected shouldRefresh = async (at: AccessToken) => {
    return at.expires < now() + 60;
  };
}

export const AuthContext = React.createContext<{
  user: null | LoggedInUser;
  tokenManager: TokenManager;
  onLogin: (user: LoggedInUser) => void;
  onLogout: () => void;
  patchUser: (data: Partial<LoggedInUser>) => void;
  refreshUser: () => Promise<void>;
}>({
  user: null,
  tokenManager: new TokenManager(axios),
  onLogin: () => {},
  onLogout: () => {},
  patchUser: () => {},
  refreshUser: () => Promise.resolve(),
});
