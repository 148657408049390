import { useTranslation } from 'react-i18next';
import { getCountryList } from '../../lib/world';
import DropdownSelect, { DropdownSelectprops } from '../widgets/DropdownSelect';

const list = getCountryList();
const countryOptions = Object.keys(getCountryList()).map((code) => ({ label: list[code], value: code }));

const CountryDropdown = ({
  selected,
  onChange,
  placeholder,
  origin,
  allowEmpty,
}: {
  allowEmpty?: boolean;
  selected?: string;
  onChange: (lang: string) => void;
  placeholder?: DropdownSelectprops['placeholder'];
  origin?: DropdownSelectprops['origin'];
}) => {
  const { t } = useTranslation();
  let options = countryOptions;
  const option = countryOptions.find((option) => option.value === selected);
  const handleChange = (option: { value: string }) => onChange(option.value);

  return (
    <DropdownSelect
      clearable={allowEmpty}
      options={options}
      selected={option}
      placeholder={placeholder ? placeholder : t('selectEllipsis')}
      onClear={() => onChange('')}
      onChange={handleChange}
      origin={origin}
    />
  );
};

export default CountryDropdown;
