import { useTranslation } from 'react-i18next';
import { getAvailableLocales } from '../../lib/l10n';
import DropdownSelect, { DropdownSelectprops } from './DropdownSelect';

export const langOptions = getAvailableLocales().map((locale) => ({ label: locale.name, value: locale.tag }));

const LangDropdown = ({
  selected,
  onChange,
  placeholder,
  origin,
}: {
  selected?: string;
  onChange: (lang: string) => void;
  placeholder?: DropdownSelectprops['placeholder'];
  origin?: DropdownSelectprops['origin'];
}) => {
  const { t } = useTranslation();
  const option = langOptions.find((option) => option.value === selected);
  const handleChange = (option: { value: string }) => onChange(option.value);
  return (
    <DropdownSelect
      options={langOptions}
      selected={option}
      placeholder={placeholder ? placeholder : t('selectEllipsis')}
      onChange={handleChange}
      origin={origin}
    />
  );
};

export default LangDropdown;
