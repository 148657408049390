import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Field, Form, Formik } from 'formik';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Redirect, useHistory } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { useMembership, usePermissionChecker } from '../../lib/hooks';
import { getErrorMessage } from '../../lib/i18n';
import { useStores, useTeams } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { AccountRole, Permission } from '../../lib/types';
import { classNames, getAccountRoleDescriptionStringKey, getAccountRoleStringKey } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import Checkbox from '../inputs/Checkbox';
import Input from '../inputs/Input';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { NotificationError } from '../Notifications';
import { broadcastSuccessToast } from '../Toasts';

const MAX_ASSIGNED = 25;

const memberInviteSchema = yup
  .object({
    email: yup.string().email().required(),
    role: yup.string().required(),
    team_ids: yup.array(yup.string()),
    store_ids: yup.array(yup.string()),
  })
  .when((data, schema) => {
    if (data.role === AccountRole.Manager) {
      if (data.team_ids.length <= 0 && data.store_ids.length <= 0) {
        return schema.concat(
          yup.object({
            store_ids: yup.array(yup.string()).min(1).max(MAX_ASSIGNED).required(),
            team_ids: yup.array(yup.string()).min(1).max(MAX_ASSIGNED).required(),
          })
        );
      }
    } else if (data.role === AccountRole.TeamCaptain) {
      return schema.concat(
        yup.object({
          team_ids: yup.array(yup.string()).min(1).max(MAX_ASSIGNED).required(),
        })
      );
    } else if (data.role === AccountRole.StoreClerk) {
      return schema.concat(
        yup.object({
          store_ids: yup.array(yup.string()).min(1).max(MAX_ASSIGNED).required(),
        })
      );
    }
    return schema;
  })
  .required();

const useMemberInviteMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { id: accountId } = useAccount();
  return useMutation(
    ({ role, email, store_ids, team_ids }: { email: string; role: string; store_ids: string[]; team_ids: string[] }) =>
      repo.inviteMember(accountId, email, role, team_ids, store_ids),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('members');
        if (data.outcome === 'upgrade') {
          queryClient.setQueryData(['member', data.member.id], data.member);
        }
      },
    }
  );
};

const MemberInvitePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const mutation = useMemberInviteMutation();
  const membership = useMembership();

  const roles = Object.values([
    AccountRole.Owner,
    AccountRole.Admin,
    AccountRole.Manager,
    AccountRole.StoreClerk,
    AccountRole.TeamCaptain,
  ]).filter((role) => membership?.assignable_roles.includes(role));

  if (!roles.length) {
    return <Redirect to="/" />;
  }

  const handleBack = () => {
    history.push('/members');
  };

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col flex-grow">
        <HeaderWithBack backHandler={handleBack}>{t('untitledAdmin')}</HeaderWithBack>
        <Formik
          initialValues={{ email: '', role: roles[0], team_ids: [] as string[], store_ids: [] as string[] }}
          onSubmit={(values, form) => {
            mutation.mutate(values, {
              onSuccess: (data) => {
                broadcastSuccessToast(t('adminInvited'));
                if (data.outcome === 'upgrade') {
                  history.push(`/member/${data.member.id}`);
                } else {
                  history.push(`/members`);
                }
              },
              onError: () => {
                // TODO Handle error.
                form.setSubmitting(false);
              },
            });
          }}
          validationSchema={memberInviteSchema}
          validateOnMount
        >
          {(formik) => {
            const canSubmit = formik.isValid && !formik.isSubmitting;

            return (
              <>
                <Form className="flex flex-col flex-grow mt-4">
                  {mutation.isError ? (
                    <div className="mb-4">
                      <NotificationError>
                        {getErrorMessage(mutation.error, {
                          ALREADY_MEMBER: 'personAlreadyMemberOfAccount',
                        })}
                      </NotificationError>
                    </div>
                  ) : null}

                  <div className="flex-grow space-y-8">
                    <div className="w-96">
                      <label>
                        <div className="leading-6 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                        <div>
                          <Field as={Input} name="email" type="email" />
                        </div>
                      </label>
                    </div>

                    <AccountMemberRoleForm
                      onChange={(field, value) => formik.setFieldValue(field, value)}
                      roles={roles}
                      role={formik.values.role}
                      storeIds={formik.values.store_ids}
                      teamIds={formik.values.team_ids}
                    />
                  </div>

                  <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                    <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                      {t('invite')}
                    </PrimaryButton>
                    <Button onClick={handleBack}>{t('discard')}</Button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </LayoutWithSidebar>
  );
};

export const AccountMemberRoleForm = ({
  roles,
  role,
  teamIds,
  storeIds,
  onChange,
}: {
  roles: AccountRole[];
  role: AccountRole;
  teamIds: string[];
  storeIds: string[];
  onChange: (field: string, value: any) => void;
}) => {
  const { t } = useTranslation();

  type ResourceMode = 'none' | 'all' | 'some';
  let teamsMode: ResourceMode = 'none';
  let storesMode: ResourceMode = 'none';

  if ([AccountRole.Admin, AccountRole.Owner].includes(role)) {
    teamsMode = 'all';
    storesMode = 'all';
  } else if (role === AccountRole.Manager) {
    teamsMode = 'some';
    storesMode = 'some';
  } else if (role === AccountRole.StoreClerk) {
    storesMode = 'some';
  } else if (role === AccountRole.TeamCaptain) {
    teamsMode = 'some';
  }

  return (
    <>
      <div className="w-96">
        <div className="leading-6 mb-1 text-gray-700 text-sm font-medium">{t('role')}</div>
        <div>
          <RoleDropdown roles={roles} value={role} onChange={(role) => onChange('role', role)} />
        </div>
      </div>

      <div className="w-full">
        <div className="leading-6 mb-1 text-gray-700 text-sm font-medium">{t('teams')}</div>
        <div>
          {teamsMode === 'none' ? (
            <div className="w-96 text-sm bg-gray-50 border-0 text-gray-800 px-2 py-2 rounded">{t('notApplicable')}</div>
          ) : null}
          {teamsMode === 'all' ? (
            <div className="w-96 text-sm bg-blue-50 border-0 text-blue-800 px-2 py-2 rounded">{t('accessToAll')}</div>
          ) : null}
          {teamsMode === 'some' ? <TeamsPicker teamIds={teamIds} onChange={(ids) => onChange('team_ids', ids)} /> : null}
        </div>
      </div>

      <div className="w-full">
        <div className="leading-6 mb-1 text-gray-700 text-sm font-medium">{t('stores')}</div>
        <div>
          {storesMode === 'none' ? (
            <div className="w-96 text-sm bg-gray-50 border-0 text-gray-800 px-2 py-2 rounded">{t('notApplicable')}</div>
          ) : null}
          {storesMode === 'all' ? (
            <div className="w-96 text-sm bg-blue-50 border-0 text-blue-800 px-2 py-2 rounded">{t('accessToAll')}</div>
          ) : null}
          {storesMode === 'some' ? <StoresPicker storeIds={storeIds} onChange={(ids) => onChange('store_ids', ids)} /> : null}
        </div>
      </div>
    </>
  );
};

const TeamsPicker = ({ teamIds, onChange }: { teamIds: string[]; onChange: (teamIds: string[]) => void }) => {
  const { isSuccess, data: teams } = useTeams(undefined, undefined, 100);
  const permChecker = usePermissionChecker();
  const { t } = useTranslation();

  const elligibleTeams = useMemo(() => {
    if (!teams) return [];
    return teams.items.filter((team) => permChecker.hasTeamPermission(team.id, Permission.ManageMember));
  }, [teams]); // eslint-disable-line

  const handleChange = (isChecked: boolean, teamId: string) => {
    const withoutTeam = teamIds.filter((id) => id !== teamId);
    if (!isChecked) return onChange(withoutTeam);
    onChange([...withoutTeam, teamId]);
  };

  return (
    <div className="w-full grid grid-cols-3 gap-1 text-sm">
      {isSuccess && teams
        ? elligibleTeams.map((team) => {
            const checked = teamIds.includes(team.id);
            return (
              <div key={team.id}>
                <label>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => handleChange(e.target.checked, team.id)}
                    disabled={!checked && teamIds.length >= MAX_ASSIGNED}
                  />
                  <div className="ml-2 inline-block">{team.name}</div>
                </label>
              </div>
            );
          })
        : t('loadingEllipsis')}
    </div>
  );
};

const StoresPicker = ({ storeIds, onChange }: { storeIds: string[]; onChange: (storeIds: string[]) => void }) => {
  const { isSuccess, data: stores } = useStores(undefined, undefined, 100);
  const permChecker = usePermissionChecker();
  const { t } = useTranslation();

  const elligibleStores = useMemo(() => {
    if (!stores) return [];
    return stores.items.filter((store) => permChecker.hasStorePermission(store.id, Permission.ManageMember));
  }, [stores]); // eslint-disable-line

  const handleChange = (isChecked: boolean, storeId: string) => {
    const withoutTeam = storeIds.filter((id) => id !== storeId);
    if (!isChecked) return onChange(withoutTeam);
    onChange([...withoutTeam, storeId]);
  };

  return (
    <div className="w-full grid grid-cols-3 gap-1 text-sm">
      {isSuccess && stores
        ? elligibleStores.map((store) => {
            const checked = storeIds.includes(store.id);
            return (
              <div key={store.id}>
                <label>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => handleChange(e.target.checked, store.id)}
                    disabled={!checked && storeIds.length >= MAX_ASSIGNED}
                  />
                  <div className="ml-2 inline-block">{store.name}</div>
                </label>
              </div>
            );
          })
        : t('loadingEllipsis')}
    </div>
  );
};

const RoleDropdown = ({
  roles,
  value,
  onChange,
}: {
  roles: AccountRole[];
  value: AccountRole;
  onChange: (opt: AccountRole) => void;
}) => {
  const { t } = useTranslation();
  const options = roles.map((role) => ({
    value: role,
    label: t(getAccountRoleStringKey(role)),
    description: t(getAccountRoleDescriptionStringKey(role)),
  }));
  const selected = options.find((o) => o.value === value);

  const handleChange = (option?: { value: AccountRole }) => {
    if (!option) return;
    onChange(option.value);
  };

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">{t('role')}</Listbox.Label>
          <div className="relative">
            <Listbox.Button
              className={classNames(
                'flex text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm hover:bg-gray-50',
                'text-gray-700 font-medium',
                'disabled:bg-gray-200 disabled:border-gray-200 disabled:cursor-default disabled:text-gray-400'
              )}
            >
              <span className="flex-grow block truncate">{selected?.label}</span>
              <div className="flex-shrink-0 ml-2 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
                <div className="inline-flex pl-1 flex-shrink-0">
                  <ChevronDownIcon className={classNames('h-5 w-5 text-gray-500')} aria-hidden="true" />
                </div>
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 w-full right-0 mt-2 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.label}</p>
                          {selected ? (
                            <span className={'text-gray-500'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={classNames('text-gray-500', 'mt-2')}>{option.description}</p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default MemberInvitePage;
