import React from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';

import './assets/tailwind.generated.css';

// The things here need to be imported before any of the application is imported, or executed.
// This is especially true in the case of yup that needs to define the locale strings before
// any of the schema are created, but as we often create the schemas statically, so this must
// happen before any of the application files are loaded.
import * as yup from 'yup';
import './lib/i18n';
import './lib/chartjs';

yup.setLocale({
  mixed: {
    required: 'i18next:validation:requiredField',
  },
});

// Application can be loaded from here!
const App = require('./App').default;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
