import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import TeamPageLayout, { useTeamPage } from '../layouts/TeamPage';
import { broadcastSuccessToast } from '../Toasts';
import { HasTicketsEnabled } from '../utils/HasTicketsEnabled';
import HelpText from '../forms/HelpText';
import EnabledDropdown from '../widgets/EnabledDropdown';

const teamSchema = yup
  .object({
    name: yup.string().required(),
    tickets_enabled: yup.bool(),
  })
  .required();

const useTeamUpdateMutation = (teamId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation((data: { name: string }) => repo.updateTeam(teamId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('teams');
      queryClient.invalidateQueries(['team', teamId]);
      broadcastSuccessToast(t('settingsSaved'));
    },
  });
};

const TeamSettingsPage = () => {
  return (
    <TeamPageLayout>
      <TeamSettingsPageContent />
    </TeamPageLayout>
  );
};

const TeamSettingsPageContent = () => {
  const { t } = useTranslation();
  const { team } = useTeamPage();
  const mutation = useTeamUpdateMutation(team.id);

  return (
    <>
      <Formik
        initialValues={{ name: team.name, tickets_enabled: team.tickets_enabled }}
        onSubmit={(values, formik) => {
          mutation.mutate(values, {
            onSettled: () => {
              formik.setSubmitting(false);
            },
            onSuccess: () => {
              formik.resetForm({ values });
            },
          });
        }}
        validationSchema={teamSchema}
        validateOnMount
      >
        {({ isValid, isSubmitting, dirty, resetForm, values, setFieldValue }) => {
          const canSubmit = !isSubmitting && isValid && dirty;
          const canDiscard = !isSubmitting && dirty;
          const handleDiscard = () => resetForm();

          return (
            <Form className="flex flex-col flex-grow">
              <div className="space-y-8">
                <div className="w-96">
                  <label>
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('name')}</div>
                    <div>
                      <Field as={Input} name="name" />
                    </div>
                  </label>
                </div>
                <HasTicketsEnabled>
                  <div className="w-96">
                    <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('tickets')}</div>
                    <div>
                      <EnabledDropdown
                        enabled={values.tickets_enabled}
                        onChange={(enabled) => setFieldValue('tickets_enabled', enabled)}
                      />
                    </div>
                  </div>
                </HasTicketsEnabled>
              </div>

              <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                  {t('save')}
                </PrimaryButton>
                <Button onClick={handleDiscard} disabled={!canDiscard}>
                  {t('discard')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TeamSettingsPage;
