import React, { ComponentProps } from 'react';
import { useHistory } from 'react-router';
import { useAnchorButtonProps } from '../lib/hooks';
import { classNames } from '../lib/utils';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  submit?: boolean;
  large?: boolean;
  small?: boolean;
  icon?: React.ComponentType<ComponentProps<'svg'>>;
  iconClassname?: ComponentProps<'svg'>['className'];
};

const sharedClasses = [
  'border',
  'disabled:bg-gray-200',
  'disabled:border-gray-200',
  'disabled:cursor-default',
  'disabled:text-gray-400',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'font-medium',
  'inline-flex',
  'items-center',
  'justify-center',
  'rounded-md',
  'shadow-sm',
  'text-sm',
  'w-auto',
];

const defaultClasses = ['bg-white', 'border-gray-300', 'border', 'hover:bg-gray-50', 'text-gray-700'];
const defaultIconClasses = ['text-gray-500'];

const primaryClasses = ['bg-blue-600', 'border', 'border-blue-600', 'hover:bg-blue-800m', 'hover:border-blue-800m', 'text-white'];
const primaryIconClasses = ['text-white'];

const dangerClasses = ['bg-white', 'border-gray-300', 'border', 'focus:ring-red-500', 'hover:bg-gray-50', 'text-red-700'];
const dangerIconClasses = ['text-gray-500'];

const defaultClassname = sharedClasses.concat(defaultClasses).join(' ');
const defaultIconClassname = defaultIconClasses.join(' ');
const primaryClassname = sharedClasses.concat(primaryClasses).join(' ');
const primaryIconClassname = primaryIconClasses.join(' ');
const dangerClassname = sharedClasses.concat(dangerClasses).join(' ');
const dangerIconClassname = dangerIconClasses.join(' ');

const sizesClassname = {
  small: ['h-[30px]', 'px-2'].join(' '),
  normal: ['h-[34px]', 'px-3'].join(' '),
  large: ['h-[38px]', 'px-4'].join(' '),
};

const ButtonBase: React.FC<ButtonProps> = ({ className, icon, iconClassname, large, small, submit, children, ...props }) => {
  const Icon = icon || null;
  const sizeClassname = large ? sizesClassname['large'] : small ? sizesClassname['small'] : sizesClassname['normal'];
  return (
    <button type={submit ? 'submit' : 'button'} className={classNames(className, sizeClassname)} {...props}>
      {Icon ? <Icon className={classNames('-ml-0.5 mr-2 h-4 w-4', iconClassname)} aria-hidden="true" /> : null}
      {children}
    </button>
  );
};

export const AnchorButton = ({
  onClick,
  ...props
}: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick'> & { onClick?: () => void }) => {
  const clickProps = useAnchorButtonProps(() => onClick && onClick());
  return <a {...props} {...clickProps} />; // eslint-disable-line
};

export const PlainButton = ({ className, ...props }: React.ComponentProps<'button'>) => {
  return <button className={classNames('focus:outline-none focus:ring-2 focus:ring-offset-2', className)} {...props} />;
};

export const Button: React.FC<ButtonProps> = ({ className, ...props }) => {
  return <ButtonBase {...props} className={classNames(defaultClassname, className)} iconClassname={defaultIconClassname} />;
};

export const PrimaryButton: React.FC<ButtonProps> = ({ className, ...props }) => {
  return <ButtonBase {...props} className={classNames(primaryClassname, className)} iconClassname={primaryIconClassname} />;
};

export const DangerButton: React.FC<ButtonProps> = ({ className, ...props }) => {
  return <ButtonBase {...props} className={classNames(dangerClassname, className)} iconClassname={dangerIconClassname} />;
};

export const LinkPrimaryButton: React.FC<ButtonProps & { to: string }> = ({ to, ...props }) => {
  const history = useHistory();
  return <PrimaryButton {...props} onClick={() => history.push(to)} />;
};
