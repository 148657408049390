import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { useSignUpMutation } from '../../lib/auth';
import { isPasswordValid } from '../../lib/validation';
import { PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import FullpageLayout from '../layouts/Fullpage';
import Logo from '../Logo';
import { NotificationError } from '../Notifications';
import Recaptcha, { useRecaptchaRef } from '../widgets/Recaptcha';

const schema = yup
  .object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    // company: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().test('is-password-valid', (value) => isPasswordValid(value)),
  })
  .required();

const SignUpPage: React.FC<{ onLogin: (url?: string) => void }> = ({ children, onLogin }) => {
  const { t } = useTranslation();
  const signUpMutation = useSignUpMutation();
  const captchaRef = useRecaptchaRef();

  const handleSubmit = async (values: any) => {
    const token = await captchaRef.current?.executeAsync();
    return signUpMutation.mutateAsync(
      { ...values, captchaToken: token },
      {
        onSuccess: () => {
          onLogin();
        },
      }
    );
  };

  const accountAlreadyExists =
    signUpMutation.isError && (signUpMutation.error as any)?.response?.data?.message === 'DASHBOARD_USER_ALREADY_EXISTS';

  return (
    <FullpageLayout>
      <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow font-medium text-center">
        <div>
          <div className="mb-6">
            <div className="mb-6 flex justify-center items-center">
              <Logo />
            </div>
            <div className="text-2xl leading-8 text-gray-600">{t('signup.createAccount')}</div>
          </div>

          <Formik
            initialValues={{ firstname: '', lastname: '', email: '', company: '', password: '' }}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnMount
          >
            {({ isSubmitting, isValid }) => {
              const canSubmit = !isSubmitting && isValid && captchaRef.current;
              return (
                <>
                  {signUpMutation.isError ? (
                    <div className="font-normal mb-4">
                      <NotificationError>
                        {accountAlreadyExists ? t('signup.emailAlreadyUsed') : t('signup.errorWhileSigningUp')}
                      </NotificationError>
                    </div>
                  ) : null}

                  <Form className="flex flex-col flex-grow text-left">
                    <Recaptcha recaptchaRef={captchaRef} />

                    <div className="flex-grow space-y-4">
                      <div className="flex gap-4">
                        <div className="w-1/2">
                          <label>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('firstName')}</div>
                            <div>
                              <Field as={Input} name="firstname" />
                            </div>
                          </label>
                        </div>
                        <div className="w-1/2">
                          <label>
                            <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('lastName')}</div>
                            <div>
                              <Field as={Input} name="lastname" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                          <div>
                            <Field as={Input} name="email" type="email" />
                          </div>
                        </label>
                      </div>
                      {/* <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('company')}</div>
                          <div>
                            <Field as={Input} name="company" maxLength={255} />
                          </div>
                        </label>
                        <div className="font-normal mt-2 text-sm text-gray-500">{t('signup.weUseCompanyNameForAccountName')}</div>
                      </div> */}
                      <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium flex items-center">
                            <div>{t('password')}</div>
                          </div>
                          <div>
                            <Field as={Input} name="password" type="password" />
                          </div>
                        </label>
                        <div className="font-normal mt-2 text-sm text-gray-500">{t('validation:passwordRequirements')}</div>
                      </div>
                    </div>
                    <div className="mt-6 flex flex-col">
                      <PrimaryButton large submit disabled={!canSubmit}>
                        {t('createAccount')}
                      </PrimaryButton>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>

        <div className="mt-6">
          <Link to="/login" className="text-blue-600">
            {t('signup.alreadyHaveAccount')}
          </Link>
        </div>
      </div>
    </FullpageLayout>
  );
};

export default SignUpPage;
