import { useTranslation } from 'react-i18next';
import { useAccount } from '../../lib/account';
import { useContinuatedQuery } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { OrdersRequestsTable } from '../OrdersTable';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import PlayersNav from '../nav/PlayersNav';
import { OrderState, RedemptionMethod } from '../../lib/types';

const useOrders = () => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  const filters = {
    state: OrderState.Pending,
    method: RedemptionMethod.Request,
  };
  return useContinuatedQuery(
    ['orders', accountId, filters],
    ({ pageParam }) => {
      return repo.getOrders(accountId, filters, undefined, undefined, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [accountId],
    }
  );
};

const PlayersOrdersPage = () => {
  const { t } = useTranslation();
  const query = useOrders();

  return (
    <LayoutWithSidebar>
      <PageHeaderWrappingNav title={t('players')}>
        <PlayersNav />
      </PageHeaderWrappingNav>
      <div className="flex flex-grow flex-col">
        <OrdersRequestsTable queryResult={query} />
      </div>
    </LayoutWithSidebar>
  );
};

export default PlayersOrdersPage;
