import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Route, Switch } from 'react-router';
import { AuthContext, LoggedInUser, TokenManager, useLoginWithSSOTokenMutation } from '../lib/auth';
import { AxiosContext } from '../lib/axios';
import { Repository, RepositoryContext, RepositoryEvent, UserRepository } from '../lib/repository';
import ForgotPasswordPage from './pages/ForgotPassword';
import InvitationLoggedOutPage from './pages/InvitationLoggedOut';
import LoginPage from './pages/Login';
import NewPasswordPage from './pages/NewPassword';
import SignUpPage from './pages/SignUp';
import { CatchWantsUrl, CatchWantsUrlAndRedirect, RedirectToWantsUrlOrChildren, WantsUrlContextProvider } from './utils/WantsUrl';

const AuthenticationManager: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const axios = useContext(AxiosContext);
  const [auth, setAuth] = useState<{ user: LoggedInUser | null }>({ user: null });
  const tokenManager = useMemo(() => new TokenManager(axios), [axios]);

  const handleLogout = () => {
    tokenManager.logout();
    setAuth({ user: null });
  };

  const handleLogin = (user: LoggedInUser) => {
    setAuth({ user });
    if (user.lang) {
      i18n.changeLanguage(user.lang);
    }
  };
  const repo = new UserRepository(axios, tokenManager);
  repo.addListener(RepositoryEvent.AuthenticationError, () => {
    handleLogout();
  });

  const handlePatchUser = async (data: Partial<LoggedInUser>) => {
    if (!auth.user) return;
    try {
      setAuth({ user: { ...auth.user, ...data } });
    } catch (err) {}
  };

  const handleRefreshUser = async () => {
    if (!auth.user) return;
    try {
      const user = await repo.getMe(true);
      setAuth({ user });
    } catch (err) {}
  };

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        tokenManager,
        onLogin: handleLogin,
        onLogout: handleLogout,
        patchUser: handlePatchUser,
        refreshUser: handleRefreshUser,
      }}
    >
      <WantsUrlContextProvider>
        <AuthenticationNegotiator repo={repo}>{children}</AuthenticationNegotiator>
      </WantsUrlContextProvider>
    </AuthContext.Provider>
  );
};

const AuthenticationNegotiator: React.FC<{ repo: Repository }> = ({ repo, ...props }) => {
  const { user, onLogin } = useContext(AuthContext);

  const handleLogin = async () => {
    onLogin(await repo.getMe());
  };

  if (!user) {
    return (
      <AuthenticationBySSONegotiator>
        <AuthenticationRestorationNegotiator repo={repo}>
          <LoggedOutRoutes onLogin={handleLogin} />
        </AuthenticationRestorationNegotiator>
      </AuthenticationBySSONegotiator>
    );
  }

  return (
    <RepositoryContext.Provider value={repo}>
      <RedirectToWantsUrlOrChildren>{props.children}</RedirectToWantsUrlOrChildren>
    </RepositoryContext.Provider>
  );
};

const AuthenticationBySSONegotiator: React.FC<{}> = ({ children }) => {
  const [ssoAttempted, setSsoAttempted] = useState(false);
  const mutation = useLoginWithSSOTokenMutation();
  const { user, onLogin } = useContext(AuthContext);

  useEffect(() => {
    if (user || ssoAttempted || !mutation.isIdle) return;
    const hash = window.location.hash;
    const parts = hash.match(/sso=([a-f0-9]+)/);
    const token = parts ? parts[1] : undefined;

    // Remove the hash from the URL.
    if (token && window.history && window.history.replaceState) {
      // This will strip out all hashtags, we probably don't want this...
      window.history.replaceState('', document.title, window.location.pathname + window.location.search);
    }

    mutation.mutate(
      { token },
      {
        onSuccess: (user) => {
          onLogin(user);
        },
        onSettled: () => {
          setSsoAttempted(true);
        },
      }
    );
  }, [ssoAttempted]); // eslint-disable-line

  if (!ssoAttempted) return null;
  return <>{children}</>;
};

const AuthenticationRestorationNegotiator: React.FC<{ repo: Repository }> = ({ children, repo }) => {
  const [restoreAttempted, setRestoreAttempted] = useState(false);
  const { user, onLogin } = useContext(AuthContext);

  const restoreMutation = useMutation(async () => {
    if (user) return user;
    return repo.getMe();
  });

  // Restoring login.
  useEffect(() => {
    if (user || restoreAttempted || !restoreMutation.isIdle) return;
    restoreMutation.mutate(undefined, {
      onSuccess: (user) => {
        onLogin(user);
      },
      onSettled: () => {
        setRestoreAttempted(true);
      },
    });
  }, [restoreAttempted]); // eslint-disable-line

  if (!restoreAttempted) return null;
  return <>{children}</>;
};

const LoggedOutRoutes: React.FC<{ onLogin: (redirectTo?: string) => void }> = ({ onLogin }) => {
  return (
    <Switch>
      <Route path="/invitation/:invitationId/:secret">
        <CatchWantsUrl />
        <InvitationLoggedOutPage />
      </Route>
      <Route path="/signup">
        <SignUpPage onLogin={onLogin} />
      </Route>
      <Route path="/forgot_password" component={ForgotPasswordPage} />
      <Route path="/reset_password" component={NewPasswordPage} />
      <Route path="/login">
        <LoginPage onLogin={onLogin} />
      </Route>
      <Route>
        <CatchWantsUrlAndRedirect to="/login" />
      </Route>
    </Switch>
  );
};

export default AuthenticationManager;
