import { UserAvatar } from '../Avatars';

const OutsideAccountLayout: React.FC = ({ children }) => {
  return (
    <div className="w-full h-full flex flex-col">
      <div className="h-16 flex inset-x-0 px-4 bg-white">
        <div className="flex-grow"></div>
        <div className="p-4">
          <UserAvatar className="w-8 h-8" />
        </div>
      </div>
      <div className="flex flex-grow bg-gray-50 py-6">
        <div className="mx-auto w-[959px]">{children}</div>
      </div>
    </div>
  );
};

export default OutsideAccountLayout;
