import i18next from 'i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, TFunction } from 'react-i18next';

// Initialise i18next.
i18next
  .use(i18nextHttpBackend)
  .use(i18nextLanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: process.env.REACT_APP_LOCALES_ROOT_URL + '/{{lng}}/{{ns}}.json',
    },

    defaultNS: 'dashboard',

    detection: {
      order: ['querystring', 'cookie', 'navigator'],
      lookupCookie: 'motrain-language',
      caches: ['cookie'],
    },

    fallbackNS: 'common',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // Not needed with React.
    },
    ns: ['dashboard', 'validation', 'transaction', 'common'],

    react: {
      transKeepBasicHtmlNodesFor: ['strong', 'em'],
    },
  });

export const availableLanguages = [
  { code: 'en', name: 'English' },
  { code: 'ca', name: 'Català' },
  { code: 'es', name: 'Español' },
  { code: 'es-AR', name: 'Español - Argentina' },
  { code: 'es-CO', name: 'Español - Colombia' },
  { code: 'es-MX', name: 'Español - México' },
  { code: 'es-VE', name: 'Español - Venezuela' },
  { code: 'pt', name: 'Português' },
  { code: 'pt-BR', name: 'Português - Brazil' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsche' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'ru', name: 'Pусский' },
  { code: 'it', name: 'Italiano' },
  { code: 'el', name: 'Ελληνικά' },
  { code: 'pl', name: 'Polski' },
  { code: 'zh', name: '中文' },
];

export const getErrorMessage = (error: any, errorCodesToStringKey?: { [index: string]: string }) => {
  if (!error) {
    return t('error.unknown');
  }

  const code = error?.response?.data?.code; // Error code returned from the API.
  const candidates = [
    errorCodesToStringKey?.[code] ? t(errorCodesToStringKey?.[code]) : null, // Translated code.
    // TODO Default will obfuscate any network error, which is not ideal.
    errorCodesToStringKey?.default ? t(errorCodesToStringKey?.default) : null, // The default, or fallback, message.
    error?.response?.data?.message, // API error returned from Axios.
    error?.request ? t('error.network') : null, // Network error returned from Axios.
    error?.message, // Traditional error message.
    t('error.unknown'),
  ];

  return candidates.find(Boolean);
};

export const t: TFunction = (key, opts) => i18next.t(key, opts);

export default i18next;
