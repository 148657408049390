import React from 'react';

// import closeSrc from '../assets/icons/x-close-small.svg';
// import { _ } from '../helpers/I18n';

const Notification: React.FC<{ type?: 'info' | 'success' | 'error' | 'warning' }> = ({ children, type = 'success' }) => {
  //   const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //     e.preventDefault();
  //     onClose && onClose();
  //   };

  const typeClasses = {
    info: 'bg-blue-50 border-0 text-blue-800',
    success: 'bg-green-100 border-green-300',
    warning: 'bg-yellow-100 border-yellow-300',
    error: 'bg-red-100 border-red-300',
  };

  const classes = typeClasses[type] || typeClasses['success'];

  return (
    <div className={`${classes} border rounded text-sm flex leading-none py-3 pl-6 pr-2 [&_a]:underline`}>
      <div className="flex-1">{children}</div>
      {/* <div className="flex-grow-0">
        <a href="#close" role="button" onClick={handleClick} className="px-3 flex items-center justify-center">
          <img src={closeSrc} alt={_('close')} />
        </a>
      </div> */}
    </div>
  );
};

export const NotificationError: React.FC = ({ children }) => {
  return <Notification type="error">{children}</Notification>;
};

export default Notification;
