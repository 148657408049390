import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ArrowLongDownIcon } from '@heroicons/react/24/solid';
import download from 'downloadjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, UseMutationResult } from 'react-query';
import { useAccount } from '../../lib/account';
import { ContinuatedQueryObserverResult, useContinuatedQuery } from '../../lib/queries';
import { ContinuableResponse, useRepo } from '../../lib/repository';
import { Transaction } from '../../lib/types';
import { AnchorButton, Button } from '../Buttons';
import { Fullscreenable, useFullscreen } from '../layouts/Fullscreen';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import ReportingNav from '../nav/ReportingNav';
import TransactionsFilters, { TransactionFiltersContext, TransactionsFiltersProvider } from '../transactions/Filters';
import TransactionsTable from '../transactions/Table';

const TX_DOWNLOAD_LIMIT = 10000; // Sync with backend.

const useTransactions = (
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  const results = useContinuatedQuery(
    ['transactions', accountId, filters, orderBy],
    ({ pageParam }) => {
      return repo.getTransactions(accountId, filters, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [accountId, orderBy, ...Object.values(filters || {})],
    }
  );

  return results;
};

const useTransactionsExportMutation = (
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();

  return useMutation(() => repo.exportTransactions(accountId, filters, orderBy), {
    onSuccess: (data) => {
      download(data, 'transactions.csv', 'text/csv');
    },
  });
};

const TransactionsPage = () => {
  return (
    <LayoutWithSidebar>
      <TransactionsFiltersProvider>
        <TransactionsContainer />
      </TransactionsFiltersProvider>
    </LayoutWithSidebar>
  );
};

const TransactionsContainer: React.FC = () => {
  const { timestampFrom, timestampTo, type, details } = useContext(TransactionFiltersContext);
  const filters = {
    type: type,
    details: details,
    dateFrom: timestampFrom,
    dateTo: timestampTo,
  };
  const query = useTransactions(filters);
  const exportMutation = useTransactionsExportMutation(filters);
  return (
    <Fullscreenable>
      <Transactions queryResult={query} exportMutation={exportMutation} />
    </Fullscreenable>
  );
};

const Transactions: React.FC<{
  exportMutation: UseMutationResult<Blob, unknown, void, unknown>;
  queryResult: ContinuatedQueryObserverResult<
    ContinuableResponse<Transaction, { users: { id: string; firstname: string; lastname: string }[] }>
  >;
}> = ({ exportMutation, queryResult }) => {
  const { t } = useTranslation();
  const { isFullscreen, goFullscreen } = useFullscreen();
  const { hasFilters } = useContext(TransactionFiltersContext);

  const { isLoading: isDownloading } = exportMutation;

  const totalRecords = queryResult?.data?.total || 0;
  const canDownload = queryResult.isSuccess && totalRecords <= TX_DOWNLOAD_LIMIT && !isDownloading;

  return (
    <>
      <PageHeaderWrappingNav
        title={
          isFullscreen ? (
            t('transactions')
          ) : (
            <AnchorButton className="inline-flex items-center" onClick={goFullscreen}>
              {t('reporting')}
              <ArrowTopRightOnSquareIcon aria-hidden="true" className="ml-2 h-6 w-6 text-gray-500" />
            </AnchorButton>
          )
        }
        buttons={
          <Button icon={ArrowLongDownIcon} disabled={!canDownload} onClick={() => exportMutation.mutate()}>
            {t('exportCsv')}
          </Button>
        }
      >
        {isFullscreen ? null : <ReportingNav />}
      </PageHeaderWrappingNav>
      <TransactionsFilters />
      <div className="mt-4">
        <TransactionsTable queryResult={queryResult} hasFilters={hasFilters} displayPlayerName />
      </div>
    </>
  );
};

export default TransactionsPage;
