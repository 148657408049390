import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Redirect } from 'react-router';
import * as yup from 'yup';
import { useUser } from '../../lib/auth';
import { getErrorMessage } from '../../lib/i18n';
import { isSuperUser } from '../../lib/perms';
import { useRepo } from '../../lib/repository';
import { PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import Input from '../inputs/Input';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import { PillLink, PillNav } from '../nav/Pill';
import { NotificationError } from '../Notifications';
import { Table, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';

const AdminNav = () => {
  return (
    <PillNav>
      {[
        {
          id: 'domains',
          name: 'Domains',
          href: `/domains`,
          exact: true,
        },
        {
          id: 'domain-admins',
          name: 'Domain admins',
          href: `/domains/admins`,
        },
      ].map((tab) => {
        return (
          <PillLink key={tab.href} to={tab.href} exact={tab.exact}>
            {tab.name}
          </PillLink>
        );
      })}
    </PillNav>
  );
};

const DomainsPage = () => {
  const user = useUser();
  if (!isSuperUser(user)) {
    return <Redirect to="/" />;
  }
  return <DomainsPageContent />;
};

const DomainsPageContent = () => {
  const { t } = useTranslation();
  const repo = useRepo();
  const queryClient = useQueryClient();
  const query = useQuery(['domains'], () => repo.getDomains());
  const mutation = useMutation(async (data: { id: string; name: string }) => {
    return repo.createDomain(data.id, data.name);
  });

  return (
    <OutsideAccountLayout>
      <div className="flex flex-col flex-grow">
        <div className="ml-2">
          <HeaderWithBack withBorder={false} backTo="/">
            Domains
          </HeaderWithBack>
        </div>

        <div className="px-10 pt-6 pb-10 bg-white shadow-sm">
          {mutation.isError ? (
            <div className="mb-6">
              <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
            </div>
          ) : null}
          <div className="mb-6">
            <Formik
              validationSchema={yup.object({
                id: yup
                  .string()
                  .matches(/^[a-z](-?[a-z])*$/)
                  .required(),
                name: yup.string().required(),
              })}
              initialValues={{ name: '', id: '' }}
              onSubmit={(data, form) => {
                mutation.mutate(data, {
                  onSuccess: () => {
                    form.setSubmitting(false);
                    form.resetForm();
                    queryClient.invalidateQueries(['domains']);
                  },
                });
                return;
              }}
            >
              {({ isValid }) => {
                return (
                  <Form>
                    <div className="flex gap-4 items-end">
                      <div className="flex-1 grid grid-cols-2 gap-4">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">ID</div>
                          <div className="flex items-center">
                            <Field as={Input} name="id" type="text" placeholder="the-domain-id" />
                          </div>
                        </label>
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">Name</div>
                          <div>
                            <Field as={Input} name="name" type="text" placeholder="The Domain" />
                          </div>
                        </label>
                      </div>
                      <div>
                        <PrimaryButton type="submit" disabled={!isValid || mutation.isLoading}>
                          Create
                        </PrimaryButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <Table>
            <Thead>
              <Th>Name</Th>
            </Thead>
            <Tbody>
              {query.isLoading ? (
                <Tr>
                  <Td>{t('loadingEllipsis')}</Td>
                </Tr>
              ) : null}
              {query.isSuccess && query.data
                ? query.data.map((domain) => (
                    <Tr key={domain.id}>
                      <TdPrimary to={`/domain/${domain.id}/admins`}>
                        {domain.name}
                        <br />
                        <span className="leading-none font-normal text-xs text-gray-500">{domain.id}</span>
                      </TdPrimary>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </div>
      </div>
    </OutsideAccountLayout>
  );
};

export default DomainsPage;
