import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission } from '../../lib/types';
import { PillLink, PillNav } from './Pill';

const TeamsNav = () => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  return (
    <PillNav>
      {[
        { name: t('all'), href: '/teams', exact: true },
        { name: t('leaderboard'), href: '/teams/leaderboard', can: permChecker.hasAccountPermission(Permission.ReadLeaderboard) },
      ]
        .filter((nav) => _.isUndefined(nav.can) || nav.can)
        .map((tab) => (
          <PillLink key={tab.href} to={tab.href} exact={tab.exact}>
            {tab.name}
          </PillLink>
        ))}
    </PillNav>
  );
};

export default TeamsNav;
