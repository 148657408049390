const DoubleCoinIcon = (props: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="18" cy="8" r="7" fill="#FFC566" stroke="#DBA03E" />
      <path d="M18 6V10" stroke="#DFA53A" strokeLinecap="square" />
      <circle cx="8" cy="8" r="7" fill="#FFC566" stroke="#DBA03E" />
      <path d="M8 6V10" stroke="#DFA53A" strokeLinecap="square" />
    </svg>
  );
};

export default DoubleCoinIcon;
