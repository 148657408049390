import { isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { hasPolicy, useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { Permission } from '../../lib/types';
import { PillLink, PillNav } from './Pill';

const AccountNav = () => {
  const { t } = useTranslation();
  const permChecker = usePermissionChecker();
  const account = useAccount();
  return (
    <PillNav>
      {[
        {
          name: t('branding'),
          href: '/account/branding',
          can: hasPolicy(account, 'use_branding') && permChecker.hasAccountPermission(Permission.ManageBranding),
          exact: false,
        },
        { name: t('contact'), href: '/account/contact', can: permChecker.hasAccountPermission(Permission.UpdateAccount) },
        { name: t('information'), href: '/account/information', can: permChecker.hasAccountPermission(Permission.UpdateAccount) },
        {
          name: t('developer'),
          href: '/account/developer',
          can: hasPolicy(account, 'use_api') && permChecker.hasAccountPermission(Permission.ManageApi),
        },
        {
          name: t('settings'),
          href: '/account/settings',
          can: permChecker.hasAccountPermission(Permission.ManageApi),
        },
      ]
        .filter((nav) => isUndefined(nav.can) || nav.can)
        .map((tab) => (
          <PillLink key={tab.href} to={tab.href} exact={tab.exact}>
            {tab.name}
          </PillLink>
        ))}
    </PillNav>
  );
};

export default AccountNav;
