import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AxiosContext } from '../../lib/axios';
import FullpageLayout from '../layouts/Fullpage';
import Logo from '../Logo';

const useInvitationValidityQuery = (id: string, secret: string) => {
  const axios = useContext(AxiosContext);
  return useQuery(
    ['invitation', id],
    async () => {
      return (await axios.get(`/_/admin-api/public/invitations/${id}/validity`, { params: { secret } })).status === 204;
    },
    { retry: false, cacheTime: 0, staleTime: 0 }
  );
};

const InvitationLoggedOutPage: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ invitationId: string; secret: string }>();
  const { isLoading, data: isValid } = useInvitationValidityQuery(params.invitationId, params.secret);

  return (
    <FullpageLayout>
      <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow text-center">
        <div>
          <div className="mb-6">
            <div className="mb-6 flex justify-center items-center">
              <Logo />
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          <div className="mt-6">
            {isValid ? (
              <>
                <p>{t('toAcceptInvitationSignInOrUp')}</p>

                <div className="mt-6 space-y-4">
                  <p>
                    <Link to="/login" className="text-blue-600">
                      {t('signup.signInToAccount')}
                    </Link>
                  </p>
                  <p>
                    <Link to="/signup" className="text-blue-600">
                      {t('signup.createAccount')}
                    </Link>
                  </p>
                </div>
              </>
            ) : (
              <p>{t('invitationInvalidOrExpired')}</p>
            )}
          </div>
        )}
      </div>
    </FullpageLayout>
  );
};

export default InvitationLoggedOutPage;
