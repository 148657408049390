import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { AuthContext, useUser } from '../../lib/auth';
import { availableLanguages, getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import { PageHeader } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { NotificationError } from '../Notifications';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const { refreshUser } = useContext(AuthContext);
  const user = useUser();
  const repo = useRepo();
  const mutation = useMutation((data: { firstname: string; lastname: string }) => repo.updateMe(data));

  const languageOptions: DropdownSelectOption[] = availableLanguages
    .sort((l1, l2) => (l1.name < l2.name ? -1 : 1))
    .map((lang) => {
      return { value: lang.code, label: lang.name };
    }, []);

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col flex-grow">
        <PageHeader>{user.name}</PageHeader>
        <Formik
          initialValues={{
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            email: user.email || '',
            lang: user.lang || '',
          }}
          onSubmit={(data, formik) => {
            mutation.mutate(data, {
              onSettled: (...args) => {
                formik.setSubmitting(false);
              },
              onSuccess: () => {
                formik.resetForm({ values: data });
                i18n.changeLanguage(data.lang);
                refreshUser();
              },
            });
          }}
          validationSchema={yup
            .object({
              firstname: yup.string().required(),
              lastname: yup.string().required(),
            })
            .required()}
        >
          {(form) => {
            const canSubmit = form.dirty && form.isValid && !form.isSubmitting;
            const selectedLanguage = languageOptions.find((lang) => lang.value === form.values.lang);
            return (
              <Form className="flex flex-col flex-grow">
                {mutation.isError ? (
                  <div className="mb-4">
                    <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
                  </div>
                ) : null}
                <div className="flex-grow space-y-6">
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('firstName')}</div>
                      <div>
                        <Field as={Input} name="firstname" />
                      </div>
                    </label>
                  </div>
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('lastName')}</div>
                      <div>
                        <Field as={Input} name="lastname" />
                      </div>
                    </label>
                  </div>
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                      <div>
                        <Field as={Input} name="email" type="email" disabled />
                      </div>
                    </label>
                  </div>
                  <div className="w-96">
                    <label>
                      <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('language')}</div>
                      <div>
                        <DropdownSelect
                          placeholder=""
                          selected={selectedLanguage}
                          options={languageOptions}
                          onChange={(e) => form.handleChange('lang')(e.value)}
                        ></DropdownSelect>
                      </div>
                    </label>
                  </div>
                </div>

                <div className="w-full border-t border-gray-100 mt-8 pt-4 flex">
                  <div className="flex-grow" />
                  <div className="flex flex-row-reverse items-end">
                    <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                      {t('save')}
                    </PrimaryButton>
                    <Button onClick={() => form.resetForm()}>{t('discard')}</Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </LayoutWithSidebar>
  );
};

export default ProfilePage;
