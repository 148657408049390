import md5 from 'blueimp-md5';
import { useUser } from '../lib/auth';
import { classNames } from '../lib/utils';

const PlaceholderAvatar: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames('inline-block rounded-full overflow-hidden bg-gray-100', className)}>
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </div>
  );
};

const PictureAvatar: React.FC<{ src: string; className?: string; alt?: string }> = ({ src, className, alt }) => {
  return <img className={classNames('inline-block rounded-full', className)} src={src} alt={alt || ''} />;
};

export const PlayerAvatar: React.FC<{
  src?: string;
  name?: string;
  // size: "2xl"
}> = ({ src, name }) => {
  const className = 'h-20 w-20';
  if (!src) {
    return <PlaceholderAvatar className={className} />;
  }
  return <PictureAvatar src={src} className={className} alt={name} />;
};

export const UserAvatar: React.FC<{ className?: string }> = ({ className }) => {
  const user = useUser();
  return (
    <PictureAvatar
      src={`https://www.gravatar.com/avatar/${md5(user.email.toLowerCase())}?d=mp`}
      alt={user.name}
      className={className}
    />
  );
};
