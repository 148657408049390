import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { AccountContext, useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { Account } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import { ToggleInForm } from '../inputs/Toggle';
import AccountSettingsPageLayout from '../layouts/AccountSettingsPage';
import { NotificationError } from '../Notifications';
import { broadcastSuccessToast } from '../Toasts';

const schema = yup
  .object({
    contact_replyto: yup.string().email().min(1), // When provided, must be valid.
    contact_signature: yup.string().min(1), // When provided, must be valid.
    contact_player_disabled: yup.boolean(),
  })
  .required();

const AccountContactPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { patchAccount } = useContext(AccountContext);
  const account = useAccount();
  const repo = useRepo();
  const mutation = useMutation((data: Partial<Account>) => repo.updateAccount(account.id, data));

  const initialValues = {
    contact_replyto: account.contact_replyto,
    contact_signature: account.contact_signature,
    contact_player_disabled: account.contact_player_disabled,
  };
  const formik = useFormik<{ contact_replyto?: string; contact_signature?: string; contact_player_disabled?: boolean }>({
    initialValues,
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: (values, form) => {
      mutation.mutate(values, {
        onSuccess: (data) => {
          patchAccount(data, true);
          broadcastSuccessToast(t('settingsSaved'));
        },
        onError: () => {
          form.resetForm();
          form.setSubmitting(false);
        },
      });
    },
  });

  // When the account changes, reset the form.
  useEffect(() => {
    formik.resetForm({ values: initialValues });
    formik.setSubmitting(false);
  }, [account]); // eslint-disable-line

  const canSubmit = formik.dirty && formik.isValid && !formik.isSubmitting;

  return (
    <AccountSettingsPageLayout>
      <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
        <div className="flex-grow">
          {mutation.isError ? (
            <div className="mb-4">
              <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
            </div>
          ) : null}
          <div className="border-b-0 border-gray-200 mb-3 pb-3">
            <h2 className="text-lg font-medium mb-4">{t('contactInformation')}</h2>
            <p>{t('contactInformationHelp')}</p>
            <div className="mt-4 flex space-x-6">
              <div className="w-96">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('replyEmail')}</div>
                  <div>
                    <Input
                      value={formik.values.contact_replyto}
                      name="contact_replyto"
                      maxLength={255}
                      type="email"
                      onChange={formik.handleChange}
                    />
                  </div>
                </label>
              </div>
              <div className="w-96">
                <label>
                  <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('emailSignature')}</div>
                  <div>
                    <Input
                      value={formik.values.contact_signature}
                      name="contact_signature"
                      maxLength={255}
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <ToggleInForm
              enabled={formik.values.contact_player_disabled}
              onChange={(enabled) => formik.setFieldValue('contact_player_disabled', enabled)}
              label={t('disableOutgoingCommsToPlayers')}
            />
          </div>
        </div>

        <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
          <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
            {t('save')}
          </PrimaryButton>
          <Button onClick={() => formik.resetForm()}>{t('discard')}</Button>
        </div>
      </form>
    </AccountSettingsPageLayout>
  );
};

export default AccountContactPage;
