import { getCodeList } from 'country-list';

let allCountriesSortedByName: { [index: string]: string };

export function getCountryList() {
  if (typeof allCountriesSortedByName === 'undefined') {
    const allCountries = getCodeList();
    allCountriesSortedByName = Object.keys(allCountries)
      .map((code) => {
        return [code, allCountries[code]];
      })
      .sort((c1, c2) => {
        return c1[1].localeCompare(c2[1]);
      })
      .reduce<{ [index: string]: string }>((carry, c) => ({ ...carry, [c[0]]: c[1] }), {});
  }
  return allCountriesSortedByName;
}
