import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { usePasswordResetRequestMutation } from '../../lib/auth';
import { PrimaryButton } from '../Buttons';
import Input from '../inputs/Input';
import FullpageLayout from '../layouts/Fullpage';
import Logo from '../Logo';
import Notification, { NotificationError } from '../Notifications';
import Recaptcha, { useRecaptchaRef } from '../widgets/Recaptcha';

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const captchaRef = useRecaptchaRef();
  const mutation = usePasswordResetRequestMutation();

  const handleSubmit = async (values: any) => {
    const token = await captchaRef.current?.executeAsync();
    return mutation.mutateAsync({ ...values, captchaToken: token });
  };

  return (
    <FullpageLayout>
      <div className="flex flex-col bg-white w-full max-w-[543px] p-14 shadow text-center">
        <div>
          <div className="mb-6">
            <div className="mb-6 flex justify-center items-center">
              <Logo />
            </div>
            <div className="text-2xl leading-8 text-gray-600 font-medium">{t('signup.forgotYourPassword')}</div>
          </div>

          <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit} validationSchema={schema} validateOnMount>
            {({ isSubmitting, isValid }) => {
              const canSubmit = !isSubmitting && isValid && captchaRef.current;

              return (
                <>
                  {mutation.isError ? (
                    <div className="mb-4">
                      <NotificationError>{t('signup.errorSendingResetPassword')}</NotificationError>
                    </div>
                  ) : null}

                  {mutation.isSuccess ? (
                    <div className="mb-4">
                      <Notification>{t('signup.passwordResetLinkSent')}</Notification>
                    </div>
                  ) : null}

                  <Form className="flex flex-col flex-grow text-left">
                    <Recaptcha recaptchaRef={captchaRef} />

                    <p className="font-normal text-sm my-4">{t('signup.enterEmailToGetResetEmail')}</p>

                    <div className="flex-grow space-y-4">
                      <div className="">
                        <label>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('email')}</div>
                          <div>
                            <Field as={Input} name="email" disabled={mutation.isLoading || mutation.isSuccess} />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="mt-6 flex flex-col">
                      <PrimaryButton large submit disabled={!canSubmit}>
                        {t('signup.resetPassword')}
                      </PrimaryButton>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>

        <div className="mt-6">
          <Link to="/login" className="text-blue-600">
            {t('signup.signInToAccount')}
          </Link>
        </div>
      </div>
    </FullpageLayout>
  );
};

export default ForgotPasswordPage;
