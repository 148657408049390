import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AccountBillingGate, { StripeCheckoutCallbackPage } from './components/AccountBillingGate';
import AccountManager from './components/AccountNegotiator';
import AuthenticationManager from './components/AuthManager';
import { ToastContainer } from './components/Toasts';
import AccountBrandingPage from './components/pages/AccountBranding';
import AccountContactPage from './components/pages/AccountContact';
import AccountDeveloperPage from './components/pages/AccountDeveloper';
import AccountInformationPage from './components/pages/AccountInformation';
import AccountSettingsPage from './components/pages/AccountSettings';
import AppAuthorisePage from './components/pages/AppAuthorisePage';
import DomainAdminsPage from './components/pages/DomainAdmins';
import DomainsPage from './components/pages/Domains';
import InvitationConfirmPage from './components/pages/InvitationConfirm';
import ItemPage from './components/pages/Item';
import ItemEditPage from './components/pages/ItemEdit';
import LeaderboardPage from './components/pages/Leaderboard';
import LevelsPage from './components/pages/Levels';
import LogoutPage from './components/pages/Logout';
import MemberPage from './components/pages/Member';
import MemberInvitePage from './components/pages/MemberInvite';
import MembersPage from './components/pages/Members';
import PlayerPage from './components/pages/Player';
import PlayersPage from './components/pages/Players';
import PlayersOrdersPage from './components/pages/PlayersOrders';
import PlayersPurgePage from './components/pages/PlayersPurge';
import ProductPage from './components/pages/Product';
import ProductEditPage from './components/pages/ProductEdit';
import ProfilePage from './components/pages/Profile';
import StoreAddItemPage from './components/pages/StoreAddItem';
import StoreAddTeamsPage from './components/pages/StoreAddTeams';
import StoreCreatePage from './components/pages/StoreCreate';
import StoreItemsPage from './components/pages/StoreItems';
import StoreSettingsPage from './components/pages/StoreSettings';
import StoreTeamsPage from './components/pages/StoreTeams';
import StoreTransactionsPage from './components/pages/StoreTransactions';
import StoresPage from './components/pages/Stores';
import TeamAddPlayerPage from './components/pages/TeamAddPlayer';
import TeamAddStoresPage from './components/pages/TeamAddStores';
import TeamCoinsImportPage from './components/pages/TeamCoinsImport';
import TeamCreatePage from './components/pages/TeamCreate';
import TeamLeaderboardPage from './components/pages/TeamLeaderboard';
import TeamMarketLayoutPage from './components/pages/TeamMarketLayout';
import TeamPlayersPage from './components/pages/TeamPlayers';
import TeamSettingsPage from './components/pages/TeamSettings';
import TeamStoresPage from './components/pages/TeamStores';
import TeamTransactionsPage from './components/pages/TeamTransactions';
import TeamsPage from './components/pages/Teams';
import TransactionsPage from './components/pages/Transactions';
import ScrollTopOnRouteChange from './components/utils/ScrollTopOnRouteChange';
import { AxiosContext, makeBaseInstance } from './lib/axios';
import OrdersPage from './components/pages/Orders';
import ReportsOverviewPage from './components/pages/ReportsOverview';
import ReportsDetailedPage from './components/pages/ReportsDetailed';

// TODO Invalidate cache when logging out.
// TODO Invalidate cache when switching accounts.
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 1000 * 10, cacheTime: 1000 * 60 * 15, refetchOnWindowFocus: false },
  },
});

const axiosClient = makeBaseInstance();

function App() {
  return (
    <React.Suspense fallback="Loading...">
      <AxiosContext.Provider value={axiosClient}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthenticationManager>
              <Switch>
                <Route path="/invitation/:invitationId/:secret" component={InvitationConfirmPage} />
                <Route path="/plans/stripe/checkout/callback" component={StripeCheckoutCallbackPage} />
                <Route path="/domain/:domainId/admins" component={DomainAdminsPage} />
                <Route path="/domains" component={DomainsPage} />
                <Route path="/logout" component={LogoutPage} />
                <Route>
                  <AccountManager>
                    <AccountBillingGate>
                      <Switch>
                        <Route path="/profile" exact component={ProfilePage} />
                        <Route path="/account/branding" component={AccountBrandingPage} />
                        <Route path="/account/contact" component={AccountContactPage} />
                        <Route path="/account/developer" component={AccountDeveloperPage} />
                        <Route path="/account/information" component={AccountInformationPage} />
                        <Route path="/account/settings" component={AccountSettingsPage} />
                        <Route path="/app/authorize" component={AppAuthorisePage} />
                        <Route path="/item/:itemId" component={ItemPage} exact />
                        <Route path="/item/:itemId/edit" component={ItemEditPage} />
                        <Route path="/levels" component={LevelsPage} />
                        <Route path="/member/:memberId" component={MemberPage} />
                        <Route path="/members" component={MembersPage} exact />
                        <Route path="/members/invite" component={MemberInvitePage} />
                        <Route path="/orders" component={OrdersPage} />
                        <Route path="/player/:playerId" component={PlayerPage} />
                        <Route path="/players" component={PlayersPage} exact />
                        <Route path="/players/purge" component={PlayersPurgePage} />
                        <Route path="/players/orders" component={PlayersOrdersPage} />
                        <Route path="/product/:productId" component={ProductPage} exact />
                        <Route path="/product/:productId/edit" component={ProductEditPage} />
                        <Route path="/reports/overview" component={ReportsOverviewPage} exact />
                        <Route path="/reports/detailed" component={ReportsDetailedPage} exact />
                        <Route path="/store/:storeId" component={StoreItemsPage} exact />
                        <Route path="/store/:storeId/add-item" component={StoreAddItemPage} />
                        <Route path="/store/:storeId/add-team" component={StoreAddTeamsPage} />
                        <Route path="/store/:storeId/teams" component={StoreTeamsPage} />
                        <Route path="/store/:storeId/settings" component={StoreSettingsPage} />
                        <Route path="/store/:storeId/transactions" component={StoreTransactionsPage} />
                        <Route path="/stores/create" component={StoreCreatePage} />
                        <Route path="/stores" component={StoresPage} />
                        <Route path="/team/:teamId" component={TeamPlayersPage} exact />
                        <Route path="/team/:teamId/add-player" component={TeamAddPlayerPage} />
                        <Route path="/team/:teamId/add-store" component={TeamAddStoresPage} />
                        <Route path="/team/:teamId/import" component={TeamCoinsImportPage} />
                        <Route path="/team/:teamId/leaderboard" component={TeamLeaderboardPage} />
                        <Route path="/team/:teamId/market" exact component={TeamStoresPage} />
                        <Route path="/team/:teamId/market/layout" component={TeamMarketLayoutPage} />
                        <Route path="/team/:teamId/settings" component={TeamSettingsPage} />
                        <Route path="/team/:teamId/transactions" component={TeamTransactionsPage} />
                        {/** TODO Remove pages that cannot be accessed as per permissions. */}
                        <Route path="/teams/create" component={TeamCreatePage} />
                        <Route path="/teams/leaderboard" component={LeaderboardPage} />
                        <Route path="/teams" component={TeamsPage} />
                        <Route path="/transactions" component={TransactionsPage} />
                        <Route>
                          <Redirect to="/players" />
                        </Route>
                      </Switch>
                    </AccountBillingGate>
                  </AccountManager>
                </Route>
              </Switch>
            </AuthenticationManager>
            <ScrollTopOnRouteChange />
          </Router>
        </QueryClientProvider>
      </AxiosContext.Provider>
      <ToastContainer />
    </React.Suspense>
  );
}

export default App;
