export type Dict = { [index: string]: any };
export type ItemSchedule = 'active' | 'expired' | 'scheduled' | 'any';

export type Account = {
  id: string;
  name: string;
  apps?: string[];
  billing_plan?: string; // Our plan ID.
  contact_replyto?: string;
  contact_signature?: string;
  contact_player_disabled?: boolean;
  cur_users: number;
  domain_id: string;
  expiry: number;
  expiry_strict?: boolean; // From doc v10.
  has_poweredby?: boolean;
  levels?: number[];
  levels_badge?: { [index: number]: string }; // API enhances to absolute URLs.
  levels_updated_on?: number;
  locales?: string[];
  logo_url?: string | null;
  max_users: number;
  payment_type: AccountPaymentType;
  platform?: string;
  points_icon_double_url?: string | null;
  policy: AccountPolicy;
  policy_overrides?: AccountPolicyOverrides;
  redemption_methods?: RedemptionMethod[];
  state: AccountState;
  svsleaderboard?: {
    anonymous: boolean;
    enabled: boolean;
    version?: number;
    version_date?: number;
  };
  team_count: number;
  tickets_auto_earn?: number;
  tickets_enabled?: boolean;
  tickets_icon_url?: string | null;
};

export enum AccountRole {
  Owner = 'owner',
  Admin = 'admin',
  Auditor = 'auditor',

  // Roles requiring stores or teams.
  Manager = 'manager',
  TeamCaptain = 'team-captain',
  StoreClerk = 'store-clerk',
}

export enum AccountPaymentType {
  Annual = 'annual',
  Monthly = 'monthly',
  Subscription = 'subscription',
  Subv2 = 'subv2',
  Trial = 'trial',
}

export type AccountMember = {
  id: string;
  account_id: string;
  user_id: string;
  role: AccountRole;
  store_ids?: string[];
  team_ids?: string[];

  // Enhanced by the API.
  user: {
    id: string;
    name: string;
    email: string;
  };
};

// As defined by the API, not the backend.
export type AccountMembership = {
  role: AccountRole;
  assignable_roles: AccountRole[];
  permissions: AccountPermissions;
};

export type AccountPermissions = {
  account: Permission[];
  teams: { [index: string]: Permission[] };
  stores: { [index: string]: Permission[] };
};

export type AccountPolicy = {
  max_dashboard_users: number; // The number of admins/account members.
  max_items_per_store: number; // The maximum number of items per store.
  max_items: number; // The total number of store items.
  max_sections: number; // The maximum number of teams/sections.
  max_stores: number; // The maximum number of stores.
  max_users: number; // The maximum number of users/players.
  use_api: boolean; // Whether the account can consume the API itself.
  use_branding: boolean; // Whether the account can use branding features.
  use_item_variants: boolean; // Whether variants can be used.
  use_l10n: boolean; // Whether items can be localised.
  use_leaderboard: boolean; // Whether the leaderobard can be used.
  use_reporting: boolean; // Whether the account can access the reporting.
  use_self_billing: boolean; // Whether the account can self-manage its billing, to some extent.
  use_shipping_integration: boolean; // Whether
  use_tickets: boolean; // Whether they can use the tickets.
  use_tickets_cost: boolean; // Whether they can set the cost of tickets.
  use_voucher_method: boolean; // Whether they can use voucher as order method.

  // Legacy or platform-specific features.
  use_moodle: boolean;
  use_course_overrides: boolean; // Whether rules can be set per course.
  use_refer_a_friend: boolean;
  use_store_iframe: boolean; // Whether the account can use the store as an iframe.
};

export type AccountPolicyOverrides = {
  max_dashboard_users?: number;
  max_items_per_store?: number;
  max_items?: number;
  max_sections?: number;
  max_stores?: number;
  max_users?: number;
  use_api?: boolean;
  use_branding?: boolean;
  use_l10n?: boolean;
  use_reporting?: boolean;
  use_shipping_integration?: boolean;
  use_tickets?: boolean;
  use_tickets_cost?: boolean;
  use_voucher_method?: boolean;

  // Legacy or platform-specific features.
  use_course_overrides?: boolean;
  use_refer_a_friend?: boolean;
  use_store_iframe?: boolean;
};

export enum AccountState {
  Active = 'active',
  Irregular = 'irregular',
  Suspended = 'suspended',
}

export type AccountStoreInformation = {
  info: {
    type: string;
    content: string;
  };
  rules?: { id: number; coins: number; label: string }[];
  l10n?: { locale: string; content?: string; rules?: { id: number; label?: string }[] }[];
};

export enum AccountSupportType {
  Email = 'email',
  Call = 'call',
  Tickets = 'tickets',
  AccountManager = 'account_manager',
}

export type DashboardUser = {
  id: string;
  account_ids: string[];
  school_ids: string[];
  name: string;
  first_name?: string;
  last_name?: string;
  email: string;
  user_role: AdminRole;
};

export enum AdminRole {
  Custom = 'custom',
  Admin = 'admin',
  AccountAdmin = 'account_admin',
  SectionAdmin = 'school_admin',
}

export type Domain = {
  id: string;
  name: string;
};

export type Job = {
  id: string;
  state: JobState;
  created_on: number; // Unix timestamp.
  started_on: null;
  completed_on: null;
  params: { [index: string]: any };
  result: { [index: string]: any };
};

export enum JobState {
  Idle = 'idle',
  Started = 'started',
  Complete = 'complete',
  Failed = 'failed',
}

export type Item = ItemPurchase | ItemAuction | ItemRaffle | ItemContribution | ItemSweepstakes;

type ItemBase = {
  id: string;
  doc_type: 'store_item';
  version: number; // The item internal version indicator.
  account_id: string;
  store_id: string; // This value was introduced with item doc version 3.

  type: ItemType;
  start_time: number;
  end_time: number;

  cost: number;
  name: string;
  description: string;
  sku?: string;
  image_url: string;
  thumbnail_url?: string; // Newer property to contain the reference to the blob thumbnail.
  redemption_method: RedemptionMethod;
  num_purchased: number; // The number of times this was purchased.
  num_items: number; // The overall quantity, or 0 for unlimited.

  // Self redemption specific.
  self_redeem_message?: string;

  // Download redemption specific.
  download_filename?: string;
  download_url?: string; // Typically is [container]/[blobname.ext].

  // When there are localisations for this item.
  l10n?: L10nList;
};

type ItemPurchaseShared = ItemBase & {
  type: ItemType.Purchase;
};

export type ItemPurchase = ItemPurchaseStandalone | ItemPurchaseVariant;

export type ItemPurchaseStandalone = ItemPurchaseShared & {
  product_id?: undefined;
};

export type ItemPurchaseVariant = ItemPurchaseShared & {
  product_id: string;
  product_name: string;
  product_variant: string;
  product_variant_order?: number;
};

export type ItemAuction = ItemBase & {
  type: ItemType.Auction;

  // Auction specific.
  auction_state: ItemAuctionState; // Note that this is always defined on an auction item.
  auction_bid?: number;
  auction_bidder?: string;
  auction_bid_count?: number;
  auction_bids?: { user_id: string; bids: number[] }[];
  handling_fee: number; // Is 0 or more.
  minimum_bid: number; // Is 1 or more.
};

export enum ItemAuctionState {
  Idle = 'idle',
  Finalized = 'finalized',
}

export type ItemContribution = ItemBase & {
  type: ItemType.Contribution;

  // Contribution specific.
  contrib_coins: number; // The number of coins contributed.
  contrib_currency_symbol: string; // The currency symbol.
  contrib_currency_value: number; // The currency value per coin.
  contrib_goal: number; // The goal for the contribution.
  contrib_max: number; // The maximum number of coins that can be contributed.
};

export type ItemRaffle = ItemRaffleV1 | ItemRaffleV2;

type ItemRaffleShared = ItemBase & {
  type: ItemType.Raffle;
  raffle_status: ItemRaffleStatus; // Note that this is always defined on a raffle item.
  draw_method?: ItemDrawMethod; // Defaults to automatic when undefined.

  // After a draw.
  raffle_draw?: {
    date: number;
    winners: string[];
  };
};

type ItemRaffleV1 = ItemRaffleShared & {
  version: 1 | 0 | undefined;
  raffle_entries?: {
    user_id: string;
    num_entries: number;
  }[];
};

export type ItemRaffleV2 = ItemRaffleShared & {
  version: 2 | 3;
  raffle_participants?: number; // Number of participants.
  raffle_tickets_sold?: number; // Number of tickets sold.
};

export enum ItemRaffleStatus {
  Open = 'open',
  Closed = 'closed',
}

export type ItemSweepstakes = ItemBase & {
  type: ItemType.Sweepstakes;
  drawn: boolean;
  draw_method: ItemDrawMethod;
  num_participants: number; // Number of participants.
  // Number of tickets sold contained in `num_purchased`.

  // During and after manual draws.
  draw_candidates?: string[];
  draw_excluded?: string[];

  // After a draw.
  drawn_on?: number;
  draw_winners?: string[];
};

export enum ItemDrawMethod {
  Auto = 'auto',
  Manual = 'manual',
}

export enum ItemType {
  Auction = 'auction',
  Contribution = 'contribution',
  Purchase = 'purchase',
  Raffle = 'raffle',
  Sweepstakes = 'sweepstakes',
}

// A voucher used in redemption of item.
export type ItemVoucher = {
  id: number;
  code: string;
  redeemed_by?: string;
  redeemed_on?: number;
};

// The definition of the l10n property on documents that can be localised.
export type L10nList = { locale: string; [index: string]: string | number }[];

export type Order = {
  id: string;
  account_id: string;
  school_id: string;
  store_id: string;
  user_id: string;
  purchase_id: string;
  state: OrderState;
  state_changed_on?: number;
  secret: string;
  type: string;
  data?: { [index: string]: any };
  item: OrderItem;
  user: OrderUser;
};

// Sub-document of Order.
export type OrderItem = {
  id: string;
  name: string;
  image_url: string;
  sku?: string;
  download_url?: string;
  download_filename?: string;
};

export enum OrderState {
  Idle = 'idle',
  Pending = 'pending',
  Complete = 'complete',
}

// Sub-document of Order.
export type OrderUser = {
  id: string;
  firstname: string;
  lastname: string;
};

export type Plan = {
  id: string;
  name: string;
  yearly_cost?: number;
  yearly_cost_per_month?: number;
  monthly_cost?: number;
  price_id?: string;
  price_id_annual?: string;
  support: AccountSupportType[];
  policy: AccountPolicy;
};

export type Player = {
  id: string;
  account_id: string;
  school_id: string;
  firstname: string;
  lastname: string;
  email?: string;
  coins: number;
  coins_earned_lifetime?: number; // Did not always exist.
  store_ids_interacted_with?: string[];
  tickets?: number;

  last_activity?: number;

  // Shipping information.
  shipping?: {
    location_name?: string;
    location_id?: string;
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
    postcode?: string;
    state?: string;
    country?: string;
  };

  // Legacy.
  username: string; // The Moodle username, if any.
  plugin_id?: string;
};

export enum Permission {
  UpdateAccount = 'update-account',
  DeleteAccount = 'delete-account',

  ManageApi = 'manage-api',
  ManageApp = 'manage-app',
  ManageBilling = 'manage-billing',
  ManageBranding = 'manage-branding',

  ReadMember = 'read-member',
  ManageMember = 'manage-member',

  ReadLeaderboard = 'read-leaderboard',
  ManageLeaderboard = 'manage-leaderboard',

  ReadLevels = 'read-levels',
  ManageLevels = 'manage-levels',

  ReadOrder = 'read-order',
  ManagerOrder = 'manage-order',

  ReadTeam = 'read-team',
  CreateTeam = 'create-team',
  UpdateTeam = 'update-team',
  DeleteTeam = 'delete-team',
  ReadTeamStore = 'read-team-store',
  ManageTeamStore = 'manage-team-store',

  ReadPlayer = 'read-player',
  CreatePlayer = 'create-player',
  UpdatePlayer = 'update-player',
  DeletePlayer = 'delete-player',
  ImpersonatePlayer = 'impersonate-player',
  AwardCoin = 'award-coin',
  PurgeInactivePlayers = 'purge-inactive-players',
  SetPlayerTeam = 'set-player-team',

  ReadTransaction = 'read-transaction',
  ReadPurchase = 'read-purchase',

  ReadStore = 'read-store',
  CreateStore = 'create-store',
  UpdateStore = 'update-store',
  DeleteStore = 'delete-store',
  ReadStoreTeam = 'read-store-team',
  ManageStoreTeam = 'manage-store-team',
  ManageStoreL10n = 'manage-store-l18n',
  ManageStoreShippingExport = 'manage-store-shipping-export',

  ReadItem = 'read-item',
  CreateItem = 'create-item',
  UpdateItem = 'update-item',
  DeleteItem = 'delete-item',
  ManageItemVouchers = 'manage-item-vouchers',

  DrawWinner = 'draw-winner',
  ReadReport = 'read-report',

  // Legacy.
  create_account = 'create_account',
  view_accounts = 'view_accounts',
  set_user_permission = 'set_user_permission',
  create_school = 'create_school',
  view_schools = 'view_schools',
  create_dashboard_user = 'create_dashboard_user',
  view_dashboard_users = 'view_dashboard_users',
  create_store_item = 'create_store_item',
  redeem_store_item = 'redeem_store_item',
  add_coins = 'add_coins',
}

export type Product = {
  id: string;
  doc_type: 'product';
  account_id: string;
  store_id: string; // This value was introduced with item doc version 3.

  name: string;
  description: string;
  cost: number;
  start_time: number;
  end_time: number;

  image_url: string;
  created_on: number;

  redemption_method: RedemptionMethod;

  // Variants.
  variants: {
    item_id: string | null;
    label: string;
    sku: string | null;
    num_items: number;
    l10n?: L10nList;
  }[];

  // Self redemption specific.
  self_redeem_message?: string;

  // When there are localisations for this item.
  l10n?: L10nList;
};

export type RaffleEntry = {
  id: string; // Unique, and formatted as [itemId]-raffle-entry-[userId].
  account_id: string;
  school_id: string; // Can point to non-existent store, in particular after migration from legacy.
  store_id?: string; // Added late August 2023.
  user_id: string;
  item_id: string; // ID of the item (raffle, or sweepstakes).
  tickets: number;

  // API enhanced.
  last_modified: number;
};

export enum RedemptionMethod {
  None = '_none',
  Self = '_self',
  Request = '_request',
  Download = '_download',
  Shipping = '_shipping',
  Voucher = '_voucher',
}

export type ReportOverview = {
  active_players: number;
  new_players: number;
  spending_players: number;
  player_referrals: number;
  courses_completed: number;
  activities_completed: number;
  coins_earned: number;
  coins_spent: number;
  items_acquired: number;
  orders_completed: number;
  coins_contributed: number;
  contributions_participants: number;
  tickets_earned: number;
  tickets_spent: number;
  sweepstakes_participants: number;
};

export type Store = {
  id: string;
  account_id: string;
  name: string;
  item_count: number;
  team_ids: string[];
  locales?: string[];

  redemptions_shipping_allow_edit: boolean;
  redemptions_shipping_export_recipients?: string;
  redemptions_shipping_required_fields: string[];

  support_email?: string;

  // API enhanced.
  teams: { id: string; name: string }[];
};

export type Team = {
  id: string;
  account_id: string;
  name: string;
  coins_earned_lifetime?: number; // Did not always exist.
  leaderboard?: {
    anonymous: boolean;
    enabled: boolean;
    version?: number; // The version of the leaderboard.
    version_date?: number; // The date at which the version was created.
  };
  player_count: number;
  tickets_enabled: boolean;

  // API enhanced, sometimes.
  stores: { id: string; name: string }[];
};

export type TeamMarket = {
  items: (Item | Product)[];
  categories: TeamMarketCategory[];
};

export type TeamMarketCategory = TeamMarketCategoryItems | TeamMarketCategoryOverflow;

type TeamMarketCategoryBase = {
  id: string;
  name: string;
};

export type TeamMarketCategoryItems = TeamMarketCategoryBase & {
  type: 'items';
  items: string[];
};

export type TeamMarketCategoryOverflow = TeamMarketCategoryBase & {
  type: 'overflow';
};

export enum TransactionType {
  ItemRedeem = 'ItemRedeem',
  ItemPurchase = 'ItemPurchase',
  ItemSelfRedeem = 'ItemSelfRedeem',
  CoinsGained = 'CoinsGained',
  CoinsExpired = 'CoinsExpired',
  BidPlaced = 'BidPlaced',
  BidWon = 'BidWon',
  ContributionMade = 'ContributionMade',
  RaffleTicketPurchased = 'RaffleTicketPurchased',
  RaffleWon = 'RaffleWon',
  SweepstakesTicketPurchased = 'SweepstakesTicketPurchased',
  SweepstakesWon = 'SweepstakesWon',
  TicketsGained = 'TicketsGained',
}

export interface TransactionReason {
  type: string;
  data?: any;
}

export interface TransactionReasonLang extends TransactionReason {
  type: 'lang';
  data: {
    string: string;
    args?: { [index: string]: string };
  };
}

export type Transaction = {
  id: string;
  type: TransactionType;
  account_id: string;
  school_id: string;
  user_id: string;
  data: { [index: string]: any };
  reason?: TransactionReason | TransactionReasonLang;
  timestamp: number;

  // This is constructed on the fly by the API.
  lang: {
    string: string;
    args: {
      [index: string]: string;
    };
  };
};
