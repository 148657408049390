import { useCallback } from 'react';
import { create } from 'zustand';
import { ItemSchedule, ItemType } from './types';

type Indexed<T> = {
  [index: string]: T;
};

const useStoresItemsFilteringState = create<{
  searchTerm: Indexed<string | undefined>;
  type: Indexed<ItemType | undefined>;
  status: Indexed<ItemSchedule | undefined>;

  reset: () => void;
  setType: (storeId: string, type?: ItemType) => void;
  setStatus: (storeId: string, status?: ItemSchedule) => void;
  setSearchTerm: (storeId: string, term?: string) => void;
}>((set, get) => ({
  searchTerm: {},
  type: {},
  status: {},

  reset: () =>
    set(
      {
        searchTerm: {},
        type: {},
        status: {},
      },
      true
    ),
  setType: (storeId: string, type?: ItemType) =>
    set({
      type: {
        ...get().type,
        [storeId]: type,
      },
    }),
  setStatus: (storeId: string, status?: ItemSchedule) =>
    set({
      status: {
        ...get().status,
        [storeId]: status,
      },
    }),
  setSearchTerm: (storeId: string, searchTerm?: string) =>
    set({
      searchTerm: {
        ...get().searchTerm,
        [storeId]: searchTerm,
      },
    }),
}));

export const useStoreItemsFilteringState = (storeId: string) => {
  const { searchTerm, type, status, setType, setStatus, setSearchTerm } = useStoresItemsFilteringState();

  const setType_ = useCallback((type?: ItemType) => setType(storeId, type), [storeId, setType]);
  const setStatus_ = useCallback((status?: ItemSchedule) => setStatus(storeId, status), [storeId, setStatus]);
  const setSearchTerm_ = useCallback((searchTerm?: string) => setSearchTerm(storeId, searchTerm), [storeId, setSearchTerm]);

  return {
    hasAnyFilters: Boolean(searchTerm?.[storeId] || type?.[storeId] || status?.[storeId]),
    searchTerm: searchTerm?.[storeId],
    type: type?.[storeId],
    status: status?.[storeId],
    setType: setType_,
    setStatus: setStatus_,
    setSearchTerm: setSearchTerm_,
  };
};
