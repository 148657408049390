import { createContext, useContext, useState } from 'react';
import FocusLock from 'react-focus-lock';
import ScrollLock from 'react-scrolllock';
import { PlainButton } from '../Buttons';
import XThinIcon from '../icons/XThin';
import Portal from '../Portal';

type FullscreenProps = {
  isFullscreen: boolean;
  goFullscreen: () => void;
  closeFullscreen: () => void;
};

const FullscreenContext = createContext<FullscreenProps>({
  isFullscreen: true,
  goFullscreen: () => {},
  closeFullscreen: () => {},
});

export const useFullscreen = () => {
  return useContext(FullscreenContext);
};

const FullscreenLayout: React.FC<{ onClose: () => void }> = ({ children, onClose }) => {
  return (
    <Portal>
      <ScrollLock>
        <FocusLock returnFocus={true}>
          <div className="w-full h-full flex flex-col overflow-y-auto fixed inset-0 bg-white z-20 outline-none" tabIndex={0}>
            <div className="absolute right-0 top-0 p-4">
              <PlainButton type="button" onClick={onClose}>
                <XThinIcon className="w-6 h-6 text-gray-800" />
              </PlainButton>
            </div>
            <div className="p-14">{children}</div>
          </div>
        </FocusLock>
      </ScrollLock>
    </Portal>
  );
};

export const Fullscreenable = (props: { children: React.ReactNode | ((props: FullscreenProps) => React.ReactNode) }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const childrenProps = {
    isFullscreen,
    goFullscreen: () => setIsFullscreen(true),
    closeFullscreen: () => setIsFullscreen(false),
  };

  const children = typeof props.children === 'function' ? props.children(childrenProps) : props.children;

  return (
    <FullscreenContext.Provider value={childrenProps}>
      {children}
      {isFullscreen ? <FullscreenLayout onClose={childrenProps.closeFullscreen}>{children}</FullscreenLayout> : null}
    </FullscreenContext.Provider>
  );
};

export default FullscreenLayout;
