import _ from 'lodash';
import { Link, LinkProps, useRouteMatch } from 'react-router-dom';
import { classNames } from '../../lib/utils';

export const PillNav: React.FC = (props) => {
  return <nav className="flex space-x-6">{props.children}</nav>;
};

export const PillLink: React.FC<
  Exclude<LinkProps, 'component'> & { strict?: boolean; exact?: boolean; active?: boolean; badgeCount?: number }
> = ({ exact, strict, active, children, badgeCount = 0, ...props }) => {
  const to = typeof props.to === 'string' ? props.to : '@@@@@@';
  const match = useRouteMatch({ path: to, strict, exact });
  const isActive = _.isUndefined(active) ? Boolean(match) : active;
  const requiresAttention = badgeCount > 0;

  return (
    <Link
      {...props}
      className={classNames(
        isActive ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md relative'
      )}
      aria-current={isActive ? 'page' : undefined}
    >
      {children}
      {requiresAttention ? <div className="bg-red-500 rounded-full w-2 h-2 absolute top-0.5 right-0.5" /> : null}
    </Link>
  );
};
