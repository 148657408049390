import { fromUnixTime, getUnixTime, startOfDay, subDays } from 'date-fns';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useAccount } from '../../lib/account';
import { usePermissionChecker } from '../../lib/hooks';
import { useRepo } from '../../lib/repository';
import { Job, JobState, Permission } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import LayoutWithSidebar from '../layouts/WithSidebar';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';
import { Table, Tbody, Td, Th, Thead, Tr } from '../widgets/Table';

const purgeSchema = yup
  .object({
    period: yup.number().required(),
  })
  .required();

const useEstimate = (since?: number) => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  return useQuery(['purge', 'estimate', since], () => repo.countPotentiallyInactiveUsers(accountId, since!), {
    staleTime: 0,
    cacheTime: 0,
    enabled: Boolean(since),
  });
};

const useJobHistory = () => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  return useQuery(['purge', 'history'], () => repo.getPurgeInactiveUsersJobHistory(accountId), {
    staleTime: 0,
    cacheTime: 0,
  });
};

const usePurgeMutation = () => {
  const { id: accountId } = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useMutation((variables: { since: number }) => repo.purgeInactiveUsers(accountId, variables.since), {
    onSuccess: () => {
      queryClient.invalidateQueries(['purge']);
    },
  });
};

function getSince(period?: string) {
  return period ? getUnixTime(startOfDay(subDays(new Date(), parseInt(period, 10) * 30))) : undefined;
}

const PlayersPurgePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const permChecker = usePermissionChecker();

  const historyQuery = useJobHistory();
  const hasPendingJob =
    historyQuery.isSuccess && historyQuery.data.some((j) => [JobState.Idle, JobState.Started].includes(j.state));

  const purgeMutation = usePurgeMutation();

  const formik = useFormik<{ period?: string }>({
    initialValues: { period: undefined },
    validationSchema: purgeSchema,
    validateOnMount: true,
    onSubmit: (values, formik) => {
      if (hasPendingJob) return;
      purgeMutation.mutate(
        { since: getSince(values.period)! },
        {
          onSuccess: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const estimateQuery = useEstimate(getSince(formik.values.period));

  const canSubmit =
    permChecker.hasAccountPermission(Permission.PurgeInactivePlayers) &&
    formik.isValid &&
    historyQuery.isSuccess &&
    !hasPendingJob &&
    estimateQuery.isSuccess &&
    estimateQuery.data > 0 &&
    !purgeMutation.isLoading;

  const handleBack = () => {
    history.push('/players');
  };

  const periodOptions: DropdownSelectOption[] = [
    {
      value: '3',
      label: t('nMonths', { count: 3 }),
    },
    {
      value: '6',
      label: t('nMonths', { count: 6 }),
    },
    {
      value: '9',
      label: t('nMonths', { count: 9 }),
    },
    {
      value: '12',
      label: t('nMonths', { count: 12 }),
    },
  ];
  const periodSelected = periodOptions.find((p) => p.value === formik.values?.period);

  return (
    <LayoutWithSidebar>
      <div className="flex flex-col">
        <HeaderWithBack backHandler={handleBack}>{t('purgeInactiveUsers')}</HeaderWithBack>
        <form onSubmit={formik.handleSubmit} className="flex flex-col flex-grow">
          <p className="mb-6">{t('purgeInactiveUsersDescription')}</p>
          <div className="flex-grow space-y-8">
            <div className="w-96">
              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('whenInactiveFor')}</div>
              <div>
                <DropdownSelect
                  selected={periodSelected}
                  placeholder={t('selectEllipsis')}
                  options={periodOptions}
                  onChange={(p) => formik.handleChange('period')(p.value)}
                />
              </div>
            </div>
            <div>
              {formik.values.period ? (
                <>
                  {estimateQuery.isLoading ? t('loadingEllipsis') : null}
                  {estimateQuery.isSuccess ? t('identifiednPotentialInactiveUsers', { count: estimateQuery.data }) : null}
                </>
              ) : null}
            </div>
          </div>

          <div className="w-full border-t border-gray-100 mt-10 pt-4 flex items-end flex-col">
            <div className="flex flex-row-reverse items-end">
              <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                {t('requestPurge')}
              </PrimaryButton>
              <Button onClick={handleBack}>{t('cancel')}</Button>
            </div>
            <div className="text-xs text-right mt-4 text-gray-500">{t('purgeHappensInBackground')}</div>
          </div>
        </form>
      </div>
      <div className="my-6">
        <h2 className="my-4 font-medium text-lg">{t('purgeRequests')}</h2>
        {historyQuery.isLoading ? t('loadingEllipsis') : null}
        {historyQuery.isSuccess ? <HistoryTable jobs={historyQuery.data} /> : null}
      </div>
    </LayoutWithSidebar>
  );
};

function HistoryTable({ jobs }: { jobs: Job[] }) {
  const { t } = useTranslation();
  return (
    <div>
      {!jobs.length ? (
        <p className="italic">{t('noneYet')}</p>
      ) : (
        <Table>
          <Thead>
            <Th>{t('createdOn')}</Th>
            <Th>{t('status')}</Th>
            <Th>{t('completedOn')}</Th>
            <Th>{t('purgeInactiveSince')}</Th>
            <Th>{t('usersPurged')}</Th>
          </Thead>
          <Tbody>
            {jobs.map((job) => {
              return (
                <Tr key={job.id}>
                  <Td>{fromUnixTime(job.created_on).toLocaleString()}</Td>
                  <Td>{t(`jobState.${job.state}`)}</Td>
                  <Td>{job.completed_on ? fromUnixTime(job.completed_on).toLocaleString() : '-'}</Td>
                  <Td>{fromUnixTime(job.params.since).toDateString()}</Td>
                  <Td>{job.state === JobState.Complete ? job.result.deleted : '-'}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}
    </div>
  );
}

export default PlayersPurgePage;
