import { DependencyList, useEffect, useMemo, useState } from 'react';
import {
  EnsuredQueryKey,
  QueryFunction,
  QueryKey,
  QueryObserverBaseResult,
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverLoadingResult,
  QueryObserverRefetchErrorResult,
  QueryObserverResult,
  QueryObserverSuccessResult,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useAccount } from './account';
import { useUser } from './auth';
import { ContinuableResponse, ContinuationToken, useRepo } from './repository';
import { ItemSchedule, ItemType, Permission, Store, Team, TeamMarket } from './types';
import { usePermissionChecker } from './hooks';

export interface ContinuatedQueryObserverBaseResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverBaseResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}

interface ContinuatedQueryObserverIdleResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverIdleResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}

interface ContinuatedQueryObserverLoadingResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverLoadingResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}
interface ContinuatedQueryObserverLoadingErrorResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverLoadingErrorResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}

interface ContinuatedQueryObserverRefetchErrorResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverRefetchErrorResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}
interface ContinuatedQueryObserverSuccessResult<TData = unknown, TError = unknown, TQueryKey = unknown>
  extends QueryObserverSuccessResult<TData, TError> {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isEnabled: boolean;
  isFirstPage: boolean;
  queryKey: TQueryKey;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
}

export declare type ContinuatedQueryObserverResult<TData = unknown, TError = unknown, TQueryKey = unknown> =
  | ContinuatedQueryObserverIdleResult<TData, TError, TQueryKey>
  | ContinuatedQueryObserverLoadingErrorResult<TData, TError, TQueryKey>
  | ContinuatedQueryObserverLoadingResult<TData, TError, TQueryKey>
  | ContinuatedQueryObserverRefetchErrorResult<TData, TError, TQueryKey>
  | ContinuatedQueryObserverSuccessResult<TData, TError, TQueryKey>;

type UseContinuatedQueryResult<TData = unknown, TError = unknown, TQueryKey = unknown> = ContinuatedQueryObserverResult<
  TData,
  TError,
  TQueryKey
>;

export const useContinuatedQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData extends { continuation?: ContinuationToken; items?: unknown[]; total?: number } = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  baseQueryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  queryOptions?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    pageSize?: number;
    resetPageDependencies?: DependencyList;
  }
): UseContinuatedQueryResult<TData, TError, TQueryKey> => {
  const [pages, setPages] = useState<ContinuationToken[]>([]);
  const queryClient = useQueryClient();
  const page = pages.slice(-1)[0] || null;

  const queryKeyArray = Array.isArray(baseQueryKey) ? baseQueryKey : baseQueryKey;
  const queryKey = (queryKeyArray as unknown[]).concat([page]) as any as TQueryKey;

  const results = useQuery(queryKey, () => queryFn({ pageParam: page, queryKey: queryKey as EnsuredQueryKey<TQueryKey> }), {
    keepPreviousData: true,
    ...queryOptions,
  });

  // Reset the pagination when filters change.
  useEffect(() => {
    setPages([]);
  }, queryOptions?.resetPageDependencies || []); // eslint-disable-line

  const isFetchingCurrentPage = results.isLoading || (results.isFetching && !Boolean(queryClient.getQueryData(queryKey)));
  const pageSize = queryOptions?.pageSize || 20;
  const total = results.data?.total || 0;

  return {
    ...results,

    hasNextPage: Boolean(results?.data?.continuation),
    hasPreviousPage: pages.length > 0,

    fetchNextPage: () => {
      if (isFetchingCurrentPage) return;
      const nextPage = results?.data?.continuation;
      nextPage && setPages(pages.concat([nextPage]));
    },
    fetchPreviousPage: () => {
      setPages(pages.slice(0, -1));
    },

    isEnabled: typeof queryOptions?.enabled === 'undefined' || Boolean(queryOptions?.enabled),
    isFirstPage: !Boolean(page),

    queryKey,

    resetPagination: () => setPages([]),

    showingFrom: results.data?.items ? pages.length * pageSize + 1 : 0,
    showingTo: results.data?.items
      ? Math.min(pages.length * pageSize + results.data.items.length, total > 0 ? total : Infinity)
      : 0,
    showingOfTotal: total,
  };
};

export const useFakeContinuableContinuatedQueryResult = <
  TType = any,
  TExtra extends {} = {},
  TData extends ContinuableResponse<TType, TExtra> = ContinuableResponse<TType, TExtra>,
  TQuery extends UseQueryResult<TData> = UseQueryResult<TData>
>(
  query: TQuery,
  pageSize = 20
): ContinuatedQueryObserverResult<TData> => {
  const { isSuccess, data } = query;
  const [page, setPage] = useState(0);

  const dataLength = data?.total || 0;
  const highestPageIndex = isSuccess ? Math.ceil((dataLength || 0) / pageSize) - 1 : 0;
  const hasNextPage = isSuccess && page < highestPageIndex;
  const hasPreviousPage = isSuccess && page > 0;

  const fetchNextPage = () => {
    if (!hasNextPage) return;
    setPage((page) => Math.min(highestPageIndex, page + 1));
  };
  const fetchPreviousPage = () => {
    if (!hasPreviousPage) return;
    setPage((page) => Math.max(0, page - 1));
  };

  const resetPagination = () => setPage(0);
  const showingFrom = isSuccess && dataLength ? page * pageSize + 1 : 0;
  const showingTo = isSuccess && dataLength ? Math.min(dataLength, pageSize + pageSize * page) : 0;
  const showingOfTotal = dataLength || 0;

  return {
    ...query,
    data: data
      ? {
          ...data,
          items: data.items?.slice(page * pageSize, page * pageSize + pageSize) || [],
        }
      : data,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFirstPage: page === 0,
    resetPagination,
    showingFrom,
    showingTo,
    showingOfTotal,
  } as any;
};

export const useFakeContinuatedQueryResult = <TQuery extends QueryObserverResult<unknown[]>>(
  query: TQuery,
  pageSize = 20
): TQuery & {
  fetchNextPage: () => void;
  fetchPreviousPage: () => void;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  isFirstPage: boolean;
  resetPagination: () => void;
  showingFrom: number;
  showingTo: number;
  showingOfTotal: number;
} => {
  const { isSuccess, data } = query;
  const [page, setPage] = useState(0);

  const highestPageIndex = isSuccess ? Math.ceil((data?.length || 0) / pageSize) - 1 : 0;
  const hasNextPage = isSuccess && page < highestPageIndex;
  const hasPreviousPage = isSuccess && page > 0;

  const fetchNextPage = () => {
    if (!hasNextPage) return;
    setPage((page) => Math.min(highestPageIndex, page + 1));
  };
  const fetchPreviousPage = () => {
    if (!hasPreviousPage) return;
    setPage((page) => Math.max(0, page - 1));
  };

  const resetPagination = () => setPage(0);
  const showingFrom = isSuccess && data?.length ? page * pageSize + 1 : 0;
  const showingTo = isSuccess && data?.length ? Math.min(data.length, pageSize + pageSize * page) : 0;
  const showingOfTotal = data?.length || 0;

  return {
    ...query,
    data: data?.slice(page * pageSize, page * pageSize + pageSize),
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFirstPage: page === 0,
    resetPagination,
    showingFrom,
    showingTo,
    showingOfTotal,
  } as any;
};

export const useItem = (itemId: string) => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const data = useQuery(['item', itemId], () => {
    return repo.getItem(itemId);
  });

  return {
    ...data,
    invalidateRelatedQueries: () => {
      if (!data.data) return;
      const item = data.data;
      queryClient.invalidateQueries(['store-content', item.store_id]);
      queryClient.invalidateQueries(['item', item.id]);
      queryClient.invalidateQueries(['item-candidates', item.id]);
      queryClient.invalidateQueries(['item-entries', item.id]);
      queryClient.invalidateQueries(['item-transactions', item.id]);
    },
  };
};

export const usePlatforms = (options?: UseQueryOptions<{ id: string; name: string }[]>) => {
  const repo = useRepo();
  return useQuery(['platforms'], () => repo.getPlatforms(), options);
};

export const usePendingOrdersCount = (options: UseQueryOptions<number>) => {
  const repo = useRepo();
  const { id: accountId } = useAccount();
  return useQuery(['pending-orders'], () => repo.countPendingOrders(accountId), {
    staleTime: 1000 * 600, // 10 min.
    cacheTime: 1000 * 3600, // 1 hr.
    ...options,
  });
};

export const useProduct = (productId: string) => {
  const repo = useRepo();
  return useQuery(['product', productId], () => {
    return repo.getProduct(productId);
  });
};

export const useRecentAccounts = (skipAccountId?: string) => {
  const user = useUser();
  const repo = useRepo();

  // We never want to show return more than 5 accounts. The actual list of recent accounts may be longer.
  const maxCount = 5;

  const effectiveAccountIds = user.recent_account_ids?.filter((id) => id !== skipAccountId) || [];
  const nRecentAccounts = Math.min(maxCount, effectiveAccountIds.length);
  const hasRecentAccounts = nRecentAccounts > 0;

  // The query fetches from the actual list of recent accounts.
  const query = useQuery(['accounts', 'recent', user.recent_account_ids], () => repo.getRecentAccounts(), {
    enabled: hasRecentAccounts,
    staleTime: 60 * 1000,
  });

  const recentAccounts = useMemo(
    () => query?.data?.filter((a) => a.id !== skipAccountId).slice(0, maxCount),
    [query.data, skipAccountId]
  );

  return {
    hasRecentAccounts,
    nRecentAccounts,
    recentAccounts,
    isLoading: query.isLoading,
  };
};

export const useStore = (storeId: string, options?: UseQueryOptions<Store>) => {
  const repo = useRepo();
  return useQuery<Store>(['store', storeId], () => repo.getStore(storeId), options);
};

export const useStoreName = (storeId?: string) => {
  const enabled = typeof storeId === 'string';
  const { data: store, isIdle, isLoading, isSuccess } = useStore(storeId || '-', { enabled: enabled });
  return isIdle || isLoading ? undefined : isSuccess && store ? store.name : '?';
};

export const useStores = (searchTerm?: string, orderBy?: string, pageSize?: number) => {
  const { id: accountId } = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useContinuatedQuery(
    ['stores', { searchTerm, orderBy, accountId }],
    ({ pageParam }) => {
      return repo.getStores(accountId, { term: searchTerm }, orderBy, pageSize, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, accountId],
      pageSize,
      onSuccess: (data) => {
        data.items.forEach((store) => {
          queryClient.setQueryData(['store', store.id], store);
        });
      },
    }
  );
};

export const useStoreContent = (
  storeId: string,
  orderBy?: string,
  filters?: {
    term?: string;
    type?: ItemType;
    status?: ItemSchedule[];
  }
) => {
  const repo = useRepo();
  return useContinuatedQuery(
    ['store-content', storeId, { orderBy, ...filters }],
    ({ pageParam }) => {
      return repo.getStoreContent(storeId, filters, orderBy, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [filters?.term, filters?.type, filters?.status, orderBy, storeId],
    }
  );
};

export const useTeam = (teamId: string, options?: UseQueryOptions<Team>) => {
  const repo = useRepo();
  const permChecker = usePermissionChecker();
  const isQueryEnabled = typeof options?.enabled === 'undefined' || Boolean(options.enabled);

  // At the moment, if we know that the user does not have the permission to read the team, deactivate the query.
  const isEnabled = isQueryEnabled && permChecker.hasTeamPermission(teamId, Permission.ReadTeam);

  return useQuery<Team>(['team', teamId], () => repo.getTeam(teamId), {
    ...options,
    enabled: isEnabled,
  });
};

export const useTeamName = (teamId?: string) => {
  const enabled = typeof teamId === 'string';
  const { data: team, isIdle, isLoading, isSuccess } = useTeam(teamId || '-', { enabled: enabled });
  return isIdle || isLoading ? undefined : isSuccess && team ? team.name : '?';
};

export const useTeams = (searchTerm?: string, orderBy?: string, pageSize?: number) => {
  const { id: accountId } = useAccount();
  const queryClient = useQueryClient();
  const repo = useRepo();
  return useContinuatedQuery(
    ['teams', { searchTerm, orderBy, accountId, pageSize }],
    ({ pageParam }) => {
      return repo.getTeams(accountId, { term: searchTerm }, orderBy, pageSize, pageParam);
    },
    {
      keepPreviousData: true,
      resetPageDependencies: [searchTerm, orderBy, accountId, pageSize],
      pageSize,
      onSuccess: (data) => {
        data.items.forEach((team) => {
          queryClient.setQueryData(['team', team.id], team);
        });
      },
    }
  );
};

export const useTeamMarket = (teamId: string, options?: Omit<UseQueryOptions<TeamMarket>, 'queryKey' | 'queryFn'>) => {
  const repo = useRepo();
  return useQuery(['team-market', teamId], () => repo.getTeamMarket(teamId), options);
};
