import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router';
import { useAnchorButtonProps } from '../lib/hooks';
import { classNames } from '../lib/utils';

export const HeaderWithBack = ({
  children,
  backTo,
  backHandler,
  sideComponent,
  withBorder = true,
}: {
  children: string;
  backTo?: string;
  backHandler?: () => void;
  sideComponent?: React.ReactNode;
  withBorder?: boolean;
}) => {
  const history = useHistory();
  const handleBack = backHandler
    ? backHandler
    : () => {
        if (backTo) {
          return history.push(backTo);
        }
        history.goBack();
      };
  const anchorProps = useAnchorButtonProps(handleBack);
  const SideComponent = sideComponent;

  return (
    <div className={classNames(withBorder ? 'border-b border-gray-100 mb-4' : '')}>
      <div className="flex mb-6">
        <div className="flex items-center flex-grow">
          <div className="mr-2">
            <a className="no-underline text-gray-800" {...anchorProps}>
              <ArrowLeftIcon className="h-5 w-5" />
            </a>
          </div>
          <h1 className="text-2xl font-semibold leading-none flex-grow">{children}</h1>
        </div>
        <div className="flex">
          <div>{SideComponent ?? null}</div>
        </div>
      </div>
    </div>
  );
};
