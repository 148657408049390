import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useFiltering, useSelection, useSorting } from '../../lib/hooks';
import { useTeams } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import SearchInput from '../inputs/Search';
import { StorePageFreeLayout, useStorePage } from '../layouts/StorePage';
import { broadcastSuccessToast } from '../Toasts';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdCheckbox,
  TdPrimary,
  Th,
  ThCheckbox,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';
import { ShorteningTagList, TeamTag } from '../widgets/Tag';

const Content = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { store } = useStorePage();

  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', { name: false });
  const query = useTeams(filterTerm, sorting.sortField, 10);
  const selection = useSelection(query, store.team_ids);
  const queryClient = useQueryClient();
  const repo = useRepo();

  const mutation = useMutation(
    async () => {
      await repo.addTeamsToStore(selection.selectedIds, store.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['store', store.id]);
        queryClient.invalidateQueries(['store-teams', store.id]);
        queryClient.invalidateQueries(['stores']);

        selection.selectedIds.forEach((teamId) => {
          queryClient.invalidateQueries(['team', teamId]);
          queryClient.invalidateQueries(['team-stores', teamId]);
        });
        queryClient.invalidateQueries(['teams']);

        broadcastSuccessToast(t('teamAdded', { count: selection.selectedIds.length }));

        handleBack();
      },
    }
  );

  const handleBack = () => history.goBack();
  const canSave = selection.selectedIds.length && !mutation.isLoading;
  const handleSave = () => {
    mutation.mutate();
  };

  return (
    <>
      <h2 className="text-xl font-semibold">{t('addTeam', { count: 99 })}</h2>
      <div className="mt-4">
        <div className="w-96">
          <SearchInput {...filterInputProps} />
        </div>
      </div>
      <div className="mt-4 flex-grow">
        <Table>
          <Thead>
            <ThCheckbox
              onChange={selection.onSelectAllChange}
              checked={selection.isEntirePageSelected}
              disabled={!selection.isEntirePageSelectable}
            />
            <ThSortableWithSorting sortKey="name" sortingResult={sorting}>
              {t('name')}
            </ThSortableWithSorting>
            <Th>{t('stores')}</Th>
          </Thead>
          <Tbody>
            {query.isLoading ? (
              <Tr>
                <Td colSpan={3}>{t('loadingEllipsis')}</Td>
              </Tr>
            ) : null}
            {query.isSuccess && query.data
              ? query.data.items.map((team) => {
                  return (
                    <Tr key={team.id}>
                      <TdCheckbox
                        onChange={() => selection.onSelectionChange(team)}
                        checked={selection.isSelected(team)}
                        disabled={!selection.isSelectable(team)}
                      />
                      <TdPrimary>{team.name}</TdPrimary>
                      <Td>
                        <ShorteningTagList component={TeamTag} tags={team.stores?.map((s) => s.name)} />
                      </Td>
                    </Tr>
                  );
                })
              : null}
          </Tbody>
          {query.isSuccess && (query.data?.total || 0) > 0 ? (
            <TablePagination
              showingFrom={query.showingFrom}
              showingTo={query.showingTo}
              showingOfTotal={query.showingOfTotal}
              hasNextPage={query.hasNextPage}
              hasPreviousPage={query.hasPreviousPage}
              onNextPageClick={query.fetchNextPage}
              onPreviousPageClick={query.fetchPreviousPage}
            />
          ) : null}
        </Table>
      </div>
      <div className="w-full mt-2 pt-4 flex flex-row-reverse items-end">
        <PrimaryButton disabled={!canSave} className="ml-3" onClick={handleSave}>
          {t('add')}
        </PrimaryButton>
        <Button onClick={handleBack}>{t('discard')}</Button>
      </div>
    </>
  );
};

const Header = () => {
  const { store } = useStorePage();
  return <HeaderWithBack>{store.name}</HeaderWithBack>;
};

const StoreAddTeamsPage = () => {
  return (
    <StorePageFreeLayout header={<Header />}>
      <Content />
    </StorePageFreeLayout>
  );
};

export default StoreAddTeamsPage;
