export const ShortDay = ({ date, ts }: { date?: Date; ts?: number }) => {
  if (!date) {
    date = new Date((ts || 0) * 1000);
  }
  return (
    <>
      {date.toLocaleDateString(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })}
    </>
  );
};
