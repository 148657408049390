import { DndContext, DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ClockIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../lib/i18n';
import { useTeamMarketMutation } from '../../lib/mutations';
import { useTeamMarket } from '../../lib/queries';
import {
  Item,
  Product,
  TeamMarket,
  TeamMarketCategory,
  TeamMarketCategoryItems,
  TeamMarketCategoryOverflow,
} from '../../lib/types';
import { classNames } from '../../lib/utils';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import { TextEntryModal } from '../Modals';
import { NotificationError } from '../Notifications';
import { Placeholder, PlaceholderList } from '../Placeholders';
import { broadcastSuccessToast } from '../Toasts';
import { TeamPageFreeLayout, useTeamPage } from '../layouts/TeamPage';
import Dropdown, { DropdownOption } from '../widgets/Dropdown';
import { fromUnixTime, isFuture } from 'date-fns';

const MAX_MARKET_CATEGORIES = 20;
const CAT_OVERFLOW_ID = 'overflow';
const ITEM_POOL_ID = 'pool';

const cleanCategoryItems = (cats: TeamMarketCategory[], items: (Product | Item)[]): TeamMarketCategory[] => {
  const itemIds = items.map((i) => i.id);
  return cats.map((cat) => {
    if (cat.type !== 'items') return cat;
    return { ...cat, items: cat.items.filter((id) => itemIds.includes(id)) };
  });
};
const getStateFromMarket = (market: TeamMarket) => {
  let cats = cleanCategoryItems(market.categories || [], market.items);
  const overflowCat = cats.find(isOverflowCat);
  if (!overflowCat) {
    cats = [...cats, { id: CAT_OVERFLOW_ID, type: 'overflow', name: '' }];
  }
  return cats;
};
const filterItemsCat = (cats: TeamMarketCategory[]): TeamMarketCategoryItems[] => cats.filter(isItemsCat);
const isItemsCat = (cat: TeamMarketCategory): cat is TeamMarketCategoryItems => cat.type === 'items';
const isOverflowCat = (cat: TeamMarketCategory): cat is TeamMarketCategoryOverflow => cat.type === 'overflow';

const ItemCard = forwardRef<HTMLDivElement, { item: Item | Product } & React.HTMLAttributes<HTMLDivElement>>(
  ({ item, className, ...props }, ref) => {
    const src = item.doc_type === 'product' ? item.image_url : item.thumbnail_url || item.image_url;
    const scheduled = isFuture(fromUnixTime(item.start_time));
    const { t } = useTranslation();
    return (
      <div
        {...props}
        ref={ref}
        className={classNames('bg-white border rounded flex text-sm gap-4 w-full overflow-hidden select-none relative', className)}
      >
        <div className="shrink-0">
          <img src={src} className="w-12 h-12 rounded" alt="" aria-hidden="true" />
        </div>
        <div className="py-1 grow-0 h-12 overflow-hidden flex items-center">{item.name}</div>
        {scheduled ? <ClockIcon className="h-4 w-4 text-gray-300 absolute top-0.5 right-0.5" title={t('scheduled')} /> : null}
      </div>
    );
  }
);

const SortableItem = ({ item }: { item: Item | Product }) => {
  const { setNodeRef, attributes, listeners, transform, transition, active } = useSortable({ id: item.id });
  return (
    <ItemCard
      item={item}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: active?.id === item.id ? 0.5 : undefined,
      }}
      {...attributes}
      {...listeners}
      className="cursor-grab"
    />
  );
};

const DraggableItem = ({ item }: { item: Item | Product }) => {
  const { setNodeRef, attributes, listeners, active } = useDraggable({ id: item.id });
  return (
    <ItemCard
      item={item}
      ref={setNodeRef}
      style={{
        opacity: active?.id === item.id ? 0.5 : undefined,
      }}
      {...attributes}
      {...listeners}
      className="cursor-grab"
    />
  );
};

const CategoryItems = ({
  category,
  allItems,
  ...props
}: {
  category: TeamMarketCategoryItems;
  allItems: (Item | Product)[];
  onNameChange: (name: string) => void;
  onDelete: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}) => {
  const { t } = useTranslation();
  const { setNodeRef, isOver, active } = useDroppable({
    id: category.id,
  });
  const isActive = useMemo(() => isOver || category.items.includes(active?.id as string), [category.items, active, isOver]);
  const hereItems = useMemo(
    () => category.items.map((id) => allItems.find((i) => i.id === id)).filter(Boolean) as (Item | Product)[],
    [allItems, category.items]
  );
  return (
    <Category category={category} placeholderName={t('untitledCategory')} {...props}>
      <DropZone ref={setNodeRef} className={classNames('grid grid-cols-4 gap-4 min-h-[70px] relative')} isOver={isActive}>
        {!hereItems.length ? (
          <div className="flex items-center justify-center text-center absolute inset-0 text-sm text-gray-600 border-2 border-dashed m-1">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-1">
              <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
              <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
            </svg>
            {t('dropItemsHere')}
          </div>
        ) : null}
        <SortableContext items={hereItems} strategy={rectSortingStrategy}>
          {hereItems.map((item, index) => (
            <SortableItem key={item.id} item={item} />
          ))}
        </SortableContext>
      </DropZone>
    </Category>
  );
};

const CategoryOverflow = (props: { category: TeamMarketCategoryOverflow; onNameChange: (name: string) => void }) => {
  const { t } = useTranslation();
  return (
    <Category {...props} placeholderName={t('everythingElse')}>
      <div className="bg-gray-50 rounded p-4 text-sm min-h-[70px] flex items-center text-left">
        {t('uncategorizedItemsWillAppearHere')}
      </div>
    </Category>
  );
};

const Category: React.FC<{
  category: TeamMarketCategory;
  placeholderName: string;
  onNameChange: (name: string) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onDelete?: () => void;
}> = ({ category, onNameChange, onDelete, onMoveUp, onMoveDown, children, placeholderName }) => {
  const { t } = useTranslation();
  const [isRenaming, setIsRenaming] = useState(false);
  const name = category.name || placeholderName;
  const handleNameChange = (name: string) => {
    onNameChange(name);
    setIsRenaming(false);
  };
  return (
    <div>
      <div className="flex">
        <div className="grow">
          <h3 className="mb-2">{name}</h3>
        </div>
        <div>
          <Dropdown
            right
            label={t('actions')}
            icon={<EllipsisVerticalIcon className="h-5 w-5" />}
            minimal
            options={
              [
                {
                  label: t('rename'),
                  onClick: () => setIsRenaming(true),
                },
                onMoveUp
                  ? {
                      label: t('moveUp'),
                      onClick: onMoveUp,
                    }
                  : null,
                onMoveDown
                  ? {
                      label: t('moveDown'),
                      onClick: onMoveDown,
                    }
                  : null,
                onDelete
                  ? {
                      label: t('delete'),
                      onClick: onDelete,
                      danger: true,
                    }
                  : null,
              ].filter(Boolean) as DropdownOption[]
            }
          />
        </div>
      </div>
      <>{children}</>
      {isRenaming ? (
        <TextEntryModal
          title={t('rename')}
          text={category.name}
          placeholder={placeholderName}
          onCancel={() => setIsRenaming(false)}
          onConfirm={handleNameChange}
          confirmButtonText={t('rename')}
        />
      ) : null}
    </div>
  );
};

const Content = ({ market }: { market: TeamMarket }) => {
  const { t } = useTranslation();
  const { team } = useTeamPage();

  const mutation = useTeamMarketMutation();
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  const [state, setState] = useState<TeamMarketCategory[]>(() => getStateFromMarket(market));
  useEffect(() => setState(getStateFromMarket(market)), [market]);

  const [dragging, setDragging] = useState<null | { id: string; origId?: string; origIndex?: number }>(null);
  const draggingId = dragging?.id;

  const itemsCats = useMemo(() => filterItemsCat(state), [state]);
  const overflowCat = useMemo<TeamMarketCategoryOverflow>(
    () => state.find(isOverflowCat) || { id: CAT_OVERFLOW_ID, type: 'overflow', name: '' },
    [state]
  );

  const items = market.items;
  const availableItems = useMemo(
    () => items.filter((item: Item | Product) => !itemsCats.some((cat) => cat.items.some((itemId) => itemId === item.id))),
    [itemsCats, items]
  );
  const draggingItem = useMemo(() => items.find((item) => item.id === draggingId), [draggingId, items]);

  const handleDiscard = () => setState(getStateFromMarket(market));
  const handleSave = () => {
    mutation.mutate(
      { teamId: team.id, categories: state },
      {
        onSuccess: () => {
          broadcastSuccessToast(t('informationSaved'));
        },
        onError: (err) => {
          broadcastSuccessToast(getErrorMessage(err));
        },
      }
    );
  };
  const canSave = !mutation.isLoading;

  const handleAddCat = useCallback(
    (name: string) => {
      setState((state) => {
        if (state.length > MAX_MARKET_CATEGORIES) return state;
        const itemsCats = filterItemsCat(state);
        const overflowCats = state.filter(isOverflowCat);
        const newCat: TeamMarketCategoryItems = { id: `cat-${Date.now()}`, type: 'items', name, items: [] };
        return [newCat, ...itemsCats, ...overflowCats];
      });
    },
    [setState]
  );

  const handleDeleteCat = useCallback(
    (catId: string) => {
      setState((state) => state.filter((cat) => cat.id !== catId));
    },
    [setState]
  );

  const handleRenameCat = (catId: string, name: string) => {
    setState((state) =>
      state.map((cat) => {
        if (cat.id !== catId) return cat;
        return { ...cat, name };
      })
    );
  };

  const handleMoveUp = (catId: string) => {
    setState((state) => {
      const idx = state.findIndex((cat) => cat.id === catId);
      if (idx <= 0) return state;
      return arrayMove(state, idx, idx - 1);
    });
  };

  const handleMoveDown = (catId: string) => {
    setState((state) => {
      const idx = state.findIndex((cat) => cat.id === catId);
      if (idx >= filterItemsCat(state).length - 1) return state;
      return arrayMove(state, idx, idx + 1);
    });
  };

  const handleDragEnd = useCallback(
    (e) => {
      const draggableId = e.active.id;
      const droppableId = e.over?.id;

      setDragging(null);
      setState((state) => {
        const itemsCats = filterItemsCat(state);
        const origCat = itemsCats.find((cat) => cat.items.includes(draggableId));
        const destCat = itemsCats.find((cat) => cat.id === droppableId || cat.items.includes(droppableId));

        // We've already updated the state in the dragOver handler.
        if (origCat !== destCat) {
          return state;
        }

        return state.map((cat) => {
          if (cat.type !== 'items') return cat;
          let items = Array.from(cat.items);
          if (!droppableId) {
            items = items.filter((i) => i !== draggableId);
          } else if (cat === destCat) {
            const idx = items.indexOf(droppableId);
            items = arrayMove(items, items.indexOf(draggableId), idx > -1 ? idx : items.length);
          }
          return { ...cat, items };
        });
      });
    },
    [setState, setDragging]
  );

  const handleDragOver = useCallback(
    (e) => {
      const draggableId = e.active.id;
      const droppableId = e.over?.id;

      setState((state) => {
        const itemsCats = filterItemsCat(state);
        const origCat = itemsCats.find((cat) => cat.items.includes(draggableId));
        const destCat = itemsCats.find((cat) => cat.id === droppableId || cat.items.includes(droppableId));
        const replaceItem = destCat && destCat.items.indexOf(droppableId) > -1 ? droppableId : null;

        // The movement within a category is managed by the sortable.
        if (origCat === destCat) {
          return state;
        }

        return state.map((cat) => {
          if (cat.type !== 'items') return cat;
          const items = cat.items;
          if (cat === destCat) {
            if (!replaceItem) {
              return {
                ...cat,
                items: [...items, draggableId],
              };
            }
            const indexAt = items.indexOf(replaceItem);
            return {
              ...cat,
              items: [...items.slice(0, indexAt), draggableId, ...items.slice(indexAt)],
            };
          } else if (!destCat) {
            if (cat.id === dragging?.origId && droppableId !== ITEM_POOL_ID) {
              let prunedItems = items;
              if (origCat && origCat.id === dragging?.origId) {
                prunedItems = items.filter((i) => i !== draggableId);
              }
              return {
                ...cat,
                items: [
                  ...prunedItems.slice(0, dragging.origIndex || 0),
                  draggableId,
                  ...prunedItems.slice(dragging.origIndex || 0),
                ],
              };
            }
          }

          if (cat === origCat) {
            return { ...cat, items: items.filter((i) => i !== draggableId) };
          }
          return cat;
        });
      });
    },
    [setState, dragging]
  );

  const handleDragStart = useCallback(
    (e) => {
      const origCat = itemsCats.find((cat) => cat.items.includes(e.active.id));
      setDragging({ id: e.active.id, origId: origCat?.id, origIndex: origCat?.items.indexOf(e.active.id) });
    },
    [setDragging, itemsCats]
  );

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
      <div className="flex mb-2">
        <div className="grow">
          <h2 className="text-xl font-semibold">{t('categorizeItems')}</h2>
        </div>
        <div>
          <Button className="ml-2" onClick={() => setIsAddingCategory(true)} disabled={state.length >= MAX_MARKET_CATEGORIES}>
            {t('addCategory')}
          </Button>
        </div>
      </div>
      <div className="mt-4 grow">
        <div className="h-full flex items-start gap-4">
          <div
            className="w-48 shrink-0 bottom-0 grow-0 sticky top-24 overflow-y-auto overscroll-contain min-h-64"
            style={{ height: 'calc(100vh - 19rem)' }}
          >
            <ItemPool items={availableItems} />
          </div>
          <div className="grow">
            <div className="space-y-6 mb-8">
              {itemsCats.map((cat, idx) => {
                return (
                  <CategoryItems
                    category={cat}
                    allItems={items}
                    onNameChange={(name) => handleRenameCat(cat.id, name)}
                    onDelete={() => handleDeleteCat(cat.id)}
                    onMoveUp={idx > 0 ? () => handleMoveUp(cat.id) : undefined}
                    onMoveDown={idx < itemsCats.length - 1 ? () => handleMoveDown(cat.id) : undefined}
                  />
                );
              })}
              <CategoryOverflow category={overflowCat} onNameChange={(name) => handleRenameCat(overflowCat.id, name)} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mat-8 flex flex-row-reverse items-end sticky bottom-0 py-4 bg-white -mb-4">
        <PrimaryButton disabled={!canSave} className="ml-3" onClick={handleSave}>
          {t('save')}
        </PrimaryButton>
        <Button onClick={handleDiscard}>{t('discard')}</Button>
      </div>
      <DragOverlay>{draggingItem ? <ItemCard item={draggingItem} className="cursor-grab bg-blue-100" /> : null}</DragOverlay>
      {isAddingCategory ? (
        <TextEntryModal
          title={t('addCategory')}
          onCancel={() => setIsAddingCategory(false)}
          onConfirm={(name) => {
            handleAddCat(name);
            setIsAddingCategory(false);
          }}
          placeholder={t('untitledCategory')}
          confirmButtonText={t('addCategory')}
        />
      ) : null}
    </DndContext>
  );
};

const ItemPool = ({ items }: { items: (Item | Product)[] }) => {
  const { setNodeRef, isOver } = useDroppable({ id: ITEM_POOL_ID });
  return (
    <DropZone ref={setNodeRef} isOver={isOver} className="space-y-2 h-full">
      {items.map((item) => (
        <DraggableItem item={item} key={item.id} />
      ))}
    </DropZone>
  );
};

const DropZone = forwardRef<HTMLDivElement, { children: React.ReactNode; isOver?: boolean; className?: string }>(
  ({ children, isOver, className }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'p-2 rounded border-2 border-dashed bg-gray-50',
          isOver ? 'border-inherit' : 'border-transparent',
          className
        )}
      >
        {children}
      </div>
    );
  }
);

const Header = () => {
  const { team } = useTeamPage();
  return <HeaderWithBack>{team.name}</HeaderWithBack>;
};

const ContentLoader = () => {
  const { team } = useTeamPage();
  const { t } = useTranslation();
  const marketQuery = useTeamMarket(team.id);

  if (marketQuery.isLoading || marketQuery.isError || !marketQuery.data) {
    return (
      <>
        <div className="flex mb-2">
          <div className="grow">
            <h2 className="text-xl font-semibold">{t('categorizeItems')}</h2>
          </div>
        </div>
        <div className="mt-4 grow">
          {marketQuery.isError ? (
            <div className="my-4">
              <NotificationError>{getErrorMessage(marketQuery.error)}</NotificationError>
            </div>
          ) : null}

          <div className="h-full flex gap-4">
            <div className="w-48 shrink-0 grow-0 h-full space-y-4">
              <PlaceholderList count={5}>
                <Placeholder className="h-16" />
              </PlaceholderList>
            </div>
            <div className="grow space-y-6">
              <PlaceholderList count={2}>
                <div>
                  <Placeholder className="h-6 mb-4 w-60" />
                  <Placeholder className="h-20" />
                </div>
              </PlaceholderList>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <Content market={marketQuery.data} />;
};

const TeamMarketLayoutPage = () => {
  return (
    <TeamPageFreeLayout header={<Header />}>
      <ContentLoader />
    </TeamPageFreeLayout>
  );
};

export default TeamMarketLayoutPage;
