import { addMonths, startOfDay } from 'date-fns';
import getUnixTime from 'date-fns/getUnixTime';
import { Field, Form, Formik } from 'formik';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { AccountContext } from '../../lib/account';
import { AuthContext, useUser } from '../../lib/auth';
import { isCoreDomain, isDomainAdmin, isSuperUser } from '../../lib/perms';
import { useRepo } from '../../lib/repository';
import { AccountPaymentType } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import Checkbox from '../inputs/Checkbox';
import Input from '../inputs/Input';
import OutsideAccountLayout from '../layouts/OutsideAccount';
import { HasPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import { DatePicker } from '../widgets/DateRangePicker';
import DropdownSelect, { DropdownSelectOption } from '../widgets/DropdownSelect';

const accountSchema = yup
  .object({
    name: yup.string().required(),
    paymentType: yup.object({ value: yup.string().required() }).required(),
    maxUsers: yup.number().min(0).max(100000),
    expiry: yup.date().required(),
  })
  .required();

const useAccountCreateMutation = () => {
  const repo = useRepo();
  const queryClient = useQueryClient();
  const auth = useContext(AuthContext);
  const accountContext = useContext(AccountContext);
  const history = useHistory();
  return useMutation(
    ({
      name,
      paymentType,
      expiry,
      expiryStrict,
      maxUsers,
    }: {
      name: string;
      paymentType: AccountPaymentType;
      maxUsers: number;
      expiry: number;
      expiryStrict: boolean;
    }) => repo.createAccount(name, paymentType, maxUsers, expiry, expiryStrict),
    {
      onSuccess: async (account) => {
        queryClient.invalidateQueries('accounts');
        await auth.refreshUser();
        accountContext.setAccount(account);
        history.push('/');
      },
    }
  );
};

const AccountCreatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useUser();
  const mutation = useAccountCreateMutation();

  const handleBack = () => {
    history.goBack();
  };

  const paymentTypeOptions = useMemo(
    () =>
      [
        { label: t('paymentTypeAnnual'), value: AccountPaymentType.Annual },
        { label: t('paymentTypeTrial'), value: AccountPaymentType.Trial },
      ].filter(Boolean) as { label: string; value: AccountPaymentType }[],
    [] // eslint-disable-line
  );

  return (
    <OutsideAccountLayout>
      <div className="flex flex-col flex-grow">
        <div className="ml-2">
          <HeaderWithBack withBorder={false}>{t('untitledAccount')}</HeaderWithBack>
        </div>

        <div className="px-10 pt-10 pb-6 bg-white shadow-sm">
          <Formik
            initialValues={{
              name: '',
              paymentType: paymentTypeOptions.find((o) => o.value === AccountPaymentType.Annual) as DropdownSelectOption,
              maxUsers: 100,
              expiry: addMonths(new Date(), 1),
              expiryStrict: true,
            }}
            onSubmit={(values, formik) => {
              mutation.mutate(
                { ...values, expiry: getUnixTime(values.expiry), paymentType: values.paymentType?.value as AccountPaymentType },
                {
                  onSuccess: (team) => {
                    // history.push(`/team/${team.id}`);
                    broadcastSuccessToast(t('accountCreated'));
                  },
                  onError: () => {
                    formik.setSubmitting(false);
                    // TODO Handle error.
                  },
                }
              );
            }}
            validationSchema={accountSchema}
            validateOnMount
          >
            {({ isValid, values, setFieldValue }) => {
              const canSubmit = isValid && !mutation.isLoading;
              return (
                <Form className="flex flex-col flex-grow mt-2">
                  <div className="flex-grow space-y-8">
                    <div className="w-128">
                      <label>
                        <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('accountName')}</div>
                        <div>
                          <Field as={Input} name="name" maxLength={255} />
                        </div>
                      </label>
                      <HasPermission checker={isSuperUser}>
                        <div className="text-gray-500 text-xs mt-2">Domain: {user.domain_id}</div>
                      </HasPermission>
                    </div>
                    <HasPermission checker={isDomainAdmin}>
                      <div className="w-128">
                        <div className="grid grid-cols-2 gap-4">
                          <HasPermission checker={isSuperUser}>
                            <HasPermission checker={isCoreDomain}>
                              <div>
                                <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('subscriptionType')}</div>
                                <div>
                                  <DropdownSelect
                                    placeholder={t('selectEllipsis')}
                                    options={paymentTypeOptions}
                                    onChange={(opt) => setFieldValue('paymentType', opt)}
                                    selected={values.paymentType}
                                  />
                                </div>
                              </div>
                            </HasPermission>
                          </HasPermission>
                          {values.paymentType?.value !== AccountPaymentType.Trial ? (
                            <label>
                              <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('players')}</div>
                              <div>
                                <Field as={Input} name="maxUsers" min={0} max={100000} />
                              </div>
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="leading-5 mb-1 text-gray-700 text-sm font-medium">{t('expirationDate')}</div>
                          <div className="w-128">
                            <div className="flex items-center gap-4">
                              <div className="w-1/2">
                                <DatePicker
                                  value={values.expiry}
                                  onChange={(date) => setFieldValue('expiry', date)}
                                  minDate={startOfDay(new Date())}
                                  className="block w-full text-sm border border-gray-300 rounded-md shadow-sm"
                                />
                              </div>
                              {values.paymentType.value !== AccountPaymentType.Trial ? (
                                <div>
                                  <label className="flex items-center text-gray-700 text-sm">
                                    <Field type="checkbox" as={Checkbox} name="expiryStrict" />
                                    <div className="ml-3">{t('lockAccountWhenPassed')}</div>
                                  </label>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </HasPermission>
                  </div>

                  <div className="w-full border-t border-gray-100 mt-10 pt-4 flex flex-row-reverse items-end">
                    <PrimaryButton disabled={!canSubmit} className="ml-3" submit>
                      {t('save')}
                    </PrimaryButton>
                    <Button onClick={handleBack}>{t('discard')}</Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </OutsideAccountLayout>
  );
};

export default AccountCreatePage;
