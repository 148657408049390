import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useFiltering, useSelection, useSorting } from '../../lib/hooks';
import { useStores } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Store } from '../../lib/types';
import { Button, PrimaryButton } from '../Buttons';
import { HeaderWithBack } from '../Headers';
import SearchInput from '../inputs/Search';
import { TeamPageFreeLayout, useTeamPage } from '../layouts/TeamPage';
import { broadcastSuccessToast } from '../Toasts';
import {
  Table,
  TablePagination,
  Tbody,
  Td,
  TdCheckbox,
  TdPrimary,
  Th,
  ThCheckbox,
  Thead,
  ThSortableWithSorting,
  Tr,
} from '../widgets/Table';

const Content = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { team } = useTeamPage();

  const { filterTerm, filterInputProps } = useFiltering();
  const sorting = useSorting('name', { name: false });
  const query = useStores(filterTerm, sorting.sortField, 10);
  const selection = useSelection<Store>(query, (item) => item.team_ids.includes(team.id));
  const queryClient = useQueryClient();
  const repo = useRepo();

  const mutation = useMutation(
    async () => {
      await repo.addStoresToTeam(selection.selectedIds, team.id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['team', team.id]);
        queryClient.invalidateQueries(['team-stores', team.id]);
        queryClient.invalidateQueries(['teams']);

        selection.selectedIds.forEach((storeId) => {
          queryClient.invalidateQueries(['store', storeId]);
          queryClient.invalidateQueries(['store-teams', storeId]);
        });
        queryClient.invalidateQueries(['stores']);

        broadcastSuccessToast(t('storeAdded', { count: selection.selectedIds.length }));

        handleBack();
      },
    }
  );

  const handleBack = () => history.goBack();
  const canSave = selection.selectedIds.length && !mutation.isLoading;
  const handleSave = () => {
    mutation.mutate();
  };

  return (
    <>
      <h2 className="text-xl font-semibold">{t('addStore', { count: 99 })}</h2>
      <div className="mt-4">
        <div className="w-96">
          <SearchInput {...filterInputProps} />
        </div>
      </div>
      <div className="mt-4 flex-grow">
        <Table>
          <Thead>
            <ThCheckbox
              onChange={selection.onSelectAllChange}
              checked={selection.isEntirePageSelected}
              disabled={!selection.isEntirePageSelectable}
            />
            <ThSortableWithSorting sortKey="name" sortingResult={sorting}>
              {t('name')}
            </ThSortableWithSorting>
            <Th>{t('teams')}</Th>
          </Thead>
          <Tbody>
            {query.isLoading ? (
              <Tr>
                <Td colSpan={3}>{t('loadingEllipsis')}</Td>
              </Tr>
            ) : null}
            {query.isSuccess && query.data
              ? query.data.items.map((store) => {
                  return (
                    <Tr key={store.id}>
                      <TdCheckbox
                        onChange={() => selection.onSelectionChange(store)}
                        checked={selection.isSelected(store)}
                        disabled={!selection.isSelectable(store)}
                      />
                      <TdPrimary>{store.name}</TdPrimary>
                      <Td>{store.item_count}</Td>
                    </Tr>
                  );
                })
              : null}
          </Tbody>
          {query.isSuccess && (query.data?.total || 0) > 0 ? (
            <TablePagination
              showingFrom={query.showingFrom}
              showingTo={query.showingTo}
              showingOfTotal={query.showingOfTotal}
              hasNextPage={query.hasNextPage}
              hasPreviousPage={query.hasPreviousPage}
              onNextPageClick={query.fetchNextPage}
              onPreviousPageClick={query.fetchPreviousPage}
            />
          ) : null}
        </Table>
      </div>
      <div className="w-full mt-2 flex flex-row-reverse items-end">
        <PrimaryButton disabled={!canSave} className="ml-3" onClick={handleSave}>
          {t('add')}
        </PrimaryButton>
        <Button onClick={handleBack}>{t('discard')}</Button>
      </div>
    </>
  );
};

const Header = () => {
  const { team } = useTeamPage();
  return <HeaderWithBack>{team.name}</HeaderWithBack>;
};

const TeamAddStoresPage = () => {
  return (
    <TeamPageFreeLayout header={<Header />}>
      <Content />
    </TeamPageFreeLayout>
  );
};

export default TeamAddStoresPage;
