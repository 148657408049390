import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react';
import { LocaleDefinition } from '../../lib/l10n';
import { classNames } from '../../lib/utils';

const ItemLocaleSelect: React.FC<{
  selected: string;
  locales: LocaleDefinition[];
  onChange: (value: string) => void;
}> = ({ selected, locales, onChange }) => {
  const locale = locales.find((locale) => locale.tag === selected)!;
  return (
    <Listbox value={selected} onChange={onChange}>
      <div className="relative inline-block">
        <Listbox.Button
          className={classNames(
            'flex items-center text-left relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 w-full rounded-full border border-gray-300 shadow-sm px-3 bg-white text-xs leading-snug hover:bg-gray-50',
            'text-gray-700 font-medium'
          )}
        >
          <span className="flex-grow block truncate">{locale.name}</span>
          <div className="flex-shrink-0 -mr-1 flex items-center divide-x divide-gray-300 divide-solid">
            <div className="inline-flex pl-1 flex-shrink-0">
              <ChevronDownIcon className={classNames('h-5 w-5', 'text-gray-500')} aria-hidden="true" />
            </div>
          </div>
        </Listbox.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className="absolute z-10 mt-2 w-56 bg-white shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
            {locales.map((locale) => (
              <Listbox.Option
                key={locale.tag}
                className={({ active }) =>
                  classNames(
                    'text-gray-700 select-none relative py-2 px-4',
                    active ? 'text-gray-900 bg-gray-100' : '',
                    selected ? 'pr-10' : ''
                  )
                }
                value={locale.tag}
              >
                {({ selected, active }) => (
                  <>
                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{locale.name}</span>

                    {selected ? (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <CheckIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default ItemLocaleSelect;
