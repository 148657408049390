import { createContext, useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import queryString from 'query-string';

const isAcceptableLocation = (url: string) => {
  return url !== '/' && !url.startsWith('/switch') && !url.startsWith('/logout');
};

const WantsUrlContext = createContext<{ wantsUrl: null | string; setWantsUrl: (url: string | null) => void }>({
  wantsUrl: null,
  setWantsUrl: (url) => {},
});

export const WantsUrlContextProvider: React.FC = ({ children }) => {
  const [wantsUrl, setWantsUrl] = useState<null | string>(null);
  const handleSetWantsUrl = (url: string | null) => {
    if (url && !isAcceptableLocation(url)) {
      return;
    } else if (url === wantsUrl) {
      return;
    }
    setWantsUrl(url);
  };
  return <WantsUrlContext.Provider value={{ wantsUrl, setWantsUrl: handleSetWantsUrl }}>{children}</WantsUrlContext.Provider>;
};

export const CatchWantsUrl: React.FC = () => {
  const location = useLocation();
  const { setWantsUrl } = useContext(WantsUrlContext);

  useEffect(() => {
    setWantsUrl(location.pathname + location.search);
  });

  return null;
};

export const CatchWantsUrlFromQueryString: React.FC = () => {
  const location = useLocation();
  const { to } = queryString.parse(location.search);
  const { setWantsUrl } = useContext(WantsUrlContext);

  useEffect(() => {
    if (typeof to !== 'string') return;
    setWantsUrl(to);
  }, [to]); // eslint-disable-line

  return null;
};

export const CatchWantsUrlAndRedirect: React.FC<{ to: string }> = ({ to }) => {
  const location = useLocation();
  const { setWantsUrl } = useContext(WantsUrlContext);

  useEffect(() => {
    setWantsUrl(location.pathname + location.search);
  });

  return location.pathname !== to ? <Redirect to={to} /> : null;
};

export const RedirectToWantsUrlOrChildren: React.FC = ({ children }) => {
  const { wantsUrl, setWantsUrl } = useContext(WantsUrlContext);

  useEffect(() => {
    setWantsUrl(null);
  });

  return <>{wantsUrl ? <Redirect to={wantsUrl} /> : children}</>;
};

export const SetWantsUrl: React.FC<{ to?: string }> = ({ to }) => {
  const { setWantsUrl } = useContext(WantsUrlContext);

  useEffect(() => {
    if (typeof to === 'undefined') return;
    setWantsUrl(to);
  });

  return null;
};
