import { format, getUnixTime, isSameMonth, isSameYear } from 'date-fns';

export const FIRST_DAY_OF_WEEK = 1; // Monday.

export function now() {
  return getUnixTime(new Date());
}

export function formatDateRange(start: Date, end: Date) {
  let formatLeft = 'd MMM yyyy';
  let formatRight = 'd MMM yyyy';
  let separator = ' - ';
  if (isSameYear(start, end)) {
    if (isSameMonth(start, end)) {
      formatLeft = 'd';
      separator = '-';
      formatRight = 'd MMM yyyy';
    } else {
      formatLeft = 'd MMM';
    }
  }
  return `${format(start, formatLeft)}${separator}${format(end, formatRight)}`;
}
