import copy from 'copy-to-clipboard';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AccountContext, useAccount } from '../../lib/account';
import { getEmbedUrlRoot } from '../../lib/env';
import { useFakeContinuatedQueryResult } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { Account, Permission } from '../../lib/types';
import { Button, DangerButton } from '../Buttons';
import Toggle from '../inputs/Toggle';
import { PageHeaderWrappingNav } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { ActionModal, ActionModalButtons, ConfirmModal } from '../Modals';
import TeamsNav from '../nav/TeamsNav';
import { HasAccountPermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import Coins from '../widgets/Coins';
import { Table, TablePagination, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';

const useAccountSvsLeaderboard = () => {
  const { id: accountId } = useAccount();
  const repo = useRepo();
  return useQuery(['svsleaderboard'], () => repo.getSvsLeaderboard(accountId), { staleTime: 60 * 1000 });
};

const useOptionsMutation = () => {
  const repo = useRepo();
  const { patchAccount } = useContext(AccountContext);
  const account = useAccount();
  return useMutation(
    (data: { enabled: boolean; anonymous: boolean }) => repo.updateAccountSvsLeaderboardOptions(account.id, data),
    {
      onMutate: (variables) => {
        const ctx = _.cloneDeep(account.svsleaderboard);
        patchAccount({ svsleaderboard: variables });
        return ctx;
      },
      onError: (err, variables, ctx: Account['svsleaderboard']) => {
        patchAccount({ svsleaderboard: ctx });
      },
    }
  );
};

const LeaderboardPage = () => {
  const { t } = useTranslation();
  const repo = useRepo();
  const account = useAccount();
  const queryClient = useQueryClient();
  const query = useFakeContinuatedQueryResult(useAccountSvsLeaderboard(), 20);
  const resetMutation = useMutation(() => repo.resetAccountSvsLeaderboard(account.id), {
    onSuccess: () => {
      broadcastSuccessToast(t('leaderboardHasBeenReset'));
      queryClient.invalidateQueries(['svsleaderboard']);
    },
  });

  const [showEmbed, setShowEmbed] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const optionsMutation = useOptionsMutation();

  const handleConfirmReset = () => {
    setShowReset(false);
    resetMutation.mutate();
  };

  const handleEmbedCopy = (snippet: string) => {
    setShowEmbed(false);
    copy(snippet);
  };

  const makeOptionChangeHander = (key: 'anonymous' | 'enabled') => (enabled?: boolean) => {
    optionsMutation.mutate({
      anonymous: Boolean(account.svsleaderboard?.anonymous),
      enabled: Boolean(account.svsleaderboard?.enabled),
      [key]: enabled,
    });
  };

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeaderWrappingNav title={t('teams')}>
          <TeamsNav />
        </PageHeaderWrappingNav>
        <HasAccountPermission perm={Permission.ManageLeaderboard}>
          <div className="border-b border-gray-100 pb-2 mb-4">
            <div className="my-4 flex items-center">
              <h2 className="text-xl leading-7 font-semibold flex-grow">{t('settings')}</h2>
              <div className="flex items-center space-x-3">
                {account.svsleaderboard?.enabled ? <Button onClick={() => setShowEmbed(true)}>{t('embedCode')}</Button> : null}
                <DangerButton onClick={() => setShowReset(true)}>{t('resetLeaderboard')}</DangerButton>
              </div>
            </div>
            <div className="my-4">
              <div className="flex space-x-10">
                <div>
                  <Toggle
                    enabled={account.svsleaderboard?.enabled}
                    onChange={makeOptionChangeHander('enabled')}
                    label={t('enabled')}
                  />
                </div>
                <div>
                  <Toggle
                    enabled={account.svsleaderboard?.anonymous}
                    onChange={makeOptionChangeHander('anonymous')}
                    label={t('anonymous')}
                  />
                </div>
              </div>
            </div>
          </div>
        </HasAccountPermission>
        <div>
          <h2 className="my-4 text-xl leading-7 font-semibold flex-grow">{t('ranking')}</h2>
          <Table>
            <Thead>
              <Th>{t('rank')}</Th>
              <Th>{t('name')}</Th>
              <Th>{t('score')}</Th>
            </Thead>
            <Tbody>
              {query.isLoading ? (
                <Tr>
                  <Td colSpan={3}>{t('loadingEllipsis')}</Td>
                </Tr>
              ) : null}
              {query.isSuccess && query.data ? (
                query.data.length ? (
                  query.data.map((entry) => (
                    <Tr>
                      <TdPrimary>{entry.rank}</TdPrimary>
                      <TdPrimary to={`/team/${entry.section.id}`}>{entry.section.name}</TdPrimary>
                      <Td>{entry.value.toLocaleString()}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={3}>{t('noEntriesEllipsis')}</Td>
                  </Tr>
                )
              ) : null}
            </Tbody>
          </Table>
          {query.isSuccess && (query.data?.length || 0) > 0 ? (
            <TablePagination
              showingFrom={query.showingFrom}
              showingTo={query.showingTo}
              showingOfTotal={query.showingOfTotal}
              hasNextPage={query.hasNextPage}
              hasPreviousPage={query.hasPreviousPage}
              onNextPageClick={query.fetchNextPage}
              onPreviousPageClick={query.fetchPreviousPage}
            />
          ) : null}
        </div>
      </div>

      {showReset ? (
        <ConfirmModal
          danger
          message={t('confirmResetLeaderboardThisCannotBeUndone')}
          title={t('resetLeaderboard')}
          onConfirm={handleConfirmReset}
          onCancel={() => setShowReset(false)}
        ></ConfirmModal>
      ) : null}

      {showEmbed ? <EmbedModal accountId={account.id} onClose={() => setShowEmbed(false)} onCopy={handleEmbedCopy} /> : null}
    </LayoutWithSidebar>
  );
};

const EmbedModal = ({
  accountId,
  onCopy,
  onClose,
}: {
  accountId: string;
  onClose: () => void;
  onCopy: (snippet: string) => void;
}) => {
  const { t } = useTranslation();
  const iframeUrl = `${getEmbedUrlRoot()}/leaderboard?account=${accountId}`;
  const snippet = `<iframe src="${iframeUrl}" style="border: 0; width: 100%; height: 400px;"></iframe>`;
  return (
    <ActionModal title={t('embedCode')} onCancel={onClose}>
      <>
        <div>
          <p className="text-sm">{t('copyPasteToEmbedLeaderboard')}</p>
          <div className="w-full mt-2">
            <textarea
              readOnly
              className="w-full px-2 py-2 rounded border border-gray-300 shadow-sm text-sm h-24"
              onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => {
                (e.target as HTMLTextAreaElement).select();
                e.preventDefault();
              }}
              onFocus={(e) => {
                e.target.select();
                e.preventDefault();
              }}
              value={snippet}
            ></textarea>
          </div>
        </div>
        <ActionModalButtons onCancel={onClose} confirmButtonText={t('copyCode')} onConfirm={() => onCopy(snippet)} />
      </>
    </ActionModal>
  );
};

export default LeaderboardPage;
