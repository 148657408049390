import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { QueryKey, QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query';
import { useAccount } from '../../lib/account';
import { getErrorMessage } from '../../lib/i18n';
import { useRepo } from '../../lib/repository';
import { Account, Permission } from '../../lib/types';
import { Button } from '../Buttons';
import AccountSettingsPageLayout from '../layouts/AccountSettingsPage';
import { ConfirmModal } from '../Modals';
import Notification, { NotificationError } from '../Notifications';
import { HasAccountPermission } from '../Permissions';
import { HasPolicy } from '../Policy';

const Section: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <div className="border-b border-gray-100 mb-8 pb-8">
      <h2 className="text-lg font-medium mb-4">{title}</h2>
      <div className="mt-2">{children}</div>
    </div>
  );
};

const AccountDeveloperPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const account = useAccount();

  return (
    <AccountSettingsPageLayout>
      <div className="flex-grow">
        <HasAccountPermission perm={Permission.ManageApi}>
          <HasPolicy policy="use_api">
            <ApiKeySection account={account} />
            <WebhookPortalSection account={account} />
          </HasPolicy>
        </HasAccountPermission>
      </div>
    </AccountSettingsPageLayout>
  );
};

const WebhookPortalSection: React.FC<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation();
  const repo = useRepo();
  const mutation = useMutation(
    async () => {
      return repo.createAccountWebhookPortalSession(account.id);
    },
    {
      onSuccess: (data) => {
        window.open(data.url, '_blank');
      },
    }
  );

  const handlePortalClick = () => {
    mutation.mutate();
  };

  return (
    <Section title={t('webhooks')}>
      {mutation.isError ? (
        <div className="my-4">
          <NotificationError>{getErrorMessage(mutation.error)}</NotificationError>
        </div>
      ) : null}
      <p className="my-4 max-w-xl">
        <Trans
          i18nKey="webhookSvixHelp"
          t={t}
          components={[
            <a
              className="text-blue-800"
              href="https://docs.svix.com/receiving/introduction"
              rel="nopener noreferrer"
              target="_blank"
            />,
          ]}
        />
      </p>
      <Button large onClick={handlePortalClick} disabled={mutation.isLoading}>
        {t('webhookPortal')}
      </Button>
    </Section>
  );
};

type TokenData = { apitoken: string; apitoken_hint: string; endpoint: string };

const ApiKeySection: React.FC<{ account: Account }> = ({ account }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [setConfirmRegen, setShowConfirmRegen] = useState(false);

  const queryKey: QueryKey = ['account-api-token', account.id];
  const repo = useRepo();
  const mutation = useMutation<TokenData | null, unknown, void>(() => repo.getApiToken(account.id, true), {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey, { exact: true, refetchActive: false });
    },
  });
  const query = useQuery(queryKey, () => repo.getApiToken(account.id), {
    enabled: mutation.isIdle && Boolean(account.policy['use_api']),
    cacheTime: 1000 * 3,
  });

  const hasExistingToken = (query.data && query.data.endpoint) || mutation.isSuccess;
  const canGenerate = query.isSuccess;

  const handleGenerate = () => {
    if (!canGenerate) {
      return;
    }
    if (hasExistingToken) {
      setShowConfirmRegen(true);
    } else {
      mutation.mutate();
    }
  };

  const handleRegenerate = () => {
    setShowConfirmRegen(false);
    mutation.mutate();
  };

  return (
    <Section title={t('apiKey')}>
      <ApiKeyContent query={query} mutation={mutation} account={account} />
      <div className="mt-4">
        <Button large onClick={handleGenerate} disabled={!canGenerate || mutation.isLoading}>
          {hasExistingToken ? t('regenerate') : t('generate')}
        </Button>
      </div>
      {setConfirmRegen ? (
        <ConfirmModal
          title={t('areYouSure')}
          message={t('confirmRotatingApiKey')}
          onConfirm={handleRegenerate}
          onCancel={() => setShowConfirmRegen(false)}
        />
      ) : null}
    </Section>
  );
};

const ApiKeyContent: React.FC<{
  account: Account;
  query: QueryObserverResult<null | TokenData>;
  mutation: any;
}> = ({ account, query, mutation }) => {
  const { t } = useTranslation();
  if (query.isLoading || mutation.isLoading) {
    return <>{t('loadingEllipsis')}</>;
  } else if (!query.isSuccess) {
    return <NotificationError>{t('error.unknown')}</NotificationError>;
  }

  let apiToken = query.data;
  let isNew = false;
  if (mutation.isSuccess) {
    apiToken = mutation.data as TokenData | null;
    isNew = true;
  }

  if (apiToken?.endpoint) {
    return (
      <>
        {isNew ? (
          <div className="my-4">
            <Notification type="warning">{t('takeNoteOfApiKeyAsWontBeVisible')}</Notification>
          </div>
        ) : null}
        <table className="w-auto">
          <tbody>
            <tr>
              <th className="pb-2 text-left">{t('accountId')}</th>
              <td className="pb-2 pl-4">{account.id}</td>
            </tr>
            <tr>
              <th className="pb-2 text-left">{t('apiKey')}</th>
              <td className="pb-2 pl-4">{apiToken.apitoken || apiToken.apitoken_hint || '*****'}</td>
            </tr>
            <tr>
              <th className="pb-2 text-left">{t('apiEndpoint')}</th>
              <td className="pb-2 pl-4">{apiToken.endpoint}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  return null;
};

export default AccountDeveloperPage;
