import { fromUnixTime } from 'date-fns';
import { isUndefined } from 'lodash';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { usePermissionChecker } from '../../lib/hooks';
import { isItemEditable } from '../../lib/item';
import { useProductDeleteMutation, useProductDuplicateMutation } from '../../lib/mutations';
import { useContinuatedQuery, useProduct, useStoreName } from '../../lib/queries';
import { useRepo } from '../../lib/repository';
import { ItemType, Permission, Product, RedemptionMethod } from '../../lib/types';
import { getRedemptionMethodStringKey } from '../../lib/utils';
import { Fullscreenable } from '../layouts/Fullscreen';
import { PageHeaderWithBackLabel } from '../layouts/partials/PageHeaders';
import LayoutWithSidebar from '../layouts/WithSidebar';
import { ConfirmModal, DuplicateModal } from '../Modals';
import { HasStorePermission } from '../Permissions';
import { broadcastSuccessToast } from '../Toasts';
import { TransactionFiltersContext, TransactionsFiltersProvider } from '../transactions/Filters';
import Coins from '../widgets/Coins';
import Dropdown from '../widgets/Dropdown';
import { Table, Tbody, Td, TdPrimary, Th, Thead, Tr } from '../widgets/Table';
import { ItemStatus, ItemTransactions, TimeRemainingInfo } from './Item';
import nl2br from 'react-nl2br';

const useProductItems = (productId: string, isEnabled: boolean) => {
  const repo = useRepo();
  return useQuery(
    ['product-items', productId],
    () => {
      return repo.getProductItems(productId);
    },
    { enabled: isEnabled }
  );
};

const LabelInfo: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className="flex">
      <div className="inline-block font-semibold whitespace-nowrap">{label}</div>
      <div className="inline-block ml-2">{children}</div>
    </div>
  );
};

export const useProductActions = (item?: Product, options: { page: 'edit' | 'view' } = { page: 'view' }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const deleteMutation = useProductDeleteMutation();
  const duplicateMutation = useProductDuplicateMutation();
  const permChecker = usePermissionChecker();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();

  const handleDelete = () => {
    if (!item || deleteMutation.isLoading) return;
    deleteMutation.mutate(
      { productId: item.id, storeId: item.store_id },
      {
        onSuccess: () => {
          broadcastSuccessToast(t('itemDeleted'));
          item.variants.forEach((variant) => {
            queryClient.invalidateQueries(['item', variant.item_id]);
          });
          history.push(`/store/${item.store_id}`);
        },
        onSettled: () => {
          setShowDeleteModal(false);
        },
      }
    );
  };

  const handleDuplicate = (name: string) => {
    if (!item || duplicateMutation.isLoading) return;
    duplicateMutation.mutate(
      { productId: item.id, name },
      {
        onSuccess: (data) => {
          broadcastSuccessToast(t('itemDuplicated'));
          history.push(`/product/${data.id}`);
        },
        onSettled: () => {
          setShowDuplicateModal(false);
        },
      }
    );
  };

  type Action = { can?: boolean; danger?: boolean; disabled?: boolean; label: string; onClick: () => void };
  const actions: Action[] = item
    ? ([
        options.page === 'view'
          ? {
              label: t('edit'),
              can: permChecker.hasStorePermission(item.store_id, Permission.UpdateItem),
              onClick: () => history.push(`/product/${item.id}/edit`),
              disabled: !isItemEditable(item),
            }
          : null,
        options.page === 'edit'
          ? {
              label: t('viewDetails'),
              can: permChecker.hasStorePermission(item.store_id, Permission.ReadItem),
              onClick: () => history.push(`/product/${item.id}`),
            }
          : null,
        {
          label: t('duplicate'),
          onClick: () => setShowDuplicateModal(true),
          can: permChecker.hasStorePermission(item.store_id, Permission.CreateItem),
        },
        {
          label: t('delete'),
          danger: true,
          onClick: () => setShowDeleteModal(true),
          can: permChecker.hasStorePermission(item.store_id, Permission.DeleteItem),
        },
      ]
        .filter(Boolean)
        .filter((action) => isUndefined((action as Action).can) || (action as Action).can) as Action[])
    : [];

  return {
    actions: actions,
    renderActions: () => {
      return (
        <>
          {showDuplicateModal ? (
            <DuplicateModal
              onConfirm={handleDuplicate}
              onCancel={() => setShowDuplicateModal(false)}
              baseName={item?.name || 'unknown'}
            />
          ) : null}

          {showDeleteModal ? (
            <ConfirmModal
              onConfirm={handleDelete}
              onCancel={() => setShowDeleteModal(false)}
              title={t('deleteItem', { count: 1 })}
              message={<Trans t={t} i18nKey="deleteItemConfirm" count={1} />}
              confirmButtonText={t('delete')}
            />
          ) : null}
        </>
      );
    },
  };
};

const ProductPage = () => {
  const { t } = useTranslation();
  const { productId } = useParams<{ productId: string }>();
  const { isLoading, data: item } = useProduct(productId);
  const variantsQuery = useProductItems(productId, Boolean(item));
  const { actions, renderActions } = useProductActions(item);
  const storeName = useStoreName(item?.store_id);

  return (
    <LayoutWithSidebar>
      <div>
        <PageHeaderWithBackLabel
          backTo={item ? `/store/${item.store_id}` : undefined}
          backLabel={storeName}
          buttons={item && actions.length ? <Dropdown right options={actions} label={t('actions')} /> : null}
        >
          {item?.name}
        </PageHeaderWithBackLabel>
        {isLoading || !item ? null : (
          <>
            <div className="flex">
              <div className="mr-6 shrink-0">
                <img src={item.image_url} alt="" role="presentation" className="w-48 border border-gray-300 rounded-lg" />
              </div>
              <div className="space-y-1">
                <div className="mb-4">{nl2br(item.description)}</div>
                <LabelInfo label={t('status')}>
                  <ItemStatus item={item} />
                </LabelInfo>
                <LabelInfo label={t('cost')}>
                  <Coins amount={item.cost} />
                </LabelInfo>

                <LabelInfo label={t('redemptionMethod')}>{t(getRedemptionMethodStringKey(item.redemption_method))}</LabelInfo>
                {item.redemption_method === RedemptionMethod.Self ? (
                  <LabelInfo label={t('messageAfterRedemption')}>{item.self_redeem_message}</LabelInfo>
                ) : null}

                <LabelInfo label={t('startDate')}>{fromUnixTime(item.start_time).toLocaleString()}</LabelInfo>
                <LabelInfo label={t('endDate')}>{fromUnixTime(item.end_time).toLocaleString()}</LabelInfo>
                <TimeRemainingInfo item={item} />
              </div>
            </div>

            <div className="my-6">
              <h2 className="font-semibold text-xl leading-8 mb-3">{t('variants')}</h2>
              <Table>
                <Thead>
                  <Th>{t('variantLabel')}</Th>
                  <Th>{t('sku')}</Th>
                  <Th>
                    {t('purchases')} / {t('items')}
                  </Th>
                </Thead>
                <Tbody>
                  {variantsQuery.isLoading || !variantsQuery.data ? (
                    <Tr key={'loading'}>
                      <Td colSpan={3}>{t('loadingEllipsis')}</Td>
                    </Tr>
                  ) : (
                    variantsQuery.data.map((item, idx) => (
                      <Tr key={idx}>
                        <TdPrimary to={`/item/${item.id}`}>{item.product_variant || '?'}</TdPrimary>
                        <Td>{item.sku || ''}</Td>
                        <Td>
                          {item.num_purchased} / {item.num_items <= 0 ? t('unlimited') : item.num_items}
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </div>

            <HasStorePermission storeId={item.store_id} perm={Permission.ReadTransaction}>
              <div className="mb-6">
                <TransactionsFiltersProvider>
                  <ProductTransactionsContainer product={item} />
                </TransactionsFiltersProvider>
              </div>
            </HasStorePermission>
          </>
        )}
        {renderActions()}
      </div>
    </LayoutWithSidebar>
  );
};

const useProductTransactions = (
  productId: string,
  filters?: {
    type?: string;
    details?: string;
    dateFrom?: number;
    dateTo?: number;
  },
  orderBy?: string
) => {
  const repo = useRepo();
  const results = useContinuatedQuery(
    ['product-transactions', productId, filters, orderBy],
    ({ pageParam }) => {
      return repo.getProductTransactions(productId, filters, orderBy, 10, pageParam);
    },
    {
      keepPreviousData: true,
      pageSize: 10,
      resetPageDependencies: [productId, orderBy, ...Object.values(filters || {})],
    }
  );

  return results;
};

const ProductTransactionsContainer: React.FC<{ product: Pick<Product, 'id' | 'redemption_method'> }> = ({ children, product }) => {
  const { timestampFrom, timestampTo, type, details } = useContext(TransactionFiltersContext);
  const query = useProductTransactions(product.id, {
    type: type,
    details: details,
    dateFrom: timestampFrom,
    dateTo: timestampTo,
  });

  return (
    <Fullscreenable>
      <ItemTransactions queryResult={query} itemType={ItemType.Purchase} redemptionMethod={product.redemption_method}>
        {children}
      </ItemTransactions>
    </Fullscreenable>
  );
};

export default ProductPage;
